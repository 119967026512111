<div class="container-fluid social-media" ngClass.lt-md="mobile">
  <div class="zupper-footer-social-media container">
    <p>Fique por dentro - @ZupperViagens</p>
    <div class="social-links">
      <ng-container *ngFor="let link of socialLinks; let last = last">
        <a class="social-link" [href]="link.url" target="_blank">
          <img [src]="link.iconUrl" class="social-link-icon">
          <span>{{link.name}}</span>
        </a>
        <div *ngIf="!last" class="divider"></div>
      </ng-container>
    </div>
  </div>
</div>
