
<div class="slider">
    <h6>Ida - Ida para {{filterDuration[0].originAirport}}</h6>
    <ng5-slider [(value)]="minValueOne" [(highValue)]="maxValueOne" [options]="optionsOne"
        (valueChange)="onMinChangeOne($event)" (highValueChange)="onMaxChangeOne($event)"></ng5-slider>
</div>
<div *ngIf="filterDuration[1]" class="slider">
    <h6>Volta - Volta para {{filterDuration[0].originAirport}}</h6>
    <ng5-slider [(value)]="minValueTwo" [(highValue)]="maxValueTwo" [options]="optionsTwo"
        (valueChange)="onMinChangeTwo($event)" (highValueChange)="onMaxChangeTwo($event)"></ng5-slider>
</div>
