<div class="alert-multi-airline">
    <span class="icon">
        <i class="fas fa-exclamation-circle"></i>
    </span>
    <p>
        Voos de Ida e Volta por <strong>Cias diferentes</strong>. Com isso, os bilhetes serão emitidos separados.
        <span class="tooltips">Saber mais
            <span class="tooltiptext">Ao comprar estas passagens saiba que alterações e cancelamentos dos voos serão gerenciados de forma separada.</span>
        </span>
    </p>
</div>
<aside class="price-container" [ngClass.gt-sm]="'with-shadow'">
    <header class="title" [ngClass]="{'best-price': bestPrice}">
        <ng-container *ngIf="bestPrice">
            <i class="fas fa-star"></i>
            <h4>Melhor Preço</h4>
        </ng-container>
        <ng-container *ngIf="!bestPrice">
            <h4>Valores desse voo</h4>
        </ng-container>
    </header>
    <footer>
        <div class="price-info">
            <p>Tarifa por Adulto
                <span>{{ this.fareByPaxType.Adult | currency : "R$"}}</span>
            </p>
            <p *ngIf="this.flightSearch.adultQty > 0">{{ this.flightSearch.adultQty }} {{ this.flightSearch.adultQty > 1
                ? 'Adultos' : 'Adulto'}} <span>{{ this.flightSearch.adultQty * this.fareByPaxType.Adult | currency :
                    "R$" }}</span></p>
            <p *ngIf="this.flightSearch.childrenQty > 0">{{ this.flightSearch.childrenQty }} Criança <span>{{
                    this.flightSearch.childrenQty * this.fareByPaxType.Child | currency : "R$" }}</span></p>
            <p *ngIf="this.flightSearch.infantQty > 0">{{ this.flightSearch.infantQty }} Bebê <span>{{
                    this.flightSearch.infantQty * this.fareByPaxType.Infant | currency : "R$" }}</span></p>
            <p>Taxas e impostos <span>R$ {{this.getTaxes | number: '1.2-2'}}</span></p>
            <p>Total da viagem<span data-zt="totalPrice">{{ this.chosenFlight.totalPrice | currency : "R$" }}</span></p>
        </div>
        <div class="send-slice" *ngIf="this.tripType != this.flightTypes.MultiSlice">
            <zc-installment [maxInstallments]="maxAllowedInstallment" [hasCardTax]="hasCardTax"></zc-installment>

            <button data-zt="buyButtonToCheckout" (click)="sendSlicesToCheckout()">Quero esse VOO</button>
        </div>
    </footer>
</aside>