import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UncombinedResultComponent } from './uncombined-result.component';
import { ResultListModule } from '../result-list/result-list.module';
import { ResultFilterModule } from '../result-filter/result-filter.module';
import { PriceMatrixModule } from '../price-matrix/price-matrix.module';
import { PriceFilterPipe } from '../../../pipes/price.filter.pipe';
import { StopsFilterPipe } from '../../../pipes/stops.filter.pipe';
import { AirlinesFilterPipe } from '../../../pipes/airlines.filter.pipe';
import { AirportsFilterPipe } from '../../../pipes/airports.filter.pipe';
import { BaggageFilterPipe } from '../../../pipes/baggage.filter.pipe';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { PriceRangeFilterPipe } from '../../../pipes/price-range.filter.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TimeDepartureFilterPipe } from '../../../pipes/time-departure.filter.pipe';
import { TimeArrivalFilterPipe } from '../../../pipes/time-arrival.filter.pipe';
import { DurationFilterPipe } from '../../../pipes/duration.filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SliceFilterPipe } from '../../../pipes/slice.filter.pipe';
import { ModalVoidSearchComponent, ZupperCommonModule } from '@zupper/common';

@NgModule({
  declarations: [
    UncombinedResultComponent,
    PriceFilterPipe,
    StopsFilterPipe,
    AirlinesFilterPipe,
    AirportsFilterPipe,
    BaggageFilterPipe,
    PriceRangeFilterPipe,
    TimeDepartureFilterPipe,
    TimeArrivalFilterPipe,
    DurationFilterPipe,
    SliceFilterPipe
  ],
  imports: [
    CommonModule,
    ResultListModule,
    ResultFilterModule,
    PriceMatrixModule,
    NgxUiLoaderModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    ZupperCommonModule
  ],
  exports: [
    UncombinedResultComponent,
    PriceFilterPipe,
    StopsFilterPipe,
    AirlinesFilterPipe,
    AirportsFilterPipe,
    BaggageFilterPipe,
    PriceRangeFilterPipe,
    TimeDepartureFilterPipe,
    TimeArrivalFilterPipe,
    DurationFilterPipe,
    SliceFilterPipe
  ],
  entryComponents: [
    ModalVoidSearchComponent
  ]
})
export class UncombinedResultModule { }
