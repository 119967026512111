import { Injectable } from '@angular/core';
import { AirlineModel } from '@zupper/data';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AirlineService {
  private static _airlines = new Set<AirlineModel>();

  static push(item: AirlineModel) {
    this._airlines.add(item);
  }

  static getAll(): AirlineModel[] {
    return Array.from(this._airlines.values());
  }

  static getInfoFromIata(iata: string) {
    const arr = Array.from(this._airlines.values());
    return arr.find(a => a.iataCode === iata);
  }

  static getNameFromIata(iata: string): string {
    const arr = Array.from(this._airlines.values());

    const found = _.find(arr, item => item.iataCode === iata);
    if (found) {
      return found.name;
    }

    return null;
  }
}
