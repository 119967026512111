<section *ngIf="isMobile ? whatsappTag?.mobile : whatsappTag?.desktop" class="whatsapp-container">
    <zupper-common-whatsapp-tag [configuration]="whatsappTag"></zupper-common-whatsapp-tag>
</section>
<header class="produtos" [ngStyle]="{ 'background-color': menuColor }">
    <section class="widthPadrao" [class.show-whatsapp-tag]="isMobile ? whatsappTag?.mobile : whatsappTag?.desktop">
        <section class="produtos-container" ngClass.lt-md="produtos-container-mobile">
            <a [href]="product.url ?? '#'" class="itemProduto"
                [class.itemProdutoActiveDestaque]="isSelectedProduct(product)" [class.no-hover]="!product.active"
                *ngFor="let product of products">
                <img [src]="product.image" *ngIf="product.image" [class]="product.badgeClass">

                <div [class]="product.class" data-zt="zupperProduct">
                    <div class="tituloProduto" [class.selecionado]="isSelectedProduct(product)"
                        [ngClass.lt-md]="{ 'destination-guide-mobile': product.product == zupperProduct.DESTINATION_GUIDE }">

                        <i *ngIf="!isMobile; else mobileScreen" [class.selecionado]="isSelectedProduct(product)"
                            class="fas {{ product.icon }} fa-2x"></i>

                        <ng-template #mobileScreen>
                            <img [class.selecionado]="isSelectedProduct(product)"
                                [src]="this[isSelectedProduct(product) ? 'productImageUrlsSelected' : 'productImageUrls'][product.product]"
                                alt="Product">
                        </ng-template>
                        
                        <span class="{{ product.badgeClass }}">{{ product.badgeText }}</span>
                        <label>{{ product.label }}</label>
                    </div>
                    <div *ngIf="isSelectedProduct(product)" class="itemProdutoActive"></div>
                </div>
            </a>
        </section>
    </section>
</header>
<!-- <section class="resumoCompra">
  <ng-container *ngTemplateOutlet="searchSummaryTpl"></ng-container>
</section> -->