import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation, HostListener } from '@angular/core';

@Component({
  selector: 'zp-button',
  templateUrl: './zp-button.component.html',
  styleUrls: ['./zp-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZupperButtonComponent implements OnInit {
  @Input() options: ZupperButtonOptions;
  @Input() disabled = false;

  @Output() onButtonClick = new EventEmitter<MouseEvent>();

  get buttonStyles(): any {
    if (!this.options) {
      return null;
    }

    const styles = {};
    if (this.options.backgroundColor || this.disabled) {
      styles['background-color'] = this.options.backgroundColor;
    }
    if (this.options.borderColor) {
      styles['border-color'] = this.options.borderColor;
    }

    return styles;
  }

  get labelStyles(): any {
    if (!this.options || this.disabled) {
      return null;
    }

    const styles = {};
    if (this.options.textColor) {
      styles['color'] = this.options.textColor;
    }

    return styles;
  }

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('click', ['$event'])
  buttonClick(ev: MouseEvent): void {
    if (!this.disabled) {
      this.onButtonClick.emit(ev);
    } else {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

}

export interface ZupperButtonOptions {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  size?: 'normal' | 'mini';
}
