<!-- side bar main wrapper -->
<div class="side-nav-bar header-side-menu"
    [ngClass]="{ 'side-nav-bar-collapsed': !(showMenu | async) }">

    <!-- overlay -->
    <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showMenu | async) }"
        (click)="onSidebarClose()"></div>

    <!-- side bar-->
    <div class="side-nav-bar-menu-container"
    [ngStyle]="getMenuStyle((showMenu | async))">

        <!-- close button -->
        <div fxLayoutAlign="space-between center" class="p-4">
          <img src="assets/img/zupper_logo.svg" class="header-menu-sidebar-logo">
          <i class="side-nav-bar-close fa fa-times" (click)="onSidebarClose()"></i>          
        </div>

        <!-- side bar content -->
        <div class="side-nav-bar-content-container">
            <ng-container *ngTemplateOutlet="templateRef"></ng-container>
        </div>
    </div>
</div>
