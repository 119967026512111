import { NgForm } from "@angular/forms";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
import moment from "moment";
import {
  RdstationNewsletterService,
  RdstationNewsletterModel,
  RdstationNewsletterPayload,
} from "@zupper/data";
import { SubSink } from "subsink";
import { LocalStorageService } from "../../../lib/storage/local-storage.service";

@Component({
  selector: "app-footer-contacts",
  templateUrl: "./footer-contacts.component.html",
  styleUrls: ["./footer-contacts.component.scss"],
})
export class FooterContactsComponent implements OnInit, OnDestroy {
  @ViewChild("newsletterSwal") private newsletterSwal: SwalComponent;
  email: string = null;
  isValid: boolean = true;

  private subs = new SubSink();

  constructor(
    private router: Router,
    private http: HttpClient,
    private converter: RdstationNewsletterService,
    private _localStorageService: LocalStorageService
  ) {}

  ngOnInit() {}

  gotoLink(url: string, target: string = "_blank") {
    this.router.navigate([]).then((result) => {
      window.open(url, target);
    });
  }

  getClass() {
    return this.isValid ? "inpRodape" : "inpRodapeInvalid";
  }

  contact_newsletter(url: string) {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.isValid = true;
      let newsletterModel = new RdstationNewsletterModel();
      newsletterModel.payload.email = form.value.email;
      let utm: any = {};

      let utmStorageData = false;
      const utmStorage = this._localStorageService.getItem<any>('utm_storage');
      if (utmStorage) {
        if (utmStorage.expiry && moment(utmStorage.expiry).isAfter(moment())) {
          utmStorageData = utmStorage.data;
        }
      }

      if (utmStorageData) {
        Object.assign(utm, utmStorageData);
        newsletterModel.payload.traffic_source = utm.utmSource;
        newsletterModel.payload.traffic_medium = utm.utmMedium;
        newsletterModel.payload.traffic_campaign = utm.utmCampaign;
      }
      let subscription = this.converter.send(newsletterModel).subscribe(response => {
        const data: SweetAlertOptions = {
          title: "Obrigado!",
          text: "Obrigado por se inscrever em nossa Newsletter.",
          icon: "success",
          confirmButtonText: "Ok",
        };
        this.newsletterSwal.update(data);
        this.newsletterSwal.fire();
        this.email = null;
      },
      error => {
         const data: SweetAlertOptions = {
           icon: "error",
           title: "Ooops!",
           text: "Não foi possível assinar a Newsletter.",
           showConfirmButton: true,
         };
         this.newsletterSwal.update(data);
         this.newsletterSwal.fire();
         this.isValid = false;
      });
      this.subs.add(subscription);
    } else {
      const data: SweetAlertOptions = {
        icon: "error",
        title: "Ooops!",
        text: "Insira um e-mail válido.",
        showConfirmButton: true,
      };
      this.newsletterSwal.update(data);
      this.newsletterSwal.fire();
      this.isValid = false;
      this.email = null;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
