import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultListItemComponent } from './result-list-item/result-list-item.component';
import { ResultListItemMultiSliceComponent } from './result-list-item-multi-slice/result-list-item-multi-slice.component';
import { FormsModule } from '@angular/forms';
import { ResultListPriceComponent } from './result-list-price/result-list-price.component';
import { ResultListFamilyComponent } from './result-list-family/result-list-family.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ZupperCommonModule } from '@zupper/common';
import { LuggageIconsModule } from '../luggage-icons/luggage-icons.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { NextDayTripModule } from '../next-day-trip/next-day-trip.module';

@NgModule({
  declarations: [
    ResultListItemComponent,
    ResultListItemMultiSliceComponent,
    ResultListPriceComponent,
    ResultListFamilyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    NgbModule,
    ZupperCommonModule,
    LuggageIconsModule,
    NextDayTripModule,
    PipesModule
  ],
  exports: [
    ResultListItemComponent,
    ResultListItemMultiSliceComponent,
    ResultListPriceComponent,
    ResultListFamilyComponent
  ]
})
export class ResultListModule { }
