<div class="zupper-login-wrapper" fxFlex fxLayout="column" fxLayout.gt-sm="row">
  <div class="zupper-login-left" [ngClass]="onClassHeaderMobile()">
    <h1 fxShow.gt-sm="true" fxHide>Por que <br>ter uma <br>conta <strong>na Zupper</strong>?</h1>
    <h1 fxShow.lt-md="true" fxHide>Por que ter uma conta <strong>na Zupper</strong>?</h1>
    <ul class="benefits">
      <li>
        <span class="icon">
          <img src="assets/img/dados-pagador.svg">
        </span>
        <span>Benefícios exclusivos</span>
        <hr>
      </li>
      <li>
        <span class="icon">
          <img src="assets/img/benefits-white.svg">
        </span>
        <span>Acompanhar pedidos</span>
        <hr>
      </li>
      <li>
        <span class="icon">
          <img src="assets/img/experiences-white.svg">
        </span>
        <span>Experiência de compra</span>
        <hr>
      </li>
    </ul>
  </div>
  <div class="zupper-login-right h-100" [ngClass]="onClassBodyMobile()">
    <div class="zupper-login-right-wrapper">
      <span class="zupper-login-close"></span>
      <header>
        <div class="go-back-mobile"><a (click)="goTo(steps.LOGIN_SELECTION)" *ngIf="currentStep != steps.LOGIN_SELECTION">&larr; Voltar</a></div>
        <img src="assets/img/logo_zupper.png" class="zupper-login-logo">
        <h3>Seja bem vindo(a)</h3>
      </header>
      <article class="login-steps">
        <ng-container *ngIf="showError">
          <section id="error-message" class="login-step">
            <app-message-display [messageType]="'error'" message="Ops... Algo deu errado" subtitle="Tente entrar novamente" buttonLabel="Voltar" (onButtonClick)="showError = false"></app-message-display>
          </section>
        </ng-container>
        <ng-container *ngIf="!showError">
          <ng-container *ngIf="currentStep === steps.LOGIN_SELECTION">
            <zc-login-selection (onEmailLogin)="goTo(steps.EMAIL)" (onGoogleLogin)="googleLogin()" (onFacebookLogin)="facebookLogin()" [isLoading]="loadingProvider"></zc-login-selection>
          </ng-container>
          <ng-container *ngIf="[steps.EMAIL, steps.PASSWORD].includes(currentStep)">
           <zc-email-login (onLoginSuccessful)="loginSuccessful()" [(currentStep)]="currentStep" (onForgotPassword)="forgotPassword()" (onSendPasswordCreationLink)="onSendPasswordCreationLink($event)"></zc-email-login>
          </ng-container>
          <ng-container *ngIf="currentStep === steps.SEND_PASSWORD_CREATION_LINK">
           <zc-send-password-creation-link [(currentStep)]="currentStep" [email]="email"></zc-send-password-creation-link>
          </ng-container>
        </ng-container>
      </article>
    </div>
  </div>
</div>
