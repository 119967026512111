import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { BaggageModel } from '../../../models/api-filter.model';

@Component({
  selector: 'app-baggage-filter',
  templateUrl: './baggage-filter.component.html',
  styleUrls: ['./baggage-filter.component.scss']
})
export class BaggageFilterComponent implements OnInit {
  @Input() clearAllFilters: boolean;
  @Input() allBaggages: BaggageModel;
  @Input() selectedBaggagesFilters: boolean[] = [];
  @Output() onFilterChangeBaggage = new EventEmitter<any>();
 
  formBaggageFilter = this._fb.group({
    baggage: this._fb.array([])
  })

  get baggageFilterArray(): FormArray {
    return this.formBaggageFilter.get('baggage') as FormArray;
  }
  public baggages = [0, 1];
  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.selectedBaggagesFilters.forEach((x, i) => {
      const formArray = this.formBaggageFilter.get('baggage') as FormArray;
      const isSelected = (x == true);
      formArray.push(new FormControl(isSelected));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      const formArray = this.baggageFilterArray;
      for (let i = 0; i < formArray.length; i++) {
        formArray.at(i).setValue(false);
      }
    }
  }

  sendFilterOptionBaggage(baggage, i) {
    const formArray = this.baggageFilterArray;
    formArray.at(i).setValue(!formArray.at(i).value);
    this.onFilterChangeBaggage.emit(formArray.value);
  }
}
