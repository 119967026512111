import { StopQtyPrice } from './../../../models/aerial-filters.model';
import { ModalVoidSearchComponent } from './../../modal-void-search/modal-void-search.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AerialSourceEnum } from './../../../services/enum/flights.enum';
import { CreditCardRulesModel } from "./../../../models/api-filter.model";
import { AirlineRules } from "@zupper/data";
import { Component, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ApiFlightType } from "@zupper/data";
import { AvailableFlightsRequest, AvailableFlightsResponse, DefaultResponseModel, SliceResponse, AerialCheckoutBuilderService, AerialCheckoutData, FlightSlicesModel, AirportService } from "@zupper/aerial-components";
import { ApiFilterModel } from "../../../models/api-filter.model";
import * as _ from "lodash";
import { AerialSearchManagerService } from '../../../services/aerial-search-manager.service';
import { EventEmitter } from 'events';
import { CheckoutPriceService } from "./../../../utils/checkout-price.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SubSink } from 'subsink';
import { DataLayerResultPageService } from '../../../services/data-layer-result-page.service';
import { StopPriceFilter } from '../../../models/aerial-filters.model';
import { AnalyticsService, AppCheckerService, CustomerLoginService, EnvironmentInterface, UrlHelper } from '@zupper/common';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { search } from 'voca';
import { ApiMSInsuranceService, StorageInsuranceService } from 'insurance-lib';
import moment from 'moment';

@Component({
  selector: "app-combined-result",
  templateUrl: "./combined-result.component.html",
  styleUrls: ["./combined-result.component.scss"],
})
export class CombinedResultComponent implements OnInit, OnDestroy {
  @Input() ciasResponse: DefaultResponseModel[][] = [];
  @Input() flightsSearchFields: AvailableFlightsRequest = new AvailableFlightsRequest();
  @Input() isInternational: boolean = false;
  @Output() buyEvent = new EventEmitter();

  flightResponse: AvailableFlightsResponse[][] = [];
  filterMeta: ApiFilterModel[][] = [];
  chosenSliceList: SliceResponse[] = [];
  chosenFlightList: AvailableFlightsResponse[] = [];
  chosenAirlineRules: AirlineRules[] = [];
  bestPrice: Array<number> = [];
  sliceTotalTaxes: Array<number> = [];
  paxTypes = { ADT: "Adult", CHD: "Child", INF: "Infant" };
  showFamilies: boolean = false;
  bestsPricesBySlices: StopPriceFilter[] = [];

  maxAllowedInstallment: number = 1;
  hasCardTax: boolean = false;
  loading: boolean = false;

  private subs = new SubSink();
  protected _isInsuranceRequired: boolean = false;
  protected _requiredCountries: string[] = [];
  protected _airportCountries: string[] = [];
  insuranceProducts: any;
  hasProductsInsurance: any;
  resultsInsurance: any = [];
  private _airportCountriesVerification: any = [];


  constructor(
    private appCheckerService: AppCheckerService,
    private service: AerialSearchManagerService,
    private priceService: CheckoutPriceService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private dataLayerService: DataLayerResultPageService,
    private urlHelper: UrlHelper,
    private aerialCheckoutBuilder: AerialCheckoutBuilderService,
    @Inject('env') private _env: EnvironmentInterface,
    private customerLoginService: CustomerLoginService,
    private _mediaObserver: MediaObserver,
    protected _apiMSInsuranceService: ApiMSInsuranceService,
    private insuranceStorageService: StorageInsuranceService
  ) { }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.loading = true;
    this.flightsSearchFields.slices.forEach((sliceFlight, sliceIndex) => {
      this.doFlightSearch(sliceIndex);
    });
  }

  doFlightSearch(flightSliceIndex: number, currentDate = null) {
    this.isInternational = JSON.parse(
      sessionStorage.getItem("isInternational")
    );

    let cias = [];
    Object.values(AerialSourceEnum).forEach((cia) => {
      cias.push(cia);
    });

    const customerSessionId = this.customerLoginService.getCustomerSessionId();

    if (customerSessionId) {
      this.flightsSearchFields.customerSessionId = customerSessionId;
    }


    this.flightsSearchFields.type = ApiFlightType.OneWay;
    if (this.isInternational) {
      this.flightsSearchFields.grouped = true;
      this.flightsSearchFields.groupedByOptions = false;
    } else {
      this.flightsSearchFields.grouped = false;
      this.flightsSearchFields.groupedByOptions = false;
      cias = cias.filter((cia) => {
        return cia != "Sabre";
      }).sort();
    }

    this.flightsSearchFields.grouped = false;
    this.flightsSearchFields.groupedByOptions = false;

    this.filterMeta[flightSliceIndex] = [];
    this.flightResponse[flightSliceIndex] = [];

    let totalSearchCount = 0;
    let returnedSearchCount = 0;
    let responseCount: number = 0;

    const observables = [] as Observable<DefaultResponseModel>[];

    cias.forEach((source, index) => {
      totalSearchCount++;
      this.flightsSearchFields.airlines = [];
      this.flightsSearchFields.airlines.push(source);
      let searchFieldSlice: AvailableFlightsRequest = { ...this.flightsSearchFields };

      let flightSlice = this.flightsSearchFields.slices[flightSliceIndex];
      if (currentDate !== null) {
        flightSlice.departureDate = currentDate;
      }

      searchFieldSlice.slices = [];
      searchFieldSlice.slices.push(flightSlice);

      observables.push(
        this.service.availableFlights(searchFieldSlice)
          .pipe(
            tap((data) => {
              if (data.result.length > 0) {
                this.resultsInsurance.push(data);
              }


              responseCount++;
              if (responseCount == cias.length) {
                this.loading = false;
                this.ngxLoader.stop();
              }
              this.chosenAirlineRules = data.meta?.airlineRules;

              if (!_.isEmpty(data.result)) {
                this.chosenAirlineRules = data.meta?.airlineRules;
                this.filterMeta[flightSliceIndex][
                  data.result[0].slices[0].source
                ] = data.meta;
                var response: AvailableFlightsResponse[][] = [];
                response[flightSliceIndex] = data.result;

                this.flightResponse[flightSliceIndex] = this.flightResponse[
                  flightSliceIndex
                ].concat(response[flightSliceIndex]);
                this.flightResponse[flightSliceIndex] = this.flightResponse[
                  flightSliceIndex
                ].sort((a, b) => (a.totalPrice >= b.totalPrice ? 1 : -1));

                this.chosenSliceList[flightSliceIndex] = this.flightResponse[flightSliceIndex][0].slices[0];
                this.chosenFlightList[flightSliceIndex] = this.flightResponse[flightSliceIndex][0];

                this.setBestPrice(flightSliceIndex, this.flightResponse[flightSliceIndex][0].totalPrice);

                for (const i of Object.keys(data.meta.airports)) {
                  if (this.chosenSliceList[flightSliceIndex].destination == data.meta.airports[i]['IATACode']) {
                    this._airportCountries.push(data.meta.airports[i]['country']);
                    this._airportCountriesVerification.push(data.meta.airports[i]['isoCode']);
                  }
                }
              } else {
                if (
                  responseCount == cias.length &&
                  this.flightResponse.length == 0
                ) {
                  const modal = this.modalService.open(ModalVoidSearchComponent, {
                    size: "lg",
                    windowClass: "custom-class",
                  });

                  modal.result.then(
                    (formData) => this.redirectToHomePage(),
                    () => { }
                  );
                }
              }
            })
          )
      );
    });

    forkJoin(observables).subscribe(
      () => {
        this._processInstallments();

      },
      (error: any) => {
        return throwError(() => error.error);
      },
      () => {
        this.loading = false;
        this.ngxLoader.stop();
        if (flightSliceIndex == 1) {
          this.setBestsPricesBySlices(this.chosenFlightList);
          this.dataLayerService.load("Aerial", this.flightsSearchFields, this.bestsPricesBySlices);
        }
        this._getInsurerRequiredCountries();
      }
    );
  }

  redirectToHomePage() {
    this.modalService.dismissAll();
    this.router.navigate(["/"]);
  }

  setBestPrice(sliceIndex: number, farePrice: number) {
    if (!this.bestPrice[sliceIndex] || this.bestPrice[sliceIndex] > farePrice) {
      this.bestPrice[sliceIndex] = farePrice;
    }
  }

  getBestPrice(sliceIndex: number) {
    if (!this.bestPrice[sliceIndex]) {
      return false;
    }
    return this.bestPrice[sliceIndex];
  }

  sendSlicesToCheckout() {
    var flightDetails = {
      credentialName: "",
      international: true,
      totalAirportTaxesPrice: 0,
      totalDu2Price: 0,
      totalDuPrice: 0,
      totalFarePrice: 0,
      totalIncentiveValue: 0,
      totalPrice: 0,
    };
    var allSlices: any[] = [];
    this.chosenFlightList.forEach((flight) => {
      allSlices = allSlices.concat(flight.slices);
      flightDetails.credentialName = flight.credentialName;
      flightDetails.international = flight.international;
      flightDetails.totalAirportTaxesPrice = flight.totalAirportTaxesPrice;
      flightDetails.totalDu2Price = flight.totalDu2Price;
      flightDetails.totalDuPrice = flight.totalDuPrice;
      flightDetails.totalFarePrice = flight.totalFarePrice;
      flightDetails.totalIncentiveValue = flight.totalIncentiveValue;
      flightDetails.totalPrice = flight.totalPrice;
    });

    const new_array = {
      meta: {
        request: {
          childrenQty: this.flightsSearchFields.childrenQty,
          infantQty: this.flightsSearchFields.infantQty,
          adultQty: this.flightsSearchFields.adultQty,
        },
      },
      result: [
        {
          credentialName: flightDetails.credentialName,
          currency: "BRL",
          desktopDeepLink: "",
          international: flightDetails.international,
          phoneDeepLink: "",
          searchId: "",
          slices: allSlices,
          tabletDeepLink: "",
          totalAirportTaxesPrice: flightDetails.totalAirportTaxesPrice,
          totalDu2Price: flightDetails.totalDu2Price,
          totalDuPrice: flightDetails.totalDuPrice,
          totalFarePrice: flightDetails.totalFarePrice,
          totalIncentiveValue: flightDetails.totalIncentiveValue,
          totalPrice: flightDetails.totalPrice,
          type: ApiFlightType.OneWay,
        },
      ],
    };

    const checkoutData: AerialCheckoutData = {
      meta: {
        request: {
          childrenQty: this.flightsSearchFields.childrenQty,
          infantQty: this.flightsSearchFields.infantQty,
          adultQty: this.flightsSearchFields.adultQty,
        },
      },
      result: this.chosenFlightList,
    };

    // this.priceService.setCheckoutParam(new_array);
    // this.urlHelper.openRouteInNewWindow(["/carrinho"]);
    const cid = this.aerialCheckoutBuilder.saveCheckoutData(checkoutData);
    let openBrowser = this.appCheckerService.isApp() ? "_self" : "_blank";
    window.open(this._env.checkout.url + '?cid=' + cid, openBrowser);
  }

  saveChosenSlice(flight: AvailableFlightsResponse, sliceIndex: number) {
    this.chosenFlightList[sliceIndex] = flight;
    this.chosenSliceList[sliceIndex] = flight.slices[0];
    this._processInstallments();
  }

  getAirportInfo(
    sliceIndex: number,
    slice: SliceResponse,
    destination?: boolean
  ) {
    let iataCode = "";
    destination ? (iataCode = slice.destination) : (iataCode = slice.origin);
    return <ApiFilterModel>(
      this.filterMeta[sliceIndex][slice.source].airports[iataCode].city.concat(
        " (",
        iataCode,
        ")"
      )
    );
  }

  getCityAndAirportText(
    slice: FlightSlicesModel,
    destination: boolean = false
  ): string {
    const city = destination ? slice.destinationCity : slice.originCity;
    const airport = destination ? slice.destinationAirport : slice.originAirport;
    return `${city} (${airport})`;
  }

  departureFlights(responseIndex: number, index: number) {
    // let slices: SliceResponse[] = [];
    // this.flightResponse[responseIndex].flights[index].forEach(flight => {
    //   flight.slices.forEach(slice => {
    //     slice.direction == 'going' || slice.direction == 'trip_1' ? slices.push(slice) : null;
    //   })
    // });
    // // Remove slices duplicados
    // slices = slices.filter((elem, index, self) => self.findIndex(
    //   (t) => { return (t.segments[0].aircraft === elem.segments[0].aircraft) }) === index);
    // return slices;
  }

  getCurrentDate(sliceIndex: number) {
    return new Date(this.flightsSearchFields.slices[sliceIndex].departureDate);
  }

  getCurrentFlightSearchFields(sliceIndex: number) {
    let search: AvailableFlightsRequest = { ...this.flightsSearchFields };
    search.slices = [];
    search.slices.push(this.flightsSearchFields.slices[sliceIndex]);
    return search;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get getTripType() {
    let searchFields: AvailableFlightsRequest = JSON.parse(
      sessionStorage.getItem("flightSearch")
    );
    return searchFields.type;
  }

  get fareByPaxType() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenSliceList.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.fareValue;
        totalFare.All += basefare.fareValue;
      });
    });
    return totalFare;
  }

  get baseFareValue() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenSliceList.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.baseFareValue;
        totalFare.All += basefare.baseFareValue;
      });
    });
    return totalFare;
  }

  get totalFeeTaxes() {
    let taxesValue = 0;
    this.chosenFlightList.forEach((flight) => {
      taxesValue +=
        flight.totalDuPrice +
        flight.totalDu2Price +
        flight.totalAirportTaxesPrice;
    });
    return taxesValue;
  }

  get totalPrice() {
    let total = 0;
    total =
      this.totalFeeTaxes +
      this.fareByPaxType.Adult * this.flightsSearchFields.adultQty +
      this.fareByPaxType.Child * this.flightsSearchFields.childrenQty +
      this.fareByPaxType.Infant * this.flightsSearchFields.infantQty;
    return total;
  }

  get totalBaseFarePrice() {
    return (
      this.baseFareValue.Adult * this.flightsSearchFields.adultQty +
      this.baseFareValue.Child * this.flightsSearchFields.childrenQty +
      this.baseFareValue.Infant * this.flightsSearchFields.infantQty
    );
  }

  get isMobile(): boolean {
    return this._mediaObserver?.isActive(['xs', 'sm']);
  }

  get totalSlicesWithFlights(): number {
    return this.flightResponse?.filter(flight => flight.length > 0)?.length;
  }

  get isMultiSlice(): boolean {
    return this.getTripType == ApiFlightType.MultiSlice;
  }

  private _processInstallments(): void {
    const configs = this.chosenSliceList.map((slice: SliceResponse, sliceIndex: number) => {
      if (this.filterMeta[sliceIndex] && this.filterMeta[sliceIndex][slice.source]) {
        const maxInstallmentConfig: { maxInstallment: number, hasTax: boolean } = (this.filterMeta[sliceIndex][slice.source]['creditRules'] as CreditCardRulesModel[])
          .filter(r => r.IATACode === slice.issuingAirline)
          .reduce((prev, curr) => {
            const maxCurrent = _.max(curr.allowedInstallments);
            const currentConfig = {
              maxInstallment: curr.minInstallmentValue.find(v => v.installmentCount === maxCurrent).installmentCount,
              hasTax: curr.hasCardTax.find(t => t.installmentCount === maxCurrent).hasTax
            };
            if (!prev) {
              return currentConfig;
            } else {
              const usePrevValue = prev.maxInstallment >= currentConfig.maxInstallment;
              return {
                maxInstallment: _.max([prev.maxInstallment, currentConfig.maxInstallment]),
                hasTax: usePrevValue ? prev.hasTax : currentConfig.hasTax
              };
            }
          }, { maxInstallment: 1, hasTax: false });

        return maxInstallmentConfig;
      }
    });

    if (configs.length) {
      const bestOption = configs.reduce((prev, curr) => {
        if (!prev) {
          return curr;
        }
        const usePrevValue = prev?.maxInstallment >= curr?.maxInstallment;
        return usePrevValue ? prev : curr;
      }, null);

      if (bestOption) {
        this.maxAllowedInstallment = bestOption?.maxInstallment;
        this.hasCardTax = bestOption?.hasTax;
      }
    }
  }

  setBestsPricesBySlices(chosenFlightList: AvailableFlightsResponse[]) {
    chosenFlightList.forEach((flight, flightIndex) => {
      this.bestsPricesBySlices.push(
        new StopPriceFilter(
          flight.slices[0].segments[0].carrierAirline,
          [new StopQtyPrice(
            flight.slices[0].segments[0].stopCount,
            flight.totalPrice
          )]
        )
      );
    });
  }

  doSearchAgain() {
    const newFlightSearch = new AvailableFlightsRequest();
    const flightSearch: AvailableFlightsRequest = JSON.parse(
      sessionStorage.getItem("flightSearch")
    );

    if (flightSearch != null) {
      newFlightSearch.type = flightSearch.type;
    }

    sessionStorage.setItem('flightSearch', JSON.stringify(newFlightSearch));

    this.redirectToHomePage();
  }

  deleteSliceByIndex(sliceIndex: number) {
    this.flightsSearchFields.slices = this.flightsSearchFields.slices.filter(
      (slice, index) => index !== sliceIndex
    );

    this.flightResponse = this.flightResponse.filter(
      (flights, index) => index !== sliceIndex
    );
  }

  get anySliceHasValue(): boolean {
    return this.flightResponse.some(flight => flight.length > 0);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private checkCredential(credential, item) {
    for (var x = 0; x < item.insurers_products_credentials.length; x++) {
      if (credential == item.insurers_products_credentials[x].credential) {
        return true;
      }
    }
    return false;
  }

  private _getInsurerRequiredCountries() {

    let proccessmentData = this.insuranceStorageService.getIsInsuranceProccessmentActive();

    if (proccessmentData.mustRedoCheck) {
      this._apiMSInsuranceService.getInsuranceProducts().subscribe((response: any) => {
        let dateExpireAt = moment(new Date()).add(1, 'hours').toDate();
        if (proccessmentData.expiredTimeSession && response.meta.insuranceYmlActive) {
          let objectSessionStorage = { item: { insuranceYmlActive: true }, expiresAt: dateExpireAt };
          this.insuranceStorageService.setStorageItem(objectSessionStorage);
        } else if (!response.meta.insuranceYmlActive) {
          let objectSessionStorage = { item: { insuranceYmlActive: false }, expiresAt: dateExpireAt };
          this.insuranceStorageService.setStorageItem(objectSessionStorage);
        }
        if (response.success && response.result.length && response.result.some((item) => item.active)) {
          this.insuranceProducts = response.result;
          this._apiMSInsuranceService.getRequiredCountries().subscribe((result: any) => {
            this._requiredCountries = result.result;
            let countrieRequired = this._requiredCountries?.some(item => { return (this._airportCountriesVerification.includes(item)); });
            return this._proccessCountriesRequired(countrieRequired);
          });
        }
      });
    }
  }

  private _proccessCountriesRequired(countrieRequired) {
    for (var x = 0; x < this.resultsInsurance.length; x++) {
      var responseModel = this.resultsInsurance[x];
      for (var y = 0; y < responseModel.result.length; y++) {
        var endObject = responseModel.result[y];
        let credential = endObject.credentialName;
        let international = endObject.international;
        if (!this._isInsuranceRequired) {
          this._isInsuranceRequired = ((this.insuranceProducts.some(item => { return item.is_aerial && item.is_national == !international && item.active && this.checkCredential(credential, item) && countrieRequired; })))
        }
      }
    }
  }
}
