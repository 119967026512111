import { Injectable } from '@angular/core';
import { ApiAirport, ApiAirportSearchService } from '@zupper/data';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AirportManagerService {
  private _airportSet = new Set<ApiAirport>();

  constructor(private _searchService: ApiAirportSearchService) {}

  register(airport: ApiAirport): void {
    this._airportSet.add(airport);
    sessionStorage.setItem('airport.' + airport.airportCode, JSON.stringify(airport));
  }

  get(code: string): ApiAirport | null {
    const setAirport = Array.from(this._airportSet.values()).find((a) => a.airportCode === code);
    if (setAirport) {
      return setAirport;
    } else {
      const storedAirport = sessionStorage.getItem(`airport.${code}`);

      if (!!storedAirport) {
        const airport = new ApiAirport();
        const parsed = JSON.parse(storedAirport);
        airport.fromApi(parsed);
        return airport;
      }
    }

    return null;
  }

  fetch(code: string): Observable<ApiAirport | null> {
    const existingAirport = this.get(code);
    if (existingAirport) return of(existingAirport);

    return this._searchService.run(code).pipe(
      map((response) => {
        const airport = new ApiAirport();

        response.forEach((res) => {
          if (res.airportCode === code) {
            airport.fromApi(res);
            this.register(airport);
          } else {
            const newAirport = new ApiAirport();
            newAirport.fromApi(res);
            this.register(newAirport);
          }
        });

        return airport;
      })
    );
  }
}
