import { Injectable } from '@angular/core';
import { FullCustomDimensionsSet, PartialCustomDimensionsSet } from './models/full-custom-dimensions-set.model';
import { DataLayerCustomDimensions } from './models/data-layer-custom-dimensions.enum';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private _currentSet: FullCustomDimensionsSet;
  private _constantDimensions = {};

  constructor() {
    this._generateEmptyDimensionsSet();
    // this._constantDimensions = [] // Get here dimensions that need to be sent at every push;
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
    .forEach(key => {
      this._currentSet[key] = someDimensions[key] || this._constantDimensions[key];
    });
  }

  trigger() {
    if (!(<any>window).dataLayer) {
      (<any>window).dataLayer = [];
    }

    (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [DataLayerCustomDimensions.MentionedBrand]: undefined,
      [DataLayerCustomDimensions.PageCategory]: undefined,
      [DataLayerCustomDimensions.WordsCount]: undefined,
      [DataLayerCustomDimensions.Something]: undefined,
    };
  }
}
