import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import { AirportService } from '../utils/airport.service';
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'priceRangeFilter'
})
export class PriceRangeFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    startingPrice: number = 0,
    endingPrice: number = 0
  ): Array<Array<AvailableFlightsResponse>> {
    if (startingPrice == 0 && endingPrice == 0) {
      this._flightFilterStateService.setFlightCounter(originalflightList.filter(_x => !!_x));
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      let foundFlights = flights.filter(
        flight =>
          Math.ceil(flight.totalPrice) >= Math.floor(startingPrice) &&
          Math.floor(flight.totalPrice) <= Math.ceil(endingPrice)
      );
      if (foundFlights.length > 0) {
        newFlightList[index] = foundFlights;
      }
    });

    const slicesIndex = originalflightList[0][0].slices.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
