<section class="melhoresOfertas">
    <header *ngIf="data && data.length > 0">
      <h2>As melhores ofertas selecionadas</h2>
      <div class="linhasOfertas svgTitleLine">
        <svg
          class="linhasOfertasXS"
          xmlns="http://www.w3.org/2000/svg"
          width="445.5"
          height="2"
          viewBox="0 0 445.5 2"
        >
          <g
            id="Grupo_89"
            data-name="Grupo 89"
            transform="translate(-560.5 -1064.5)"
          >
            <line
              id="Linha_33"
              data-name="Linha 33"
              x2="102.594"
              transform="translate(561.5 1065.5)"
              fill="none"
              stroke="#7a7a7a"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Linha_37"
              data-name="Linha 37"
              x2="258.5"
              transform="translate(746.5 1065.5)"
              fill="none"
              stroke="#7a7a7a"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Linha_34"
              data-name="Linha 34"
              x2="22.211"
              transform="translate(678.901 1065.5)"
              fill="none"
              stroke="#7a7a7a"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Linha_35"
              data-name="Linha 35"
              x2="11.634"
              transform="translate(715.92 1065.5)"
              fill="none"
              stroke="#7a7a7a"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
    </header>
    <ng-template #placeholder>
      <div class="ph-item">
        <div class="ph-col-12">
          <div class="ph-picture"></div>
        </div>
        <div class="ph-col-2">
          <div class="ph-avatar"></div>
        </div>
        <div>
          <div class="ph-row">
            <div class="ph-col-12 empty"></div>
            <div class="ph-col-12 bigger"></div>
          </div>
        </div>
      </div>
    </ng-template>
    <section class="bodyMelhoresOfertas widthPadrao">
      <div class="segurarCarrosel d-block" *ngIf="loading">
        <div class="carrosel-item">
          <div class="item">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <ng-container *ngTemplateOutlet="placeholder"></ng-container>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 d-md-block d-none">
                <ng-container *ngTemplateOutlet="placeholder"></ng-container>
              </div>
              <div
                class="col-xl-4 col-lg-4 d-lg-block d-md-none d-sm-none d-none">
                <ng-container *ngTemplateOutlet="placeholder"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="segurarCarrosel d-block d-none" *ngIf="!loading">
        <owl-carousel-o [options]="carouselOptions" class="owl-nome">
          <ng-template *ngFor="let promo of data" carouselSlide [id]="promo.guid">
            <div class="item carrosel-item">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div
                    class="blocosOfertas carrosel-item"
                    [ngStyle]="promo.getImageStyle()"
                  >
                    <i class="fas fa-plane"></i>
                    <div class="descricaoItem">
                      <h3>{{ promo.title }}</h3>
                      <div class="row">
                        <div class="col-5 p0">
                          <p>A partir de</p>
                          <h4><span>R$</span> {{ promo.totalFare }}</h4>
                        </div>
                        <div class="col-7 buttonQVDiv">
                          <button (click)="promoClick(promo)">
                            Quero Viajar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </section>
  </section>
