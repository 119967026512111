<article class="slice-not-found-panel py-4 py-sm-5 px-3 px-sm-5">
    <section>
        <img src="assets/images/slice-not-found.svg">
    </section>
    <section>
        <h5 class="fw-semibold text-center">Viajante, não encontramos resultados para {{!!sliceIndex ? 'este trecho' : 'estes trechos'}}!</h5>
    </section>
    <section class="d-flex flex-column flex-sm-row" [ngClass]="{'gap-3 gap-sm-5': !!sliceIndex}">
        <zp-button [options]="doSearchAgainBtn" (onButtonClick)="doSearchAgain.emit()">Refazer pesquisa</zp-button>
        <zp-button [options]="removeSliceBtn" (onButtonClick)="removeSlice.emit(sliceIndex)" *ngIf="!!sliceIndex">Remover trecho {{ sliceIndex + 1 }}</zp-button>
    </section>
</article>
