import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { EnvironmentInterface } from '../../models/environment.interface';

@Component({
  selector: 'zupper-security-seal',
  templateUrl: './security-seal.component.html',
  styleUrls: ['./security-seal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecuritySealComponent implements AfterViewInit {
  @ViewChild('goDaddy') goDaddyContainer: ElementRef<HTMLElement>;
  @ViewChild('siteBlindado') siteBlindadoContainer: ElementRef<HTMLElement>;
  @Input() type: 'goDaddy' | 'siteBlindado' = 'goDaddy';
  @Input() mode: 'mini' | 'normal' = 'normal';

  constructor(
    @Inject('env') private _env: EnvironmentInterface,
    @Inject(DOCUMENT) private _document: any
  ) { }

  ngAfterViewInit(): void {
    if(this.type === 'goDaddy' && !!this._env.security?.goDaddy) {
      const goDaddySeal = this.buildGoDaddySeal(this._env.security?.goDaddy);
      this.goDaddyContainer.nativeElement.appendChild(goDaddySeal);

      if (this.mode === 'mini') {
        this.goDaddyContainer.nativeElement.classList.add('mini-seal')
      }
    }
    else if (this.type === 'siteBlindado' && !!this._env.security?.siteBlindado) {
      try{
        const siteBlindadoSeal = this.buildSiteBlindadoSeal(this._env.security?.siteBlindado);
        this.siteBlindadoContainer.nativeElement.appendChild(siteBlindadoSeal);
        if (this.mode === 'mini') {
          this.siteBlindadoContainer.nativeElement.classList.add('mini-seal')
        }
      } catch(e){
        console.log('securityseal error', e);
      }
    }
  }

  buildGoDaddySeal(url: string): HTMLScriptElement {
    const element: HTMLScriptElement = this._document.createElement('script');
    element.src = url;
    element.type = 'text/javascript';
    element.async = true;

    return element;
  }

  buildSiteBlindadoSeal(url: string): HTMLScriptElement {
    const element: HTMLScriptElement = this._document.createElement('script');
    if (this._env.production) { // will generate errors when not in production
      element.src = url;
      element.type = 'text/javascript';
    }

    return element;
  }

}
