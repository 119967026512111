import { OnChanges, OnDestroy } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ApiFlightType } from '@zupper/data';
import { AirportSearchModel, AirportsModel, FlightSlicesModel } from '@zupper/aerial-components';
import { AerialSearchManagerService } from '../../../services/aerial-search-manager.service';
import * as _ from 'lodash';
import { toUpper } from 'lodash';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';

@Component({
  selector: "app-find-airports",
  templateUrl: "./find-airports.component.html",
  styleUrls: ["./find-airports.component.scss"],
})
export class FindAirportsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string = "Origem";
  @Input() isOrigin: boolean = true;
  @Input() flightSearchFields: AirportSearchModel;
  @Input() multiDestinationIndex: number = 0;
  @Input() tripTypeChosen;
  @Output() chosenAirport = new EventEmitter();

  @ViewChild("airportCode") airportCodeField: ElementRef;

  private subs = new SubSink();
  airportDisplay: string = "";
  userIsNotTyping: any = null;
  airportCodeDisplay: string = "";
  availableAirports: AirportsModel[] = [];
  errors: any[] = [];

  constructor(private service: AerialSearchManagerService) { }

  ngOnInit(): void {
    if (
      !_.isEmpty(this.flightSearchFields.slices[this.multiDestinationIndex])
    ) {
      let slice: FlightSlicesModel = this.flightSearchFields.slices[
        this.multiDestinationIndex
      ];

      if (this.isOrigin && slice.originAirport != undefined) {
        this.getAirportInfo(slice.originAirport);
      } else {
        if (slice.destinationAirport != undefined) {
          this.getAirportInfo(slice.destinationAirport);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("tripTypeChosen")) {
      this.errors = [];
    }
  }

  get getClass() {
    return this.airportCodeDisplay != ""
      ? "tripChoosenWithCode"
      : "tripChoosenWithoutCode";
  }

  get showClearFields() {
    if (this.airportDisplay != "") {
      return true;
    }
    return false;
  }

  clearFields(popup: NgbPopover = null) {
    this.airportCodeDisplay = "";
    this.airportDisplay = "";
    this.airportCodeField.nativeElement.removeAttribute("readonly");
    this.airportCodeField.nativeElement.focus();
    if (popup) {
      popup.open();
    }
  }

  getAirportInfo(airportCode: string) {
    let cacheIndex = "airport." + airportCode;
    if (sessionStorage.getItem(cacheIndex)) {
      let sessionAirport = JSON.parse(sessionStorage.getItem(cacheIndex));
      let airport: AirportsModel = sessionAirport;
      this.airportDisplay = this.cleanUpDisplay(airport, false);
      return airport.airport_code;
    }

    this.subs.add(this.service.availableAirports(airportCode).subscribe(
      (data) => {
        this.availableAirports = data.getResult();
        let airport: AirportsModel = this.availableAirports.find((model) => {
          return model.airport_code == airportCode;
        });
        this.airportDisplay = this.cleanUpDisplay(airport, false);
      },
      (error) => {
        return new AirportsModel();
      }
    ));
  }

  inputBlur(popup: NgbPopover): void {
    setTimeout(() => {
      if (popup.isOpen) {
        popup.close();
      }
    }, 500);
  }

  searchAvailableAirports(event: any, popup: NgbPopover = null) {
    if (popup) {
      popup.open();
    }
    clearTimeout(this.userIsNotTyping);
    let chars = event.target.value.length;

    if (chars === 0 && event.key === 'Backspace' && !!popup && popup.isOpen) {
      popup.close();
    }

    if (
      (chars >= 2 && event.key != "Backspace") ||
      (chars >= 4 && event.key == "Backspace")
    ) {
      this.userIsNotTyping = setTimeout(() => {
        this.subs.add(this.service.availableAirports(event.target.value).subscribe((data) => {
          this.availableAirports = data.getResult();
        }));
      }, 500);
    } else {
      this.availableAirports = [];
    }
  }

  get isDisabled(): boolean {
    return !!(this.airportCodeDisplay && this.airportDisplay);
  }

  setChoosenAirport(airport: AirportsModel) {
    this.airportDisplay = this.cleanUpDisplay(airport, false);
    sessionStorage.setItem(
      "airport." + airport.airport_code,
      JSON.stringify(airport)
    );
    this.errors = [];
    this.chosenAirport.emit(airport);
  }

  displayAirportInfo(airport: AirportsModel) {
    return this.cleanUpDisplay(airport, true);
  }

  cleanUpDisplay(airport: AirportsModel, description: boolean) {
    let result = "";
    if (description) {
      airport.description
        ? (result = result.concat(airport.description, ", "))
        : null;
    }

    airport.city ? (result = result.concat(airport.city, ", ")) : null;
    airport.region ? (result = result.concat(airport.region, ", ")) : null;
    airport.country ? (result = result.concat(airport.country)) : null;

    this.airportCodeDisplay = toUpper(airport.airport_code);

    return result;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get hasError() {
    return this.errors.length > 0 ? "borderRed" : "borderGray";
  }

  isValid(): boolean {
    if (this.airportCodeDisplay != "") {    
      return true;
    } else {
      this.errors.push("Aeroporto é um campo obrigatório");
      return false;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
