import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zc-installment',
  templateUrl: './zc-installment.component.html',
  styleUrls: ['./zc-installment.component.scss']
})
export class ZcInstallmentComponent implements OnInit {
  @Input() maxInstallments: number = 0;
  @Input() hasCardTax: boolean = false;
  @Input() isMobileAndMultiSlice: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get iconCard(): string {
    const iconFilename: string = this.isMobileAndMultiSlice ? 'icon-card-gray.svg' : 'icon-card.svg';
    return `assets/img/${iconFilename}`;
  }

}
