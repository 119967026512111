import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuService {
  private subject$: BehaviorSubject<boolean>;

  public get currentMenuStatus$(): Observable<boolean> {
    return this.subject$;
  }

  constructor() {
    this.subject$ = new BehaviorSubject<boolean>(false);
  }

  public setStatus(open: boolean): void {
    this.subject$.next(open);
  }

  public ngOnDestroy(): void {
    this.subject$.complete();
  }
}
