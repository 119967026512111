import { Injectable, inject } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, first } from 'rxjs';
import { ContentConfigurationService } from '../configuration.service';
import { WhatsappTag } from '../models/configuration';
import { TagServiceService } from '../tag-service.service';
import { WEBSITE_PAGE } from '@zupper/data';

@Injectable({
  providedIn: 'root',
})
export class ContentConfigurationResolver implements Resolve<WhatsappTag> {
  constructor(
    private contentConfigurationService: ContentConfigurationService,
    private tagServiceService: TagServiceService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WhatsappTag> | Promise<WhatsappTag> {
    return this.contentConfigurationService.configurations$
      .pipe(first((data) => !!data))
      .toPromise()
      .then((data) => {
        const routePath = route.routeConfig.path.toUpperCase();
        const currentRoute =
          routePath == '' && route.data.product === 1
            ? WEBSITE_PAGE.HOME
            : routePath === WEBSITE_PAGE.RESULTS && route.data.product === 1
            ? route.routeConfig.path.toUpperCase()
            : '';
        const model = data.whatsappModel 
          ? new WhatsappTag(data.whatsappModel, currentRoute)
          : null;

        this.tagServiceService.setConfigurations({
          destinationGuideModel: data.destinationGuideModel,
          whatsappTag: model,
        });
        return model;
      });
  }
}

export const contentConfigurationResolver: ResolveFn<WhatsappTag> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<WhatsappTag> | Promise<WhatsappTag> => {
  return inject(ContentConfigurationResolver).resolve(route, state);
};
