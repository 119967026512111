<section class="list-container" gdColumns.lt-md="1fr" gdColumns="1.9fr 1.1fr">

  <ng-container *ngIf="isMultiCias">
    <div class="alert-multi-airline">
      <span class="icon">
        <i class="fas fa-exclamation-circle"></i>
      </span>
      <p>
        Voos de Ida e Volta por <strong>Cias diferentes</strong>. Com isso, os bilhetes serão emitidos separados.
        <span class="tooltips">Saber mais
          <span class="tooltiptext">Ao comprar estas passagens saiba que alterações e cancelamentos dos voos serão gerenciados de forma separada.</span>
        </span>
      </p>
    </div>
  </ng-container>

  <article class="available-flights">

    <div *ngFor="let i of [].constructor(this.totalSlices); let sliceIndex = index; let isLast= last"
      [ngClass]="getDirectionClass(sliceIndex)" data-zt="flightDirection">

      <header class="flight-info" fxShow.xs="false" fxHide.gt-xs="false">
        <span><i class="fas fa-plane"></i>{{getSliceIdentification(sliceIndex)}}</span>
        <h5> De: <span data-zt="originAirport"><b>{{departureAirport(priceGroup[0].slices[sliceIndex])}}</b></span> &nbsp;-&nbsp; Para: <span data-zt="destinationAirport"><b>{{arrivalAirport(priceGroup[0].slices[sliceIndex])}}</b></span></h5>
      </header>

      <header class="mobile-flight-info" fxShow.xs="true" fxHide.gt-xs="true">
        <section class="slice-identification">
          <span><i class="fas fa-plane"></i>{{getSliceIdentification(sliceIndex)}}</span>
          <h6 [ngStyle]="{'margin=left': '0.8rem'}">{{getSliceIdentification(sliceIndex)}}:<strong> {{getSliceIdentificationDate(sliceIndex)}}</strong></h6>
        </section>
        <section [ngStyle]="{ 'display': 'flex', 'justify-content': 'space-between' }">
          <h6>De <br> <strong> {{departureAirport(priceGroup[0].slices[sliceIndex])}}</strong></h6>
          <h6>Para <br> <strong>{{arrivalAirport(priceGroup[0].slices[sliceIndex])}}</strong></h6>
        </section>
      </header>
      <app-flight-option (chosenFlightSlice)="saveChosenSlice($event, groupIndex, sliceIndex)"
        (chosenFlight)="saveChosenFlight($event, groupIndex, sliceIndex)" [flights]="priceGroup"
        [groupIndex]="groupIndex" [filterMeta]="filterMeta" [totalSlices]="totalSlices" [sliceIndex]="sliceIndex"
        [isMultiCias]="isMultiCias" [selectedFlight]="selectedFlightList[groupIndex][sliceIndex]"
        [selectedFamilyName]="isInternational || isMultiCias ? null : selectedFamilyName[groupIndex]"
        [isMobile]="isMobile">
      </app-flight-option>

    </div>
  </article>

  <aside class="price-info" [ngClass]="{'multi-cias': isMultiCias}">
    <app-same-airline-flight-group-summary *ngIf="!isMultiCias" [flightList]="priceGroup" 
      [chosenFlight]="isInternational ? priceGroup[0] : selectedFlightResponseByFamilyCheck"
      [chosenSlices]="chosenSliceList[groupIndex] == undefined ? [] : chosenSliceList[groupIndex]"
      [bestPrice]="groupIndex == 0" [radioName]="groupIndex" [filterMeta]="this.filterMeta"
      [showFamilies]="showFamilies" (selectedFamilyName)="setSelectedFamily(groupIndex, $event, this.flightList)"
    >
    </app-same-airline-flight-group-summary>
    <app-multi-airline-flight-group-summary *ngIf="isMultiCias" [flightList]="priceGroup" [chosenFlight]="priceGroup[0]"
      [chosenSlices]="chosenSliceList[groupIndex] == undefined ? [] : chosenSliceList[groupIndex]"
      [bestPrice]="groupIndex == 0" [radioName]="groupIndex" [filterMeta]="this.filterMeta"
      [showFamilies]="showFamilies" (selectedFamilyName)="setSelectedFamily(groupIndex, $event, this.flightList)">
    </app-multi-airline-flight-group-summary>
  </aside>
</section>
