<ng-container *ngIf="showHeader">
  <aerial-header-menu [templateRef]="menuContent"></aerial-header-menu>
  <zupper-header (mobileMenuClick)="openSideMenu()"></zupper-header>
</ng-container>
<modal-connection-internet></modal-connection-internet>
<modal-cookie></modal-cookie>
<app-header-product [tagModels]="tagModels" [product]="product" *ngIf="showProductsHeader"></app-header-product>
<router-outlet></router-outlet>
<ngx-ui-loader></ngx-ui-loader>
<app-footer-contacts *ngIf="showFooterMail"></app-footer-contacts>
<app-footer></app-footer>

<ng-template #menuContent>
  <aerial-side-menu></aerial-side-menu>
  <button (click)="goToSearch()" class="header-sidebar-cta cta">
    <img src="assets/img/blue-plane.svg"> <span class="ml-1">Buscar nova viagem</span>
  </button>
</ng-template>