import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import pkgInfo from '../../../package.json'
import { browserTracingIntegration } from '@sentry/angular-ivy';

const version = pkgInfo.version;

Sentry.init({
  dsn: "https://4bec2ad4a5544c56b3fa28f3d15b7949@o554544.ingest.sentry.io/5683372",
  beforeSend(event, hint) {
    const isNonErrorException = event?.exception?.values?.[0]?.value?.startsWith('Non-Error exception captured') ||
        hint?.originalException?.['message']?.startsWith('Non-Error exception captured');

    if (isNonErrorException) {
        return null;
    }

    return event;
},
//replaysOnErrorSampleRate: 0.1,
//replaysSessionSampleRate: 1.0,

  integrations: [
    browserTracingIntegration(),
  ],
  tracePropagationTargets: ["localhost", /^\//],
  release: version,
  environment: environment.environment,
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
