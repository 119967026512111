import { OnDestroy } from '@angular/core';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, Subscription } from 'rxjs';
import { HeaderMenuService } from '../../header-menu.service';

@Component({
  selector: 'aerial-header-menu',
  templateUrl: './aerial-header-menu.component.html',
  styleUrls: ['./aerial-header-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AerialHeaderMenuComponent implements OnInit, OnDestroy {
  showMenu: Observable<boolean>;

  @Input() templateRef: any;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth - 50;
  private mediaObserverSubscription$: Subscription;

  constructor(
    private _menuService: HeaderMenuService,
    private media: MediaObserver
  ) {}

  ngOnInit(): void {
    this.mediaObserverSubscription$ = this.media
      .asObservable()
      .subscribe((change: MediaChange[]) => {
         const mobile = ['xs', 'sm'];
         if (mobile.includes(change[0].mqAlias)) {
          this.navWidth = window.innerWidth - 50;
         }
      });
    this.showMenu = this._menuService.currentMenuStatus$;
  }

  onSidebarClose(): void {
    this._menuService.setStatus(false);
  }

  getMenuStyle(showMenu: boolean): any {
    let navBarStyle: any = {};

    navBarStyle['transition'] =
      'left ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle['width'] = this.navWidth + 'px';
    navBarStyle['left'] = (showMenu ? 0 : this.navWidth * -1) + 'px';

    return navBarStyle;
  }

  ngOnDestroy(): void {
    this.mediaObserverSubscription$.unsubscribe();
  }
}
