import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZupperVersionService {
  constructor() {

  }
  
  setVersion(version: string){
    window['zupperVersion'] = version;
  }

  getVersion(): string | undefined {
    return window['zupperVersion'];
  }
}