import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-void-search',
  templateUrl: './modal-void-search.component.html',
  styleUrls: ['./modal-void-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalVoidSearchComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
}
