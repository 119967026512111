import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { 
  ApiAerialPromo, 
  ApiHotelPromotionsService,
  ApiAerialGeolocationIpService,
  ApiAerialGeolocation, 
  ApiAerialPromotionsService
} from '@zupper/data';

@Component({
  selector: 'zupper-common-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.scss']
})
export class PromosComponent implements OnInit {
  @Output() promoSelection = new EventEmitter<any>();
  @Input() type;

  data: ApiAerialPromo[];
  loading: boolean;
  userLocation: ApiAerialGeolocation = new ApiAerialGeolocation();

  carouselOptions: OwlOptions = {
    autoplay: true,
    autoplayHoverPause: true,
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    navText: [
      // tslint:disable-next-line: max-line-length
      "<div class='setasOferta row'><div class='col-xl-2  col-lg-2 col-md-2 col-sm-2  col-1 p0 txL'><i class='fas fa-chevron-left'></i></div><div class='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11  p0 txL'><div class='setaMenor'><i class='fas fa-chevron-left'></i></div></div></div>",
      // tslint:disable-next-line: max-line-length
      "<div class='setasOferta setasOfertaRight row'><div class='col-10 p0 txR'><div class='setaMenor'><i class='fas fa-chevron-right'></i></div></div><div class='col-2  p0 txL'><i class='fas fa-chevron-right'></i></div></div> ",
    ],
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 2,
      },
      768: {
        items: 3,
      },
    },
  };

  constructor(
    private promoService: ApiAerialPromotionsService,
    private geolocation: ApiAerialGeolocationIpService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.promoService.getAll().subscribe(
      (data: ApiAerialPromo[]) => {
        this.data = data.sort((a, b) => (a.order >= b.order ? 1 : -1));
      },
      () => null,
      () => {
        this.loading = false;
      }
    );

    this.geolocation.get().subscribe((result: ApiAerialGeolocation) => {
      this.userLocation = result;
    });
  }

  promoClick(promo: ApiAerialPromo) {
    let info = {
      "promo" : {},
      "userLocation" : {}
    };
    info.promo = promo;
    info.userLocation = this.userLocation;

    this.promoSelection.emit(info);
  }

}
