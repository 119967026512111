import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CorporateLoginComponent } from './corporate-login.component';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ZupperProduct } from "@zupper/common";
import { HomeComponent } from '../../../components/home/home.component';
import { whitelabelLogoutResolver } from '../../resolvers/whitelabel-logout.resolver';
import { CorporateLoginGuard } from '../../guards/corporate-login.guard';
import { CorporateAuthGuard } from '../../guards/corporate-auth.guard';

const loginRouteData = {
  product: ZupperProduct.AERIAL,
  showHeader: true,
  showFooter: true,
  showHeaderLinks: false
};

const homeRouteData = {
  product: ZupperProduct.AERIAL,
  showHeader: true,
  showHeaderLinks: true
};

const routes: Routes = [
  {
    path: 'sair',
    component: HomeComponent,
    data: {
      ...homeRouteData,
      fromCorporateLogout: true
    },
    resolve: {
      whitelabel: whitelabelLogoutResolver
    }
  },
  {
    path: ':corporateName/entrar',
    component: CorporateLoginComponent,
    canActivate: [CorporateLoginGuard],
    data: loginRouteData,
    runGuardsAndResolvers: 'always'
  },
  {
    path: ':corporateName',
    component: HomeComponent,
    data: homeRouteData,
    canActivate: [CorporateAuthGuard],
    runGuardsAndResolvers: 'always'
  },
];
@NgModule({
  declarations: [
    CorporateLoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SweetAlert2Module,
    RouterModule.forChild(routes)
  ],
  exports: [
    CorporateLoginComponent
  ]
})
export class CorporateLoginModule { }
