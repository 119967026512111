import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../constants.service';
import { ZupperSocialMedia } from '../../models/zupper-social-media.model';

@Component({
  selector: 'zupper-footer-social-media',
  templateUrl: './footer-social-media.component.html',
  styleUrls: ['./footer-social-media.component.scss']
})
export class FooterSocialMediaComponent implements OnInit {

  socialLinks: ZupperSocialMedia[] = this._constantsService.socialMedia;

  constructor(private _constantsService: ConstantsService) { }

  ngOnInit(): void {
  }

}
