import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from './home/home.module';
import { FlightSearchModule } from './flight-search/flight-search.module';
import { ResultsModule } from './results/results.module';
import { HeaderProductSearchComponent } from './header-product-search/header-product-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivacyPolicyCookieComponent } from './privacy-policy-cookie/privacy-policy-cookie.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { DeepLinkNoResultsComponent } from './deep-link-no-results/deep-link-no-results.component';
@NgModule({
  declarations: [HeaderProductSearchComponent, PrivacyPolicyCookieComponent, WhoWeAreComponent, DeepLinkNoResultsComponent ],
  imports: [
    CommonModule,
    HomeModule,
    FlightSearchModule,
    ResultsModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module
  ],
  exports: [
    ResultsModule,
    DeepLinkNoResultsComponent
  ]
})
export class ComponentsModule { }
