import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AirlineServiceService {
  getFilteredAirlines(issuingAirline: string): string {
    const sources = ['G3', 'AD', 'LA', '2Z'];
    if (issuingAirline == 'JJ'){
      return 'LA';
    }
    else if (sources.includes(issuingAirline)) {
      return issuingAirline;
    } else {
      //SABRE
      return 'Sabre';
    }
  }
}
