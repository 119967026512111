import { ApiFilterModel } from "./api-filter.model";

export class DefaultResponseModel {
  success: boolean;
  error: string[];
  result: any;
  meta?: any;

  getResult(): any {
    return this.result;
  }
}

export class AirportsModel {
  airport_code: string;
  city: string;
  countries_iso: string;
  country: string;
  description: string;
  id: string;
  is_tti_only: string;
  latitude: string;
  longitude: string;
  misspellings: string;
  priority: string;
  region: string;
}

export class ApiFlightSlice {
  originAirport: string;
  destinationAirport: string;
  departureDate: string;
}

export class AirportSearchModel {
  adultQty: number;
  airlines: string[];
  childrenQty: number;
  forceCacheRefresh: boolean;
  grouped: boolean;
  groupedByOptions: boolean;
  infantQty: number;
  onlyBusinessClass?: boolean;
  onlyEconomicClass?: boolean;
  slices: FlightSlicesModel[];
  type: string;
  offSet: number;
  limit: number;

  constructor() {
    this.slices = [];
    this.adultQty = 1;
    this.childrenQty = 0;
    this.infantQty = 0;
  }

  addAdult() {
    if (this.adultQty < 8) { this.adultQty++; }
  }
  removeAdult() {
    if (this.adultQty > 1) { this.adultQty--; }
  }

  addChild() {
    if (this.childrenQty < 8) { this.childrenQty++ }
  }
  removeChild() {
    if (this.childrenQty > 0) { this.childrenQty--; }
  }

  addInfant() {
    if (this.infantQty < 8) { this.infantQty++ }
  }
  removeInfant() {
    if (this.infantQty > 0) { this.infantQty--; }
  }
}

export class FlightSlicesModel {
  originAirport: string;
  destinationAirport: string;
  originCity?: string;
  destinationCity?: string;
  departureDate: any;
}

export class AvailableFlightsRequest {
  adultQty: number;
  airlines: string[];
  childrenQty: number;
  forceCacheRefresh: boolean;
  grouped: boolean;
  groupedByOptions: boolean;
  infantQty: number;
  onlyBusinessClass: boolean;
  onlyEconomicClass: boolean;
  slices: FlightSlicesModel[];
  offSet: number;
  limit: number;
  type: string;
  credentialName: string = null;
  customerSessionId: string = null;
}

export class AvailableFlightsResponse {
  airline: string;
  credentialName: string;
  currency: string;
  duValue: number;
  du2Value: number;
  desktopDeepLink: string;
  flightId: string;
  international: boolean;
  phoneDeepLink: string;
  searchId: string;
  slices: SliceResponse[];
  tabletDeepLink: string;
  totalAirportTaxesPrice: number;
  totalCreditCardFee: number;
  totalDu2Price: number;
  totalDuPrice: number;
  totalFarePrice: number;
  totalIncentiveValue: number;
  totalPrice: number;
  type: string;
  ciasDiff: boolean;
  isMultipleAirlinesFlight: boolean;
  showDetails?: boolean = false;
  scaleCount: number;
  connectionCount: number;

  get isCiasDiff(): boolean {
    return this.ciasDiff;
  }

  get isInternational(): boolean {
    return this.international;
  }

  get hasScale(): boolean {
    return this.slices.length <= 2 && this.slices.some(slice => slice.segments.some(segment => segment.scaleCount));
  }

  get hasConnection(): boolean {
    return this.slices.length <= 2 && this.slices.some(slice => slice.segments.some(segment => segment.isConnection));
  }

  get hasCodeShare(): boolean {
    return this.slices.some(slice => slice.hasCodeShareSegment);
  }
}

export class SliceResponse {
  arrivalDate: string;
  baseFares: BaseFaresModel[];
  mainFamily: string;
  creditCardFee: number;
  departureDate: string;
  destination: string;
  direction: string;
  du2Price: number;
  duPrice: number;
  flightDuration: string;
  flightTime: string;
  freeText: number;
  incentivePrice: number;
  issuingAirline: string;
  origin: string;
  searchId: string;
  searchSliceId: string;
  apiSliceId: string;
  segments: SegmentsResponseModel[];
  source: string;
  taxPrice: number;
  identification: number;
  skip: boolean;
  isScale: boolean;
  isConnection: boolean;

  get hasCodeShareSegment(): boolean {
    return this.segments.some(segment => segment.isCodeShare);
  }
}

export class BaseFaresModel {
  baggageAllowance: boolean;
  baggageDescription: string;
  baseCurrency: string;
  baseDuValue: number;
  baseFareValue: number;
  baseTaxValue: number;
  creditCardFee: number;
  currency: string;
  discont: number;
  du2Value: number;
  duValue: number;
  exchangeRate: number;
  family: string;
  familyItems: FamilyItems[];
  fareValue: number;
  freeText: string;
  incentiveValue: number;
  paxType: string;
  rule: string;
  ruleCode: string;
  taxValue: number;
}

export class FamilyItems {
  name: string;
  description: string;
  iconName: string;
  sortOrder: number;
}

export class SegmentsResponseModel {
  aircraft: string;
  arrivalDate: string;
  cabin: string;
  carrierAirline: string;
  class: string;
  departureDate: string;
  destinationAirport: string;
  flightNumber: string;
  flightDuration: string;
  flightTime: string;
  freeText: string;
  originAirport: string;
  scaleCount: number;
  sellerAirline: string;
  stopCount: number;
  stops: [];
  isScale: boolean;
  isConnection: boolean;

  get isCodeShare(): boolean {
    return this.carrierAirline !== this.sellerAirline;
  }
}

export class MultiDestinationResponse {
  flights: AvailableFlightsResponse[][] = [];
  filter: ApiFilterModel;
}
