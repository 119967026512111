import { Component, OnInit } from '@angular/core';
import { CustomerLoginService, LocalStorageService } from '@zupper/common';

@Component({
  selector: 'app-modal-whitelabel',
  templateUrl: './modal-whitelabel.component.html',
  styleUrls: ['./modal-whitelabel.component.scss']
})
export class ModalWhitelabelComponent implements OnInit {
  public corporateName: string;
  constructor(
    private localStorageService: LocalStorageService,
    private loginService: CustomerLoginService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Define o aceite ou a recusa dos termos
   * @param option
   */
  defineTerms(option: string):void {
    this.localStorageService.setItem('accept_terms', option);
    if (option == 'refuse') {
      this.loginService.logout().subscribe(
        () => {
          window.location.href = "/parceiro/" + this.corporateName;
        }
      );
    } else {
      window.location.href = "/";
    }

  }
}
