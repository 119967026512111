export enum DataLayerThanks {
  event = 'event',
  number = 'number',
  airline = 'airline',
  discount = 'discount',
  duValue = 'duValue',
  du2Value = 'du2Value',
  totalAirportTaxes = 'totalAirportTaxes',
  totalCreditCardFee = 'totalCreditCardFee',
  totalFarePrice = 'totalFarePrice',
  totalIncentiveValue = 'totalIncentiveValue',
  totalPrice = 'totalPrice',
  totalWithDiscount = 'totalWithDiscount',
  type = 'type',
  paymentType = 'paymentType',
}
