<div>
    <div class="modal-header">
        <button class="btn-close" (click)="activeModal.close()">
            <span class="material-symbols-outlined">arrow_back_ios</span>
        </button>
        <div>
            <span class="material-symbols-outlined">filter_list</span>
            Filtrar voos
        </div>
        <button class="btn-close" (click)="activeModal.close()">
            <span class="material-symbols-outlined">close</span>
        </button>
    </div>
    <div class="modal-body">
        <article class="filter-options">
            <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Paradas</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.stopQuantities.length == 0}" class="filtered-data">
                        (
                        <ng-container *ngIf="filterOptionsMobile.stopQuantities.length > 0; else allStops">
                            <ng-container *ngFor="let qty of filterOptionsMobile.stopQuantities">
                                <span class="filtered-value" *ngIf="qty || qty === 0">
                                    {{qty == 0 ? 'Direto' : qty}}</span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allStops>Todas</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <app-stop-filter 
                        [orderedStopQuantities]="orderedStopQuantities"
                        [clearAllFilters]="clearAllFilters"
                        (onFilterChange)="sendFilterOption($event)" 
                        [selectedStopFilters]="selectedStopFilters"
                        [filterOptionsMobile]="filterOptionsMobile">
                    </app-stop-filter>
                </div>

                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsed = ! isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        {{ isCollapsed == true ? 'Abrir Paradas' : ' Minimizar Paradas'}}
                        <span [ngClass]="{'classTransform': isCollapsed}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

             <div class="filter-option"> 
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Cias aéreas</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.airlines.length == 0}" class="filtered-data">
                        (
                        <ng-container *ngIf="filterOptionsMobile.airlines.length > 0; else allAirlines">
                            <ng-container *ngFor="let airline of availableAirlines">
                                <span class="filtered-value"
                                    *ngIf="filterOptionsMobile.airlines.indexOf(airline.iataCode) != -1">
                                    {{airline.name}}
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allAirlines>Todas</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedCia">
                    <app-cias-filter 
                        [availableAirlines]="availableAirlines" 
                        [clearAllFilters]="clearAllFilters"
                        (onFilterChangeCias)="sendFilterOptionCias($event)"
                        [selectedAirlinesFilters]="selectedAirlinesFilters"
                        [filterOptionsMobile]="filterOptionsMobile"
                        [isMultiCias]="isMultiCias">
                    </app-cias-filter>
                </div>
                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedCia = ! isCollapsedCia"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        {{ isCollapsedCia == true ? 'Abrir Cias' : ' Minimizar Cias'}}
                        <span [ngClass]="{'classTransform': isCollapsedCia}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

             <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Aeroportos</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.airports.length == 0}" class="filtered-data">
                        (
                        <ng-container *ngIf="filterOptionsMobile.airports.length > 0; else allAirports">
                            <ng-container *ngFor="let airport of availableAirports">
                                <span class="filtered-value"
                                    *ngIf="filterOptionsMobile.airports.indexOf(airport.IATACode) != -1">
                                    {{airport.IATACode}}
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allAirports>Todos</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedAirport">
                    <app-airport-filter 
                        [availableAirports]="availableAirports"
                        [clearAllFilters]="clearAllFilters"
                        (onFilterChangeAirport)="sendFilterOptionAirport($event)"
                        [selectedAirportsFilters]="selectedAirportsFilters"
                        [filterOptionsMobile]="filterOptionsMobile">
                    </app-airport-filter>
                </div>

                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedAirport = ! isCollapsedAirport"
                        [attr.aria-expanded]="!isCollapsedAirport" aria-controls="collapseExample">
                        {{ isCollapsedAirport == true ? 'Abrir Aeroportos' : ' Minimizar Aeroportos'}}
                        <span [ngClass]="{'classTransform': isCollapsedAirport}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

             <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Preços</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.minimalPrice == 0 && filterOptionsMobile.maximumPrice == 0 }"
                        class="filtered-data price">
                        (
                        <ng-container
                            *ngIf="
                                      (filterOptionsMobile.minimalPrice > 0 && filterOptionsMobile.minimalPrice != minimalPrice) ||
                                      (filterOptionsMobile.maximumPrice > 0 && filterOptionsMobile.maximumPrice != maximumPrice) ;else allPrices">
                            <ng-container if="selectedMinimalPrice != minimalPrice">de {{filterOptionsMobile.minimalPrice |
                                currency:
                                "R$"}}
                            </ng-container>
                            <ng-container if="selectedMaximumPrice != maximumPrice"> até {{filterOptionsMobile.maximumPrice |
                                currency:
                                "R$"}}</ng-container>
                        </ng-container>
                        <ng-template #allPrices>Todos</ng-template>
                        )
                    </span>
                </div>

                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedPrices">
                    <div class="template-container">
                        <app-price-filter 
                            [filterOptionsMinimalPrice]="filterOptionsMobile.minimalPrice" 
                            [clearAllFilters]="clearAllFilters"
                            [filterOptionsMaximumPrice]="filterOptionsMobile.maximumPrice" 
                            [filterOptionsMobile]="filterOptionsMobile"
                            [minimalPrice]="minimalPrice" [maximumPrice]="maximumPrice"
                            (pricesValuesChange)="sendFilterOpitionPrice($event)">
                        </app-price-filter>
                    </div>
                </div>

                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedPrices = ! isCollapsedPrices"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        {{ isCollapsedPrices == true ? 'Abrir Preços' : ' Minimizar Preços'}}
                        <span [ngClass]="{'classTransform': isCollapsedPrices}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

            <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Horário de saída</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.timeDeparture.length == 0}"
                        class="filtered-data time-filter">
                        (
                        <ng-container *ngIf="filterOptionsMobile.timeDeparture.length > 0; else allDepartures">
                            <ng-container *ngFor="let departureTime of filterOptionsMobile.timeDeparture">
                                <span class="filtered-value">
                                    {{departureTime.minimalTimeSelected}}:00 -
                                    {{departureTime.maximumTimeSelected == 24 ? '23:59':
                                    departureTime.maximumTimeSelected + ':00'}}
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allDepartures>Todos</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedDeparture">
                    <div class="template-container">
                        <app-departure-filter 
                        [timeDeparture]="timeDeparture"
                        [clearAllFilters]="clearAllFilters"
                        (departureValuesChange)="sendFilterOpitionDeparture($event)"
                        [filterOptionsMobile]="filterOptionsMobile.timeDeparture">
                        </app-departure-filter>
                    </div>
                </div>
                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedDeparture = ! isCollapsedDeparture"
                        [attr.aria-expanded]="!isCollapsedDeparture" aria-controls="collapseExample">
                        {{ isCollapsedDeparture == true ? 'Abrir Horário de Saída' : ' Minimizar Horário de Saída'}}
                        <span [ngClass]="{'classTransform': isCollapsedDeparture}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

            <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Horário de chegada</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.timeArrival.length == 0}"
                        class="filtered-data time-filter">
                        (
                        <ng-container *ngIf="filterOptionsMobile.timeArrival.length > 0; else allArrival">
                            <ng-container *ngFor="let arrivalTime of filterOptionsMobile.timeArrival">
                                <span class="filtered-value">
                                    {{arrivalTime.minimalTimeSelected}}:00 -
                                    {{arrivalTime.maximumTimeSelected == 24 ? '23:59':
                                    arrivalTime.maximumTimeSelected + ':00'}}
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allArrival>Todos</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedArrival">
                    <div class="template-container">
                        <app-arrival-filter 
                        [timeArrival]="timeArrival"
                        [clearAllFilters]="clearAllFilters"
                        (arrivalValuesChange)="sendFilterOpitionArrival($event)"
                        [filterOptionsMobile]="filterOptionsMobile.timeArrival">
                        </app-arrival-filter>
                    </div>
                </div>
                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedArrival = ! isCollapsedArrival"
                        [attr.aria-expanded]="!isCollapsedArrival" aria-controls="collapseExample">
                        {{ isCollapsedArrival == true ? 'Abrir Horário de Chegada' : ' Minimizar Horário de Chegada'}}
                        <span [ngClass]="{'classTransform': isCollapsedArrival}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div>

            <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5> Duração do voo</h5>
                    <span [ngClass]="{'no-filter': filterOptionsMobile.filterDuration.length == 0}"
                        class="filtered-data time-filter">
                        (
                        <ng-container *ngIf="filterOptionsMobile.filterDuration.length > 0; else allDuration">
                            <ng-container *ngFor="let duration of filterOptionsMobile.filterDuration">
                                <span class="filtered-value">
                                    {{duration.minimalDurationSelected}}:00 -
                                    {{duration.maximumDurationSelected}}:00
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #allDuration>Todos</ng-template>
                        )
                    </span>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedDuration">
                    <div class="template-container">

                        <app-duration-filter 
                            [clearAllFilters]="clearAllFilters"
                            [filterDuration]="filterDuration"
                            (durationValuesChange)="sendFilterOpitionDuration($event)"
                            [filterOptionsMobile]="filterOptionsMobile.filterDuration"
                        >
                        </app-duration-filter>
                    </div>
                </div>
                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedDuration = ! isCollapsedDuration"
                        [attr.aria-expanded]="!isCollapsedDuration" aria-controls="collapseExample">
                        {{ isCollapsedDuration == true ? 'Abrir Duração do Voo' : ' Minimizar Duração do Voo'}}
                        <span [ngClass]="{'classTransform': isCollapsedDuration}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div> 

            <div class="filter-option">
                <div class="label" style="display: flex; align-items: center;">
                    <h5>Bagagens</h5>
                    <span
                        [ngClass]="{'no-filter': filterOptionsMobile.baggage.freeBaggage == filterOptionsMobile.baggage.paidBaggage }"
                        class="filtered-data">
                        (
                        <ng-container
                            *ngIf="filterOptionsMobile.baggage.freeBaggage !== filterOptionsMobile.baggage.paidBaggage; else allBaggages">
                            <span class="filtered-value">
                                {{filterOptionsMobile.baggage.freeBaggage? 'Com Bagagem
                                Despachada' : 'Apenas bagagem de mão'}}
                            </span>
                        </ng-container>
                        <ng-template #allBaggages>Todos</ng-template>
                        )
                    </span>
                </div>

                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedBaggage">
                    <app-baggage-filter 
                        (onFilterChangeBaggage)="sendFilterOptionBaggage($event)"
                        [clearAllFilters]="clearAllFilters"
                       [selectedBaggagesFilters]="selectedBaggagesFilters"
                        [allBaggages]="filterOptionsMobile.baggage "
                       >
                    </app-baggage-filter>
                </div>

                <div class="divider" style="display: flex; flex-direction: row-reverse;">
                    <button class="button-divider" (click)="isCollapsedBaggage = ! isCollapsedBaggage"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        {{ isCollapsedBaggage == true ? 'Abrir Bagagens' : ' Minimizar Bagagens'}}
                        <span [ngClass]="{'classTransform': isCollapsedBaggage}" class="material-symbols-outlined">
                            expand_less</span>
                    </button>
                    <hr class="solid" style=" flex-grow: 1;">
                </div>
            </div> 
        </article>

    </div>
    <div class="modal-footer">
        <span class="clear-filter">
            <p class="click-link" (click)="clear()">Limpar Seleção</p>
        </span>

        <button (click)="applyFilter()" class="button-apply-filter">
            <span class="material-symbols-outlined">filter_list</span>
            <link rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
            Aplicar Filtros
        </button>

    </div>

</div>