import { Component, Input, OnInit } from '@angular/core';
import { ApiOfferLabel } from '@zupper/data';

@Component({
  selector: 'zc-label-offer',
  templateUrl: './zc-label-offer.component.html',
  styleUrls: ['./zc-label-offer.component.scss']
})
export class ZcLabelOfferComponent implements OnInit {
  @Input() labelData: ApiOfferLabel;
  
  constructor() { }

  ngOnInit(): void {
  }

}
