import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { BaggageModel } from '../models/api-filter.model';
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'baggageFilter'
})
export class BaggageFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    baggage: BaggageModel = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (baggage == null) {
      this._flightFilterStateService.setFlightCounter(originalflightList.filter(_x => !!_x));
      return originalflightList;
    }

    if (
      (baggage.freeBaggage == true && baggage.paidBaggage == true) ||
      (baggage.freeBaggage == false && baggage.paidBaggage == false)
    ) {
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      flights.forEach((flight: AvailableFlightsResponse) => {
        flight.slices.forEach((slice, sliceIndex: number) => {
          if (slice.baseFares[0].baggageAllowance == baggage.freeBaggage) {
            if (!newFlightList[index]) {
              newFlightList[index] = [];
            }
            newFlightList[index].push(flight);
          }
        });
      });
    });

    const slicesIndex = originalflightList[0][0].slices.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
