import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WEBSITE_PAGE, WEBSITE_VIEWPORT } from '@zupper/data';
import moment from 'moment';
import { Subject, filter, takeUntil } from 'rxjs';
import { SubSink } from 'subsink';
import { AbandonmentModalComponent } from './../lib/components/abandonment-modal/abandonment-modal.component';
import { LocalStorageService } from './../lib/storage/local-storage.service';
import { AbandomentModalTimerService } from './abandonment-modal-timer.service';
import { AbandonmentModalConfiguration } from './models/abandonment-modal-configuration';
import { EnvironmentInterface } from './models/environment.interface';
import { UserInteractionDetectorService } from './user-interaction-detector.service';

@Injectable({
    providedIn: 'root'
})
export class AbandomentModalManagerService implements OnDestroy {

    private interactionSubject: Subject<void> = new Subject<void>();
    private _subs = new SubSink();
    private _isModalOpen: boolean;

    constructor(private _userInteractionDetectorService: UserInteractionDetectorService, private _abandomentModalTimerService: AbandomentModalTimerService,
        private _modal: NgbModal, private _localStorageService: LocalStorageService, private _mediaObserver: MediaObserver, @Inject('env') private _env: EnvironmentInterface,) { }

    public init(abandonmentModalConfiguration: AbandonmentModalConfiguration, page: WEBSITE_PAGE): void {
        const idleTime: number = abandonmentModalConfiguration.idleTimeActivation(this.isMobile ? WEBSITE_VIEWPORT.MOBILE : WEBSITE_VIEWPORT.DESKTOP, page);
        if (idleTime > 0) {
            this._abandomentModalTimerService.startTimer(moment.duration(idleTime / 60, 'minutes'));
            this._subs.add(this._userInteractionDetectorService.detectClicks()
                .pipe(filter(() => idleTime > 0),
                    takeUntil(this.interactionSubject))
                .subscribe(() => {
                    if (!this._isModalOpen) this._abandomentModalTimerService.restartTimer();
                }),
                this._userInteractionDetectorService.detectMouseMovements()
                    .pipe(filter(() => idleTime > 0),
                        takeUntil(this.interactionSubject))
                    .subscribe(() => {
                        if (!this._isModalOpen) this._abandomentModalTimerService.restartTimer();
                    }),
                this._abandomentModalTimerService.timerFinished$
                    .pipe(filter(() => idleTime > 0))
                    .subscribe((finished: boolean) => {
                        if (finished && !this._isModalOpen) {
                            this.openModal(abandonmentModalConfiguration, page);
                        }
                    })
            );
        } else {
            this._subs.add(this._userInteractionDetectorService.detectClosePageAttempt()
                .pipe(filter(() => abandonmentModalConfiguration.isActivatedByCloseAttempt(this.isMobile ? WEBSITE_VIEWPORT.MOBILE : WEBSITE_VIEWPORT.DESKTOP, page)))
                .subscribe(() => {
                    if (!this._isModalOpen) this.openModal(abandonmentModalConfiguration, page);
                }))
        }

    }

    openModal(abandonmentModal: AbandonmentModalConfiguration, page: WEBSITE_PAGE): void {
        if (!this._localStorageService.getTimedItem('expirationDateShowAbandonmentModal')) {
            this._isModalOpen = true;
            const modalRef = this._modal.open(AbandonmentModalComponent, {
                size: "lg",
                centered: true,
                windowClass: 'abandonment-modal',
                backdrop: 'static',
                keyboard: false
            });
            modalRef.componentInstance.abandonmentModalConfig = abandonmentModal;
            modalRef.componentInstance.page = page;
            modalRef.result.then(() => {
                const idleTime: number = abandonmentModal.idleTimeActivation(this.isMobile ? WEBSITE_VIEWPORT.MOBILE : WEBSITE_VIEWPORT.DESKTOP, page);
                this._isModalOpen = false;
                this._localStorageService.setTimedItem('expirationDateShowAbandonmentModal', true, moment().add(this._env.abandonmentModalExpiration, 'hours'));
                if (idleTime > 0) {
                    this._abandomentModalTimerService.restartTimer();
                }
            });
        }
    }

    get isMobile(): boolean {
        return this._mediaObserver.isActive(['xs', 'sm']);
    }

    ngOnDestroy(): void {
        this._subs?.unsubscribe?.();
    }
}
