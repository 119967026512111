import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiDestinationSearchComponent } from './multi-destination-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FindAirportsModule } from '../find-airports/find-airports.module';
import { CalendarModule } from '../calendar/calendar.module';
import { PassengersModule } from '../passengers/passengers.module';
import { BREAKPOINT, FlexLayoutModule } from '@angular/flex-layout';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseStrategy } from '@zupper/common';

const MIDDLE_MD_BREAKPOINT = [{
  alias: 'midMd',
  suffix: 'MidMd',
  mediaQuery: 'screen and (min-width: 600px) and (max-width: 750px)',
  overlapping: false,
}];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: MIDDLE_MD_BREAKPOINT,
  multi: true
};

@NgModule({
  declarations: [
    MultiDestinationSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FindAirportsModule,
    CalendarModule,
    PassengersModule,
    FlexLayoutModule
  ],
  exports: [
    MultiDestinationSearchComponent
  ],
  providers: [
    CustomBreakPointsProvider,
    { provide: RouteReuseStrategy, useClass: ReuseStrategy},
  ]
})
export class MultiDestinationSearchModule { }
