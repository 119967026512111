import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'stopsFilter'
})
export class StopsFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    stops: Array<number> = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (stops == null || stops.length == 0) {
      this._flightFilterStateService.setFlightCounter(originalflightList.filter(_x => !!_x));
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];

    if (stops.length == 1 && stops[0] == 0) {
      originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
        let returnThisFlight = true;
        flights.forEach((flight: AvailableFlightsResponse) => {
          flight.slices.forEach((slice: SliceResponse) => {
            if (stops.indexOf(slice.segments.length - 1) == -1) {
              returnThisFlight = false;
            }
          });
        })
        if (returnThisFlight) {
          newFlightList[index] = originalflightList[index];
        }
      });
    } else {
      //TIRAR DUVIDAS SOBRE COMO ESSE OBJETO É CONSTRUIDO. É FIXADO UM?
      originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
        let returnThisFlight = true;
        flights.forEach((flight: AvailableFlightsResponse) => {
          flight.slices.forEach((slice: SliceResponse) => {
            if (stops.indexOf(slice.segments.length - 1) == -1) {
              returnThisFlight = false;
              return false;
            }
          });
        })
        if (returnThisFlight) {
          newFlightList[index] = originalflightList[index];
        }
      });
    }

    const slicesIndex = stops.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
