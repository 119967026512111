import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCustomer } from '@zupper/data';
import { Subscription } from 'rxjs';
import { EnvironmentInterface } from '../../models/environment.interface';
import { CustomerLoginService } from '../../customer-login.service';
import { OrderTrackingModalComponent } from '../order-tracking-modal/order-tracking-modal.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  customer: ApiCustomer;
  private customerSubscription: Subscription;

  get accountLink(): string {
    return this.env.account.baseUrl;
  }

  constructor(
    private modalService: NgbModal,
    private customerLoginService: CustomerLoginService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject('env') private env: EnvironmentInterface
  ) { }

  ngOnInit(): void {
    this.customerSubscription = this.customerLoginService.currentCustomer$.subscribe(
      (customer: ApiCustomer | null) => {
        this.customer = customer;
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  openOrderTrackingForm(): void {
    if (!this.customer) { // only show order tracking modal if there is no logged user
      const modalRef = this.modalService.open(OrderTrackingModalComponent, {
        size: 'xl',
        centered: true,
      });
    } else { // if the user is logged in, redirect to account
      window.open(`${this.env['account']['baseUrl']}/meus-pedidos`);
    }

  }

  openHelpCenter(ev: Event): void {
    ev.preventDefault();
    window.open('https://centraldeajuda.zupper.com.br/', '_blank');
  }

}
