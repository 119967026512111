import { Injectable } from '@angular/core';
import { CheckoutDataManagerService, ZupperProduct } from '@zupper/common';
import { AvailableFlightsResponse, BaseFaresModel, SegmentsResponseModel, SliceResponse } from '../models/aerial-api-response.model';
import { AerialCheckoutData } from '../models/checkout/aerial-checkout-data.interface';

@Injectable({
  providedIn: 'root'
})
export class AerialCheckoutBuilderService {
  constructor(
    private _checkoutDataManager: CheckoutDataManagerService
  ) { }

  public saveCheckoutData(data: AerialCheckoutData): string {
    this._prepareData(data);
    const key = this._checkoutDataManager.register(ZupperProduct.AERIAL, data);

    return key;
  }

  retrieveCheckoutData(key: string): AerialCheckoutData | null {
    const currentData = this._checkoutDataManager.retrieve(key);

    return currentData && currentData.product === ZupperProduct.AERIAL
      ? this._buildFlightsResponse(currentData.data)
      : null;
  }

  private _prepareData(data: AerialCheckoutData): void {
    // Ordena o resultado pela data de embarque para nao dar erro no DSG
    if (data && data.result && data.result.length && data.result[0].slices) {
      data.result[0].slices.sort(function (a, b) {
        if (a.departureDate > b.departureDate) {
          return 1;
        }
        if (a.departureDate < b.departureDate) {
          return -1;
        }
        return 0;
      });
    }
  }

  private _buildFlightsResponse(data: AerialCheckoutData): AerialCheckoutData {
    let flightsCopies: AvailableFlightsResponse[] = data.result.map((flight: AvailableFlightsResponse) => {
      let flightCopy = Object.assign(new AvailableFlightsResponse(), flight);
      flightCopy.slices = flight.slices.map((slice: SliceResponse) => {
        let sliceCopy = Object.assign(new SliceResponse(), slice);
        sliceCopy.baseFares = slice.baseFares.map((baseFare: BaseFaresModel) => Object.assign(new BaseFaresModel(), baseFare));
        sliceCopy.segments = slice.segments.map((segment: SegmentsResponseModel) => Object.assign(new SegmentsResponseModel(), segment));
        return sliceCopy;
      });
      return flightCopy;
    });
    data.result = [];
    data.result = flightsCopies;
    return data;
  }
}
