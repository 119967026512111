<section class="families-container">
  <ng-container *ngFor="
    let flight of this.flightList
    | uniqueFamily as filteredResults;
    let index = index;
    let isFirst = first;
  ">
    <article class="item"
      class="{{ getItemClass(flight.airline, flight.slices[0].mainFamily) }}"
      [ngClass]="filteredResults.length == 3 ? 'three-items' : null"
      *ngIf="flight && flight.slices[0].baseFares[0].family"
      data-zt="familiesContainer"
    >
        <h4 class="family-name">{{flight.slices[0].baseFares[0].family}}</h4>
        <div class="input-wrapper">
            <span></span>
            <input #selectedRadio type="radio"
              (click)="setSelectFamily(flight, index)"
              [checked]="index === chosenFamilyIndex || isFirst"
              [name]="this.radioName"
              [id]="this.radioName + '-' + index"
            >
        </div>
        <label (click)="setSelectFamily(flight, index)" class="details-wrapper" [for]="this.radioName + '-' + index">
            <p class="price">
                {{index > chosenFamilyIndex ? '+' : index < chosenFamilyIndex ? '-' : null}}
                 R$ {{ getFamilyPrice(index, filteredResults) | number: '1.2-2'}}
            </p>
            <div class="small-line"></div>
            <small class="baggage">
              <i
                class="fas"
                [ngClass]="{
                  'fa-times': ! familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('baggage').checked,
                  'fa-check': familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('baggage').checked
                }"
              ></i>{{familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('baggage').text}}
            </small>
            <small class="seat">
              <i class="fas"
                [ngClass]="{
                  'fa-times': ! familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('seat').checked,
                  'fa-check': familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('seat').checked
                }"
              ></i>{{familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('seat').text}}</small>
            <small class="refund">
              <i class="fas"
                [ngClass]="{
                  'fa-times': ! familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('refund').checked,
                  'fa-check': familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('refund').checked
                }"
              ></i>{{familyDetails.getAirline(flight.airline).getFamily(flight.slices[0].mainFamily).getField('refund').text}}</small>
        </label>
    </article>
  </ng-container>
</section>
<div class="familyDetailsLink" fxHide.lt-md="true" (click)="openFlightFamily()" *ngIf="this.flightList.length > 0">
  Ver mais detalhes "{{lastFamilyName}}"
</div>
