<label class="default-input-label"><i class="fas fa-calendar-day"></i> Ida</label>

<label class="default-input-label" *ngIf="calendarType == availableCalendarTypes.TwoDates"><i
    class="fas fa-calendar-day"></i> Volta</label>

<div [ngClass]="getClass" #calendarPopup="ngbPopover" [ngbPopover]="calendarTemplate" placement="bottom"
  [autoClose]="'outside'" data-zt="calendarTrigger">
  <div [class]="calendarType == availableCalendarTypes.OneDate ? 'oneWay' : null">
    <span [class]="
            (
              !flightSlices[0] ||
              !flightSlices[0].departureDate
            ) ? 'placeholder' : ''
          ">
      {{
      (
      flightSlices[0] &&
      flightSlices[0].departureDate
      ) ? (formatter.format(flightSlices[0].departureDate) | date:"dd/MM/yyyy")
      : 'Data de ida'
      }}
    </span>
  </div>
  <div *ngIf="calendarType == availableCalendarTypes.TwoDates">
    <span [class]="!flightSlices[1].departureDate ? 'placeholder' : ''">
      {{flightSlices[1].departureDate ? (formatter.format(flightSlices[1].departureDate) | date:"dd/MM/yyyy") : 'Data de
      volta'}}
    </span>
  </div>
</div>

<ng-template #calendarTemplate>
  <header class="popup-header">
    <span><i class="fas fa-calendar-day"></i> Datas</span>
    <i class="fas fa-times" (click)="calendarPopup.close()"></i>
  </header>
  <div class="template-popup-calendario">
    <div class="select-dates mobile-only">
      <div [class]="calendarType == availableCalendarTypes.OneDate ? 'oneWay' : null">
        <span [class]="
                (
                  !flightSlices[0] ||
                  !flightSlices[0].departureDate
                  ) ? 'placeholder' : ''
                ">
          {{
          (
          flightSlices[0] &&
          flightSlices[0].departureDate
          )
          ? formatter.format(flightSlices[0].departureDate)
          : 'Data de ida'
          }}
        </span>
      </div>
      <div *ngIf="calendarType == availableCalendarTypes.TwoDates">
        <span [class]="!flightSlices[1].departureDate ? 'placeholder' : ''">
          {{flightSlices[1].departureDate ? formatter.format(flightSlices[1].departureDate) : 'Data de volta'}}
        </span>
      </div>
    </div>
    <div class="calendar" data-zt="calendarPopup">
      <ngb-datepicker *ngIf="calendarType == availableCalendarTypes.OneDate"
      [minDate]="minDate[multiDestinationIndex]"
      [maxDate]="maxDate[multiDestinationIndex]"
      [displayMonths]="1"
      (dateSelect)="onDateSelection($event, null, calendarPopup)"
      [dayTemplate]="oneDate" navigation="select"
      [outsideDays]="'hidden'"
      [showWeekNumbers]="false"
      outsideDays="visible"
      [navigation]="'arrows'">
      </ngb-datepicker>
      <ng-template #oneDate let-date let-focused="focused">
        <span class="custom-day" data-zt="calendarDate" [attr.data-zt-value]="date | calendarDate"
          [class.focused]="focused" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>

      <ngb-datepicker *ngIf="calendarType == availableCalendarTypes.TwoDates" #dp
        (dateSelect)="onDateSelection($event, null, calendarPopup)"
        [minDate]="minDate[multiDestinationIndex]"
        [maxDate]="maxDate[multiDestinationIndex]"
        [displayMonths]="widthPopupCalendar"
        [outsideDays]="'hidden'"
        [dayTemplate]="twoDates"
        [navigation]="'arrows'">
      </ngb-datepicker>
      <ng-template #twoDates let-date let-focused="focused">
        <span data-zt="calendarDate" [attr.data-zt-value]="date | calendarDate" class="custom-day"
          [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
    <button type="button" class="button-close-popup mobile-only" (click)="calendarPopup.close()">Confirmar</button>
  </div>
</ng-template>
