import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ZupperDataModule } from '@zupper/data';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxMaskModule } from 'ngx-mask';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainSearchEngineComponent } from './main-search-engine.component';
import { ZcFindAirportsModule } from '../zc-find-airports/zc-find-airports.module';
import { ZcCalendarModule } from '../zc-calendar/zc-calendar.module';
import { ZcPassengerGuestModule } from '../zc-passenger-guest/zc-passenger-guest.module';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [
    MainSearchEngineComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ZupperDataModule,
    NgbModule,
    // NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    FlexLayoutModule,
    ZcFindAirportsModule,
    ZcCalendarModule,
    ZcPassengerGuestModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [
    MainSearchEngineComponent
  ],
  providers: [
    provideEnvironmentNgxMask()
  ]
})
export class MainSearchEngineModule { }
