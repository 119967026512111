import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AvailableFlightsRequest, FlightSlicesModel } from '@zupper/aerial-components';
import { CalendarTypeEnum } from '../../../services/enum/flights.enum';
import * as _ from 'lodash';
import { ApiFlightType } from '@zupper/data';
import moment from 'moment';

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  @Input() calendarType: string;
  @Input() flightSearchFields: AvailableFlightsRequest;
  @Input() multiDestinationIndex: number = 0;
  @Input() minDate;
  @Input() maxDate;
  @Output() chosenDates = new EventEmitter();

  flightSlices: FlightSlicesModel[] = [new FlightSlicesModel(), new FlightSlicesModel()];
  tripAmountDays: number;
  hoveredDate: NgbDate | null = null;
  errors: any[] = [];

  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar
  ) {
    this.calendarType = CalendarTypeEnum.OneDate;
  }

  ngOnInit(): void {
    if (!this.minDate) this.minDate = [];    
    if (!this.maxDate) this.maxDate = [];

    this.minDate[this.multiDestinationIndex] = this.currentDate();
    this.maxDate[this.multiDestinationIndex] = this.adjustMaxDate(this.minDate[this.multiDestinationIndex]);
    
    const { checkin, checkout } = this.getCheckinAndCheckout(this.flightSearchFields.slices);
    if (!_.isEmpty(this.flightSearchFields.slices[0])) {
      if (typeof checkin == "string") {
        this.flightSlices[0].departureDate = this.getBootstrapDate(checkin);
        this.flightSearchFields.slices[0].departureDate = this.flightSlices[0].departureDate;
      } else {
        this.flightSlices[0].departureDate = checkin;
      }

      if (this.flightSearchFields.type == this.flightTypes.RoundTrip) {
        if (this.flightSearchFields.slices[1]) {
          if (typeof checkout == "string") {
            this.flightSlices[1].departureDate = this.getBootstrapDate(checkout);
          } else {
            this.flightSlices[1].departureDate = checkout;
          }
        }

        let firstDate = this.flightSlices[0].departureDate;
        const secondDate = this.flightSearchFields.slices[1] ? this.flightSlices[1].departureDate : null;
        this.onDateSelection(firstDate, secondDate);
      } else {
        if (this.flightSearchFields.type == ApiFlightType.MultiSlice) {
          let slice = this.flightSearchFields.slices;
          if (slice.length > 1) {
            const destinationSlice = slice[this.multiDestinationIndex];
            if (typeof destinationSlice.departureDate === 'string') {
              this.flightSlices[0].departureDate = this.getBootstrapDate(destinationSlice.departureDate);
            } else {
              this.flightSlices[0].departureDate = destinationSlice.departureDate;
            }
            this.minDate[this.multiDestinationIndex] = this.flightSlices[0].departureDate;
            this.onDateSelection(this.flightSlices[0].departureDate);
          }
        }
      }
    }
  }

  get widthPopupCalendar() {
    return window.innerWidth < 769 ? 1 : 2;
  }

  private adjustMaxDate(minDate: NgbDate): void {
    this.maxDate = Array.from(this.minDate);
    const maxDate = { ...this.maxDate[this.multiDestinationIndex] };
    maxDate.year += 1;
    this.maxDate[this.multiDestinationIndex] = { ...maxDate };
  }

  onDateSelection(mainDate: NgbDate, secondDate?: NgbDate | undefined, popup?: NgbPopover | undefined) {
    let slices = this.flightSlices;
    const { checkin, checkout } = this.getCheckinAndCheckout();
    switch (this.calendarType) {
      case CalendarTypeEnum.TwoDates:
        if (secondDate) {
          slices[0].departureDate = mainDate;
          slices[1].departureDate = secondDate;
        } else {
          if (!checkin && !checkout) {
            slices[0].departureDate = mainDate;
          } else if (
            checkin &&
            !checkout &&
            (mainDate.after(<NgbDateStruct>checkin) || mainDate.equals(<NgbDateStruct>checkin))
          ) {
            slices[1].departureDate = mainDate;
            popup?.close?.();
          } else {
            slices[0].departureDate = mainDate;
            slices[1].departureDate = null;
          }
        }
        slices[0].departureDate && slices[1].departureDate ? this.errors = [] : null;
        break;

      case CalendarTypeEnum.OneDate:
        slices[0].departureDate = mainDate;
        if (slices.length > 1) {
          slices.pop();
        }
        if (this.flightSearchFields.type == ApiFlightType.MultiSlice) {
          let countSlice = this.flightSearchFields.slices[this.multiDestinationIndex + 1];
          if (countSlice && countSlice.departureDate != undefined) {
            if (
              this.flightSearchFields.slices[this.multiDestinationIndex + 1].departureDate.day < slices[0].departureDate.day
            ) {
              for (var i = this.multiDestinationIndex + 1; i < this.flightSearchFields.slices.length; i++) {
                this.flightSearchFields.slices[i].departureDate = null;
                this.minDate[i] = mainDate;
              }
            }
          } else if (countSlice && countSlice.departureDate == null) {
            for (var i = this.multiDestinationIndex + 1; i < this.flightSearchFields.slices.length; i++) {
              this.minDate[i] = mainDate;
            }
          }
        }
        this.errors = [];
        popup?.close?.();
        break;

      default:
        break;
    }
    this.chosenDates.emit(slices);
  }

  get availableCalendarTypes(): typeof CalendarTypeEnum {
    return CalendarTypeEnum;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get getClass() {
    return this.errors.length > 0 ? "select-dates-red" : "select-dates";
  }

  isValid(): boolean {
    const { checkin, checkout } = this.getCheckinAndCheckout();
    switch (this.calendarType) {
      case CalendarTypeEnum.OneDate:
        if (checkin) {
          return true;
        } else {
          this.errors.push("Preencha Corretamente as datas");
          return false;
        }
      case CalendarTypeEnum.TwoDates:
        if (checkin && checkout) {
          if (checkin > checkout) {
            this.errors.push("Data de chegada deve ser maior que a de partida");
          } else {
            return true;
          }
        } else {
          this.errors.push("Preencha Corretamente as datas");
          return false;
        }
      default:
        return false;
    }
  }

  // ## inicio do Comportamento padrão do Calendario
  isHovered(date: NgbDate) {
    const { checkin, checkout } = this.getCheckinAndCheckout();
    return (
      checkin &&
      !checkout &&
      this.hoveredDate &&
      date.after(<NgbDateStruct>checkin) &&
      date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    const { checkin, checkout } = this.getCheckinAndCheckout();
    return (
      checkout &&
      date.after(<NgbDateStruct>checkin) &&
      date.before(<NgbDateStruct>checkout)
    );
  }
  isRange(date: NgbDate) {
    const { checkin, checkout } = this.getCheckinAndCheckout();
    return (
      date.equals(<NgbDateStruct>checkin) ||
      (checkout && date.equals(<NgbDateStruct>checkout)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  currentDate(data = new Date()) {
    data.setDate(data.getDate() + 1);
    let currentDate = {};
    let year = data.getFullYear();
    let month = data.getMonth() + 1;
    let day = data.getDate();
    currentDate = { year: year, month: month, day: day };
    return currentDate;
  }

  getCheckinAndCheckout(slices: FlightSlicesModel[] = this.flightSlices) {
    return  {
      checkin: slices?.[0]?.departureDate,
      checkout: slices?.[1]?.departureDate
    };
  }

  getBootstrapDate(date: string, daysInFuture = 0) {
    const dateClass = moment(date, 'YYYY-MM-DD');

    return isNaN(dateClass.valueOf()) 
      ? undefined 
      : new NgbDate(
        dateClass.year(),
        dateClass.month() + 1,
        dateClass.date() + daysInFuture
      );
  }
  // ## fim Calendario
}
