import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { FilterTime } from '../../../models/api-filter.model';

@Component({
  selector: 'app-arrival-filter',
  templateUrl: './arrival-filter.component.html',
  styleUrls: ['./arrival-filter.component.scss'],
})
export class ArrivalFilterComponent implements OnInit {
  @Input() timeArrival: Array<FilterTime> = [];
  @Input() filterOptionsMobile: any;
  @Input() clearAllFilters: boolean;
  @Output() arrivalValuesChange = new EventEmitter<any>();

  minValueOne = DEFAULT_MIN_VALUE;
  maxValueOne = DEFAULT_MAX_VALUE;
  minValueTwo = DEFAULT_MIN_VALUE;
  maxValueTwo = DEFAULT_MAX_VALUE;

  optionsOne = { ...defaultFilterOptionsMobile };
  optionsTwo = { ...defaultFilterOptionsMobile };

  constructor() {}

  ngOnInit(): void {
    this.setNewCeil();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      const [firstTimeArrival, secondTimeArrival] = this.timeArrival;
      this.minValueOne = firstTimeArrival.minimalTime;
      this.maxValueOne = firstTimeArrival.maximumTime;
      this.minValueTwo = secondTimeArrival.minimalTime;
      this.maxValueTwo = secondTimeArrival.maximumTime;
    }
  }

  setNewCeil(): void {
    let { minimalTimeSelected, maximumTimeSelected, minimalTime, maximumTime } =
      this.timeArrival?.[0] ?? {};
    this.minValueOne = minimalTimeSelected ?? DEFAULT_MIN_VALUE;
    this.maxValueOne = maximumTimeSelected ?? DEFAULT_MAX_VALUE;
    this.optionsOne = {
      ...this.optionsOne,
      floor: minimalTime,
      ceil: maximumTime,
    };

    if (this.timeArrival?.[1]) {
      let { minimalTimeSelected, maximumTimeSelected, minimalTime, maximumTime } =
        this.timeArrival?.[1] ?? {};
      this.minValueTwo = minimalTimeSelected ?? DEFAULT_MIN_VALUE;
      this.maxValueTwo = maximumTimeSelected ?? DEFAULT_MAX_VALUE;
      this.optionsTwo = {
        ...this.optionsTwo,
        floor: minimalTime,
        ceil: maximumTime,
      };
    }
  }

  onTimeChange($event, accessor: 'minimalTimeSelected' | 'maximumTimeSelected', index: number = 0) {
    if (this.timeArrival?.at(index)) {
      this.timeArrival.at(index)[accessor] = $event;
    }
    this.onChange();
  }

  onMinChangeOne($event) {
    this.onTimeChange($event, 'minimalTimeSelected');
  }

  onMaxChangeOne($event) {
    this.onTimeChange($event, 'maximumTimeSelected');
  }

  onMinChangeTwo($event) {
    this.onTimeChange($event, 'minimalTimeSelected', 1);
  }

  onMaxChangeTwo($event) {
    this.onTimeChange($event, 'maximumTimeSelected', 1);
  }

  onChange() {
    this.arrivalValuesChange.emit(this.timeArrival);
  }
}

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_MAX_VALUE = 24;
const defaultFilterOptionsMobile: Options = {
  floor: DEFAULT_MIN_VALUE,
  ceil: DEFAULT_MAX_VALUE,
  translate: (value: any, label: LabelType): string => value + 'h00',
};
