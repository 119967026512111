import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AirportInfoModel,
  BaggageModel,
  FilterAirlinesModel,
  FilterDuration,
  FilterOptions,
  FilterTime,
} from '../../../models/api-filter.model';
import { of } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  ResultFilterBaggage,
  ResultFilterDuration,
  ResultFiltersParams,
  ResultFilterTime,
} from '../../../models/result-params.model';
import moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMobileComponent } from '../modal-mobile/modal-mobile.component';
import { LabelType, Options } from 'ng5-slider';
import { FlightFilterStateService } from '../../../services/flight-filter-state.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-result-filter',
  templateUrl: './result-filter.component.html',
  styleUrls: ['./result-filter.component.scss'],
})
export class ResultFilterComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('templateBottomSheet') TemplateBottomSheet: TemplateRef<any>;
  @Input() availableStopQuantities: Array<number> = [];
  @Input() availableAirlines: Array<FilterAirlinesModel> = [];
  @Input() availableAirports: Array<AirportInfoModel> = [];
  @Input() minimalPrice: number = 0;
  @Input() maximumPrice: number = 0;
  @Input() selectedMinimalPrice: number = 0;
  @Input() selectedMaximumPrice: number = 0;
  @Input() filterDuration: Array<FilterDuration> = [];
  @Input() filterOptions: FilterOptions = new FilterOptions();
  @Input() timeDeparture: Array<FilterTime> = [];
  @Input() timeArrival: Array<FilterTime> = [];
  @Input() isLoading: boolean = true;
  @Input() isMultiCias: boolean;
  @Input() initialValues: ResultFiltersParams;

  @Output() filterOptionsChange = new EventEmitter<FilterOptions>();
  numberFlights: number = 0;
  private subs = new SubSink();

  filterForm: FormGroup;
  private currentFilters: ResultFiltersParams;
  public windowScrolled = false;
  private buttonOffsetTop: number;
  private modalRef: NgbModalRef;
  public filtersMobile: FilterOptions = new FilterOptions();
  public isMobileFiltersInitialState: boolean = true;
  public sortingOptions: string[] = ['Menor preço', 'Mais rápido', 'Recomendado'];
  public isDropdownOpen = false;
  public selectedOption: string = 'Menor preço';

  constructor(
    private _flightFilterStateService: FlightFilterStateService,
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private changesDetectRef: ChangeDetectorRef
  ) {
    this.filterForm = this.formBuilder.group({
      stopQuantities: new FormArray([]),
      airlines: new FormArray([]),
      airports: new FormArray([]),
      baggages: new FormArray([]),
    });

    this.subs.add(this._flightFilterStateService.flightCounter$.subscribe(data => {
      this.numberFlights = data;
    }));
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.setupInitialValues();
    this.buttonOffSet();
    this.selectedMinimalPrice = this.filterOptions.minimalPrice;
    this.selectedMaximumPrice = this.filterOptions.maximumPrice;
  }

  setupInitialValues() {
    if (!!this.initialValues) {
      this.currentFilters = this.initialValues;
      const {stops, airlines, airports, baggage, minPrice, maxPrice, departureTime, durationTime, arrivalTime} = this.initialValues

      if (stops.length > 0) this.filterOptions.stopQuantities = stops;
      if (airlines.length > 0) this.filterOptions.airlines = airlines;
      if (airports.length > 0) this.filterOptions.airports = airports;
      if (!!baggage) this.filterOptions.baggage = new BaggageModel(baggage.free, baggage.paid);
      if (minPrice > 0) this.filterOptions.minimalPrice = minPrice;
      if (maxPrice > 0) this.filterOptions.maximumPrice = maxPrice;
      if (departureTime.length > 0) this.filterOptions.timeDeparture = this.timeDeparture;
      if (durationTime.length > 0) this.filterOptions.filterDuration = this.filterDuration;
      if (arrivalTime.length > 0) this.filterOptions.timeArrival = this.timeArrival;
    } else {
      this.currentFilters = new ResultFiltersParams();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stopQuantitiesFormArray.clear();
    this.airlinesFormArray.clear();
    this.airportsFormArray.clear();
    this.baggagesFormArray.clear();

    Object.values(changes.availableStopQuantities.currentValue).forEach((value: number, index) => {
      this.stopQuantitiesFormArray.push(new FormControl(this.filterOptions.stopQuantities.includes(value)));
    });

    Object.values(changes.availableAirlines.currentValue).forEach((value: FilterAirlinesModel) => {
      this.airlinesFormArray.push(new FormControl(this.filterOptions.airlines.includes(value.iataCode)));
    });

    Object.values(changes.availableAirports.currentValue).forEach((value: AirportInfoModel) => {
      this.airportsFormArray.push(new FormControl(this.filterOptions.airports.includes(value.IATACode)));
    });

    this.baggagesFormArray.push(new FormControl(this.filterOptions.baggage ? this.filterOptions.baggage.freeBaggage : false));
    this.baggagesFormArray.push(new FormControl(this.filterOptions.baggage ? this.filterOptions.baggage.paidBaggage : false));
  }

  get stopQuantitiesFormArray() {
    return this.filterForm.controls.stopQuantities as FormArray;
  }

  get airlinesFormArray() {
    return this.filterForm.controls.airlines as FormArray;
  }

  get airportsFormArray() {
    return this.filterForm.controls.airports as FormArray;
  }

  get baggagesFormArray() {
    return this.filterForm.controls.baggages as FormArray;
  }

  get orderedStopQuantities(): number[] {
    return this.availableStopQuantities.sort();
  }

  buttonOffSet() {
    const buttonEl = document.querySelector('.button-filter') as HTMLElement;
    if (buttonEl) {
      this.buttonOffsetTop = buttonEl.offsetTop;
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll(): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const windowHeight = window.innerHeight;
    if (scrollTop > this.buttonOffsetTop - windowHeight && scrollTop != 0) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  open() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(ModalMobileComponent, {
        size: 'lg',
        windowClass: 'modal-xl',
      });

      const modalInstance = this.modalRef.componentInstance as ModalMobileComponent;

      modalInstance.sendFilters.subscribe((event) => {
        this.sendFilters(event);
      });
      modalInstance.clearFilters.subscribe(() => {
        this.clearFilters();
      });

      modalInstance.initValueFromResultFilterComponent(this);

      this.modalRef.result.then(() => {
        this.modalRef = null;
        this.isMobileFiltersInitialState = this.isFiltersMobileInInitialState();
      });
    }
  }

  sendFilters(result: FilterOptions) {
    this.filtersMobile = result;
    const {stopQuantities, airlines, airports, baggage, filterDuration, timeArrival, timeDeparture} = result;
    if (!!stopQuantities && stopQuantities.length > 0) {
      this.updateStopsFilterOptions(stopQuantities);
    }
    if (!!airlines && airlines.length > 0) {
      this.updateAirlinesFilterOptions(airlines);
    }
    if (!!airports && airports.length > 0) {
      this.updateAirportsFilterOptions(airports);
    }
    if (!!baggage && baggage.length > 0) {
      this.updateBaggageFilterOptions(baggage);
    }
    if (!!filterDuration) {
      this.updateDurationFilterOptions(filterDuration);
    }
    if (!!timeArrival && timeArrival.length > 0) {
      this.updateTimeArrivalFilterOptions(timeArrival);
    }
    if (!!timeDeparture && timeDeparture.length > 0) {
      this.updateTimeDepartureFilterOptions(timeDeparture);
    }
    if (!!result) {
      this.updatePriceFilterOptions(result);
    }

    this.updateURL();
  }

  updateStopsFilterOptions(result: number[]) {
    this.filterForm.value.stopQuantities = result;
    this.filterOptions.stopQuantities = this.filterForm.value.stopQuantities.map((value, index) => {
      if (value == true) {
        return this.availableStopQuantities[index];
      }
      return false;
    }).filter(v => v !== false);
    this.currentFilters.stops = this.filterOptions.stopQuantities;
  }
  sendStopsFilterOptions(popup: NgbPopover = null) {
    this.updateStopsFilterOptions(this.filterForm.value.stopQuantities);
    this.updateURL();
    popup?.close();
  }

  updateAirlinesFilterOptions(result: string[]) {
    this.filterForm.value.airlines = result;
    this.filterOptions.airlines = this.filterForm.value.airlines.map((value, index) => {
        if (value == true) {
          return this.availableAirlines[index].iataCode;
        }
        return false;
      }).filter(v => v !== false);
    this.currentFilters.airlines = this.filterOptions.airlines;
  }
  sendAirlinesFilterOptions(popup: NgbPopover = null) {
    this.updateAirlinesFilterOptions(this.filterForm.value.airlines);
    this.updateURL();
    popup?.close();
  }

  updateAirportsFilterOptions(result: string[]) {
    this.filterForm.value.airports = result;
    this.filterOptions.airports = this.filterForm.value.airports.map((value, index) => {
        if (value == true) {
          return this.availableAirports[index].IATACode;
        }
        return false;
      }).filter(v => v !== false);
    this.currentFilters.airports = this.filterOptions.airports;
  }
  sendAirportsFilterOptions(popup: NgbPopover = null) {
    this.updateAirportsFilterOptions(this.filterForm.value.airports);
    this.updateURL();
    popup?.close();
  }

  updateBaggageFilterOptions(result: FilterOptions) {
    this.filterForm.value.baggages = result;
    let baggageModel = new BaggageModel(
      this.filterForm.value.baggages[0],
      this.filterForm.value.baggages[1]
    );
    this.filterOptions.baggage = baggageModel;
    this.currentFilters.baggage = new ResultFilterBaggage();
    this.currentFilters.baggage.free = baggageModel.freeBaggage;
    this.currentFilters.baggage.paid = baggageModel.paidBaggage;
  }
  sendBaggageFilterOptions(popup: NgbPopover = null) {
    this.updateBaggageFilterOptions(this.filterForm.value.baggages);
    this.updateURL();
    popup?.close();
  }

  updatePriceFilterOptions(result?: FilterOptions): void {
    this.selectedMinimalPrice = result?.minimalPrice ?? this.selectedMinimalPrice;
    this.selectedMaximumPrice = result?.maximumPrice ?? this.selectedMaximumPrice;
    this.ngxLoader.startBackground();
    this.filterOptions.minimalPrice = this.selectedMinimalPrice;
    this.filterOptions.maximumPrice = this.selectedMaximumPrice;
    this.ngxLoader.stopBackground();
  }
  sendPriceFilterOptions(popup: NgbPopover = null): void {
    this.updatePriceFilterOptions();
    this.updateURL();
    popup?.close();
  }

  updateDurationFilterOptions(result?: FilterDuration[]): void {
    this.filterOptions.filterDuration = result ?? this.filterDuration;
  }
  sendDurationFilterOptions(popup: NgbPopover = null): void {
    this.updateDurationFilterOptions();
    this.updateURL();
    popup?.close();
  }

  updateTimeArrivalFilterOptions(result: Array<FilterTime>) {
    const maximumSelected = result[0].maximumTimeSelected === 24 ? moment().hour(23).endOf('hour') : moment().hour(result[0].maximumTimeSelected).startOf('hour');
    this.currentFilters.arrivalTime = this.timeArrival.map((item, index) => {
      const filterTime = new ResultFilterTime();
      filterTime.minTime = moment().hour(item.minimalTimeSelected).startOf('hour');
      filterTime.maxTime = maximumSelected;

      return filterTime;
    });
    this.filterOptions.timeArrival = [...result];
  }
  sendTimeArrivalFilterOptions(popup: NgbPopover = null): void {
    let listFilterTime = this.timeArrival.map(_filterTime => {
      _filterTime.minimalDate = new Date(_filterTime.departureDate + this.convertDate(_filterTime.minimalTime.toString()))
      _filterTime.maximumDate = new Date(_filterTime.departureDate + this.convertDate(_filterTime.maximumTime.toString()))
      return _filterTime;
    });

    this.filterOptions.timeArrival = listFilterTime;
    this.changesDetectRef.detectChanges();
    this.updateURL();
    popup?.close();
  }

  updateTimeDepartureFilterOptions(result: Array<FilterTime>): void {
    const maximumTimeSelected = result[0].maximumTimeSelected === 24 ? moment().hour(23).endOf('hour') : moment().hour(result[0].maximumTimeSelected).startOf('hour');
    this.currentFilters.departureTime = this.timeDeparture.map((item, index) => {
      const filterTime = new ResultFilterTime();
      filterTime.minTime = moment().hour(item.minimalTimeSelected).startOf('hour');
      filterTime.maxTime = maximumTimeSelected;
      return filterTime;
    });
    this.filterOptions.timeDeparture = [...result];
    this.changesDetectRef.detectChanges();
  }
  sendTimeDepartureFilterOptions(popup: NgbPopover = null): void {
    let listFilterTime = this.timeDeparture.map(_filterTime => {
      _filterTime.minimalDate = new Date(_filterTime.departureDate + this.convertDate(_filterTime.minimalTime.toString()))
      _filterTime.maximumDate = new Date(_filterTime.departureDate + this.convertDate(_filterTime.maximumTime.toString()))
      return _filterTime
    });
    this.ngxLoader.startBackground();
    this.filterOptions.timeDeparture = listFilterTime;
    this.changesDetectRef.detectChanges();
    this.updateURL();
    this.ngxLoader.stopBackground();
    popup?.close();
  }

  onPriceFilterChange(selectedValues: number[]): void {
    this.selectedMinimalPrice = selectedValues[0];
    this.selectedMaximumPrice = selectedValues[1];
    this.currentFilters.minPrice = this.selectedMinimalPrice;
    this.currentFilters.maxPrice = this.selectedMaximumPrice;
  }

  onPriceMinChange(event) {
    this.selectedMinimalPrice = event;
    this.currentFilters.minPrice = this.selectedMinimalPrice;
  }

  onPriceMaxChange(event) {
    this.selectedMinimalPrice = event;
    this.currentFilters.minPrice = this.selectedMinimalPrice;
  }

  onTimeDepartureFilterChange(selectedValues: number[], filterTime: FilterTime): void {
    filterTime.minimalTimeSelected = selectedValues[0];
    filterTime.maximumTimeSelected = selectedValues[1];

    const maximumTimeSelected = filterTime.maximumTimeSelected === 24
        ? moment().hour(23).endOf('hour')
        : moment().hour(filterTime.maximumTimeSelected).startOf('hour');
    this.currentFilters.departureTime = this.timeDeparture.map((item, index) => {
      const filterTime = new ResultFilterTime();
      filterTime.minTime = moment().hour(item.minimalTimeSelected).startOf('hour');
      filterTime.maxTime = maximumTimeSelected;
      return filterTime;
    });
  }

  onTimeArrivalFilterChange(selectedValues: number[], filterTime: FilterTime): void {
    filterTime.minimalTimeSelected = selectedValues[0];
    filterTime.maximumTimeSelected = selectedValues[1];

    const maximumSelected = filterTime.maximumTimeSelected === 24 ? moment().hour(23).endOf('hour') : moment().hour(filterTime.maximumTimeSelected).startOf('hour');

    this.currentFilters.arrivalTime = this.timeArrival.map((item, index) => {
      const filterTime = new ResultFilterTime();
      filterTime.minTime = moment().hour(item.minimalTimeSelected).startOf('hour');
      filterTime.maxTime = maximumSelected;
      return filterTime;
    });
  }

  onDurationFilterChange(selectedValues: number[], duration: FilterDuration): void {
    duration.minimalDurationSelected = selectedValues[0];
    duration.maximumDurationSelected = selectedValues[1];
    this.currentFilters.durationTime = this.filterDuration.map((d) => {
      const filterTime = new ResultFilterDuration();
      filterTime.minDuration = moment.duration().add(d.minimalDurationSelected, 'h');
      filterTime.maxDuration = moment.duration().add(d.maximumDurationSelected, 'h');
      return filterTime;
    });
  }

  convertDate(minimalString) {
    if (minimalString.length < 2) {
      minimalString = '0' + minimalString;
    }

    const time = minimalString == '24' ? '23:59:59' : minimalString + ':00:00';

    let result = 'T' + time;
    return result;
  }

  clearFilters(popup: NgbPopover = null): void {
    this.ngxLoader.startBackground();
    this.clearStopsFilter();
    this.clearPriceFilter();
    this.clearAirlinesFilter();
    this.clearAirportsFilter();
    this.clearBaggageFilter();
    this.clearTimeDepartureFilter();
    this.clearTimeArrivalFilter();
    this.clearDurationFilter();
    this.updateURL();
    this.ngxLoader.stopBackground();
  }

  clearStopsFilter(popup: NgbPopover = null): void {
    this.stopQuantitiesFormArray.controls.map((c) => c.setValue(false));
    this.filterOptions.stopQuantities = [];
    this.currentFilters.stops = [];
    this.updateURL();
    popup?.close();
  }

  clearPriceFilter(popup: NgbPopover = null): void {
    this.filterOptions.minimalPrice = 0;
    this.filterOptions.maximumPrice = 0;
    this.currentFilters.minPrice = null;
    this.currentFilters.maxPrice = null;
    this.updateURL();
    popup?.close();
  }

  clearAirlinesFilter(popup: NgbPopover = null): void {
    this.airlinesFormArray.controls.map((c) => c.setValue(false));
    this.filterOptions.airlines = [];
    this.currentFilters.airlines = [];
    this.updateURL();
    popup?.close();
  }

  clearAirportsFilter(popup: NgbPopover = null): void {
    this.airportsFormArray.controls.map((c) => c.setValue(false));
    this.filterOptions.airports = [];
    this.currentFilters.airports = [];
    this.updateURL();
    popup?.close();
  }

  clearBaggageFilter(popup: NgbPopover = null): void {
    this.baggagesFormArray.controls.map((c) => c.setValue(false));
    this.filterOptions.baggage = new BaggageModel();
    this.currentFilters.baggage = null;
    this.updateURL();
    popup?.close();
  }

  clearTimeDepartureFilter(popup: NgbPopover = null): void {
    this.filterOptions.timeDeparture = [];
    this.timeDeparture.map((_departure) => {
      _departure.minimalTimeSelected = 0;
      _departure.maximumTimeSelected = 24;
    });
    this.currentFilters.departureTime = [];
    this.updateURL();
    popup?.close();
  }

  clearTimeArrivalFilter(popup: NgbPopover = null): void {
    this.filterOptions.timeArrival = [];
    this.timeArrival.map((_arrival) => {
      _arrival.minimalTimeSelected = 0;
      _arrival.maximumTimeSelected = 24;
    });
    this.currentFilters.arrivalTime = [];
    this.updateURL();
    popup?.close();
  }

  clearDurationFilter(popup: NgbPopover = null): void {
    this.filterOptions.filterDuration = [];
    this.filterDuration.map((_duration) => {
      _duration.minimalDurationSelected = _duration.minimalDuration;
      _duration.maximumDurationSelected = _duration.maximumDuration;
    });
    this.currentFilters.durationTime = [];
    this.updateURL();
    popup?.close();
  }

  updateURL() {
    this.ngxLoader.startBackground();
    this.filterOptionsChange.emit(this.filterOptions);
    const stringified = this.currentFilters.toQueryParamsJson();
    const params = { filters: stringified };
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: 'merge',
      })
      .toString();

    this.location.replaceState(url);
    this.ngxLoader.stopBackground();
  }

  isFiltersMobileInInitialState(): boolean {
    if (this.checkEmptyOrUnmarkedFiltersMobileArray('stopQuantities')) {
      return false;
    }

    else if (this.checkEmptyOrUnmarkedFiltersMobileArray('airlines')) {
      return false;
    }

    else if (this.checkEmptyOrUnmarkedFiltersMobileArray('airports')) {
      return false;
    }

    else if (!(this.filtersMobile.baggage instanceof BaggageModel) && this.checkEmptyOrUnmarkedFiltersMobileArray('baggage')) {
      return false;
    }

    else if (!this.isPriceInitialMobileState() || !this.isNoBaggageSelectedMobile()) {
      return false;
    }

    else if (!this.isTimeDepartureInitialMobile() || !this.isTimeArrivalInitialMobile() || !this.isDurationInitialMobile()) {
      return false;
    }

    return true;
  }

  checkEmptyOrUnmarkedFiltersMobileArray(key: string): boolean {
    return this.filtersMobile[key]?.length > 0 && !(this.filtersMobile[key].every((value: boolean) => value === false));
  }

  isPriceInitialMobileState(): boolean {
    const isMinimalPriceInitial = this.filtersMobile.minimalPrice === 0 || this.filtersMobile.minimalPrice === this.minimalPrice;
    const isMaximumPriceInitial = this.filtersMobile.maximumPrice === 0 || this.filtersMobile.maximumPrice === this.maximumPrice;
    return isMinimalPriceInitial && isMaximumPriceInitial;
  }

  isNoBaggageSelectedMobile(): boolean {
    return !this.filtersMobile.baggage?.freeBaggage && !this.filtersMobile.baggage?.paidBaggage;
  }

  isTimeDepartureInitialMobile(): boolean {
    if (this.filtersMobile.timeDeparture.length === 0) {
      return true;
    }

    for (const item of this.filtersMobile.timeDeparture) {
      if (item.minimalTime !== item.minimalTimeSelected || item.maximumTime !== item.maximumTimeSelected) {
        return false;
      }
    }
    return true;
  }

  isTimeArrivalInitialMobile(): boolean {
    if (this.filtersMobile.timeArrival.length === 0) {
      return true;
    }

    for (const item of this.filtersMobile.timeArrival) {
      if (item.minimalTime !== item.minimalTimeSelected || item.maximumTime !== item.maximumTimeSelected) {
        return false;
      }
    }
    return true;
  }

  isDurationInitialMobile(): boolean {
    if (this.filtersMobile.filterDuration.length === 0) {
      return true;
    }

    for (const item of this.filtersMobile.filterDuration) {
      if (item.minimalDuration !== item.minimalDurationSelected || item.maximumDuration !== item.maximumDurationSelected) {
        return false;
      }
    }
    return true;
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: string, optIndex: number): void {
    this.selectedOption = option;
    this.isDropdownOpen = false;
    switch (optIndex) {
      //Menor preço
      case 0:
        this.filterOptions.filterMinimumPrice = true;
        this.filterOptions.filterQuickTripTime = false;
        this.filterOptions.filterRecommendedTrip = false;
        break;
      //Mais rápido
      case 1:
        this.filterOptions.filterMinimumPrice = false;
        this.filterOptions.filterQuickTripTime = true;
        this.filterOptions.filterRecommendedTrip = false;
        break;
      //Recomendado
      case 2:
        this.filterOptions.filterMinimumPrice = false;
        this.filterOptions.filterQuickTripTime = false;
        this.filterOptions.filterRecommendedTrip = true;
        break;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
