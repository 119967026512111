import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NpnSliderModule } from "npn-slider";
import { ModalMobileComponent } from './modal-mobile.component';
import { StopFilterModule } from '../stop-filter/stop-filter.module';
import { CiasFilterModule } from '../cias-filter/cias-filter.module';
import { AirportFilterModule } from '../airport-filter/airport-filter.module';
import { BaggageFilterModule } from '../baggage-filter/baggage-filter.module';
import { PriceFilterModule } from '../price-filter/price-filter.module';
import { DepartureFilterModule } from '../departure-filter/departure-filter.module';
import { ArrivalFilterModule } from '../arrival-filter/arrival-filter.module';
import { DurationFilterModule } from '../duration-filter/duration-filter.module';

@NgModule({
  declarations: [
    ModalMobileComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    NpnSliderModule,
    StopFilterModule,
    CiasFilterModule,
    AirportFilterModule,
    BaggageFilterModule,
    PriceFilterModule,
    DepartureFilterModule,
    ArrivalFilterModule,
    DurationFilterModule
  ],
  exports: [
    ModalMobileComponent  ]
})
export class ModalMobileModule { }
