<header class="award" *ngIf="bestPrice">
    <i class="fas fa-award"></i>
    <h4>Melhor Preço</h4>
</header>
<aside class="price-container" [ngClass.gt-sm]="'with-shadow'">
    <header *ngIf="showFamilies" class="family">
        <app-result-list-family
          [flightList]="this.flightList"
          [radioName]="this.radioName"
          (selectedFlight)="setChosenFlight($event)"
          (selectedFamilyName)="setSelectedFamily($event)"
          (upsellPrice)="setUpsellPrice($event)"
        >
        </app-result-list-family>
    </header>
    <footer>
        <div class="price-info">
            <p>Tarifa por Adulto
              <span>{{ this.fareByPaxType.Adult | currency : "R$"}}</span>
            </p>
            <p *ngIf="this.flightSearch.adultQty > 0">{{ this.flightSearch.adultQty }} {{ this.flightSearch.adultQty > 1 ? 'Adultos' : 'Adulto'}} <span>{{ this.flightSearch.adultQty * this.fareByPaxType.Adult | currency : "R$" }}</span></p>
            <p *ngIf="this.flightSearch.childrenQty > 0">{{ this.flightSearch.childrenQty }} Criança <span>{{ this.flightSearch.childrenQty * this.fareByPaxType.Child | currency : "R$" }}</span></p>
            <p *ngIf="this.flightSearch.infantQty > 0">{{ this.flightSearch.infantQty }} Bebê <span>{{ this.flightSearch.infantQty * this.fareByPaxType.Infant | currency : "R$" }}</span></p>
            <p>Taxas e impostos <span>R$ {{this.getTaxes | number: '1.2-2'}}</span></p>
            <p>Total <span data-zt="totalPrice">{{ this.chosenFlight.totalPrice | currency : "R$" }}</span></p>
        </div>
        <div class="send-slice" *ngIf="this.tripType != this.flightTypes.MultiSlice">
            <zc-installment
                [maxInstallments]="maxAllowedInstallment"
                [hasCardTax]="hasCardTax"
            ></zc-installment>

            <button data-zt="buyButtonToCheckout" (click)="sendSlicesToCheckout()">Comprar</button>
        </div>
    </footer>
</aside>
