import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutConfig } from './models/layout-config';

@Injectable({
  providedIn: 'root'
})
export class LayoutConfigService implements OnDestroy {
  private subject$: BehaviorSubject<LayoutConfig>;

  public get currentConfig$(): Observable<LayoutConfig> {
    return this.subject$;
  }

  constructor() {
    const initialModel = new LayoutConfig();
    this.subject$ = new BehaviorSubject<LayoutConfig>(initialModel);
  }

  public setConfig(showHeader: boolean, showFooter: boolean, showHeaderLinks = true): void {
    const model = new LayoutConfig();
    model.showFooter = showFooter;
    model.showHeader = showHeader;
    model.showHeaderLinks = showHeaderLinks

    this.subject$.next(model);
  }

  public ngOnDestroy(): void {
    this.subject$.complete();
  }
}
