<section class="engine-search-container">
    <form #tripForm="ngForm" class="trip-form" gdColumns="1fr 1fr 1.2fr 1fr .6fr" gdColumns.md="1fr 1fr 1.2fr" gdColumns.lt-md="1fr" gdGap="0.5em">
        <div class="form-group origin-section" data-zt="flightSearchOrigin">
            <app-find-airports
              #elOriginAirport
              [flightSearchFields]="this.flightSearchFields"
              (chosenAirport)="setOrigin($event)"
              [tripTypeChosen]="this.tripTypeChosen">
            </app-find-airports>
        </div>
        <div class="form-group destination-section" data-zt="flightSearchDestination">
            <app-find-airports
              #elDestinationAirport
              title='Destino'
              [flightSearchFields]="this.flightSearchFields"
              [isOrigin]="false"
              (chosenAirport)="setDestination($event)"
              [tripTypeChosen]="this.tripTypeChosen">
            </app-find-airports>
        </div>
        <div class="form-group dates" data-zt="flightSearchDateSelection">
            <app-calendar *ngIf="this.flightSearchFields.type == this.flightTypes.RoundTrip"
              #elDepartureDate
              [calendarType]="this.calendarTypes.TwoDates"
              (chosenDates)="setDates($event)"
              [flightSearchFields]="this.flightSearchFields">
            </app-calendar>
            <app-calendar *ngIf="this.flightSearchFields.type == this.flightTypes.OneWay"
              #elArrivalDate
              [calendarType]="this.calendarTypes.OneDate"
              (chosenDates)="setDates($event)"
              [flightSearchFields]="this.flightSearchFields">
            </app-calendar>
        </div>
        <div class="form-group passenger-group" data-zt="flightSearchPassengerConfig">
            <app-passengers
              #elPassengers
              [(flightSearchFields)]="this.flightSearchFields">
            </app-passengers>
        </div>
        <div class="form-group find-hotels txC">
            <div>
                <small *ngIf="this.tripAmountDays && this.flightSearchFields.type == this.flightTypes.RoundTrip">
                    Viagem de
                    <span data-zt="flightSearchTripDays" class="amount-days-accommodation">{{ this.tripAmountDays }}
                        {{ this.tripAmountDays > 1 ? "dias" : "dia" }} </span>
                </small>
            </div>
            <button data-zt="flightSearchSubmit" type="submit" (click)="onSubmit()">Buscar</button>
        </div>
    </form>
</section>
