import { Component, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ApiAirportSearchService, ApiFlightType, ApiFlightClass, ApiCustomer } from '@zupper/data';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  AnalyticsService,
  CustomerLoginService,
  HeaderMenuService,
  LayoutConfig,
  LayoutConfigService,
  TagServiceService,
  ZupperProduct,
  ZupperVersionService,
  TagModels,
} from '@zupper/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import * as _ from 'lodash';
import { SubSink } from 'subsink';
import { filter } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { environment } from '../environments/environment';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  product = ZupperProduct.AERIAL;

  searchForm: FormGroup;
  loading: boolean;
  showAdvanced: boolean;
  departureDateOptions: Object;
  returnDateOptions: Object;

  showHeaderLinks: boolean = true;

  peopleQtyList: number[];
  adultQtyList: number[];
  flightClassesList: object[];
  selectedOriginDescription: any;
  selectedDestinationDescription: any;
  selectedCityOriginDescription: any;
  selectedCityDestinationDescription: any;
  showProductsHeader: boolean = true;
  showFooterMail: boolean = true;

  showHeader = false;
  mobileMenuOpen = false;
  currentCustomer: ApiCustomer;
  showFooter = true;
  tagModels: TagModels;

  private subs = new SubSink();
  private subscriptionTag: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private airportSearch: ApiAirportSearchService,
    private modalService: NgbModal,
    private analytics: AnalyticsService,
    private router: Router,
    private media: MediaObserver,
    private headerMenuService: HeaderMenuService,
    private changeDetectionRef: ChangeDetectorRef,
    private customerLoginService: CustomerLoginService,
    private layoutConfigService: LayoutConfigService,
    private zupperVersionService: ZupperVersionService,
    private tagServiceService: TagServiceService
  ) {
    this.subs.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.analytics.session();
          this.analytics.pageView();
        }
      })
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd && event.snapshot.children.length == 0)
      )
      .subscribe((event: ActivationEnd) => {
        if (
          event.snapshot.data.hasOwnProperty('showHeader') &&
          event.snapshot.data['showHeader'] == false
        ) {
          this.showProductsHeader = false;
        } else {
          this.showProductsHeader = true;
        }

        if (
          event.snapshot.data.hasOwnProperty('showHeaderLinks') &&
          event.snapshot.data['showHeaderLinks'] == false
        ) {
          this.showHeaderLinks = false;
        } else {
          this.showHeaderLinks = true;
        }

        if (
          event.snapshot.data.hasOwnProperty('showFooterMail') &&
          event.snapshot.data['showFooterMail'] == false
        ) {
          this.showFooterMail = false;
        } else {
          this.showFooterMail = true;
        }
      });

    this.peopleQtyList = [0, 1, 2, 3, 4, 5, 6];
    this.adultQtyList = [1, 2, 3, 4, 5, 6];
    this.flightClassesList = [
      { title: 'Primeira Classe', value: ApiFlightClass.First },
      { title: 'Econômica', value: ApiFlightClass.Economic },
      { title: 'Executiva', value: ApiFlightClass.Business },
    ];

    this.departureDateOptions = {
      min: moment(),
      max: moment().add(moment.duration(12, 'month')),
    };

    this.returnDateOptions = {
      min: moment(),
      max: moment().add(moment.duration(12, 'month')),
    };

    this.showAdvanced = false;
    this.searchForm = this.fb.group({
      type: [''],
      originAirport: [''],
      originDescriptionAirport: [''],
      selectedOriginAirport: [''],
      destinationAirport: [''],
      destinationDescriptionAirport: [''],
      selectedDestinationAirport: [''],
      departureDate: [''],
      returnDate: [''],
      adultQty: [''],
      childrenQty: [''],
      infantQty: [''],
      flightClass: [''],
    });
  }

  ngOnInit() {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.headerMenuService.setStatus(false);
      }
    });
    this.subscriptionTag = this.tagServiceService.configuration$.subscribe((data) => {
      this.tagModels = data;
    });
    this.subs.add(
      this.media.asObservable().subscribe((change: MediaChange[]) => {
        const mobile = ['xs', 'sm'];
        if (mobile.includes(change[0].mqAlias)) {
          document.body.classList.add('mobile');
        } else {
          document.body.classList.remove('mobile');
        }
      })
    );
    this.subs.add(
      this.headerMenuService.currentMenuStatus$.subscribe((status: boolean) => {
        this.mobileMenuOpen = status;
        this.changeDetectionRef.detectChanges();
      })
    );
    this.subs.add(
      this.customerLoginService.currentCustomer$.subscribe((customer: ApiCustomer) => {
        this.currentCustomer = customer;
      })
    );
    this.subs.add(
      this.layoutConfigService.currentConfig$.subscribe((config: LayoutConfig) => {
        this.showHeader = config.showHeader;
        this.showFooter = config.showFooter;
        this.changeDetectionRef.detectChanges();
      })
    );

    let subscription = this.activatedRoute.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('type')) {
        this.searchForm.controls['type'].setValue(params['type']);
        this.searchForm.controls['originAirport'].setValue(params['slices[0][originAirport]']);
        this.searchForm.controls['destinationAirport'].setValue(
          params['slices[0][destinationAirport]']
        );
        this.searchForm.controls['departureDate'].setValue(
          moment(params['slices[0][departureDate]'])
        );
        this.searchForm.controls['adultQty'].setValue(
          params.hasOwnProperty('adultQty') ? params['adultQty'] : 1
        );
        this.searchForm.controls['childrenQty'].setValue(
          params.hasOwnProperty('childrenQty') ? params['childrenQty'] : 0
        );
        this.searchForm.controls['infantQty'].setValue(
          params.hasOwnProperty('infantQty') ? params['infantQty'] : 0
        );

        if (params['type'] === ApiFlightType.RoundTrip) {
          this.searchForm.controls['returnDate'].setValue(
            moment(params['slices[1][departureDate]'])
          );
        } else {
          this.searchForm.controls['returnDate'].disable();
        }

        const flightClass =
          params.hasOwnProperty('flightClasses') &&
          _.isArray(params['flightClasses']) &&
          _.isString(params['flightClasses'][0])
            ? params['flightClasses'][0]
            : ApiFlightClass.Economic;

        this.searchForm.controls['flightClass'].setValue(flightClass);

        this.initAutoComplete();
      }
    });

    this.subs.add(subscription);

    this.subs.add(
      this.searchForm.controls['type'].valueChanges.subscribe((val) => {
        if (val === <string>ApiFlightType.OneWay) {
          this.searchForm.controls['returnDate'].setValue(null);
          this.searchForm.controls['returnDate'].disable();
        } else {
          this.searchForm.controls['returnDate'].enable();
          if (moment.isMoment(this.searchForm.controls['departureDate'].value)) {
            const departureDate = this.searchForm.controls['departureDate'].value.clone();
            this.searchForm.controls['returnDate'].setValue(departureDate.add(1, 'day'));
          }
        }
      })
    );
  }

  openSideMenu(): void {
    this.mobileMenuOpen = true;
    this.headerMenuService.setStatus(true);
  }
  goToSearch(): void {
    window.open(environment.aerial.url, '_top');
  }

  initAutoComplete() {
    // origin
    const originAirport = this.searchForm.controls['originAirport'].value;
    if (originAirport) {
      let airportSubscription = this.searchAirport(originAirport).subscribe((airports) => {
        this.selectedOriginDescription = airports[0]['description'];
        this.selectedCityOriginDescription = airports[0]['city'];
        this.searchForm.controls['selectedOriginAirport'].setValue(airports[0]);
        this.searchForm.controls['originDescriptionAirport'].setValue(airports[0]['description']);
      });

      this.subs.add(airportSubscription);
    }
    // destination
    const destinationAirport = this.searchForm.controls['destinationAirport'].value;
    if (destinationAirport) {
      let destAirportSubscription = this.searchAirport(destinationAirport).subscribe((airports) => {
        this.selectedDestinationDescription = airports[0]['description'];
        this.selectedCityDestinationDescription = airports[0]['city'];
        this.searchForm.controls['selectedDestinationAirport'].setValue(airports[0]);
        this.searchForm.controls['destinationDescriptionAirport'].setValue(
          airports[0]['description']
        );
      });
      this.subs.add(destAirportSubscription);
    } else {
      this.selectedDestinationDescription = null;
    }
  }

  searchAirport = (query: string) => {
    return new Observable((obs) => {
      let airportSubscription = this.airportSearch.run(query).subscribe((airports) => {
        obs.next(
          airports.map((a) => ({
            title: a.airportCode,
            subtitle: a.getFullLocationDescription(),
            description: a.getLocationDescription(),
            country: a.country,
            city: a.city,
          }))
        );
        obs.complete();
      });

      this.subs.add(airportSubscription);
    });
  };

  getDescPassengers() {
    const passengersDesc = [];
    // tslint:disable-next-line: max-line-length
    passengersDesc.push(
      `${this.searchForm.controls['adultQty'].value} ` +
        (this.searchForm.controls['adultQty'].value > 1 ? 'Adultos' : 'Adulto')
    );

    if (this.searchForm.controls['childrenQty'].value > 0) {
      passengersDesc.push(
        `${this.searchForm.controls['childrenQty'].value} ` +
          (this.searchForm.controls['childrenQty'].value > 1 ? 'Crianças' : 'Criança')
      );
    }

    if (this.searchForm.controls['infantQty'].value > 0) {
      passengersDesc.push(
        `${this.searchForm.controls['infantQty'].value} ` +
          (this.searchForm.controls['infantQty'].value > 1 ? 'Bebês' : 'Bebê')
      );
    }

    return passengersDesc.join(', ');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.subscriptionTag.unsubscribe();
  }
}
