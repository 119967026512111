import { Injectable } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {

  private connectionStatusSubject$ = new Subject<boolean>();

  constructor() {
    window.addEventListener('online', () => this.attConnectionStatus());
    window.addEventListener('offline', () => this.attConnectionStatus());
  
  }

  get online(): boolean {
    return !!window.navigator.onLine;
  }

  get connectionStatus(): Observable<boolean> {
     return this.connectionStatusSubject$.asObservable();
  }

  attConnectionStatus() {
    this.connectionStatusSubject$.next(this.online);
  }

}
