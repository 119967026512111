import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { SegmentsResponseModel, SliceResponse, AvailableFlightsResponse } from '@zupper/aerial-components';
import { ApiFilterModel, FilterAirlinesModel } from 'projects/aerial/src/app/models/api-filter.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: "app-result-list-item-multi-slice",
  templateUrl: "./result-list-item-multi-slice.component.html",
  styleUrls: ["./result-list-item-multi-slice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ResultListItemMultiSliceComponent implements OnInit {
  currentFlightId: string = undefined;
  @Input() responseIdentification: number = 0;
  @Input() flightIdentification: number = 0;
  @Input() slices: SliceResponse[] = [];
  @Input() flights: AvailableFlightsResponse[] = [];

  @Input() filterMeta: ApiFilterModel[][] = [];
  @Output() chosenFlight = new EventEmitter();

  public get isMobile(): boolean {
    return this._mediaObserver.isActive(['xs', 'sm']);
  }

  constructor(private _mediaObserver: MediaObserver) { }
  public expandDetails = [];


  ngOnInit(): void {
    this.currentFlightId = this.flights.at(0).flightId;
  }

  sendFlightSlice(flight: AvailableFlightsResponse, event: MouseEvent) {
    this.chosenFlight.emit(flight);
    this.currentFlightId = flight.flightId;
  }

  getSliceDuration(slice: SliceResponse | SegmentsResponseModel) {
    let departure = new Date(slice.departureDate);
    let arrival = new Date(slice.arrivalDate);

    return this.timeDiffCalc(arrival, departure);
  }

  getTransferDuration(
    first: SegmentsResponseModel,
    second: SegmentsResponseModel
  ) {
    let departure = new Date(first.arrivalDate);
    let arrival = new Date(second.departureDate);

    return this.timeDiffCalc(arrival, departure);
  }

  private timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let result = hours.toString().concat("h");
    minutes > 0 ? result.concat(minutes.toString()) : null;
    return hours.toString().concat("h", minutes.toString());
  }

  getStopsCount(slice: SliceResponse) {
    let segments = slice.segments.length - 1;
    return segments > 0
      ? segments == 1
        ? segments.toString().concat(" parada")
        : segments.toString().concat(" paradas")
      : "Voo Direto";
  }

  public toggleSliceDetails(flightIndex: number, event: MouseEvent): void {
    event.stopPropagation();
    const index = this.expandDetails.indexOf(flightIndex);
    this.expandDetails.includes(flightIndex)
      ? this.expandDetails.splice(index, 1)
      : this.expandDetails.push(flightIndex)
  }

  getAirlineInfo(slice: SliceResponse): FilterAirlinesModel {
    let response = this.filterMeta[this.flightIdentification][slice.source].filterData.trip_1.airlines.filter((airline) => {
      return airline.iataCode == slice.issuingAirline;
    })[0];

    if (response == undefined || response == null) {
      let rulesNull = {
        name: "Airline " + slice.issuingAirline,
        iataCode: slice.issuingAirline,
        logoUrl: "/assets/images/airlines/404.png",
        color: null,
      };
      return rulesNull;
    }
    return response;
  }
}
