import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniqueFamilyPipe } from './uniqueFamily.pipe';
import { UniqueFlightPipe } from './uniqueFlight.pipe';
import { FamilyPipe } from './family.pipe';



@NgModule({
  declarations: [
    UniqueFamilyPipe,
    UniqueFlightPipe,
    FamilyPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UniqueFamilyPipe,
    UniqueFlightPipe,
    FamilyPipe
  ]
})
export class PipesModule { }
