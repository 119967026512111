import { Injectable } from '@angular/core';
import { CreditCard } from 'angular-cc-library';
import { CREDIT_CARD_CONFIG } from '@zupper/data';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ValidationsService {
  constructor() {}

  isValidCC(value: string): boolean {
    value = value.replace(' ', '');
    const cardsConfig = CREDIT_CARD_CONFIG;

    return !!cardsConfig.find((c) => c.regex.test(value));
  }

  isValidCCDate(value: string) {
    if (!value || value.length < 4) {
      return false;
    }

    const parts: Array<any> = value.match(/[0-9]{2}/g);
    const oDate: any = this.validDate(parts[1] * 1 + 2000, parseInt(parts[0], 10), 1);

    if (!oDate) {
      return false;
    }

    return oDate > new Date();
  }

  validDate(year: number, month: number, day: number) {
    if (month < 1 || month > 12) {
      return false;
    }

    if (day < 1 || day > 31) {
      return false;
    }

    return new Date(year, month, day);
  }

  validCPF(strCPF: string) {
    let Soma: number;
    let Resto: number;

    Soma = 0;

    if (!/^(\d)(?!\1+$)\d*$/.test(strCPF)) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    }

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    }

    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11), 10)) {
      return false;
    }

    return true;
  }

  creditCardFlag(value: string) {
    let ret: string;
    const typeCard = [
      {
        name: 'ELO',
        regex:
          /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
      },
      { name: 'VISA', regex: /^4/ },
      {
        name: 'MASTER',
        regex:
          /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      },
      { name: 'AMEX', regex: /^3[47]{1}/ },
      { name: 'DINERS', regex: /^3(?:0[0-5]|[68]){1}/ },
      { name: 'HIPER', regex: /^606282/ },
    ];

    for (const i in typeCard) {
      if (typeCard[i].regex.test(value)) {
        ret = typeCard[i].name;
        break;
      }
    }

    return ret;
  }

  isValidPhone(value: string) {
    if (!value) {
      return true;
    }
    value = value.replace(/[^0-9]/g, '');
    return this.isCellPhone(value) ? value.length === 11 : value.length === 10;
  }

  isCellPhone(value: string) {
    if (!value) {
      return true;
    }
    value = value.length > 0 ? value.replace(/[^0-9]/g, '') : '';
    return value.length >= 3 && value.substr(2, 1) === '9';
  }

  isValidEmail(value: any): boolean {
    if (!value) {
      value = '';
    }

    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value)) {
      return false;
    }
    let firstCharacter = value.charAt(0);
    if (!isNaN(firstCharacter) || re.test(value)) {
      return true;
    }
  }

  isValidAddress(value: string): boolean {
    if (value != null) {
      value = value.trim();
    }

    if (!value || !value.length) {
      return false;
    }

    if (!this.validateAccentuatedNames(value)) {
      return false;
    }

    return true;
  }

  isValidTextWithNumber(value: string): boolean {
    if (value != null) {
      value = value.trim();
    }

    if (value == '' || value == null) {
      return true;
    }

    if (!this.validateAccentuatedAndNumbers(value)) {
      return false;
    }

    return true;
  }

  hasAgnome(value: string): boolean {
    const parts = value
      .split(' ')
      .map((v) => v.toLowerCase())
      .map((v) => this.slugify(v));

    const agnomeList = [
      'Junior',
      'Neto',
      'Filho',
      'Sobrinho',
      'Primeiro',
      'Segundo',
      'Terceiro',
      'II',
      'III',
    ].map((v) => v.toLowerCase()); // Convert tudo em minusculo

    const hasAgnome = agnomeList.filter((v) => parts.indexOf(v) !== -1).length > 0;

    if (parts.length === 1 && hasAgnome) {
      return true;
    }

    return false;
  }

  isValidName(value: string, ignore_whitespace?: boolean, max_words?: number): boolean {
    if (value != null) {
      value = value.trim();
    }    

    if (!value || !value.length) {
      return false;
    }

    if (!this.validateAccentuatedNames(value)) {
      return false;
    }

    const parts = value
      .split(' ')
      .map((v) => v.toLowerCase())
      .map((v) => this.slugify(v));
    // Se for primeiro nome e existir mais de uma palavra da erro
    /* if (!ignore_whitespace && parts.length > 1) {
          return false;
      } */
    // Verifica se a quantidade de palavras é maior do que a quantidade maxima
    if (max_words && parts.length > max_words) {
      return false;
    }

    if (this.hasAgnome(value)) {
      return false;
    }

    return true;
  }

  private validateAccentuatedNames(data: string): boolean {
    return /^[A-Z àÀáÁâÂãäÄÅåªèÈéÉêÊëËìÌíÍîÎïÏòÒóÓôÔõÕöÖºùÙúÚûÛüÜñÑçÇ]+$/gim.test(data);
  }

  private validateAccentuatedAndNumbers(data: string): boolean {
    return /^[A-Z 0-9 àÀáÁâÂãäÄÅåªèÈéÉêÊëËìÌíÍîÎïÏòÒóÓôÔõÕöÖºùÙúÚûÛüÜñÑçÇ]+$/gim.test(data);
  }

  private slugify(value: string): string {
    return (
      value
        .toString()
        .toLowerCase()
        .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a') // Special Characters #1
        .replace(/[èÈéÉêÊëË]+/g, 'e') // Special Characters #2
        .replace(/[ìÌíÍîÎïÏ]+/g, 'i') // Special Characters #3
        .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o') // Special Characters #4
        .replace(/[ùÙúÚûÛüÜ]+/g, 'u') // Special Characters #5
        .replace(/[ýÝÿŸ]+/g, 'y') // Special Characters #6
        .replace(/[ñÑ]+/g, 'n') // Special Characters #7
        .replace(/[çÇ]+/g, 'c') // Special Characters #8
        .replace(/[ß]+/g, 'ss') // Special Characters #9
        .replace(/[Ææ]+/g, 'ae') // Special Characters #10
        .replace(/[Øøœ]+/g, 'oe') // Special Characters #11
        .replace(/[%]+/g, 'pct') // Special Characters #12
        // .replace(/\s+/g, '-')           		// Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        // .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')
    );
  }

  private checkCard(num: any): boolean {
    return CreditCard.luhnCheck(num);
  }

  private checkCard_old(num: any) {
    const msg = [];
    let tipo = null;
    let total = 0;
    let operadora: string;

    if (num.length > 16 || num[0] === 0) {
      msg.push('Número de cartão inválido');
    } else {
      const arr = [];

      for (let i = 0; i < num.length; i++) {
        if (i % 2 === 0) {
          const dig = num[i] * 2;

          if (dig > 9) {
            const dig1 = dig.toString().substr(0, 1);
            const dig2 = dig.toString().substr(1, 1);
            arr[i] = parseInt(dig1, 10) + parseInt(dig2, 10);
          } else {
            arr[i] = dig;
          }

          total += parseInt(arr[i], 10);
        } else {
          arr[i] = parseInt(num[i], 10);
          total += parseInt(arr[i], 10);
        }
      }

      switch (parseInt(num[0], 10)) {
        case 0:
          msg.push('Número incorreto');
          break;
        case 1:
          tipo = 'Empresas Aéreas';
          break;
        case 2:
          tipo = 'Empresas Aéreas';
          break;
        case 3:
          tipo = 'Viagens e Entretenimento';
          if (parseInt(num[0] + num[1], 10) === 34 || parseInt(num[0] + num[1], 10) === 37) {
            operadora = 'Amex';
          }
          if (parseInt(num[0] + num[1], 10) === 36) {
            operadora = 'Diners';
          }
          break;
        case 4:
          tipo = 'Bancos e Instituições Financeiras';
          operadora = 'visa';
          break;
        case 5:
          if (parseInt(num[0] + num[1], 10) >= 51 && parseInt(num[0] + num[1], 10) <= 55) {
            operadora = 'Mastercard';
          }
          tipo = 'Bancos e Instituições Financeiras';
          operadora = 'Mastercard';
          break;
        case 6:
          tipo = 'Bancos e Comerciais';
          operadora = '';
          break;
        case 7:
          tipo = 'Companhias de petróleo';
          operadora = '';
          break;
        case 8:
          tipo = 'Companhia de telecomunicações';
          operadora = '';
          break;
        case 9:
          tipo = 'Nacionais';
          operadora = '';
          break;
        default:
          msg.push('Número incorreto');
          break;
      }
    }

    if (msg.length === 0 && total % 10 === 0) {
      // console.log('Cartão válido: (' + total + ')');
      // console.log('Tipo: ' + tipo);
      // console.log('Operadora: ' + operadora);

      return true;
    }
    // console.log(msg);
    // console.log('Cartão inválido: (' + total + ')');
    return false;
  }

  isValidAdultBirthday(value: string, format = 'YYYY-MM-DD'): boolean {
    const mm = moment(value, format);
    if (!mm.isValid()) {
      return false;
    }

    return moment().diff(mm, 'years') >= 18;
  }

  isValidBirthday(value: string, type: 'ADT' | 'CHD' | 'INF', format = 'YYYY-MM-DD'): boolean {
    const valueMoment = moment(value, format);
    const diff = moment().diff(valueMoment, 'years');

    switch (type) {
      case 'ADT':
        return diff >= 12;
      case 'CHD':
        return diff >= 2 && diff < 12;
      case 'INF':
        return diff < 2;
    }
  }

  isValidCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj.split('').every((char) => char === cnpj[0])) return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(1))) return false;

    return true;
  }

  isValidUsername(value: string): boolean {
    return value && (value.toLowerCase() === 'abc123' || value.toLowerCase() === '123abc');
  }

  isEqualsTo(value1: string, value2: string): boolean {
    return value1 === value2;
  }
}
