import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { WEBSITE_PAGE } from '@zupper/data';
import { AbandonmentModalConfiguration, AbandonmentModalDataConfiguration, AbandonmentModalDeviceConfiguration } from '../../models/abandonment-modal-configuration';
import { WhatsappChatLinkGeneratorService } from '../../whatsapp-chat-link-generator.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zupper-common-abandonment-modal',
  templateUrl: './abandonment-modal.component.html',
  styleUrls: ['./abandonment-modal.component.scss']
})
export class AbandonmentModalComponent {
  @Input() public abandonmentModalConfig: AbandonmentModalConfiguration;
  @Input() public page: WEBSITE_PAGE
  public innerWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  constructor(private _mediaObserver: MediaObserver, private whatsappService: WhatsappChatLinkGeneratorService, public activeModal: NgbActiveModal) {
    this.innerWidth = window.innerWidth;
  }

  get isMobile(): boolean {
    return this._mediaObserver.isActive(['xs', 'sm']);
  }

  get modalDeviceConfig(): AbandonmentModalDeviceConfiguration {
    return this.isMobile ? this.abandonmentModalConfig?.mobileModalConfig : this.abandonmentModalConfig?.desktopModalConfig;
  }

  get modalDataConfig(): AbandonmentModalDataConfiguration {
    switch (this.page) {
      case WEBSITE_PAGE.AERIAL_RESULTS:
        return this.modalDeviceConfig?.resultsConfig;
      case WEBSITE_PAGE.CHECKOUT:
        return this.modalDeviceConfig?.checkoutConfig;
    }
  }

  get ctaText(): string {
    return this.isMobile ? 'Atendimento personalizado por whatsapp' : 'Falar com especialista por whatsapp';
  }

  public onCtaClick(): void {
    const whatsappLink = this.whatsappService.generateInternationalOfferLink(
      this.abandonmentModalConfig.whatsappContactNumber,
      this.page
    );
    window.open(whatsappLink, '_blank');
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
