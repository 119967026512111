import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringMaxLength'
})
export class StringMaxLengthPipe implements PipeTransform {

  transform(value: string, maxLength: number = 10): unknown {
    return value.length > maxLength ? `${value.substr(0, maxLength)}...` : value;
  }

}
