import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InternetConnectionService } from '../../internet-connection.service';


@Component({
  selector: 'modal-connection-internet',
  templateUrl: './modal-connection-internet.component.html',
  styleUrls: ['./modal-connection-internet.component.scss']
})
export class ModalConnectionInternetComponent implements OnInit {

  @ViewChild('connectionModal') connectionModal: TemplateRef<any>;
  private modalRef: NgbModalRef;
  images: any = {};

  constructor(
    private modalService: NgbModal,
    private internetConnectionService: InternetConnectionService
  ) {

    this.listenStatusConnection();
  }

  ngOnInit(): void {
  }

  openConnectionModal(): void {
    this.modalRef = this.modalService.open(this.connectionModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  verificarInternet(): void {
    if (this.internetConnectionService.online) {
      if (this.modalRef) {
        this.modalRef.close(); 
        this.modalRef = null; 
      }
    }
  }

  private listenStatusConnection() {
    this.internetConnectionService.connectionStatus
      .subscribe(online => {
        if (online) {
          console.log('está online');
          this.verificarInternet();
          if (this.modalRef && this.modalRef.componentInstance) {
            this.modalRef.close();
            this.modalRef = null;
          }
        } else {
          console.log('está offline');
          if (!this.modalRef || !this.modalRef.componentInstance) {
            this.openConnectionModal();
          }
        }
      });
  }

}