<div class="abandonment-modal">
    <div class="top-bubble">
        <img src="assets/img/abandonment-modal-top-bubble.svg">
    </div>
    <div type="button" class="close-button" (click)="closeModal()">
        <img src="assets/img/close.svg">
    </div>
    <div class="abandonment-info" *ngIf="!!abandonmentModalConfig">
        <div class="icon">
            <img src="assets/img/abandonment-modal-central-icon.svg">
        </div>
        <div class="title-wrapper">
            <h2 class="primary-text" [innerHtml]="modalDataConfig.primaryText"></h2>
        </div>
        <h3 class="secondary-text" [innerHtml]="modalDataConfig.secondaryText"></h3>
        <div class="cta-wrapper">
            <div class="green-background" (click)="onCtaClick()">
                <i class="fab fa-whatsapp"></i>
                <span [innerHTML]="ctaText"></span>
            </div>
        </div>
        <small class="no-help-link" (click)="closeModal()">Não quero ajuda</small>
    </div>
    <div class="bottom-bubble">
        <img [src]="innerWidth > 767.98 ? 'assets/img/abandonment-modal-bottom-bubble.svg' : 'assets/img/abandonment-modal-bottom-bubble-mobile.svg'">
    </div>
</div>