import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FilterAirlinesModel, FilterOptions } from '../../../models/api-filter.model';

@Component({
  selector: 'app-cias-filter',
  templateUrl: './cias-filter.component.html',
  styleUrls: ['./cias-filter.component.scss']
})
export class CiasFilterComponent implements OnInit {

  @Input() availableAirlines: Array<FilterAirlinesModel> = [];
  @Input() isMultiCias: boolean;
  @Input() filterOptionsMobile: FilterOptions;
  @Input() selectedAirlinesFilters: boolean[] = [];
  @Input() clearAllFilters: boolean;
  @Output() onFilterChangeCias = new EventEmitter<any>();

  formCiasFilter = this._fb.group({
    cias: this._fb.array([])
  })

  get ciasFilterArray(): FormArray {
    return this.formCiasFilter.get('cias') as FormArray;
  }

  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.isMultiCias && this.availableAirlines.length < 5) {
      this.availableAirlines.push({
        name: "Ida e volta com a mesma Cia", color: "", iataCode: "SAME", logoUrl: ""
      },
        { name: "Ida e volta com Cias diferentes ", color: "", iataCode: "MULTI", logoUrl: "" }),
        this.selectedAirlinesFilters.push(false, false)
    }

    this.selectedAirlinesFilters.forEach((selected, i) => {
      const isSelected = selected || false;
      this.ciasFilterArray.push(new FormControl(isSelected));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      const formArray = this.ciasFilterArray;
      for (let i = 0; i < formArray.length; i++) {
        formArray.at(i).setValue(false);
      }
    }
  }

  sendFilterOption(cias, i) {
    const formArray = this.ciasFilterArray;
    formArray.at(i).setValue(!formArray.at(i).value);
    this.onFilterChangeCias.emit(formArray.value);
  }

}
