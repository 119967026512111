import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { FormsModule } from '@angular/forms';
import { FlightSearchModule } from '../flight-search/flight-search.module';
import { AerialPromosComponent } from '../aerial-promos/aerial-promos.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ZupperCommonModule } from '@zupper/common';
import { AerialComponentsModule } from '@zupper/aerial-components';

@NgModule({
  declarations: [HomeComponent, AerialPromosComponent],
  imports: [
    CommonModule,
    FlightSearchModule,
    FormsModule,
    CarouselModule,
    ZupperCommonModule,
    AerialComponentsModule,
  ],
})
export class HomeModule {}
