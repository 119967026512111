import { WEBSITE_PAGE, WEBSITE_VIEWPORT } from '@zupper/data';
import { AbandonmentModalConfiguration } from './abandonment-modal-configuration';

export class WhatsappTagConfiguration {
  screenSizes: WebsiteScreenSize[];
  tagText: string;
  ctaText: string;
  whatsappContactNumber: string;

  constructor() {
    this.screenSizes = [];
    this.tagText = '';
    this.ctaText = '';
    this.whatsappContactNumber = '';
  }
}

export class WebsiteScreenSize {
  viewport: WEBSITE_VIEWPORT;
  active: boolean;
  pages: PageSelection[];

  constructor() {
    this.viewport = WEBSITE_VIEWPORT.DESKTOP;
    this.active = false;
    this.pages = [];
  }
}

export class PageSelection {
  page: WEBSITE_PAGE;
  isSelected: boolean;

  constructor() {
    this.page = WEBSITE_PAGE.HOME;
    this.isSelected = false;
  }

  static isSelected(selection: PageSelection, currentRoute: string) {
    return selection?.page === currentRoute && selection.isSelected;
  }
}

export class WhatsappTag {
  desktop: boolean;
  mobile: boolean;
  ctaText: string;
  tagText: string;
  whatsappContactNumber: string;
  route: string;

  constructor(config?: WhatsappTagConfiguration, currentRoute?: string) {
    this.ctaText = config.ctaText;
    this.tagText = config.tagText;
    this.whatsappContactNumber = config.whatsappContactNumber;
    this.route = currentRoute;

    for (const data of config.screenSizes) {
      const selectedPages = data.pages.filter((screen) =>
        PageSelection.isSelected(screen, currentRoute)
      );
      if (!selectedPages.length) continue;

      const isActiveDesktop = data['viewport'] == WEBSITE_VIEWPORT.DESKTOP && data.active;
      const isActiveMobile = data['viewport'] == WEBSITE_VIEWPORT.MOBILE && data.active;

      selectedPages.forEach(() => {
        if (isActiveDesktop) this.desktop = true;
        else this.mobile = isActiveMobile;
      });
    }
  }
}

export class DestinationGuideConfiguration {
  isActive: boolean;
  url: string;

  constructor(input?: DestinationGuideConfigurationAttributes) {
    this.isActive = input?.isActive ?? false;
    this.url = input?.url ?? '';
  }
}

interface DestinationGuideConfigurationAttributes {
  isActive: boolean;
  url: string;
}

export interface TagConfigurations {
  whatsappModel: WhatsappTagConfiguration;
  destinationGuideModel: DestinationGuideConfiguration;
  abandonmentModalModel: AbandonmentModalConfiguration;
}
export interface TagModels {
  whatsappTag?: WhatsappTag;
  destinationGuideModel?: DestinationGuideConfiguration;
  abandonmentModalModel?: AbandonmentModalConfiguration;
}
