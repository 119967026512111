import { Injectable, OnDestroy } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TimerService } from './timer.service';

@Injectable({
    providedIn: 'root'
})
export class AbandomentModalTimerService implements OnDestroy {

    private _sub: Subscription;
    private _internalTimer$: Observable<number>;
    private _abandomentModalExpirationTime: moment.Duration;

    public timerFinished$: BehaviorSubject<boolean>;

    constructor(private _timerService: TimerService) { }

    startTimer(abandomentModalExpirationTime: moment.Duration): void {
        this._abandomentModalExpirationTime = abandomentModalExpirationTime;
        this._init();
        this._internalTimer$ = this._timerService.createTimer('abandomentModalTimer', abandomentModalExpirationTime);
        this.timeCounter();
    }

    restartTimer(): void {
        this._sub?.unsubscribe?.();
        this._internalTimer$ = this._timerService.resetTimer('abandomentModalTimer');
        this.timeCounter();
    }

    timeCounter(): void {
        this._sub = this._internalTimer$.subscribe((seconds: number) => {
            const remainingTime = this._abandomentModalExpirationTime.clone().subtract(seconds + 1, 'seconds');
            this.timerFinished$.next(remainingTime.asSeconds() == 0);
        });
    }

    private _init(): void {
        if (!this.timerFinished$ || this.timerFinished$.closed) {
            this.timerFinished$ = new BehaviorSubject<boolean>(false);
        }
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe?.();
        this._internalTimer$ = null;
    }
}
