import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCustomer } from '@zupper/data';
import { Subscription } from 'rxjs';
import { ContentConfigurationService } from '../../configuration.service';
import { CustomerLoginService } from '../../customer-login.service';
import { TagConfigurations } from '../../models/configuration';
import { LocalStorageService } from '../../storage/local-storage.service';
import { OrderTrackingModalComponent } from '../order-tracking-modal/order-tracking-modal.component';

@Component({
  selector: 'zupper-header',
  templateUrl: './zupper-header.component.html',
  styleUrls: ['./zupper-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ZupperHeaderComponent implements OnInit, OnDestroy {
  @Output() mobileMenuClick = new EventEmitter();
  @Input() showLinks = true;
  @Input() isCheckout = false;
  @Input() logo: string;
  private customerSubscription: Subscription;
  public contentConfigurationSubscription: Subscription;
  public contentConfiguration: TagConfigurations;
  public isDestinationGuideAvailable: boolean = false;
  customer: ApiCustomer;
  isCobrand: boolean = false;

  constructor(
    private modalService: NgbModal,
    private customerLoginService: CustomerLoginService,
    private changeDetectorRef: ChangeDetectorRef,
    private _localStorageService: LocalStorageService,
    private contentConfigurationService: ContentConfigurationService,
    @Inject('env') private env: any
  ) { }

  ngOnInit(): void {
    this.customerSubscription = this.customerLoginService.currentCustomer$.subscribe(
      (customer: ApiCustomer | null) => {
        this.customer = customer;
        this.changeDetectorRef.detectChanges();
      }
    );

    this.contentConfigurationSubscription = this.contentConfigurationService.configurations$.subscribe(
      (data: TagConfigurations) => {
        this.contentConfiguration = data;
        this.isDestinationGuideAvailable = this.contentConfiguration?.destinationGuideModel?.isActive;
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.customerSubscription.unsubscribe();
    this.contentConfigurationSubscription.unsubscribe();
  }

  menuClick(event: Event): void {
    event.preventDefault();
    this.mobileMenuClick.emit();
  }

  get hasWhiteLabel(): boolean {
    if(this._localStorageService.getItem('whitelabel') && !this.isCobrand){
      this.isCobrand = true;
    }
    
    return this.isCobrand;
  }

  openOrderTrackingForm(): void {
    if (!this.customer) { // only show order tracking modal if there is no logged user
      const modalRef = this.modalService.open(OrderTrackingModalComponent, {
        size: 'xl',
        centered: true,
      });
    } else { // if the user is logged in, redirect to account
      window.open(`${this.env['account']['baseUrl']}/meus-pedidos`);
    }

  }

  navigateToZupperHome(): void {
    window.open(this.env.aerial.url, '_self');
    this._localStorageService.removeItem('whitelabel');
  }

  navigateToDestinationGuide(): void {
    window.open(this.contentConfiguration?.destinationGuideModel.url, '_self');
  }
}
