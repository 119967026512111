<div class="luggage-wrapper"
  #luggagePopover="ngbPopover"
  [ngbPopover]="isMobile ? '' : luggagePopup"
  triggers="mouseenter:mouseleave"
>
  <ng-container *ngIf="isMultiCias; else sameCias">
    <i class="backpack-cias allowed"></i>
    <i class="luggage-rolling-cias allowed"></i>
    <i class="big-luggage-rolling-allowed-cias" *ngIf="baggageAllowance"></i>
    <i class="big-luggage-rolling-cias" *ngIf="!baggageAllowance"> 
      <i class="close-cias"></i>
    </i>
  </ng-container>

  <ng-template #sameCias>
    <ng-container *ngIf="displayMobileIcons; else displayDefaultIcons">
      <img src="assets/img/custom-svg-icons/outlined-briefcase-blue.svg" alt="briefcase">
      <img src="assets/img/custom-svg-icons/outlined-suitcase-rolling-blue.svg" alt="rolling-suitcase">
      <img [src]="baggageAllowance ? suitcaseBlueIcon : suitcaseGrayIcon" alt="suitcase">
    </ng-container>
    <ng-template #displayDefaultIcons>
      <i class="fas fa-briefcase allowed"></i>
      <i class="fas fa-suitcase-rolling allowed"></i>
      <i class="fas fa-suitcase" [ngClass]="baggageAllowance ? 'allowed' : null"></i>
    </ng-template>
  </ng-template>
  <span *ngIf="isMobile">Bagagem despachada não incluída</span>
</div>

<ng-template #luggagePopup>
  <div class="luggage-popup">
    <div class="grid backpack">
      <div class="icon" [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">
        <i class="fas fa-briefcase allowed"></i>
      </div>
      <div class="detail">
        <h4 [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">Incluso uma mochila ou bolsa</h4>
        <p>Tamanho limitado a caber abaixo do assento dianteiro.</p>
      </div>
    </div>
    <div class="grid suitcase-rolling">
      <div class="icon" [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">
        <i class="fas fa-suitcase-rolling allowed"></i>
      </div>
      <div class="detail">
        <h4 [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">Incluso bagagem de mão</h4>
        <p>Tamanho limitado a caber no compartimento superior do avião. Até 10kg.</p>
      </div>
    </div>
    <div class="grid suitcase" *ngIf="baggageAllowance">
      <div class="icon" [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">
        <i class="fas fa-suitcase" [ngClass]="baggageAllowance ? 'allowed' : null"></i>
      </div>
      <div class="detail">
        <h4 [ngClass]="{'blue': sliceIndex == 0, 'green': sliceIndex == 1}">Incluso bagagem para despachar</h4>
        <p>1 Mala de até 23kg por adulto.</p>
        <p>O Despache deve ser feito durante o check-in no aeroporto.</p>
      </div>
    </div>
    <div class="grid suitcase" *ngIf="!baggageAllowance">
      <div class="icon">
        <i class="fas fa-suitcase" [ngClass]="baggageAllowance ? 'allowed' : null"></i>
      </div>
      <div class="detail">
        <h4>Não incluso bagagem para despachar</h4>
        <p>É possível alterar a tarifa incluindo nas opções ao lado.</p>
      </div>
    </div>
  </div>
</ng-template>
