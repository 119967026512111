import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
// import {Price} from './model/price.model';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { Price } from '@zupper/data';
import { LocalStorageService } from '@zupper/common';

@Injectable({
  providedIn: 'root'
})
export class CheckoutPriceService {
  // private serviceUrl = environment.apiUrl + '/rest/v2/session/login';
  private serviceUrl = environment.apiPriceUrl + '/rest/v2/aerial/price';
  private content: Price;

  constructor(
    private http: HttpClient,
    private _localStorageService: LocalStorageService
  ) { }

  public getContent() {
    return this.content;
  }

  public setContet( content: Price ) {
    this.content = new Price(content);
  }

  public postPrice() {
    return new Promise( (resolve, reject) => {
      const parm = this.getParm();
      this.getPrice(parm).subscribe((item: any) => {
        this.setContet(item);
        resolve(item);
      },
      (error) => {
        reject(error);
      });
    });
  }

  private getPrice(parm: object): Observable<string> {
    return this.http.post(this.serviceUrl, parm)
      .pipe(
          map((res: any) => res),
          catchError(error => throwError(() => new Error(error.message || error)))
      );
  }

  public setCheckoutParam(item: object) {
    // Ordena o resultado pela data de embarque para nao dar erro no DSG
    if (item && item['result'] && item['result'].length && item['result'][0].slices) {
      item['result'][0].slices.sort(function(a, b) {
        if (a.departureDate > b.departureDate) {
          return 1;
        }
        if (a.departureDate < b.departureDate) {
          return -1;
        }
        return 0;
      });
    }

    this._localStorageService.setItem('checkout_param', item);
  }

  private getParm(): object {
    const new_parm = this._localStorageService.getItem<any>('checkout_param');

    if (new_parm) {
      new_parm.result[0]['passengers'] = this.getpassengers(new_parm.meta.request);
    }

    return new_parm.result[0];
  }

  private getpassengers(parm): object {
    const passengers = [];

    const adultQty = parm.adultQty;
    const childrenQty = parm.childrenQty;
    const infantQty = parm.infantQty;

    if ( adultQty >= 1 ) {
      for (let i = 0; i < adultQty; i++ ) {
        passengers.push(this.createpassengers('ADT'));
      }
    }

    if ( childrenQty >= 1 ) {
      for ( let i = 0; i < childrenQty; i++ ) {
        passengers.push(this.createpassengers('CHD'));
      }
    }

    if ( infantQty >= 1 ) {
      for ( let i = 0; i < infantQty; i++ ) {
        passengers.push(this.createpassengers('INF'));
      }
    }
    return passengers;
  }

  private createpassengers(type) {
    return {
      'firstName': null,
      'lastName': null,
      'type': type,
      'gender': null,
      'birthDate': null,
      'email': null,
      'cpf': null,
      'rg': null
    };
  }
}
