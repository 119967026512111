export enum ApiLogLevel {
  DEBUG     = 'DEBUG',
  INFO      = 'INFO',
  NOTICE    = 'NOTICE',
  WARNING   = 'WARNING',
  ERROR     = 'ERROR',
  CRITICAL  = 'CRITICAL',
  ALERT     = 'ALERT',
  EMERGENCY = 'EMERGENCY',
}

export class LogEntryModel {
    level: ApiLogLevel;
    logName: string;
    message: string;
    context: any;
    token: string;
    origin: string;

    constructor() {
      this.origin = 'WEBSITE';
    }
}
