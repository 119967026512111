<section class="background-engine-search" [ngStyle]="{ 'background': 'url(' + bannerImage + ') center bottom / cover no-repeat' }">
    <div class="background-engine-search-wrapper">
        <div class="logo-wrapper">
            <img [src]="logoImage">
        </div>
        <app-flight-search data-zt="aerialSearchEngine"></app-flight-search>
    </div>
</section>
<aerial-search-history-list class="widthPadrao" [whatsappContactNumber]="whatsappTag?.whatsappContactNumber"></aerial-search-history-list>
<zc-offers [zupperProductOffer]="zupperProductOffer"></zc-offers>
<!-- <app-aerial-promos *ngIf="qtyPromo > qtyPromoDefault" (promoSelection)="onPromoSelected($event)"></app-aerial-promos> -->
<zupper-common-promos *ngIf="qtyPromo > qtyPromoDefault" (promoSelection)="onPromoSelected($event)"
    type="aerial"></zupper-common-promos>
<section class="home-banners widthPadrao">
    <div class="div-large-banner">
        <app-banner-carousel type="power" location="home" class="large-banner"></app-banner-carousel>
    </div>
    <div class="div-small-banner">
        <app-banner-carousel type="power" location="footer" class="small-banner"></app-banner-carousel>
    </div>
</section>
<style type="text/css">
    .b2,
    .b3 {
        display: none;
    }
</style>

