import { ErrorMessage } from "ng-bootstrap-form-validation";

export const CUSTOM_ERRORS: ErrorMessage[] = [
  {
    error: 'required',
    format: customErrorRequired
  },
  {
    error: 'email',
    format: customErrorEmail
  },
  {
    error: 'existingEmail',
    format: customErrorEmailExists
  }
];

export function customErrorRequired(label: string, error: any): string {
  return 'O campo é obrigatório';
}

export function customErrorEmail(label: string, error: any): string {
  return 'Ops... Verifique o formato de e-mail digitado';
}

export function customErrorEmailExists(label: string, error: any): string {
  if (error === true) {
    return 'Este e-mail já possui uma conta';
  } else if (error === false) {
    return 'O e-mail informado não existe em nossa base de dados';
  } else {
    return 'Não foi possível validar o e-mail';
  }
}
