import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'airlinesFilter'
})
export class AirlinesFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    airlines: Array<string> = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (airlines == null || airlines.length == 0) {
      this._flightFilterStateService.setFlightCounter(originalflightList.filter(_x => !!_x));
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      if (airlines.indexOf(flights[0].airline) != -1) {
        newFlightList[index] = originalflightList[index];
      }
      if (airlines.find(_airline => _airline === "MULTI") && flights[0].isMultipleAirlinesFlight) {
        newFlightList[index] = originalflightList[index];
      }
      if (airlines.find(_airline => _airline === "SAME") && !flights[0].isMultipleAirlinesFlight) {
        newFlightList[index] = originalflightList[index];
      }
    });

    const slicesIndex = airlines.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
