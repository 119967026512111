import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindAirportsComponent } from './find-airports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FindAirportsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    FindAirportsComponent
  ]
})
export class FindAirportsModule { }
