<div class="aerial-side-menu">
  <h1>O que você está procurando?</h1>
  <ul class="aerial-side-menu-list">
    <li>
      <a
        [href]="links.homeZupperUrl"
        (click)="clearWhiteLabel()"
      >
        <span class="icon home-zupper"> </span>
        <span>Ir para Home Zupper</span>
      </a>
    </li>
    <li>
      <a
        [href]="links.helpCentralUrl"
        target="_blank"
        routerLinkActive="active"
      >
        <span class="icon customer-calls"> </span>
        <span>Central de ajuda</span>
      </a>
    </li>
    <li>
      <a (click)="openOrderTrackingForm()">
        <span class="icon customer-orders"> </span>
        <span>Acompanhar pedido</span>
      </a>
    </li>
    <li>
      <a [href]="links.loginUrl" data-zt="signInButton">
        <span class="icon customer-profile"></span>
        <span>Entrar na Minha Conta</span>
      </a>
    </li>
    <li *ngIf="!!contentConfiguration?.destinationGuideModel.url && isDestinationGuideAvailable">
      <a [href]="contentConfiguration?.destinationGuideModel.url" data-zt="destinationGuideButton">
        <span class="icon map"></span>
        <span><u>Guia de Destinos</u></span>
      </a>
    </li>
  </ul>
</div>
