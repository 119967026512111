<div class="aerial-search-history-root" *ngIf="loading || (searchHistory | async)?.length">
    <div class="aerial-search-history-component-wrapper">
        <div class="aerial-search-history-component-title">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 5H20" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14 8H17" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2"
                    stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 22L20 20" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>

            <span>Pesquisas recentes de <strong>voos</strong></span>
        </div>

        <div class="aerial-search-history-list-wrapper" *ngIf="!loading; else loadingComponent">
            <ng-template ngFor [ngForOf]="searchHistory | async" let-history>
                <aerial-search-history [aerialSearchHistory]="history" (continueService)="continueService(history)"
                    (continueSearch)="continueSearch(history)"></aerial-search-history>
            </ng-template>
        </div>

        <ng-template #loadingComponent>
            <div class="aerial-search-history-list-loading">
                <div class="aerial-search-history-loading-spinner">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.4951 12C24.4951 18.6274 19.1225 24 12.4951 24C5.8677 24 0.495117 18.6274 0.495117 12C0.495117 5.37258 5.8677 0 12.4951 0C19.1225 0 24.4951 5.37258 24.4951 12ZM4.09512 12C4.09512 16.6392 7.85593 20.4 12.4951 20.4C17.1343 20.4 20.8951 16.6392 20.8951 12C20.8951 7.36081 17.1343 3.6 12.4951 3.6C7.85593 3.6 4.09512 7.36081 4.09512 12Z"
                            fill="url(#paint0_angular_599_6127)" />
                        <defs>
                            <radialGradient id="paint0_angular_599_6127" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(12.4951 12) rotate(90) scale(12)">
                                <stop stop-color="#009DAF" />
                                <stop offset="1" stop-color="#009DAF" stop-opacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>

                </div>
                <span>Carregando pesquisas recentes...</span>
            </div>
        </ng-template>
    </div>
</div>