import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AirportModel, ApiAirportSearchService, ApiOfferBlockItem, ApiOfferService } from '@zupper/data';
import moment from 'moment';
import * as qs from 'querystring';
import { EnvironmentInterface } from '../../../models/environment.interface';
import { ZupperProductOffer } from '../../../models/zupper-product.enum';
import * as _ from 'lodash';

@Component({
  selector: 'zc-offers-card',
  templateUrl: './zc-offers-card.component.html',
  styleUrls: ['./zc-offers-card.component.scss']
})
export class ZcOffersCardComponent implements OnInit {
  @Input() offerBlockItem: ApiOfferBlockItem;
  @Input() titleProduct: string = "Voo + Hospedagem";
  @Input() zupperProductOffer: ZupperProductOffer;
  airports: AirportModel[];
  destination: string;
  queryParams: any;
  maxInstallments: number = 0;
  hasCardTax: boolean = false;

  constructor(
    private _apiOfferService: ApiOfferService,
    private _apiAirportSearchService: ApiAirportSearchService,
    private _router: Router,
    @Inject('env') private _env
  ) { }

  private _getBaseUrl(): string {
    const environment: EnvironmentInterface = this._env;
    switch (this.zupperProductOffer) {
      case ZupperProductOffer.AERIAL:
        return environment.aerial.url;
      case ZupperProductOffer.HOTEL:
        return environment.hotel.url;
      case ZupperProductOffer.PACKAGES:
        return environment.packages.url;
    }
  }

  ngOnInit(): void {
    this._convertSearchRequestToQueryParams();
    this._processInstallments();
    this._apiOfferService.airportsSubject$.subscribe((res: AirportModel[]) => {
      this.airports = res;
      this._searchAirport('destinationAirport');
    });
  }

  get imageMain(): string {
    const image = this.offerBlockItem.offer.images.find(_image => _image.isMainImage);
    return image ? image.url : 'assets/images/hotel-sem-foto.svg';
  }

  private _searchAirport(fieldName: string): void {
    const airportCode = this.offerBlockItem.offer.fields.find(_field => _field.fieldName === fieldName)?.fieldValue;
    if(airportCode) {
      this.destination = this.airports[airportCode]['city'];
    }
  }

  private _convertSearchRequestToQueryParams(): void {
    const baseObj: DeeplinkData = {
      originAirport: this._searchOfferFields('originAirport'),
      destinationAirport: this._searchOfferFields('destinationAirport'),
      departureDate: this._searchOfferFields('departureDate') ? moment(this._searchOfferFields('departureDate')).format('YYYY-MM-DD') : undefined,
      returnDate: this._searchOfferFields('returnDate') ? moment(this._searchOfferFields('returnDate')).format('YYYY-MM-DD') : undefined,
      onlyEconomicClass: true,
      offerVersionId: this.offerBlockItem.offer.offerVersionId
    };

    let params = {};
    Object.keys(baseObj).forEach(k => {
      if (!!baseObj[k]) {
        params[k] = (baseObj[k] as string);
      }
    });
    if(this._searchOfferFields('adultQty')) {
      params[`rooms[0][adultQty]`] = this._searchOfferFields('adultQty');
    }
    if(this._searchOfferFields('childAges')) {
      params[`rooms[0][childAges]`] = this._searchOfferFields('childAges').split(',').map(_x => Number(_x));
      params[`rooms[0][childAges]`].forEach((ages, index) => {
        params[`rooms[0][childAges][${index}]`] = ages.toString();
      })
    }
    this.queryParams = params;
  }

  private _searchOfferFields(field: string): any {
    return this.offerBlockItem.offer.fields.find(_field => _field.fieldName === field)?.fieldValue
  }

  redirectResult(): void {
    const params = qs.stringify(this.queryParams);

    const url = `${this._getBaseRedirectUrl()}/resultados?${params}`;
    window.open(url, '_self');
  }

  private _getBaseRedirectUrl(): string {
    const environment: EnvironmentInterface = this._env;
    switch (this.zupperProductOffer) {
      case ZupperProductOffer.AERIAL:
        return environment.aerial.url;
      case ZupperProductOffer.HOTEL:
        return environment.hotel.url;
      case ZupperProductOffer.PACKAGES:
        return environment.packages.url;
      default:
        return '';
    }
  }

  private _processInstallments(): void {
    const maxInstallmentConfig: {maxInstallment: number, hasTax: boolean} = this.offerBlockItem.offer.creditRules
      .reduce((prev, curr) => {
        const maxCurrent = _.max(curr.allowedInstallments);
        const maxInstallment = curr.minInstallmentValue.find(v => v.installmentCount === maxCurrent).installmentCount;
        const foundTaxConfig = curr.hasCardTax.find(t => t.installmentCount === maxInstallment);
        const currentConfig = {
          maxInstallment: maxInstallment,
          hasTax: foundTaxConfig?.hasTax ?? this.hasCardTax
        };
        if (!prev) {
          return currentConfig;
        } else {
          const usePrevValue = prev.maxInstallment >= currentConfig.maxInstallment;
          return {
            maxInstallment: _.max([prev.maxInstallment, currentConfig.maxInstallment]),
            hasTax: usePrevValue ? prev.hasTax : currentConfig.hasTax
          };
        }
      }, { maxInstallment: this.maxInstallments, hasTax: this.hasCardTax });

    this.maxInstallments = maxInstallmentConfig.maxInstallment;
    this.hasCardTax = maxInstallmentConfig.hasTax;
  }

}

export interface DeeplinkData {
  originAirport: string;
  destinationAirport: string;
  departureDate: moment.Moment | string;
  returnDate: moment.Moment | string;
  onlyEconomicClass: boolean;
  offerVersionId: number;
}
