import { Component, Inject } from '@angular/core';
import { AerialCheckoutBuilderService, AerialCheckoutData, AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { ApiFlightType, AppCheckerService, EnvironmentInterface } from '@zupper/common';
import * as _ from 'lodash';
import { CreditCardRulesModel } from '../../../../models/api-filter.model';
import { AbstractAerialResultComponent } from './aerial-result';

@Component({
  selector: 'app-result-list-price',
  templateUrl: './result-list-price.component.html',
  styleUrls: ['./result-list-price.component.scss'],
})
export class ResultListPriceComponent extends AbstractAerialResultComponent {
  constructor(
    protected appCheckerService: AppCheckerService,
    protected aerialCheckoutBuilder: AerialCheckoutBuilderService,
    @Inject('env') protected env: EnvironmentInterface
  ) {
    super(appCheckerService, aerialCheckoutBuilder, env);
  }

  setSelectedFamily(familyName: string) {
    this.selectedFamilyName.emit(familyName);
  }

  sendSlicesToCheckout() {
    let flight = this.chosenFlight;
    flight.slices = this.chosenSlices;

    const checkoutData: AerialCheckoutData = {
      meta: {
        request: {
          childrenQty: this.flightSearch.childrenQty,
          infantQty: this.flightSearch.infantQty,
          adultQty: this.flightSearch.adultQty,
        },
      },
      result: [
        flight
      ],
    };
    sessionStorage.setItem("upsellPrice", JSON.stringify(this.upsellPrice));
    const cid = this.aerialCheckoutBuilder.saveCheckoutData(checkoutData);

    let openBrowser = this.appCheckerService.isApp() ? "_self" : "_blank";
    window.open(this.env.checkout.url + '?cid=' + cid, openBrowser);
  }

  setChosenFlight(flight: AvailableFlightsResponse) {
    this.chosenFlight = flight;
  }

  get getTaxes() {
    return (
      Number((this.chosenFlight?.totalDuPrice | 0)) +
      Number((this.chosenFlight?.totalDu2Price | 0)) +
      Number((this.chosenFlight?.totalAirportTaxesPrice | 0))
    );
  }

  get getAdultTotalPrice() {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result +=
        this.getTotalByAdult +
        slice.baseFares[0].du2Value +
        slice.baseFares[0].incentiveValue;
    });
    return result;
  }

  get getTotalByAdult() {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result += slice.baseFares[0].fareValue + slice.baseFares[0].taxValue;
    });
    return result;
  }

  get totalTaxes() {
    return this.getTaxes;
  }

  get totalPrice() {
    return this.chosenFlight.totalPrice;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get baseFareValue() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenSlices.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.baseFareValue;
        totalFare.All += basefare.baseFareValue;
      });
    });
    return totalFare;
  }

  get totalBaseFarePrice() {
    return (
      this.baseFareValue.Adult * this.flightSearch.adultQty +
      this.baseFareValue.Child * this.flightSearch.childrenQty +
      this.baseFareValue.Infant * this.flightSearch.infantQty
    );
  }

  get fareByPaxType() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenFlight?.slices?.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.fareValue;
        totalFare.All += basefare.fareValue;
      });
    });
    return totalFare;
  }

  setUpsellPrice(upsellPrice: number) {
    this.upsellPrice = upsellPrice;
  }

  processInstallments = () => {
    const configs = this.chosenFlight.slices.map((slice: SliceResponse) => {
      const maxInstallmentConfig: { maxInstallment: number; hasTax: boolean } = (
        this.filterMeta[slice.source]['creditRules'] as CreditCardRulesModel[]
      )
        .filter((r) => r.IATACode === slice.issuingAirline)
        .reduce(
          (prev, curr) => {
            const maxCurrent = _.max(curr.allowedInstallments);
            const maxInstallment = curr.minInstallmentValue.find(
              (v) => v.installmentCount === maxCurrent
            ).installmentCount;
            const foundTaxConfig = curr.hasCardTax.find(
              (t) => t.installmentCount === maxInstallment
            );
            const currentConfig = {
              maxInstallment: maxInstallment,
              hasTax: foundTaxConfig?.hasTax ?? this.hasCardTax,
            };
            if (!prev) {
              return currentConfig;
            } else {
              const usePrevValue = prev.maxInstallment >= currentConfig.maxInstallment;
              return {
                maxInstallment: _.max([prev.maxInstallment, currentConfig.maxInstallment]),
                hasTax: usePrevValue ? prev.hasTax : currentConfig.hasTax,
              };
            }
          },
          { maxInstallment: this.maxAllowedInstallment, hasTax: this.hasCardTax }
        );

      return maxInstallmentConfig;
    });

    const bestOption = configs.reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      const usePrevValue = prev.maxInstallment >= curr.maxInstallment;
      return usePrevValue ? prev : curr;
    }, null);

    this.maxAllowedInstallment = bestOption.maxInstallment;
    this.hasCardTax = bestOption.hasTax;
  };
}
