export class AirlineFamilyListModel {
  airlines: Array<AirlineFamilyModel> = [];

  push(airlineModel: AirlineFamilyModel) {
    this.airlines.push(airlineModel);
  }

  getAirline(airlineName: string): AirlineFamilyModel {
    let foundAirline = this.airlines.find(a => a.airline == (airlineName === "JJ" ? "LA" : airlineName));
    return (foundAirline)
      ? foundAirline
      : new AirlineFamilyModel(airlineName);
  }
}

export class AirlineFamilyModel {
  airline: string;
  families: Array<FamilyDataModel> = [];

  constructor(airline: string, families: Array<FamilyDataModel> = []) {
    this.airline = airline;
    this.families = families;
  }

  addFamily(family: FamilyDataModel): this {
    this.families.push(family);
    return this;
  }

  getFamily(familyName: string): FamilyDataModel {
    let foundFamily = this.families.find(f => f.name == familyName);
    return (foundFamily)
      ? foundFamily
      : new FamilyDataModel(familyName);
  }
}

export class FamilyDataModel {
  name: string;
  fields: Array<FamilyFieldModel> = [];

  constructor(name: string, fields: Array<FamilyFieldModel> = []) {
    this.name = name;
    this.fields = fields;
  }

  addField(field: FamilyFieldModel): this {
    this.fields.push(field);
    return this;
  }

  getField(fieldName: string): FamilyFieldModel {
    let foundField = this.fields.find(f => f.name == fieldName);
    return (foundField)
      ? foundField
      : new FamilyFieldModel(null, false, null);
  }
}

export class FamilyFieldModel {
  name: string;
  checked: boolean;
  text: string;

  constructor(name: string, checked: boolean, text: string) {
    this.name = name;
    this.checked = checked;
    this.text = text;
  }
}
