import { AutoInitializable } from '@zupper/data';

export enum ZupperProduct {
  AERIAL = 1,
  HOTEL = 2,
  VEHICLE = 3,
  INSURANCE = 4,
  PACKAGES = 5,
  ACCOUNT = 6,
  DESTINATION_GUIDE = 7,
}

export class ZupperProductModel extends AutoInitializable {
  label: string;
  icon: string;
  class: string;
  badgeText: string;
  badgeClass: string;
  order: number;
  product: ZupperProduct;
  visible: boolean;
  active: boolean;
  url: string;
  image?: string;
}

export enum ZupperProductOffer {
  AERIAL = 1,
  HOTEL = 2,
  PACKAGES = 3,
}
