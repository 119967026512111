import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  checkCookieEnabled(): boolean {
    const cookieEnabled = navigator.cookieEnabled;

    if (!cookieEnabled) {
      // Verificar se é possível definir um cookie de teste
      document.cookie = 'cookieTest=1';
      const hasCookie = document.cookie.indexOf('cookieTest=') !== -1;

      // Remover o cookie de teste
      document.cookie = 'cookieTest=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      return hasCookie;
    }

    return true;
  }
}