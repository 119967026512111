<section>
    <div class="icon-closed" (click)="activeModal.close('')">
        <!-- <i class="fas fa-times-circle"></i> -->
        <img src="assets/img/close.svg">
    </div>
    <div class="modal-header">
        <img src="assets/img/logo_zupper_white.svg">
    </div>
    <div class="modal-body">
        <div class="image">
            <img src="assets/img/bg-order-tracking.svg">
        </div>
        <div class="text-content">
            <h3>Acompanhar Pedidos agora é</h3>
            <h2>Minha Conta Zupper</h2>
            <h4>Para acompanhar online o andamento de seus pedidos é preciso ter uma conta Zupper. Você conseguirá ter acesso aos pedidos anteriores mesmo que a conta seja criada agora</h4>
            <button type="button" class="btn" (click)="customerOrders($event)">
                Acessar Minha Conta
            </button>
            <p class="new-account">
                <a (click)="register($event)">
                    Criar uma conta
                </a>
            </p>
        </div>
    </div>
</section>
