import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { PartnerIdentifierEnum } from './models/partner-identifier.enum';

@Injectable({
  providedIn: 'root'
})
export class PartnerIdentificationService {

  constructor() {
  }

  getIdPartner(queryParamMap: ParamMap): string | null {
    const partnerKey = Object.values(PartnerIdentifierEnum).find(k => queryParamMap.has(k));
    return partnerKey ? queryParamMap.get(partnerKey) : null;
  }
}