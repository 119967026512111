import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsComponent } from './results.component';
import { UncombinedResultModule } from './uncombined-result/uncombined-result.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PriceMatrixModule } from './price-matrix/price-matrix.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModalFlightFamilyComponent } from '../modal-flight-family/modal-flight-family.component';
import { AirlinePipe } from '../../pipes/airline.pipe';
import { MatchHeightDirective } from '../../utils/match-height.directive';
import { ModalVoidSearchComponent, ZupperCommonModule } from '@zupper/common';
import { SearchSummaryComponent } from './search-summary/search-summary.component';

@NgModule({
  declarations: [
    ResultsComponent,
    // SearchSummaryComponent
    ModalFlightFamilyComponent,
    SearchSummaryComponent,
    AirlinePipe,
    MatchHeightDirective
  ],
  imports: [
    CommonModule,
    UncombinedResultModule,
    FormsModule,
    ReactiveFormsModule,
    ZupperCommonModule
  ],
  exports: [
    ResultsComponent,
    UncombinedResultModule,
    AirlinePipe,
    MatchHeightDirective,
    SearchSummaryComponent
  ],
  entryComponents: [
    ModalFlightFamilyComponent,
    ModalVoidSearchComponent
  ]
})
export class ResultsModule { }
