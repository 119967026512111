export class ApiFilterModel {
  APIVersion: string;
  airlineRules: AirlineRulesModel[];
  airports: AirportInfoModel[];
  filterData: FilterDataModel;
  filteredResults: number;
  pageNumber: number;
  pageResults: number;
  totalPages: number;
  totalResults: number;
}

export class AirlineRulesModel {
  creditCardRules: CreditCardRulesModel[];
  destinationAirport: string;
  originAirport: string;
  sliceIndex: number;
}

export class CreditCardRulesModel {
  IATACode: string;
  allowedInstallments: number[];
  cardAbbreviation: string;
  cardDescription: string;
  currency: string;
  minFareValue: CreditCardRulesValueModel[];
  minInstallmentValue: CreditCardRulesValueModel[];
  hasCardTax: {hasTax: boolean, installmentRuleId: number, installmentCount: number}[];
}

export class CreditCardRulesValueModel {
  value: number;
  installmentRuleId: number;
  installmentCount: number;
}

export class AirportInfoModel {
  IATACode: string;
  altitude: number;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  timezone: string;
}

export class FilterDataModel {
  priceByAirline: any;
  priceByAirport: any;
  priceByStop: any;
  requestFilters: {
    maxPrice: number;
    minPrice: number;
  };
  trip_1: Trip1Model;
}

export class Trip1Model {
  airlines: FilterAirlinesModel[];
  flightClasses: string[];
  luggageOptions: boolean[];
  maxFareValue: number;
  minFareValue: number;
  stopQuantities: number[];
}

export class FilterAirlinesModel {
  color: string;
  iataCode: string;
  logoUrl: string;
  name: string;
}

export class FilterOptions {
  stopQuantities: number[] = [];
  price: number = 0;
  minimalPrice: number = 0;
  maximumPrice: number = 0;
  airlines: Array<string> = [];
  airports: Array<string> = [];
  baggage: any;
  timeDeparture: FilterTime[] = [];
  timeArrival: FilterTime[] = [];
  filterDuration: FilterDuration[] = [];
  filterMinimumPrice: boolean = false;
  filterQuickTripTime: boolean = false;
  filterRecommendedTrip: boolean = false;
}

export class FilterTime {
  originAirport: string;
  destinationAirport: string;
  departureDate: Date;
  minimalDate: Date;
  maximumDate: Date;
  minimalTime: number;
  maximumTime: number;
  minimalTimeSelected: number;
  maximumTimeSelected: number;
}

export class FilterDuration {
  originAirport: string;
  destinationAirport: string;
  minimalDuration: number = 0;
  maximumDuration: number = 0;
  minimalDurationSelected: number = 0;
  maximumDurationSelected: number = 0;
}

export class BaggageModel {
  freeBaggage: boolean;
  paidBaggage: boolean;

  constructor(freeBaggage: boolean = false, paidBaggage: boolean = false) {
    this.freeBaggage = freeBaggage;
    this.paidBaggage = paidBaggage;
  }
}

