import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { GclidTrackingService } from "../gclid-tracking.service";

@Injectable({ providedIn: "root" })
export class GclidResolver implements Resolve<void> {
  constructor(
    private gclidTrackingService: GclidTrackingService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void | Observable<void> | Promise<void> {
    const queryParams = route.queryParamMap;

    const gclidParam = queryParams.keys.find(k => k.toLocaleLowerCase() === 'gclid');

    if (!!gclidParam) {
      const gclid = queryParams.get(gclidParam);
      this.gclidTrackingService.setGCLID(gclid);
    }
  }
}

export const gclidResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(GclidResolver).resolve(route, state);
};
