import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CombinedResultComponent } from './combined-result.component';
import { ResultListModule } from '../result-list/result-list.module';
import { CloseDatesComponent } from './close-dates/close-dates.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { ZupperCommonModule } from '@zupper/common';
import { ResultNotFoundComponent } from './result-not-found/result-not-found.component';
import { RequiredCountriesComponent } from 'insurance-lib';



@NgModule({
  declarations: [CombinedResultComponent, CloseDatesComponent, ResultNotFoundComponent],
  imports: [
    CommonModule,
    ResultListModule,
    NgxUiLoaderModule,
    FlexLayoutModule,
    MomentModule,
    ZupperCommonModule,
    RequiredCountriesComponent
  ],
  exports: [
    CombinedResultComponent
  ]
})
export class CombinedResultModule { }
