import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightDurationPipe } from './flight-duration.pipe';


@NgModule({
  declarations: [FlightDurationPipe],
  imports: [CommonModule],
  exports: [FlightDurationPipe]
})
export class PipesModule { }
