import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CookieService } from '../../cookie-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-cookie',
  templateUrl: './modal-cookie.component.html',
  styleUrls: ['./modal-cookie.component.scss']
})
export class ModalCookieComponent implements OnInit {

  @ViewChild('cookieModal') cookieModal: TemplateRef<any>;
  private modalRef1: NgbModalRef;
  isCollapseExpanded = {
    google: true,
    safari: false
  };
  teste: boolean = false;

  constructor(
    private cookieService: CookieService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }


  openCookieModal(): void {
    const hasCookieBlocker = this.cookieService.checkCookieEnabled();
    if (hasCookieBlocker) {
      this.modalRef1 = this.modalService.open(this.cookieModal, { size: 'lg', backdrop: 'static', keyboard: false });
    }
  }

  toggleCollapse(collapseType: string): void {
    if (collapseType === 'google') {
      this.isCollapseExpanded.google = !this.isCollapseExpanded.google;
      this.isCollapseExpanded.safari = false;
    } else if (collapseType === 'safari') {
      this.isCollapseExpanded.safari = !this.isCollapseExpanded.safari;
      this.isCollapseExpanded.google = false;
    }
  }
  
  open(): void {
    this.modalService.open(this.cookieModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }
}
