import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { FilterTime } from '../../../models/api-filter.model';

@Component({
  selector: 'app-departure-filter',
  templateUrl: './departure-filter.component.html',
  styleUrls: ['./departure-filter.component.scss']
})

export class DepartureFilterComponent implements OnInit {

  @Input() timeDeparture: Array<FilterTime> = [];
  @Input() filterOptionsMobile: any;
  @Input() clearAllFilters: boolean;
  @Output() departureValuesChange = new EventEmitter<any>();

  minValueOne: number = 0;
  maxValueOne: number = 24;
  minValueTwo: number = 0;
  maxValueTwo: number = 24;
  optionsOne: Options = {
    floor: 0,
    ceil: 24,
    translate: (value: any, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + 'h00';
        case LabelType.High:
          return + value + 'h00';
        default:
          return value + 'h00';
      }
    }
  }

  optionsTwo: Options = {
    floor: 0,
    ceil: 24,
    translate: (value: any, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + 'h00';
        case LabelType.High:
          return value + 'h00';
        default:
          return value + 'h00';
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.setNewCeil();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      this.minValueOne = this.timeDeparture[0].minimalTime;
      this.maxValueOne = this.timeDeparture[0].maximumTime;
      this.minValueTwo = this.timeDeparture[1].minimalTime;
      this.maxValueTwo = this.timeDeparture[1].maximumTime;
    }
  }

  setNewCeil(): void {
    this.minValueOne = this.timeDeparture[0].minimalTimeSelected;
    this.maxValueOne = this.timeDeparture[0].maximumTimeSelected;
    const newOptions: Options = Object.assign({}, this.optionsOne);
    newOptions.floor = this.timeDeparture[0].minimalTime;
    newOptions.ceil = this.timeDeparture[0].maximumTime;
    this.optionsOne = newOptions;

    if (this.timeDeparture[1]) {
      this.minValueTwo = this.timeDeparture[1].minimalTimeSelected;
      this.maxValueTwo = this.timeDeparture[1].maximumTimeSelected;
      const newOptionsTwo: Options = Object.assign({}, this.optionsTwo);
      newOptionsTwo.floor = this.timeDeparture[1].minimalTime;
      newOptionsTwo.ceil = this.timeDeparture[1].maximumTime;
      this.optionsTwo = newOptionsTwo;
    }

  }

  onMinChangeOne($event) {
    this.timeDeparture.map((value, i) => {
      if (i == 0) {
        value.minimalTimeSelected = $event;
      }
    })
    this.onChange();
  }

  onMaxChangeOne($event) {
    this.timeDeparture.map((value, i) => {
      if (i == 0) {
        value.maximumTimeSelected = $event;
      }
    })
    this.onChange();
  }

  onMinChangeTwo($event) {
    this.timeDeparture.map((value, i) => {
      if (i == 1) {
        value.minimalTimeSelected = $event;
      }
    })
    this.onChange();
  }

  onMaxChangeTwo($event) {
    this.timeDeparture.map((value, i) => {
      if (i == 1) {
        value.maximumTimeSelected = $event;

      }
    })
    this.onChange();
  }

  onChange() {
    this.departureValuesChange.emit(this.timeDeparture);
  }
}
