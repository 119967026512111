import { Inject, Injectable } from '@angular/core';
import { STORAGE_KEY_PREFIX } from '../tokens/storage-key-prefix.token';
import { WINDOW } from '../tokens/window.token';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends StorageService {

  constructor(
    @Inject(WINDOW) window: any, // Window
    @Inject(STORAGE_KEY_PREFIX) prefix: string
  ) {
    super(window.sessionStorage, prefix);
  }
}
