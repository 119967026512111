import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, Subject, interval, map, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimerService {

    private _timers: Map<string, { timer: Observable<number>, subject: Subject<void> }> = new Map<string, { timer: Observable<number>, subject: Subject<void>}>();

    createTimer(identifier: string, duration: moment.Duration): Observable<number> {
        this._clearTimer(identifier);
        const subject = new Subject<void>();
        const timer$ = this.counter(duration); 
        this._timers.set(identifier, { timer: timer$, subject: subject });
        return timer$;
    }

    resetTimer(identifier: string): Observable<number> {
        this._timers.get(identifier)?.subject.next();
        return this._timers.get(identifier)?.timer;
    }

    counter(duration: moment.Duration): Observable<number> {
        return interval(1000).pipe(
            map((n: number) => n),
            take(duration.asSeconds())
        );
    }

    private _clearTimer(identifier: string): void {
        this._timers.get(identifier)?.subject.complete?.();
        this._timers.delete(identifier);
    }

}