import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Subject, catchError, of, tap } from 'rxjs';
import { CustomerLoginService } from '../../../../customer-login.service';
import { FormValidatorService } from '../../../../form-validator.service';
import { ACCOUNT_STEPS } from '../../../../models/account-steps.enum';

@Component({
  selector: 'zc-send-password-creation-link',
  templateUrl: './send-password-creation-link.component.html',
  styleUrls: ['./send-password-creation-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SendPasswordCreationLinkComponent implements OnInit {
  steps = ACCOUNT_STEPS;

  @Input() email: string;
  @Input() currentStep: ACCOUNT_STEPS = ACCOUNT_STEPS.EMAIL;
  @Output() currentStepChange = new EventEmitter<ACCOUNT_STEPS>();

  public loading = false;
  public successMessage: string;
  public errorMessage: string;

  @ViewChildren(NgbTooltip) capsTooltips: QueryList<NgbTooltip>;

  constructor(
    private _customerLoginService: CustomerLoginService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  onSendLink() {
    this.loading = true;
    this._customerLoginService
      .sendPasswordCreationLink(this.email)
      .pipe(
        tap((response) => {
          if (response)
            this.successMessage = 'Link enviado com sucesso! Confira sua caixa de e-mail!';
        }),
        catchError((error) => {
          this.errorMessage = 'Ops!... Não foi possível enviar o link. Tente novamente mais tarde.';
          return of(false);
        }),
      )
      .subscribe(() => {
        this.loading = false;
        this._changeDetectorRef.detectChanges();
      });
  }

  goTo(step: ACCOUNT_STEPS): void {
    this.currentStepChange.emit(step);
    this._changeDetectorRef.detectChanges();
  }
}
