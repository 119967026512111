export enum ApiFlightClass {
    Economic = 'Economic Class',
    Business = 'Business Class',
    First = 'First Class'
}

export enum ApiFlightType {
    RoundTrip = "roundTrip",
    OneWay = "oneWay",
    MultiSlice = "multiSlice"
}

export enum ApiTripTypeEnum {
    ROUNDTRIP = 'ROUND_TRIP',
    ONEWAY = 'ONE_WAY',
    MULTISLICE = 'MULTI_SLICE'
}

export enum CalendarTypeEnum {
    OneDate = "oneDate",
    TwoDates = "twoDates",
}

export enum AerialSourceEnum {
    Gol = 'G3',
    Azul = 'AD',
    Latam = 'LA',
    Passaredo = '2Z',
    Sabre = 'Sabre'
}
