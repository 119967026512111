export enum ApiFlightClass {
    EconomyPremium = 'Economy Premium',
    Economic = 'Economic Class',
    Business = 'Business Class',
    First = 'First Class'
}

export enum ApiFlightClassMapping {
    EconomyPremium = 'Economy Premium',
    Economy = 'Econômica',
    Business = 'Executiva',
    First = 'Primeira Classe'
}

export enum ApiFlightType {
    RoundTrip = "roundTrip",
    OneWay = "oneWay",
    MultiSlice = "multiSlice"
}

export enum CalendarTypeEnum {
    OneDate = "oneDate",
    TwoDates = "twoDates",
}

export enum AerialSourceEnum {
  Gol = "G3",
  Azul = "AD",
  Latam = "LA",
  Passaredo = "2Z",
  Sabre = "Sabre",
}
