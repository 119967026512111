import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextDayTripComponent } from './next-day-trip.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    NextDayTripComponent
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    NextDayTripComponent
  ]
})
export class NextDayTripModule { }
