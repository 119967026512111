import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import {
  ApiContentConfigurationService,
  ConfigurationAttributes,
  WEBSITE_PAGE,
  WEBSITE_VIEWPORT,
} from '@zupper/data';
import {
  DestinationGuideConfiguration,
  TagConfigurations,
  WebsiteScreenSize,
  WhatsappTagConfiguration,
} from './models/configuration';
import { AbandonmentModalConfiguration, AbandonmentModalDataConfiguration, AbandonmentModalDeviceConfiguration } from './models/abandonment-modal-configuration';
import { AbandonmentModalDataEnum } from './models/configuration.enum';

@Injectable({
  providedIn: 'root',
})
export class ContentConfigurationService implements OnDestroy {
  configurations$ = new BehaviorSubject<TagConfigurations>(null);

  constructor(private apiContentConfigService: ApiContentConfigurationService) { }

  init(): void {
    this.getConfigurations().subscribe(() => { });
  }

  getConfigurations(): Observable<TagConfigurations> {
    return this.apiContentConfigService.getConfiguration().pipe(
      map((response) => {
        const whatsAppConfig = response.find((r) => r.attributes.value == 'whatsappTag');
        const whatsappModel = this.getWhatsappTagConfigurationModel(whatsAppConfig?.attributes?.configurations)

        const destinationGuideConfig = response.find((r) => r.attributes.value == 'destinationGuide');
        const destinationGuideModel = this.getDestinationGuideConfigurationModel(
          destinationGuideConfig?.attributes?.configurations ?? []
        );

        const abandonmentModalConfig = response.find((r) => r.attributes.value == 'modalTag');
        const abandonmentModalModel = this.getAbandomentModalConfigurationModel(
          abandonmentModalConfig.attributes.configurations
        );

        return { whatsappModel, destinationGuideModel, abandonmentModalModel };
      }),
      tap((models) => {
        this.configurations$.next(models);
      })
    );
  }

  private getDestinationGuideConfigurationModel(configurations: ConfigurationAttributes[]) {
    const model = new DestinationGuideConfiguration();
    model.isActive = !!this.getDescription(configurations, 'ATIVAR');
    model.url = this.getDescription(configurations, 'URL');
    return model;
  }

  private getWhatsappTagConfigurationModel(configurations: ConfigurationAttributes[]): WhatsappTagConfiguration | null {
    if(configurations) {
      const model = new WhatsappTagConfiguration();
      model.tagText = this.getDescription(configurations, 'TAG');
      model.ctaText = this.getDescription(configurations, 'CTA');
      model.whatsappContactNumber = this.getDescription(configurations, 'NÚMERO DO WHATSAPP');
      model.screenSizes = configurations
        .filter(
          (config) =>
            config.name.toUpperCase() === 'DESKTOP' || config.name.toUpperCase() === 'MOBILE'
        )
        .map((config) => this.mapToScreenSize(config))
        .filter((screenSize) => !!screenSize);
      return model;
    } else {
      return null;
    }
  }

  private getAbandomentModalConfigurationModel(configurations: ConfigurationAttributes[]) {
    const model = new AbandonmentModalConfiguration();
    const abandonmentModalDesktopConfig = configurations.find((r) => r.name.toUpperCase() == WEBSITE_VIEWPORT.DESKTOP);
    const abandonmentModalMobileConfig = configurations.find((r) => r.name.toUpperCase() == WEBSITE_VIEWPORT.MOBILE);
    model.whatsappContactNumber = configurations.find((r) => r.name == AbandonmentModalDataEnum.WHATSAPP)?.value.toString();
    model.desktopModalConfig = this.getAbandonmentModalDeviceConfiguration(
      abandonmentModalDesktopConfig?.configurations ?? []
    );

    model.desktopModalConfig.activated = Boolean(abandonmentModalDesktopConfig.value);
    model.mobileModalConfig = this.getAbandonmentModalDeviceConfiguration(
      abandonmentModalMobileConfig?.configurations ?? []
    );
    model.mobileModalConfig.activated = Boolean(abandonmentModalMobileConfig.value);
    return model;
  }

  private getAbandonmentModalDeviceConfiguration(configurations: ConfigurationAttributes[]) {
    const model = new AbandonmentModalDeviceConfiguration();
    const abandonmentModalResultsConfig = configurations.find((r) => r.name.toUpperCase() == WEBSITE_PAGE.RESULTS);
    const abandonmentModalCheckoutConfig = configurations.find((r) => r.name.toUpperCase() == WEBSITE_PAGE.CHECKOUT);
    model.resultsConfig = this.getAbandonmentModalDataConfiguration(
      abandonmentModalResultsConfig?.configurations ?? []
    );
    model.resultsConfig.activated = Boolean(abandonmentModalResultsConfig?.value);
    model.checkoutConfig = this.getAbandonmentModalDataConfiguration(
      abandonmentModalCheckoutConfig?.configurations ?? []
    );
    model.checkoutConfig.activated = Boolean(abandonmentModalCheckoutConfig?.value);
    return model;
  }

  private getAbandonmentModalDataConfiguration(configurations: ConfigurationAttributes[]) {
    const model = new AbandonmentModalDataConfiguration();
    model.primaryText = configurations.find((r) => r.name == AbandonmentModalDataEnum.PRIMARY_TEXT)?.value.toString();
    model.secondaryText = configurations.find((r) => r.name == AbandonmentModalDataEnum.SECONDARY_TEXT)?.value.toString();
    const modalType = configurations.find((r) => r.name == AbandonmentModalDataEnum.MODAL_TYPE);
    model.pageAbandomentActivation = this.getPageAbandonmentBehaviour(
      modalType?.configurations ?? null
    );
    model.idleTimeActivation = this.getIdleTimeBehaviour(
      modalType?.configurations ?? null
    );
    return model;
  }

  private getPageAbandonmentBehaviour(configurations: ConfigurationAttributes[]): boolean {
    return Boolean(configurations?.find((r) => r.name == AbandonmentModalDataEnum.PAGE_ABANDONMENT)?.value) ?? null;
  }

  private getIdleTimeBehaviour(configurations: ConfigurationAttributes[]): number {
    return configurations[0].value ? this.getIdleTimeConfiguration(configurations?.find((r) => r.name == AbandonmentModalDataEnum.IDLE_TIME).configurations) : null;
  }

  private getIdleTimeConfiguration(configurations: ConfigurationAttributes[]): number {
    const timeOptionsConf = configurations?.find((r) => r.name == AbandonmentModalDataEnum.TIME)?.configurations ?? [];
    return Number(timeOptionsConf.find(t => t.value === true)?.name?.match(/^\d+/)[0] ?? null);
  }

  private getDescription(configurations: ConfigurationAttributes[], keyword: string): string {
    const config = configurations?.find((config) => config.name.toUpperCase().includes(keyword));
    return config ? config.value.toString() : '';
  }

  private mapToScreenSize(config: any): WebsiteScreenSize | undefined {
    const name = config.name.toUpperCase();

    if (name !== 'DESKTOP' && name !== 'MOBILE') {
      return undefined;
    }
    const screenSize = new WebsiteScreenSize();
    screenSize.viewport = name === 'DESKTOP' ? WEBSITE_VIEWPORT.DESKTOP : WEBSITE_VIEWPORT.MOBILE;
    screenSize.active = config.value;
    screenSize.pages = config.configurations.map((pageConfig) => {
      return pageConfig.configurations?.map(innerConfig => ({
        page: this.getPageEnumValue(innerConfig.name),
        isSelected: innerConfig.value,
      }))
    }).flat();

    return screenSize;
  }

  private getPageEnumValue(pageName: string): WEBSITE_PAGE | undefined {
    return (
      {
        HOME: WEBSITE_PAGE.HOME,
        RESULTADOS: WEBSITE_PAGE.RESULTS,
        CHECKOUT: WEBSITE_PAGE.CHECKOUT,
        THANKS: WEBSITE_PAGE.THANKS,
        'AERIAL HOME': WEBSITE_PAGE.AERIAL_HOME,
        'AERIAL RESULTS': WEBSITE_PAGE.AERIAL_RESULTS,
        'HOTEL HOME': WEBSITE_PAGE.HOTEL_HOME,
        'HOTEL RESULTS': WEBSITE_PAGE.HOTEL_RESULTS,
        'HOTEL ROOM SELECTION': WEBSITE_PAGE.HOTEL_ROOM_SELECTION,
        'PACKAGES HOME': WEBSITE_PAGE.PACKAGES_HOME,
        'PACKAGES RESULTS': WEBSITE_PAGE.PACKAGES_RESULTS,
        'PACKAGES ROOM SELECTION': WEBSITE_PAGE.PACKAGES_ROOM_SELECTION,
      }[pageName.toUpperCase()] ?? undefined
    );
  }

  ngOnDestroy(): void {
    this.configurations$.complete();
  }
}
