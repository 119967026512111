import { Injectable } from '@angular/core';

import { AirportModel, WEBSITE_PAGE } from '@zupper/data';
import { AvailableFlightsRequest } from './models/aerial/aerial-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class WhatsappChatLinkGeneratorService {
  searchFields: AvailableFlightsRequest;
  airports: { [key: string]: AirportModel } = {};

  generateInternationalOfferLink(contactNumber: string, route: string): string {
    this.getDataSearch();
    const message = this.generateInternationalOfferMessage(route);
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${contactNumber}/?text=${encodedMessage}`;
  }

  private generateInternationalOfferMessage(route): string {
    const message = 'Olá, quero a melhor oferta para minha viagem Internacional';

    return route === WEBSITE_PAGE.HOME ? message : this.getResultsMessage();
  }

  private getDataSearch(): void {
    const searchFieldsJson = sessionStorage.getItem('flightSearch');
    this.searchFields = searchFieldsJson ? JSON.parse(searchFieldsJson) : { slices: [] };

    for (const slice of this.searchFields.slices) {
      const originAirportData = JSON.parse(
        sessionStorage.getItem('airport.' + slice.originAirport)
      );
      const destinationAirportData = JSON.parse(
        sessionStorage.getItem('airport.' + slice.destinationAirport)
      );
      this.airports[slice.originAirport] = originAirportData;
      this.airports[slice.destinationAirport] = destinationAirportData;
    }
  }

  private getResultsMessage(): string {
    const searchSlices = this.searchFields.slices;
    const originAirport = this.airports[searchSlices[0].originAirport]?.city;
    const destinationAirport = this.airports[searchSlices[0].destinationAirport]?.city;
    const departureGoing = searchSlices[0]?.departureDate ? this.formatDate(searchSlices[0].departureDate) : null;
    const departureReturn = searchSlices[1]?.departureDate ? this.formatDate(searchSlices[1].departureDate) : null;
    const adultQty = this.searchFields.adultQty || 0;
    const childrenQty = this.searchFields.childrenQty || 0;
    const infantQty = this.searchFields.infantQty || 0;

    let message = `Olá, estou buscando a melhor oferta para ${originAirport}/${destinationAirport}, Ida ${departureGoing},`;

    if (departureReturn) {
      message += ` Volta ${departureReturn},`;
    }

    if (adultQty > 0) {
      message += ` ${adultQty === 1 ? '1 adulto' : `${adultQty} adultos`}${
        childrenQty > 0 ? ',' : ''
      }`;
    }

    if (childrenQty > 0) {
      message += ` ${childrenQty === 1 ? '1 criança' : `${childrenQty} crianças`}${
        infantQty > 0 ? ',' : ''
      }`;
    }

    if (infantQty > 0) {
      message += ` ${infantQty === 1 ? '1 bebê' : `${infantQty} bebês`}`;
    }

    return message;
  }

  private formatDate(dateStr: string): string {
    const parts = dateStr.split('-');
    const day = parts[2];
    const month = parts[1];
    return `${day}/${month}`;
  }
}
