import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WEBSITE_VIEWPORT } from '@zupper/data';
import { Observable, map, take } from 'rxjs';
import { ContentConfigurationService } from '../configuration.service';
import { AbandonmentModalConfiguration } from '../models/abandonment-modal-configuration';
import { TagConfigurations } from '../models/configuration';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class AbandonmentModalConfigurationResolver {

  constructor(private _contentConfigurationService: ContentConfigurationService, private _mediaObserver: MediaObserver) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AbandonmentModalConfiguration | Observable<AbandonmentModalConfiguration> | Promise<AbandonmentModalConfiguration> {
    const isInternational = (sessionStorage.getItem('isInternational') === 'true') ?? false;
    return this._contentConfigurationService.getConfigurations().pipe(
      map((data: TagConfigurations) => data.abandonmentModalModel.isActive(this.isMobile ? WEBSITE_VIEWPORT.MOBILE : WEBSITE_VIEWPORT.DESKTOP, route.data.page) && isInternational ? data.abandonmentModalModel : null),
      take(1)
    );
  }

  get isMobile(): boolean {
    return this._mediaObserver.isActive(['xs', 'sm']);
  }
}
