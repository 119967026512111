import { HttpParams } from "@angular/common/http";
import { ParamMap, Params } from "@angular/router";
import { ApiFlightType } from "@zupper/data";
import moment from 'moment';

export class ResultParams {
  type: ApiFlightType;
  adultQty: number;
  childrenQty: number;
  infantQty: number;
  airlines: string[];
  slices: ResultSliceParams[];

  public get isValid(): boolean {
    if (!this.type) {
      return false;
    }

    if (!this.adultQty || this.adultQty === 0) {
      return false;
    }

    if (!this.slices || this.slices.length === 0) {
      return false;
    }

    return true;
  }

  fromParams(params: ParamMap) {
    this.type = params.get('type') && Object.values(ApiFlightType).includes(params.get('type') as ApiFlightType)
      ? params.get('type') as ApiFlightType
      : null;

    this.adultQty = !!parseInt(params.get('adultQty'), 10) ? parseInt(params.get('adultQty'), 10) : 0;
    this.childrenQty = !!parseInt(params.get('childrenQty'), 10) ? parseInt(params.get('childrenQty'), 10) : 0;
    this.infantQty = !!parseInt(params.get('infantQty'), 10) ? parseInt(params.get('infantQty'), 10) : 0;

    this.airlines = [];

    const slicesParams = params.get('slices');
    const parsedJsonSlices = JSON.parse(slicesParams);

    if (parsedJsonSlices && Array.isArray(parsedJsonSlices)) {
      this.slices = [];
      for (let i = 0; i < parsedJsonSlices.length; i++) {
        const slice = new ResultSliceParams();
        slice.destinationAirport = parsedJsonSlices[i]['destinationAirport'] ?? null;
        slice.originAirport = parsedJsonSlices[i]['originAirport'] ?? null;
        slice.departureDate = !!parsedJsonSlices[i]['departureDate']
          ? moment(parsedJsonSlices[i]['departureDate'], moment.HTML5_FMT.DATE)
          : null;

        if (!slice.isValid) {
          this.slices = [];
          break;
        }

        this.slices.push(slice);
      }
    } else {
      this.slices = [];
    }

  }
}

export class ResultSliceParams {
  destinationAirport: string;
  originAirport: string;
  departureDate: moment.Moment;

  public get isValid(): boolean {
    return !!this.destinationAirport && !!this.originAirport && !!this.departureDate && this.departureDate.isValid();
  }
}

export class ResultFiltersParams {
  stops: number[] = [];
  airlines: string[] = [];
  airports: string[] = [];
  minPrice: number;
  maxPrice: number;
  baggage: ResultFilterBaggage = null;
  departureTime: ResultFilterTime[] = [];
  arrivalTime: ResultFilterTime[] = [];
  durationTime: ResultFilterDuration[] = [];

  /**
   *
   * @description Example:
   * filters={
   *    "stops": [0, 1],
   *    "airlines": ["G3", "LA"],
   *    "airports": ["GRU"],
   *    "minPrice": 300,
   *    "maxPrice": 840.5,
   *    "baggage": { "free": true, "paid": false },
   *    "departureTime": [{"minTime": "05:00", "maxTime": "12:00"}, null]
   *  }
   */
  fromJson(stringifiedParams: string) {
    let parsedParams = null;
    try {
      parsedParams = JSON.parse(stringifiedParams);
    } catch (e) {
      return;
    }

    if (parsedParams) {
      if (parsedParams['stops'] && Array.isArray(parsedParams['stops'])) {
        this.stops.push(...parsedParams['stops'].filter(x => Number.isInteger(x)));
      }

      if (parsedParams['airlines'] && Array.isArray(parsedParams['airlines'])) {
        this.airlines.push(...parsedParams['airlines']);
      }

      if (parsedParams['airports'] && Array.isArray(parsedParams['airports'])) {
        this.airports.push(...parsedParams['airports']);
      }

      if (parsedParams['minPrice'] && Number.isFinite(parsedParams['minPrice'])) {
        this.minPrice = parsedParams['minPrice'];
      }

      if (parsedParams['maxPrice'] && Number.isFinite(parsedParams['maxPrice'])) {
        this.maxPrice = parsedParams['maxPrice'];
      }

      if (parsedParams['baggage'] && typeof parsedParams['baggage'] === 'object') {
        this.baggage = new ResultFilterBaggage();
        this.baggage.free = parsedParams['baggage']['free'] ?? false;
        this.baggage.paid = parsedParams['baggage']['paid'] ?? false;
      }

      if (parsedParams['departureTime'] && Array.isArray(parsedParams['departureTime'])) {
        parsedParams['departureTime'].forEach((slice, index) => {
          if (!!slice && slice.hasOwnProperty('minTime') && slice.hasOwnProperty('maxTime')) {
            const minTime = moment(slice['minTime'], 'HH:mm');
            const maxTime = moment(slice['maxTime'], 'HH:mm');

            if (minTime.isValid() && maxTime.isValid()) {
              const timeObj = new ResultFilterTime();
              timeObj.minTime = minTime;
              timeObj.maxTime = maxTime;
              this.departureTime[index] = timeObj;
            } else {
              this.departureTime[index] = null;
            }

          } else {
            this.departureTime[index] = null;
          }
        });
      }

      if (parsedParams['arrivalTime'] && Array.isArray(parsedParams['arrivalTime'])) {
        parsedParams['arrivalTime'].forEach((slice, index) => {
          if (!!slice && slice.hasOwnProperty('minTime') && slice.hasOwnProperty('maxTime')) {
            const minTime = moment(slice['minTime'], 'HH:mm');
            const maxTime = moment(slice['maxTime'], 'HH:mm');

            if (minTime.isValid() && maxTime.isValid()) {
              const timeObj = new ResultFilterTime();
              timeObj.minTime = minTime;
              timeObj.maxTime = maxTime;
              this.arrivalTime[index] = timeObj;
            } else {
              this.arrivalTime[index] = null;
            }

          } else {
            this.arrivalTime[index] = null;
          }
        });
      }

      if (parsedParams['durationTime'] && Array.isArray(parsedParams['durationTime'])) {
        parsedParams['durationTime'].forEach((slice, index) => {
          if (!!slice && slice.hasOwnProperty('minTime') && slice.hasOwnProperty('maxTime')) {
            const minTime = moment.duration(slice['minTime']);
            const maxTime = moment.duration(slice['maxTime']);

            if (minTime.isValid() && maxTime.isValid()) {
              const timeObj = new ResultFilterDuration();
              timeObj.minDuration = minTime;
              timeObj.maxDuration = maxTime;
              this.durationTime[index] = timeObj;
            } else {
              this.durationTime[index] = null;
            }

          } else {
            this.durationTime[index] = null;
          }
        });
      }
    }
  }

  toQueryParamsJson(): string {
    let params = {};

    if (this.stops.length > 0) {
      params['stops'] = this.stops;
    }

    if (this.airlines.length > 0) {
      params['airlines'] = this.airlines;
    }

    if (this.airports.length > 0) {
      params['airports'] = this.airports;
    }

    if (!!this.maxPrice) {
      params['maxPrice'] = this.maxPrice;
    }

    if (!!this.minPrice) {
      params['minPrice'] = this.minPrice;
    }

    if (!!this.baggage) {
      params['baggage'] = { free: this.baggage.free, paid: this.baggage.paid };
    }

    if (this.departureTime.length > 0) {
      params['departureTime'] = this.departureTime.map(d => {
        if (d) {
          return { minTime: d.minTime.minutes(0).format('HH:mm'), maxTime: d.maxTime.minutes(0).format('HH:mm') };
        } else {
          return null;
        }
      });
    }

    if (this.arrivalTime.length > 0) {
      params['arrivalTime'] = this.arrivalTime.map(d => {
        if (d) {
          return { minTime: d.minTime.minutes(0).format('HH:mm'), maxTime: d.maxTime.minutes(0).format('HH:mm') };
        } else {
          return null;
        }
      });
    }

    if (this.durationTime.length > 0) {
      params['durationTime'] = this.durationTime.map(d => {
        if (d) {
          return { minTime: Math.floor(d.minDuration.asHours()).toString() + ':00', maxTime: Math.floor(d.maxDuration.asHours()).toString() + ':00' };
        } else {
          return null;
        }
      });
    }

    return JSON.stringify(params);
  }
}

export class ResultFilterTime {
  minTime: moment.Moment;
  maxTime: moment.Moment;
}

export class ResultFilterDuration {
  minDuration: moment.Duration;
  maxDuration: moment.Duration;
}

export class ResultFilterBaggage {
  free: boolean;
  paid: boolean;
}
