import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UncombinedResultComponent } from './uncombined-result.component';
import { ResultListModule } from '../result-list/result-list.module';
import { ResultFilterModule } from '../result-filter/result-filter.module';
import { PriceMatrixModule } from '../price-matrix/price-matrix.module';
import { PriceFilterPipe } from '../../../pipes/price.filter.pipe';
import { StopsFilterPipe } from '../../../pipes/stops.filter.pipe';
import { AirlinesFilterPipe } from '../../../pipes/airlines.filter.pipe';
import { AirportsFilterPipe } from '../../../pipes/airports.filter.pipe';
import { BaggageFilterPipe } from '../../../pipes/baggage.filter.pipe';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { PriceRangeFilterPipe } from '../../../pipes/price-range.filter.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TimeDepartureFilterPipe } from '../../../pipes/time-departure.filter.pipe';
import { TimeArrivalFilterPipe } from '../../../pipes/time-arrival.filter.pipe';
import { DurationFilterPipe } from '../../../pipes/duration.filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SliceFilterPipe } from '../../../pipes/slice.filter.pipe';
import { AerialComponentsModule } from '@zupper/aerial-components';
import { FlightGroupComponent } from './flight-group/flight-group.component';
import { FlightOptionComponent } from './flight-option/flight-option.component';
import { LuggageIconsModule } from '../luggage-icons/luggage-icons.module';
import { NextDayTripModule } from '../next-day-trip/next-day-trip.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SameAirlineFlightGroupSummaryComponent } from './same-airline-flight-group-summary/same-airline-flight-group-summary.component';
import { ZupperCommonModule } from '@zupper/common';
import { MultiAirlineFlightGroupSummaryComponent } from './multi-airline-flight-group-summary/multi-airline-flight-group-summary.component';
import { MinimumPriceFilterPipe } from '../../../pipes/minimum-price.pipe';
import { QuickTripTimeFilterPipe } from '../../../pipes/quick-trip-time.pipe';
import { RecommendedTripFilterPipe } from '../../../pipes/recommended-trip.pipe';
import { FlightDurationPipe } from '@zupper/aerial-components';
import { RequiredCountriesComponent } from "insurance-lib";

@NgModule({
  declarations: [
    UncombinedResultComponent,
    PriceFilterPipe,
    StopsFilterPipe,
    AirlinesFilterPipe,
    AirportsFilterPipe,
    BaggageFilterPipe,
    PriceRangeFilterPipe,
    TimeDepartureFilterPipe,
    TimeArrivalFilterPipe,
    DurationFilterPipe,
    SliceFilterPipe,
    FlightGroupComponent,
    FlightOptionComponent,
    SameAirlineFlightGroupSummaryComponent,
    MultiAirlineFlightGroupSummaryComponent,
    MinimumPriceFilterPipe,
    QuickTripTimeFilterPipe,
    RecommendedTripFilterPipe
  ],
    imports: [
        CommonModule,
        ResultListModule,
        ResultFilterModule,
        PriceMatrixModule,
        NgxUiLoaderModule,
        FlexLayoutModule,
        InfiniteScrollModule,
        AerialComponentsModule,
        LuggageIconsModule,
        NextDayTripModule,
        PipesModule,
        ZupperCommonModule,
        RequiredCountriesComponent,
    ],
  exports: [
    UncombinedResultComponent,
    PriceFilterPipe,
    StopsFilterPipe,
    AirlinesFilterPipe,
    AirportsFilterPipe,
    BaggageFilterPipe,
    PriceRangeFilterPipe,
    TimeDepartureFilterPipe,
    TimeArrivalFilterPipe,
    DurationFilterPipe,
    SliceFilterPipe
  ],
  providers: [
    FlightDurationPipe
  ]
})
export class UncombinedResultModule { }
