import { RootRoutes } from './../../../services/enum/root-routes.enum';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ApiCorporateLoginService, ApiCorporateModel } from '@zupper/data';
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ModalWhitelabelComponent } from '../../../components/modal-whitelabel/modal-whitelabel.component';
import { CustomerLoginService, EnvironmentInterface, LocalStorageService } from '@zupper/common';
@Component({
  selector: "app-corporate-login",
  templateUrl: "./corporate-login.component.html",
  styleUrls: ["./corporate-login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CorporateLoginComponent implements OnInit {
  @ViewChild("loginFailedSwal")
  public loginFailedSwal: SwalComponent;

  whitelabel: ApiCorporateModel = null;
  username: string;
  password: string;
  public corporateName: string;

  get createAccountUrl(): string {
    return this.env.account.registerUrl;
  }

  get forgotPasswordUrl(): string {
    return `${this.env.account.baseUrl}/conta/recuperar-senha`;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: CustomerLoginService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    @Inject('env') private env: EnvironmentInterface
  ) { }

  ngOnInit(): void {
    this.whitelabel = this.localStorageService.getItem<ApiCorporateModel|any>('whitelabel', {});
    const corporateName = this.activatedRoute.snapshot.paramMap.get("corporateName").toLowerCase();
    if(!this.whitelabel || _.isEmpty(this.whitelabel) || this.whitelabel.companyName.toLowerCase() !== corporateName) {
      this.router.navigateByUrl("/parceiro/"+corporateName);
    }
    this.corporateName = corporateName;
  }

  whitelabelLogin() {
    this.loginService
      .corporateLogin(this.username, this.password, this.whitelabel.corporateId)
      .subscribe(
        (response) => {
          if (!response.branding) {
            this.loginFailedSwal.fire();
            return false;
          }

          if (this.corporateName == 'bradesco' && this.localStorageService.getItem('accept_terms') != 'accept') {
            const modal = this.modalService.open(ModalWhitelabelComponent, {
              size: "lg",
              backdrop  : 'static',
              keyboard  : false
            });

            modal.componentInstance.corporateName = this.corporateName;
          } else {
            window.open(this.env.websiteUrl, '_self');
          }
        },
        (error: any) => {
          this.loginFailedSwal.fire();
        }
      );
  }

  whitelabelLogout() {
    this.loginService.logout().subscribe(() => {
      window.open(this.env.websiteUrl, '_self');
    });
  }
}
