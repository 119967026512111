import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultFilterComponent } from './result-filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { StopFilterModule } from '../stop-filter/stop-filter.module';
import { BaggageFilterModule } from '../baggage-filter/baggage-filter.module';
import { CiasFilterModule } from '../cias-filter/cias-filter.module';
import { AirportFilterModule } from '../airport-filter/airport-filter.module';
import { PriceFilterModule } from '../price-filter/price-filter.module';
import { ModalMobileModule } from '../modal-mobile/modal-mobile.module';
import { Ng5SliderModule } from 'ng5-slider';
import { NpnSliderModule } from 'npn-slider';


@NgModule({
  declarations: [
    ResultFilterComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    StopFilterModule,
    BaggageFilterModule,
    CiasFilterModule,
    AirportFilterModule,
    PriceFilterModule,
    ModalMobileModule,
    Ng5SliderModule,
    NpnSliderModule
  ],
  exports: [
    ResultFilterComponent
  ]
})
export class ResultFilterModule { }
