import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as _ from 'lodash';
@Pipe({
  name: 'minimumPriceFilter',
})
export class MinimumPriceFilterPipe implements PipeTransform {
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    filterMinimumPrice: boolean = false
  ): Array<Array<AvailableFlightsResponse>> {
    if (filterMinimumPrice) {
      let newFlightList: Array<Array<AvailableFlightsResponse>> =
        originalflightList.map((item) => item);
      newFlightList.sort((a, b) => {
        return Math.ceil(a[0].totalPrice) - Math.ceil(b[0].totalPrice);
      });
      return newFlightList;
    } else {
      return originalflightList;
    }
  }
}