import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { EnvironmentInterface } from './models/environment.interface';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GclidTrackingService {
  private _storageKey = 'gclid';

  constructor(
    @Inject('env') private env: EnvironmentInterface,
    private localStorageService: LocalStorageService
  ) { }

  setGCLID(gclid: string): void {
    const data: GCLIDStorageData = {
      gclid: gclid,
      expiration: moment().add(this.env.tracking.storageExpiration).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    };
    this.localStorageService.setItem('gclid', data);
  }

  getGCLID(): string | null {
    const storedData = this.localStorageService.getItem<GCLIDStorageData>('gclid');
    if (!storedData) {
      return null;
    }

    const storedExpiration = moment(storedData.expiration, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    if (storedExpiration.isBefore(moment())) {
      return null;
    } else {
      return storedData.gclid;
    }
  }
}

interface GCLIDStorageData {
  gclid: string;
  expiration: string;
}
