<header
  class="container-fluid zupper-header"
  [class.is-checkout]="isCheckout"
  [class.has-news-tag]="!isCheckout && isDestinationGuideAvailable"
  ngClass.lt-md="mobile"
>
  <div *ngIf="!isCheckout && isDestinationGuideAvailable" class="news-tag-mobile" fxHide fxShow.lt-md>
    <img class="star-eyes-emoji" src="assets/img/header/star-eyes-emoji.png" alt="image">
    <span>Novidade</span>
  </div>
  <div class="container">
    <div class="row-zupper">
      <a class="mobile-menu" fxShow fxHide.gt-sm (click)="menuClick($event)" *ngIf="!isCheckout">
        <img src="assets/img/menu-icon.svg">
      </a>
      <div class="spacer checkout" fxHide fxShow.gt-sm *ngIf="isCheckout"></div>
      <div class="logo-row" fxHide fxShow.gt-sm (click)="navigateToZupperHome()">
        <zupper-logo [isCheckout]="isCheckout" [hasWhiteLabel]="hasWhiteLabel" ></zupper-logo>
      </div>
      <zupper-logo fxHide fxShow.lt-md [isCheckout]="isCheckout" [class.is-checkout]="isCheckout"  [ngClass.lt-sm]="'small-mobile'"></zupper-logo>
      <div class="spacer" fxHide fxShow.gt-sm *ngIf="!hasWhiteLabel"></div>
      <div class="header-links" *ngIf="showLinks">
        <a (click)="navigateToZupperHome()" target="_blank" class="help-central" [ngStyle]="{'font-size': hasWhiteLabel ? '14px' : '16px'}"
          fxHide fxShow.gt-sm *ngIf="!isCheckout && hasWhiteLabel">
          <img src="assets/img/header/departure-flight-icon.svg"> Home Zupper
        </a>
        <div *ngIf="!isCheckout && isDestinationGuideAvailable" fxHide fxShow.gt-sm (click)="navigateToDestinationGuide()">
          <a [ngStyle]="{'font-size': hasWhiteLabel ? '14px' : '16px'}">
            <img src="assets/img/header/map.svg"> Guia de Destinos
          </a>
          <div class="news-tag-desktop">
            <img class="star-eyes-emoji" src="assets/img/header/star-eyes-emoji.png" alt="image">
            <span>Novidade</span>
          </div>
        </div>
        <a href="https://centraldeajuda.zupper.com.br/" target="_blank" class="help-central" [ngStyle]="{'font-size': hasWhiteLabel ? '14px' : '16px'}"
          fxHide fxShow.gt-sm *ngIf="!isCheckout">
          <img src="assets/img/header/question-mark-help.svg"> Ajuda
        </a>
        <a (click)="openOrderTrackingForm()" class="order-tracking" [ngStyle]="{'font-size': hasWhiteLabel ? '14px' : '16px'}"
          fxHide fxShow.gt-sm *ngIf="!isCheckout">
          <img src="assets/img/header/list.svg"> Acompanhar pedido
        </a>
        <div fxHide fxShow.gt-sm class="vertical-line"></div>
        <zc-customer-menu [isCheckout]="isCheckout" [customer]="customer"></zc-customer-menu>
      </div>
    </div>
  </div>
</header>
