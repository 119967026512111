import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ZupperButtonOptions } from '@zupper/common';

@Component({
  selector: 'app-result-not-found',
  templateUrl: './result-not-found.component.html',
  styleUrls: ['./result-not-found.component.scss']
})
export class ResultNotFoundComponent {
  @Input() sliceIndex: number;
  @Output() removeSlice = new EventEmitter<number>();
  @Output() doSearchAgain = new EventEmitter();
  private readonly _transparentBg: string = '#FFFFFF00';
  private readonly _varLinkColor: string = 'var(--link-color)';

  readonly doSearchAgainBtn: ZupperButtonOptions = {
    backgroundColor: this._transparentBg,
    textColor: this._varLinkColor,
    borderColor: this._varLinkColor
  };

  readonly removeSliceBtn: ZupperButtonOptions = {
    backgroundColor: this._transparentBg,
    textColor: 'currentcolor'
  }
}
