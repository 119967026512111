import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDisplayComponent implements OnInit {
  @Input() messageType = 'success';
  @Input() message: string;
  @Input() subtitle: string;
  @Input() showButton = true;
  @Input() buttonLabel = 'Voltar';
  @Input() showIcon = true;
  @Output() onButtonClick = new EventEmitter();

  get icon(): string {
    switch (this.messageType) {
      case MESSAGE_DISPLAY_TYPE.SUCCESS:
        return '/assets/img/valid-field.png';
      case MESSAGE_DISPLAY_TYPE.ERROR:
        return '/assets/img/invalid-field.png';
    }
  }

  get messageText(): string {
    if (!!this.message && this.message.length > 0) {
      return this.message;
    }

    switch (this.messageType) {
      case MESSAGE_DISPLAY_TYPE.SUCCESS:
        return 'Sucesso!';
      case MESSAGE_DISPLAY_TYPE.ERROR:
        return 'Ops... Algo deu errado';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  buttonClick(event: Event): void {
    event.preventDefault();
    this.onButtonClick.emit();
  }
}

export enum MESSAGE_DISPLAY_TYPE {
  SUCCESS = 'success',
  ERROR = 'error'
}
