import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchModule } from './one-destination-search/search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FindAirportsModule } from './find-airports/find-airports.module';
import { CalendarModule } from './calendar/calendar.module';
import { PassengersModule } from './passengers/passengers.module';
import { MultiDestinationSearchModule } from './multi-destination-search/multi-destination-search.module';
import { FlightSearchComponent } from './flight-search.component';



@NgModule({
  declarations: [FlightSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SearchModule,
    MultiDestinationSearchModule,
    FindAirportsModule,
    CalendarModule,
    PassengersModule,
  ],
  exports: [
    FlightSearchComponent,
    SearchModule,
    MultiDestinationSearchModule,
  ],
  entryComponents: [
    FlightSearchComponent
  ]
})
export class FlightSearchModule { }
