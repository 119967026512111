import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Pipe({
  name: 'calendarDate'
})
export class CalendarDatePipe implements PipeTransform {

  transform(value: NgbDate): string {
    if (!!value) {
      return moment({ year: value.year, month: value.month - 1, day: value.day}).format('YYYY-MM-DD');
    }

    return '';
  }

}
