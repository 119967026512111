import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TagModels } from './models/configuration';

@Injectable({
  providedIn: 'root',
})
export class TagServiceService {
  private configurationSubject = new BehaviorSubject<TagModels>(null);
  configuration$ = this.configurationSubject.asObservable();

  setConfigurations(data: TagModels) {
    this.configurationSubject.next(data);
  }
}
