import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AirportSearchModel, AirportsModel, FlightSlicesModel } from '@zupper/aerial-components';
import { CustomerLoginService } from '@zupper/common';
import {
  AerialSearchHistory,
  AerialSearchHistorySlice,
  SearchHistoryService,
  SearchHistoryStrategyFactoryService,
} from '@zupper/search-history';
import moment from 'moment';
import { ApiFlightType, CalendarTypeEnum } from '../../../services/enum/flights.enum';
import { CalendarComponent } from './../calendar/calendar.component';
import { FindAirportsComponent } from './../find-airports/find-airports.component';
import { PassengersComponent } from './../passengers/passengers.component';
@Component({
  selector: 'app-one-destination-search',
  templateUrl: './one-destination-search.component.html',
  styleUrls: ['./one-destination-search.component.scss'],
})
export class OneDestinationSearchComponent implements OnInit {
  @Input() flightSearchFields: AirportSearchModel = new AirportSearchModel();
  @Input() tripTypeChosen;
  @ViewChildren(
    'elOriginAirport, elDestinationAirport, elDepartureDate, elArrivalDate, elPassengers'
  )
  elements: QueryList<ElementRef>;

  availableOriginAirports: AirportsModel[] = [];
  availableDestinationAirports: AirportsModel[] = [];

  originDisplay: string = '';
  destinationDisplay: string = '';
  passengerDisplay: string = '';
  userIsNotTyping: any = null;
  tripAmountDays: number;

  constructor(
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private searchHistoryStrategyFactory: SearchHistoryStrategyFactoryService,
    private customerLoginService: CustomerLoginService
  ) {}

  ngOnInit(): void {
    if (JSON.parse(sessionStorage.getItem('flightSearch'))) {
      Object.assign(this.flightSearchFields, JSON.parse(sessionStorage.getItem('flightSearch')));
      this.flightSearchFields.type = this.tripTypeChosen;
    }
    if (this.flightSearchFields.slices.length == 0) {
      for (let i = 0; i < 2; i++) {
        this.flightSearchFields.slices.push(new FlightSlicesModel());
      }
    }
  }

  onSubmit() {
    if (!this.validateForm()) {
      return false;
    }

    var slices = [];
    let maxSlices = this.flightSearchFields.type == ApiFlightType.OneWay ? 1 : 2;

    let isInternational = false;
    for (let i = 0; i < maxSlices; i++) {
      let slice = this.flightSearchFields.slices[i];
      if (i == 1) {
        slice.originAirport = this.flightSearchFields.slices[0].destinationAirport;
        slice.destinationAirport = this.flightSearchFields.slices[0].originAirport;
      }

      let originAirport: AirportsModel = JSON.parse(
        sessionStorage.getItem('airport.' + slice.originAirport)
      );
      let destinationAirport: AirportsModel = JSON.parse(
        sessionStorage.getItem('airport.' + slice.destinationAirport)
      );

      if (![originAirport.countries_iso, destinationAirport.countries_iso].includes('BR')) {
        isInternational = true;
      }
      slice.departureDate = this.formatter.format(slice.departureDate);
      slices.push(slice);
    }

    this.flightSearchFields.slices = slices;
    sessionStorage.setItem('isInternational', JSON.stringify(isInternational));
    sessionStorage.setItem('flightSearch', JSON.stringify(this.flightSearchFields));

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    const searchHistory = this.generateSearchHistory(isInternational);
    this.prepareSearchHistoryService();
    this.searchHistoryService.save(searchHistory).subscribe();

    this.router.navigate(['/resultados'], {
      queryParams: {
        type: this.flightSearchFields.type,
        adultQty: this.flightSearchFields.adultQty,
        childrenQty: this.flightSearchFields.childrenQty,
        infantQty: this.flightSearchFields.infantQty,
        onlyBusinessClass: this.flightSearchFields.onlyBusinessClass,
        slices: JSON.stringify(slices),
      },
    });
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get calendarTypes(): typeof CalendarTypeEnum {
    return CalendarTypeEnum;
  }

  setOrigin({ airport_code }: AirportsModel) {
    if (this.flightSearchFields.slices.length != 0) {
      this.flightSearchFields.slices[0].originAirport = airport_code;
      if (this.flightSearchFields.slices[1]) {
        this.flightSearchFields.slices[1].destinationAirport = airport_code;
      }
    }
  }

  setDestination({ airport_code }: AirportsModel) {
    if (this.flightSearchFields.slices.length != 0) {
      this.flightSearchFields.slices[0].destinationAirport = airport_code;
      if (this.flightSearchFields.slices[1]) {
        this.flightSearchFields.slices[1].originAirport = airport_code;
      }
    }
  }

  setDates(slices: FlightSlicesModel[]) {
    this.flightSearchFields.slices[0].departureDate = slices[0].departureDate;

    let departureDate = this.flightSearchFields.slices[0].departureDate;
    let arrivalDate = null;

    if (slices[1]) {
      if (!this.flightSearchFields.slices[1]) {
        this.flightSearchFields.slices.push(new FlightSlicesModel());
      }
      this.flightSearchFields.slices[1].departureDate = slices[1].departureDate;
      arrivalDate = slices[1].departureDate;
    }

    if(this.flightSearchFields.type == ApiFlightType.RoundTrip && departureDate && arrivalDate) {
      let first = moment(
        departureDate.year + '-' + departureDate.month + '-' + departureDate.day, 'YYYY-MM-DD'
      );
      let second = moment(arrivalDate.year + '-' + arrivalDate.month + '-' + arrivalDate.day, 'YYYY-MM-DD');
      this.tripAmountDays = second.diff(first, 'days');
    }
  }

  validateForm() {
    let origin: string = '';
    let destination: string = '';
    let isValid = true;

    this.elements.forEach((el, elIndex) => {
      if (el instanceof FindAirportsComponent) {
        if (el.isOrigin) {
          origin = el.airportCodeDisplay;
          if (!el.isValid()) {
            isValid = false;
          }
        } else {
          destination = el.airportCodeDisplay;
          if (!el.isValid()) {
            isValid = false;
          }
        }
        if (origin != '' && destination != '' && origin == destination) {
          el.errors.push('Origem e Destino precisam ser diferentes');
          isValid = false;
        }
      } else if (el instanceof CalendarComponent) {
        !el.isValid() ? (isValid = false) : null;
      } else if (el instanceof PassengersComponent) {
        !el.isValid() ? (isValid = false) : null;
      }
    });

    return isValid;
  }

  private generateSearchHistory(isInternational?: boolean): AerialSearchHistory {
    const { adultQty, childrenQty, infantQty, slices, type } = this.flightSearchFields;
    const aerialSearchHistory = new AerialSearchHistory();
    aerialSearchHistory.adultQuantity = adultQty;
    aerialSearchHistory.childrenQuantity = childrenQty;
    aerialSearchHistory.infantQuantity = infantQty;
    aerialSearchHistory.type = type;
    aerialSearchHistory.isInternational = !!isInternational;

    slices.forEach((slice, index) =>
      aerialSearchHistory.addSlice({
        destination: slice.destinationAirport,
        origin: slice.originAirport,
        goingDate: slice.departureDate,
        sliceOrder: index,
      } as AerialSearchHistorySlice)
    );
    // aerialSearchHistory.addTravelerGroup(new TravelerGroup(...));
    return aerialSearchHistory;
  }

  private prepareSearchHistoryService(): void {
    const customerSessionId = this.customerLoginService.getCustomerSessionId();
    const strategy = this.searchHistoryStrategyFactory.getStrategy(customerSessionId);
    this.searchHistoryService.setStrategy(strategy);
  }
}
