import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { CustomerLoginService } from '@zupper/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class WhitelabelLogoutResolver {
  constructor(
    private customerLoginService: CustomerLoginService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.customerLoginService.logout().pipe(
      map(() => {
        return true;
      })
    );
  }
}

export const whitelabelLogoutResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(WhitelabelLogoutResolver).resolve(route, state);
};