<footer>
  <div class="container-fluid footer-menu">
    <div class="container">
      <div class="row footer-grid" gdColumns.lt-lg="">
        <div ngClass.lt-md="mobile">
          <div class="zupper-travels" fxFlex fxLayout="column">
            <h3>Zupper Viagens</h3>
            <div fxFlex fxLayout="row nowrap">
              <div class="links-container" fxFlex fxLayout="column">
                <a href="/" target="_blank">Início</a>
                <a href="https://www.zupper.com.br/quem-somos" target="_blank">Sobre nós</a>
                <a href="https://centraldeajuda.zupper.com.br/" target="_blank">Central de Ajuda</a>
                <a [href]="accountLink" target="_blank">Minha Conta</a>
                <a (click)="openOrderTrackingForm()">Acompanhar Pedido</a>
              </div>
            </div>
          </div>
          <div class="zupper-blog" fxFlex fxLayout="column">
            <h3>Blog Zupper</h3>
            <div fxFlex fxLayout="row nowrap">
              <div class="links-container" fxFlex fxLayout="column">
                <a href="https://www.zupper.com.br/blog/categoria/destinos-nacionais/">Destinos Nacionais</a>
                <a href="https://www.zupper.com.br/blog/categoria/destinos-internacionais/">Destinos Internacionais</a>
                <a href="https://www.zupper.com.br/blog/categoria/dicas/">Dicas de Viagem</a>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-options" fxFlex fxLayout="column" fxLayoutAlign="start center">
          <h3>Formas de Pagamento</h3>
          <p class="subtitle">Cartões de crédito</p>
          <div class="image-list">
            <img src="assets/img/footer/visa.png" srcset="assets/img/footer/visa@2x.png 2x">
            <img src="assets/img/footer/mastercard.png" srcset="assets/img/footer/mastercard@2x.png 2x">
            <img src="assets/img/footer/elo.png" srcset="assets/img/footer/elo@2x.png 2x">
            <img src="assets/img/footer/amex-2.png" srcset="assets/img/footer/amex-2@2x.png 2x">
            <img src="assets/img/footer/hipercard.png" srcset="assets/img/footer/hipercard@2x.png 2x">
            <img src="assets/img/footer/diners.png" srcset="assets/img/footer/diners@2x.png 2x">
          </div>
          <div class="image-list bank-transfer-cash mt-4">
            <div>
              <p class="subtitle">Transferência bancária e dinheiro</p>
              <img src="assets/img/footer/safety-pay.png" srcset="assets/img/footer/safety-pay@2x.png 2x">
            </div>
            <div class="">
              <p class="subtitle">&nbsp;</p>
              <img src="assets/img/footer/pix.png" srcset="assets/img/footer/pix@2x.png 2x">
            </div>
          </div>
        </div>
        <div class="buy-safe">
          <h3>Compre com segurança</h3>
          <div class="image-list column mt-3 mb-3">
            <!-- <img src="assets/img/footer/blindado.png" srcset="assets/img/footer/blindado@2x.png 2x"> -->
            <zupper-security-seal type="siteBlindado"></zupper-security-seal>
            <zupper-security-seal type="goDaddy"></zupper-security-seal>
          </div>
          <p class="subtitle"><strong>Certificações do turismo</strong></p>
          <div class="image-list mt-2">
            <img src="assets/img/footer/brasil-turismo.png" srcset="assets/img/footer/brasil-turismo@2x.png 2x">
            <img src="assets/img/footer/abav.png" srcset="assets/img/footer/abav@2x.png 2x">
            <img src="assets/img/footer/iata.png" srcset="assets/img/footer/iata@2x.png 2x">
          </div>
        </div>
      </div>
    </div>
  </div>
  <zupper-footer-social-media></zupper-footer-social-media>
  <div class="container-fluid footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col">
          <p>
            <a target="_blank" [routerLink]="['/termos-e-condicoes']">Termos e condições</a> |
            <a target="_blank" [routerLink]="['/politica-de-privacidade']">Política de privacidade</a> |
            <a target="_blank" [routerLink]="['/politica-de-cookie']">Política de Cookies</a> |
            <a target="_blank" href="https://www.zupper.com.br/quem-somos">Sobre nós</a>
          </p>
          <p class="mt-2 mb-2">
            Canais de Atendimento:
            <strong><a target="_blank" class="clickable" (click)="openHelpCenter($event)">Central de Ajuda</a></strong> |
            <strong>Telefone: (11) 4871-5700</strong>
          </p>
          <p><strong>Kontik Viagens</strong> - Ministério do Turismo - Embratur <strong>26.001978.10.0010-6</strong> |
            Rua Itapeva, 26 4º Andar, Bela Vista, CEP 01332-000, São Paulo-SP, CNPJ: <strong>73.431.686/0001-49</strong>
          </p>
          <p>Todos os direitos reservados © - 2023</p>
        </div>
      </div>
    </div>
  </div>
</footer>
