import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LabelType, Options } from 'ng5-slider';



@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss']
})
export class PriceFilterComponent implements OnInit {

  @Input() filterOptionsMinimalPrice: number;
  @Input() filterOptionsMaximumPrice: number;
  @Input() minimalPrice: number = 0;
  @Input() maximumPrice: number = 0;
  @Input() selectedMinimalPrice: number = 0;
  @Input() selectedMaximumPrice: number = 0;
  @Input() filterOptions: any;
  @Input() filterOptionsMobile: any;
  @Input() clearAllFilters: boolean;

  @Output() pricesValuesChange = new EventEmitter<{ minimalPrice: number, maximumPrice: number }>();

  minValue: number = 0;
  maxValue: number = 100000;
  options: Options = {
    floor: 0,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return 'R$' + value;
        case LabelType.High:
          return 'R$' + value;
        default:
          return 'R$' + value;
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.selectedMinimalPrice =  this.filterOptionsMinimalPrice;
    this.selectedMaximumPrice =  this.filterOptionsMaximumPrice;
    this.setNewCeil();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      this.minValue = this.minimalPrice;
      this.maxValue = this.maximumPrice;
    }
  }

  setNewCeil(): void {
    this.minValue =  (this.selectedMinimalPrice == 0 ? this.minimalPrice : this.selectedMinimalPrice);
    this.maxValue =  (this.selectedMaximumPrice == 0 ? this.maximumPrice : this.selectedMaximumPrice);
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = this.minimalPrice;
    newOptions.ceil = this.maximumPrice;
    this.options = newOptions;
  }

  onPriceMinChange(event) {  
    this.selectedMinimalPrice = event;
    this.sendPrice();
  }

  onPriceMaxChange(event) {
    this.selectedMaximumPrice = event;
    this.sendPrice();
  }

  sendPrice() {
    this.pricesValuesChange.emit({
      minimalPrice: this.selectedMinimalPrice,
      maximumPrice: this.selectedMaximumPrice
    });
  }

}
