import { Injectable } from '@angular/core';
import {
  LoggerService,
  LogConfigModel,
  ZupperVersionService,
  ContentConfigurationService,
} from '@zupper/common';
import { environment } from '../../environments/environment';
import { ApiBaseService } from '@zupper/data';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private apiBaseService: ApiBaseService,
    private zupperVersionService: ZupperVersionService,
    private whatsappTagConfigurationService: ContentConfigurationService
  ) {}

  loadApp(version: string) {
    // initialize moment.js locale
    moment.locale('pt-BR');
    moment.fn.toJSON = function () {
      return this.format();
    };

    // set ZupperAPI urls for the whole application
    this.apiBaseService.setBaseUrl(environment.apiUrl);
    this.apiBaseService.setApiPriceUrl(environment.apiPriceUrl);
    this.apiBaseService.setApiBookUrl(environment.apiBookUrl);
    this.apiBaseService.setExternalConfigUrl(environment.externalConfigUrl);
    this.apiBaseService.setZupperBlogFeedUrl(environment.zupperBlogFeedUrl);
    this.apiBaseService.setUrlAerial(environment.aerial.url);
    this.apiBaseService.setUrlHotel(environment.hotel.url);

    this.whatsappTagConfigurationService.init();

    // set logging configuration
    const loggerConfig = new LogConfigModel();
    loggerConfig.serverLogLevel = environment.logging.serverLogLevel;
    loggerConfig.serverLoggingUrl = environment.logging.serverLoggingUrl;

    this.zupperVersionService.setVersion(version);

    LoggerService.setConfig(loggerConfig);
  }
}
