import { Component, Inject } from '@angular/core';
import { AerialCheckoutBuilderService } from '@zupper/aerial-components';
import { AppCheckerService, EnvironmentInterface } from '@zupper/common';
import * as _ from 'lodash';
import { CreditCardRulesModel } from '../../../../models/api-filter.model';
import { AbstractAerialResultComponent } from '../../result-list/result-list-price/aerial-result';

@Component({
  selector: 'app-multi-airline-flight-group-summary',
  templateUrl: './multi-airline-flight-group-summary.component.html',
  styleUrls: ['./multi-airline-flight-group-summary.component.scss'],
})
export class MultiAirlineFlightGroupSummaryComponent extends AbstractAerialResultComponent {
  constructor(
    protected appCheckerService: AppCheckerService,
    protected aerialCheckoutBuilder: AerialCheckoutBuilderService,
    @Inject('env') protected env: EnvironmentInterface
  ) {
    super(appCheckerService, aerialCheckoutBuilder, env);
  }

  processInstallments = () => {
    const airlines = this.chosenFlight.airline;
    const maxInstallmentConfig: { maxInstallment: number; hasTax: boolean } = (
      this.filterMeta[airlines]['creditRules'] as CreditCardRulesModel[]
    ).reduce(
      (prev, curr) => {
        const maxCurrent = _.max(curr.allowedInstallments);
        const maxInstallment = curr.minInstallmentValue.find(
          (v) => v.installmentCount === maxCurrent
        ).installmentCount;
        const foundTaxConfig = curr.hasCardTax.find((t) => t.installmentCount === maxInstallment);
        const currentConfig = {
          maxInstallment: maxInstallment,
          hasTax: foundTaxConfig?.hasTax ?? this.hasCardTax,
        };
        if (!prev) {
          return currentConfig;
        } else {
          const usePrevValue = prev.maxInstallment >= currentConfig.maxInstallment;
          return {
            maxInstallment: _.max([prev.maxInstallment, currentConfig.maxInstallment]),
            hasTax: usePrevValue ? prev.hasTax : currentConfig.hasTax,
          };
        }
      },
      { maxInstallment: this.maxAllowedInstallment, hasTax: this.hasCardTax }
    );

    this.maxAllowedInstallment = maxInstallmentConfig.maxInstallment;
    this.hasCardTax = maxInstallmentConfig.hasTax;
  };
}
