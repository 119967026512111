import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as _ from 'lodash';

@Pipe({
  name: 'uniqueFlight'
})
export class UniqueFlightPipe implements PipeTransform {
  flightList: Array<string> = [];

  addFlight(flightId: string): boolean {
    let isFlightAdded = this.flightList.indexOf(flightId);
    this.flightList.push(flightId);
    return isFlightAdded == -1;
  }

  transform(flights: Array<AvailableFlightsResponse>, sliceId: number = 0): Array<AvailableFlightsResponse> {
    this.flightList = [];
    let filteredFlights: Array<AvailableFlightsResponse> = [];

    filteredFlights = _.uniqBy(flights, (flight) => {
      return flight.slices[sliceId].apiSliceId;
    });

    let sortedFlights = filteredFlights.sort((f1, f2) => {
      if (f1.slices[sliceId].departureDate > f2.slices[sliceId].departureDate) {
        return 1;
      } else if (
        f1.slices[sliceId].departureDate < f2.slices[sliceId].departureDate
      ) {
        return -1;
      } else {
        let duration1 = this._convertFlightDurationToMinutes(
          f1.slices[sliceId].flightDuration
        );
        let duration2 = this._convertFlightDurationToMinutes(
          f2.slices[sliceId].flightDuration
        );
        return duration1 - duration2;
      }
    });
    

    return sortedFlights;
  }

  private _convertFlightDurationToMinutes(duration: string): number {
    let daysMatch = duration.match(/(\d+)D/);
    let hoursMatch = duration.match(/(\d+)H/);
    let minutesMatch = duration.match(/(\d+)M/);
    let days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    return days * 1440 + hours * 60 + minutes;
  }
}
