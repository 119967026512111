import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';

@Pipe({
  name: 'family'
})
export class FamilyPipe implements PipeTransform {
  transform(flights: Array<AvailableFlightsResponse>, name: string, sliceIndex: number = 0): Array<AvailableFlightsResponse> {
    let filteredFlights: Array<AvailableFlightsResponse> = [];

    flights.forEach((flight) => {
      if (
        flight.slices[sliceIndex] != undefined && (
          flight.slices[sliceIndex].mainFamily == name ||
          name == undefined ||
          name == null
        )
      ) {
        filteredFlights.push(flight);
      }
    });

    return filteredFlights;
  }
}
