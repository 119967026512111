import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from "@angular/router";
import { AvailableFlightsRequest, FlightSlicesModel } from "@zupper/aerial-components";
import { LocalStorageService } from '@zupper/common';
import { ApiCampaignModel, ApiCorporateModel } from "@zupper/data";
import moment from 'moment';
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { ResultParams } from "../models/result-params.model";
import { AerialSearchManagerService } from "../services/aerial-search-manager.service";
import { AirportService } from "../utils/airport.service";
import _ from "lodash";

@Injectable({ providedIn: "root" })
export class ResultsSearchResolver {
  constructor(
    private service: AerialSearchManagerService,
    private localStorageService: LocalStorageService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let queryParams = route.queryParamMap;
    let resultParams = new ResultParams();
    resultParams.fromParams(queryParams);

    if (resultParams.isValid) {
      let airportSet = new Set<string>();
      resultParams.slices.forEach(slice => {
        airportSet.add(slice.originAirport);
        airportSet.add(slice.destinationAirport);
      });

      let observables: Observable<{ code: string, country: string, cityName: string }>[] = [];

      airportSet.forEach(airportIata => {
        let cachedInfo = AirportService.getInfoFromIata(airportIata);
        if (!!cachedInfo) {
          sessionStorage.setItem(
            'airport.' + cachedInfo.airportCode,
            JSON.stringify(cachedInfo)
          );
          observables.push(of({ code: cachedInfo.airportCode, country: cachedInfo.countryISO, cityName: cachedInfo.city }));
        } else {
          let apiSearch = this.service.availableAirports(airportIata)
            .pipe(
              map(list => {
                sessionStorage.setItem(
                  'airport.' + list.result[0]['airport_code'],
                  JSON.stringify(list.result[0])
                );
                return { code: list.result[0]['airport_code'], country: list.result[0]['countries_iso'], cityName: list.result[0]['city'] };
              })
            );
          observables.push(apiSearch);
        }
      });

      return forkJoin(observables).pipe(map(results => {
        const codeCity = new Map<string, string>();
        results.forEach(result => {
          codeCity.set(result.code, result.cityName);
        });
        let isInternational = results.filter(airportInfo => airportInfo.country !== 'BR').length > 0;
        let flightRequest = new AvailableFlightsRequest();
        flightRequest.adultQty = resultParams.adultQty;
        flightRequest.childrenQty = resultParams.childrenQty;
        flightRequest.infantQty = resultParams.infantQty;
        flightRequest.type = resultParams.type;
        flightRequest.onlyBusinessClass = resultParams.onlyBusinessClass;
        flightRequest.slices = resultParams.slices.map(slice => {
          let requestSlice = new FlightSlicesModel();
          requestSlice.departureDate = slice.departureDate.format(moment.HTML5_FMT.DATE);
          requestSlice.destinationAirport = slice.destinationAirport;
          requestSlice.originAirport = slice.originAirport;
          requestSlice.destinationCity = codeCity.get(slice.destinationAirport);
          requestSlice.originCity = codeCity.get(slice.originAirport);
          return requestSlice;
        });

        if (isInternational) {
          flightRequest.grouped = true;
          flightRequest.groupedByOptions = false;
        } else {
          flightRequest.grouped = false;
          flightRequest.groupedByOptions = true;
        }

        resultParams.credentialName != null
          ? (flightRequest.credentialName = resultParams.credentialName)
          : (flightRequest.credentialName = null);

        sessionStorage.setItem('flightSearch', JSON.stringify(flightRequest));
        sessionStorage.setItem('isInternational', JSON.stringify(isInternational));

        let utmCampaign = this.localStorageService.getItem<any>('utmCampaign');
        if (!!utmCampaign) {

          if (utmCampaign['data']) {
            let utmCampaignData: ApiCampaignModel = _.isString(utmCampaign.data) ? JSON.parse(utmCampaign.data) : utmCampaign['data'];

            if (utmCampaign.expiry && moment(utmCampaign.expiry).isAfter(moment())) {
              flightRequest.credentialName = utmCampaignData.credentialGroup;
            }
          }
        }


        let whitelabelData = this.localStorageService.getItem<ApiCorporateModel | any>('whitelabel', {});
        if (whitelabelData.credentialName) {
          flightRequest.credentialName = whitelabelData.credentialName;
        }
        return flightRequest;
      }));
    }

  }
}

export const resultsSearchResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(ResultsSearchResolver).resolve(route, state);
};
