<app-price-matrix
  fxHide.lt-md
  [airlineStopsPrices]="airlineStopsPrices"
  [multiAirlineStopsPrices]="multiAirlineStopsPrices"
  [airlines]="airlines"
  [maxStopsCount]="maxStopsCount"
  [bestPrices]="bestPricesByStops"
  (selectedPrice)="priceFilter($event)"
  (selectedAirline)="airlineFilter($event)"
></app-price-matrix>

<div class="required-countries-mb-30">
  <lib-required-countries *ngIf="_isInsuranceRequired"
  [isSearchPage]="true"></lib-required-countries>
</div>

<app-result-filter
  [(filterOptions)]="this.filterOptions"
  [availableStopQuantities]="this.stopQuantitiesList"
  [availableAirlines]="airlines"
  [availableAirports]="airports"
  [minimalPrice]="minimalPrice"
  [maximumPrice]="maximumPrice"
  [timeDeparture]="timeDeparture"
  [timeArrival]="timeArrival"
  [filterDuration]="filterDuration"
  [isLoading]="flightList.length == 0"
  [initialValues]="initialFilterValues"
  [isMultiCias]="isMultiCias"
></app-result-filter>

<div infiniteScroll [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
  <ng-container
    *ngFor="
      let priceGroup of this.flightList
        | timeDepartureFilter : this.filterOptions.timeDeparture
        | timeArrivalFilter : this.filterOptions.timeArrival
        | durationFilter : this.filterOptions.filterDuration
        | airportsFilter : this.filterOptions.airports
        | priceRangeFilter
          : this.filterOptions.minimalPrice
          : this.filterOptions.maximumPrice
        | airlinesFilter : this.filterOptions.airlines
        | stopsFilter : this.filterOptions.stopQuantities
        | baggageFilter : this.filterOptions.baggage
        | minimumPriceFilter : this.filterOptions.filterMinimumPrice
        | quickTripTimeFilter : this.filterOptions.filterQuickTripTime
        | recommendedTripFilter : this.filterOptions.filterRecommendedTrip;
        let groupIndex = index
      "
  >
    <app-flight-group
      *ngIf="getOnTotalFlightFilter(priceGroup, groupIndex)"
      [priceGroup]="priceGroup"
      [flightGroupsInitialShow]="flightGroupsInitialShow"
      [flightGroupsTotal]="flightGroupsTotal"
      [totalSlices]="totalSlices"
      [filterMeta]="filterMeta"
      [flightsSearchFields]="flightsSearchFields"
      [flightList]="flightList"
      [selectedFlightList]="selectedFlightList"
      [showFamilies]="showFamilies"
      [groupIndex]="flightList.indexOf(priceGroup) === -1 ? groupIndex : flightList.indexOf(priceGroup)"
      [isMobile]="mediaObserver.isActive('xs')"
    ></app-flight-group>
  </ng-container>
</div>
<div class="placeholder-container" *ngIf="this.flightList.length == 0">
  <div class="ph-item" *ngFor="let item of [].constructor(4)">
    <article class="loading-container">
      <div>
        <div class="row"></div>
        <div class="row"></div>
        <div class="row"></div>
      </div>
      <div>
        <div class="row"></div>
        <div class="button"></div>
      </div>
    </article>
  </div>
</div>
