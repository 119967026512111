<div class="label-container">
  <div class="icon-wrapper">
    <div class="label-badge" [style.background-color]="labelData.color"></div>
    <div class="label-icon"><i [ngClass]="labelData.icon"></i></div>
  </div>
  <div class="label-text" [style.background-color]="labelData.color">
    <span class="text">
      {{labelData.labelText}}
    </span>
  </div>
</div>