<figure class="whatsapp-tag">
    <div class="wrapper"></div>
    <div class="whatsapp-tag-container">
        <div class="trip">
            <i class="fas fa-globe"></i>
            <span [innerHTML]="configuration.tagText"></span>
        </div>
        <div class="green-background" (click)="onCtaClick()">
            <i class="fab fa-whatsapp"></i>
            <span [innerHTML]="configuration.ctaText"></span>
        </div>
    </div>
</figure>