import { Component, OnInit, AfterViewInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomerLoginService } from './../../customer-login.service';
import { ACCOUNT_STEPS } from '../../models/account-steps.enum';
import { ApiCustomer } from '@zupper/data';
// import { PerfectScrollbarDirective } from 'ngx-scrollbar';

@Component({
  selector: 'zc-login',
  templateUrl: './zc-login.component.html',
  styleUrls: ['./zc-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZcLoginComponent implements OnInit, AfterViewInit {
  @Output() onLoginSuccessful = new EventEmitter();
  @Output() onForgotPassword = new EventEmitter();
  @Output() onLoginError = new EventEmitter<Error>();

  // @ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;

  public steps = ACCOUNT_STEPS;
  public currentStep = ACCOUNT_STEPS.LOGIN_SELECTION;

  public loading = false;
  public loadingProvider = false;

  public email: string;

  showError = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _customerLoginService: CustomerLoginService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   if (!!this.perfectScrollbar) {
    //     this.perfectScrollbar.update();
    //   }
    // });
  }

  goTo(step: ACCOUNT_STEPS): void {
    this.currentStep = step;
    this._changeDetectorRef.detectChanges();
  }

  onSendPasswordCreationLink(email: string) {
    this.email = email;
  }

  loginSuccessful(): void {
    this.onLoginSuccessful.emit();
  }

  forgotPassword(): void {
    this.onForgotPassword.emit();
  }

  googleLogin(): void {
    this.loadingProvider = true;
    this._customerLoginService.googleSignIn().subscribe(
      (customer: ApiCustomer) => {
        this.loadingProvider = false;
        this.onLoginSuccessful.emit();
      },
      (error: Error) => {
        this.loginError(error);
      }
    );
  }

  facebookLogin(): void {
    this.loadingProvider = true;
    this._customerLoginService.facebookSignIn().subscribe(
      (customer: ApiCustomer) => {
        this.loadingProvider = false;
        this.onLoginSuccessful.emit();
      },
      (error: Error) => {
        this.loginError(error);
      }
    );
  }

  loginError(error: Error): void {
    this.loadingProvider = false;
    this.showError = true;
    this.onLoginError.emit(error);
  }

  onClassHeaderMobile() {
    return this.currentStep != this.steps.LOGIN_SELECTION ? 'zupper-login-left-data-mobile' : '';
  }

  onClassBodyMobile() {
    return this.currentStep != this.steps.LOGIN_SELECTION ? 'zupper-login-right-mobile' : '';
  }
}


