import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AerialSourceEnum, GroupedFlightMapper } from '@zupper/data';
import { FlightNotificationModel } from '../models/flight-notification.model';

@Injectable({
  providedIn: 'root'
})
export class FlightResultNotifierService {
  private message = new Subject<FlightNotificationModel>();

  constructor() { }

  sendMessage(airline: AerialSourceEnum, flights: GroupedFlightMapper[], filterData: any): void {
      const model = new FlightNotificationModel();
      model.airline = airline;
      model.flights = flights;
      model.filterData = filterData;

      this.message.next(model);
  }

  getMessage(): Observable<FlightNotificationModel> {
      return this.message.asObservable();
  }
}
