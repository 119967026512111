import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiBannerService, ApiCorporateModel, BannerModel } from '@zupper/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { SubSink } from 'subsink';
import { LocalStorageService } from '../../storage/local-storage.service';

@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss']
})
export class BannerCarouselComponent implements OnInit, OnDestroy {
  @Input('type') type: string;
  @Input('location') location: string;

  private subs = new SubSink();

  loading: boolean;

  data: BannerModel[];

  loaderOptions = {
    id: 'home-carousel'
  };

  carouselOptions: OwlOptions = {
    dots: false,
    center: true,
    touchDrag: true,
    loop: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: { items: 1 }
    }
  };

  current: BannerModel;

  constructor(
    private bannerService: ApiBannerService,
    private loaderService: NgxUiLoaderService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.loading = true;

    let whitelabel = this.localStorageService.getItem<ApiCorporateModel | any>('whitelabel', {});
    let type = this.type;
    if (whitelabel.companyName) {
      type = whitelabel.companyName;
      this.type = type;
    }
    this.getBanners(type);

  }

  getBanners(type) {
    let subscription = this.bannerService.getBanners(type, this.location)
      .subscribe(
        (result: BannerModel[]) => {
          this.data = result.sort((a, b) => a.order < b.order ? -1 : 1);
          this.current = this.data[0];
          this.loading = false;

          if(type !== "power" && result && result.length == 0) {
            this.type = "power";
            this.getBanners(this.type);
          }
        },
        erro => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );

    this.subs.add(subscription);
  }

  bannerClick(url: string): void {
    if (url !== null && url !== undefined) {
      window.location.href = url;
    }
  }

  slideChanged(data: SlidesOutputData) {
    this.current = this.data[data.startPosition];
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
