import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMG_LINKS } from './utils/zupper-logo-banner-urls';

@Injectable({
    providedIn: 'root'
})
export class ZupperLogoBannerService {
    private _logoSrcSubject: BehaviorSubject<string> = new BehaviorSubject<string>(IMG_LINKS.CAMPAIGN_LOGO_URL);
    private _bannerSrcSubject: BehaviorSubject<string> = new BehaviorSubject<string>(IMG_LINKS.CAMPAIGN_BANNER_URL);

    public logoSrc$: Observable<string> = this._logoSrcSubject.asObservable();
    public bannerSrc$: Observable<string> = this._bannerSrcSubject.asObservable();

    constructor() {
        this.updateBannerSrc();
        window.addEventListener('resize', () => {
            this.updateBannerSrc();
        });
    }

    private updateBannerSrc() {
        const mobileWidth = 768;
        const isMobile = window.innerWidth < mobileWidth;
        const currentDate = new Date();
        const changeDate = new Date('2024-03-31T23:59');

        // If the current date is superior than changing date (31/03/2024 23:59) then we should go back to default assets configuration.
        if (currentDate > changeDate) {
            this._bannerSrcSubject.next(isMobile ? IMG_LINKS.DEFAULT_BANNER_MOBILE_URL : IMG_LINKS.DEFAULT_BANNER_URL);
            this._logoSrcSubject.next(IMG_LINKS.DEFAULT_LOGO_URL);
        }
        // If it isn't then we should choose between mobile banner or desktop banner current campaign (both provided by mkt team).
        else {
            this._bannerSrcSubject.next(isMobile ? IMG_LINKS.CAMPAIGN_BANNER_MOBILE_URL : IMG_LINKS.CAMPAIGN_BANNER_URL);
        }
    }

    public getLogoSrc(): Observable<string> {
        return this.logoSrc$;
    }

    public getBannerSrc(): Observable<string> {
        return this.bannerSrc$;
    }
}