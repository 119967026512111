import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as moment from 'moment';
import * as _ from 'lodash';
@Pipe({
  name: 'quickTripTimeFilter',
})
export class QuickTripTimeFilterPipe implements PipeTransform {
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    filterQuickTripTime: boolean = false
  ): Array<Array<AvailableFlightsResponse>> {
    if (filterQuickTripTime) {
      let newFlightList: Array<Array<AvailableFlightsResponse>> = originalflightList.map((item) => item);
      newFlightList.sort((a, b) => {
        const durationA = calculateSmallestDuration(a);
        const durationB = calculateSmallestDuration(b);
        if(durationA === durationB){
          return Math.ceil(a[0].totalPrice) - Math.ceil(b[0].totalPrice);
        }
        if(durationA < durationB) {
          return 1;
        } else {
          return -1;
        }
      });
      return newFlightList;
    } else {
      return originalflightList;
    }
  }
}
function calculateSmallestDuration(obj: AvailableFlightsResponse[]) {
  let smallestGoingDuration = Infinity;
  let smallestReturnDuration = Infinity;
  obj.forEach((item) => {
    item.slices.forEach((slice) => {
      const departureDate = moment(slice.departureDate);
      const arrivalDate = moment(slice.arrivalDate);
      const duration = moment.duration(departureDate.diff(arrivalDate));
      const sliceDuration = duration.asMilliseconds();
      if (slice.direction === 'going' && sliceDuration < smallestGoingDuration) {
        smallestGoingDuration = sliceDuration;
      }
      if (slice.direction === 'return' && sliceDuration < smallestReturnDuration) {
        smallestReturnDuration = sliceDuration;
      }
    })
  })
  return Math.min(smallestGoingDuration, smallestReturnDuration);
}