<article class="price-matrix" data-zt="priceMatrix" *ngIf="airlineStopsPrices.length > 0">
  <perfect-scrollbar class="ps-show-always">
    <table width="100%">
      <thead>
        <tr>
          <th class="first-column">Todas as cias</th>
          <th class="best-prices"><i class="fas fa-award"></i> Melhores Preços</th>
          <th
            class="multi-airlines"
            data-zt="multiAirlinesFilter"
            *ngIf="!!multiAirlineStopsPrices"
            (click)="selectAirline('MULTI')">
            <img src="assets/images/multi-cias.svg">
            Cias diferentes
          </th>
          <th
            *ngFor="let stopPrices of airlineStopsPricesFilter"
            class="airline-logo"
            (click)="selectAirline(stopPrices.airline)"
          >
            <img class="airline-logo" src="{{getAirlineInfo(stopPrices.airline).logoUrl}}">

          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rowIndex of [].constructor(maxStopsCount+1); let i = index">
          <td class="first-column">
            <span *ngIf="i == 0">Sem Paradas</span>
            <span *ngIf="i == 1">1 Parada</span>
            <span *ngIf="i > 1">{{i}} Paradas</span>
          </td>
          <td class="best-prices">
            <span (click)="selectPrice(getBestPrice(i), null, i)">{{getBestPrice(i) | currency: "R$"}}</span>
          </td>
          <td *ngIf="!!multiAirlineStopsPrices" class="airline-price">
            <span
              [ngClass]="{'font-weight-bold': getBestPrice(i) == multiAirlineStopsPrices.getPrice(i).price}"
              class="price"
              *ngIf="multiAirlineStopsPrices.getPrice(i) !== null"
              (click)="selectPrice(multiAirlineStopsPrices.getPrice(i).price, multiAirlineStopsPrices.airline, i)">
              {{ multiAirlineStopsPrices.getPrice(i).price | currency : "R$"}}
            </span>
            <span class="no-price" *ngIf="multiAirlineStopsPrices.getPrice(i) == null">
              -
            </span>
          </td>
          <td class="airline-price" *ngFor="let stopPrices of airlineStopsPricesFilter; let index = index">
            <span
              [ngClass]="{'font-weight-bold': getBestPrice(i) == stopPrices.getPrice(i).price}"
              class="price-pointer"
              *ngIf="stopPrices.getPrice(i) !== null"
              (click)="selectPrice(stopPrices.getPrice(i).price, stopPrices.airline, i)">
              {{ stopPrices.getPrice(i).price | currency : "R$"}}
            </span>
            <span class="no-price" *ngIf="stopPrices.getPrice(i) == null">
              -
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </perfect-scrollbar>
</article>
<article class="price-matrix" data-zt="priceMatrix" *ngIf="airlineStopsPrices.length == 0">
  <div class="ph-item" *ngFor="let item of [].constructor(4)">
      <article class="loading-container">
          <div>
              <div class="row"></div>
              <div class="row"></div>
              <div class="row"></div>
          </div>
      </article>
  </div>
</article>
