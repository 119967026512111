<section id="login-selection" class="login-step">
  <ng-container *ngIf="!isLoading">
    <a href="#" class="login-selection-button email" (click)="emailLogin($event)" data-zt="signWithEmail">
      <span class="icon">
        <img src="assets/img/mail-white.svg">
      </span>
      <span>Entrar com meu e-mail</span>
    </a>
    <div class="separator">
      <span>Ou</span>
    </div>
    <a href="#" (click)="googleLogin($event)" class="login-selection-button google" data-zt="signWithGoogle">
      <span class="icon">
        <img src="assets/img/google.png">
      </span>
      <span>Entrar com <strong>Google</strong></span>
    </a>
    <a href="#" (click)="facebookLogin($event)" class="login-selection-button facebook">
      <span class="icon">
        <img src="assets/img/facebook-white.png">
      </span>
      <span>Entrar com <strong>Facebook</strong></span>
    </a>
    <p class="home-link"><a [href]="homeLink" target="_self">Ir para o início do site</a></p>
  <p class="account-create-text">Ainda não tem uma conta? <a [routerLink]="['/conta/nova-conta']">Criar conta agora</a></p>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <p class="account-loading">Carregando suas contas {{ providerName }}</p>
    <p class="account-waiting">Aguarde...</p>
    <div class="ph-item">
        <article class="loading-container">
            <div>
                <div class="row"></div>
            </div>
        </article>
    </div>
  </ng-container>

</section>
