import { OrderTrackingModalComponent } from './../order-tracking-modal/order-tracking-modal.component';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../storage/local-storage.service';
import { ContentConfigurationService } from '../../configuration.service';
import { Router } from '@angular/router';
import { TagConfigurations } from '../../models/configuration';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aerial-side-menu',
  templateUrl: './aerial-side-menu.component.html',
  styleUrls: ['./aerial-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AerialSideMenuComponent implements OnInit {
  public contentConfiguration: TagConfigurations;
  public subscription: Subscription;
  public isDestinationGuideAvailable: boolean = false;

  constructor(
    private modalService: NgbModal,
    private _localStorageService: LocalStorageService,
    private contentConfigurationService: ContentConfigurationService,
    @Inject('env') private env: any
  ) {}

  ngOnInit(): void {
    this.subscription =
      this.contentConfigurationService.configurations$.subscribe(
        (data: TagConfigurations) => {
          this.contentConfiguration = data;
          this.isDestinationGuideAvailable = this.contentConfiguration?.destinationGuideModel?.isActive;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get links() {
    return this.env['links'];
  }

  openOrderTrackingForm(): void {
    const modalRef = this.modalService.open(OrderTrackingModalComponent, {
      size: 'xl',
      centered: true,
    });
  }

  clearWhiteLabel(): void {
    this._localStorageService.removeItem('whitelabel');
  }
}
