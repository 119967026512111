<section class="CarroselSectionBanner">
    <div class="container-fluid" *ngIf="loading">
        <div class="ph-item">
            <div class="row">
                <div class="ph-picture"></div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="! loading && data">
        <owl-carousel-o #bannerCarousel [options]="carouselOptions" (changed)="slideChanged($event)">
            <ng-container *ngFor="let banner of data">
                <ng-template carouselSlide [id]="banner.image">
                  <!-- <img src="{{ banner.fullPath }}"> -->
                    
                        <img src="{{banner.fullPath }}"
                          class="CarroselBanner"
                          [ngClass]="{'has-click': banner.url != ''}"
                          (click)="bannerClick(banner.url)"
                        >
                    
                </ng-template>
            </ng-container>
            
        </owl-carousel-o>
        <div class="navegacaoCarrosel">
            <ng-container *ngFor="let banner of data">
                <div class="navegacaoCirculo" [ngClass]="{ 'navegacaoCirculoActive': current.image === banner.image }" (click)="bannerCarousel.to(banner.image)"></div>
            </ng-container>
        </div>
    </ng-container>

</section>
