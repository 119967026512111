import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { ApiTokenRepositoryService } from '@zupper/data';
import { LogConfigModel } from './log-config.model';
import { ApiLogLevel, LogEntryModel } from './api-log-level.enum';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private static config = new LogConfigModel();
  constructor(
    private tokenService: ApiTokenRepositoryService,
    private httpService: HttpClient
) {
}

static setConfig(config: LogConfigModel) {
  LoggerService.config = config;
}

static getConfig() {
  return LoggerService.config;
}

log(level: ApiLogLevel, message: string, context: any = null) {
  // this.tokenService.get().pipe(
  //   map((token: string) => {
  //     const logEntry    = new LogEntryModel();
  //     logEntry.level    = level;
  //     logEntry.message  = message;
  //     logEntry.context  = context;
  //     logEntry.token    = token;

  //     return logEntry;
  //   }),
  //   tap((logEntry: LogEntryModel) => {
  //     if (LoggerService.config.serverLogLevel === logEntry.level) {
  //       logEntry.logName = 'UNCAUGHT_ERROR';
  //       this.httpService.post(LoggerService.config.serverLoggingUrl, logEntry).subscribe((response) => {
  //         if (response['success'] === true) {
  //           console.info('Error logged successfully to the API');
  //         } else {
  //           console.warn('Unable to log error to the API');
  //         }
  //       });
  //     }

  //     switch (logEntry.level) {
  //       case ApiLogLevel.INFO:
  //       case ApiLogLevel.DEBUG:
  //         console.info(logEntry.message, logEntry);
  //         break;
  //       case ApiLogLevel.ALERT:
  //       case ApiLogLevel.NOTICE:
  //       case ApiLogLevel.WARNING:
  //         console.warn(logEntry.message, logEntry);
  //         break;
  //       default:
  //         console.error(logEntry.message, logEntry);
  //     }
  //   })
  // ).subscribe();
}

debug(message: string, context: any = null) {
  return this.log(ApiLogLevel.DEBUG, message, context);
}

error(message: string, context: any = null) {
  return this.log(ApiLogLevel.ERROR, message, context);
}

critical(message: string, context: any = null) {
  return this.log(ApiLogLevel.CRITICAL, message, context);
}

warn(message: string, context: any = null) {
  return this.log(ApiLogLevel.WARNING, message, context);
}

info(message: string, context: any = null) {
  return this.log(ApiLogLevel.INFO, message, context);
}
}
