import { Component, Input, OnInit } from '@angular/core';
import { AirportSearchModel } from '@zupper/aerial-components';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss']
})
export class PassengersComponent implements OnInit {
  passengerDisplay: string = '';
  classDisplay: string = '';
  peopleAmount: number = 0;

  @Input() flightSearchFields: AirportSearchModel;

  constructor() {
  }

  ngOnInit(): void {
    this.fillAmountPeopleFlight();
    this.setFlightClass();
  }

  fillAmountGuestsRooms() {
    let peopleAmount = this.flightSearchFields.adultQty + this.flightSearchFields.childrenQty + this.flightSearchFields.infantQty;
    this.passengerDisplay = (peopleAmount + (peopleAmount > 1 ? ' passageiros' : ' passageiro'));
  }

  fillAmountPeopleFlight() {
    this.peopleAmount = this.flightSearchFields.adultQty + this.flightSearchFields.childrenQty + this.flightSearchFields.infantQty;
    this.passengerDisplay = (this.peopleAmount + (this.peopleAmount > 1 ? ' passageiros' : ' passageiro'));
  }

  chooseClass(aClass: string) {
    if (aClass == "economic") {
      this.classDisplay = "Econômica";
      this.flightSearchFields.onlyBusinessClass = false;
      this.flightSearchFields.onlyEconomicClass = true;
    } else {
      if (aClass == "business") {
        this.classDisplay = "Executiva";
        this.flightSearchFields.onlyBusinessClass = true;
        this.flightSearchFields.onlyEconomicClass = false;
      }
    }
  }

  setFlightClass() {
    if( this.flightSearchFields.onlyBusinessClass == true) {
      this.chooseClass('business');
      return "business";
    } else {
      this.chooseClass("economic");
      return "economic";
    }
  }

  addAdult() {
    if (this.peopleAmount < 8) {
      this.flightSearchFields.addAdult();
      this.fillAmountPeopleFlight();
    }
  }
  removeAdult() {
    if (this.peopleAmount > 1 && this.flightSearchFields.adultQty > this.flightSearchFields.infantQty) {
      this.flightSearchFields.removeAdult();
      this.fillAmountPeopleFlight();
    }
  }

  addChild() {
    if (this.peopleAmount < 8) {
      this.flightSearchFields.addChild();
      this.fillAmountPeopleFlight();
    }
  }
  removeChild() {
    if (this.peopleAmount > 1 && this.flightSearchFields.childrenQty > 0) {
      this.flightSearchFields.removeChild();
      this.fillAmountPeopleFlight();
    }
  }

  addInfant() {
    if (this.peopleAmount < 8 && this.flightSearchFields.adultQty > this.flightSearchFields.infantQty) {
      this.flightSearchFields.addInfant();
      this.fillAmountPeopleFlight();
    }
  }
  removeInfant() {
    if (this.peopleAmount > 1 && this.flightSearchFields.infantQty > 0) {
      this.flightSearchFields.removeInfant();
      this.fillAmountPeopleFlight();
    }
  }

  isValid() {
    return this.peopleAmount > 0 ? true : false;
  }
}
