<swal #modal></swal>
<app-search-summary *ngIf="flightsSearchFields" [searchRequest]="flightsSearchFields"></app-search-summary>

<section class="result-container">
    <div class="combination" fxHide.lt-md="true">
        <h3 class="description">Melhores tarifas encontradas</h3>
        <button style="display: none;" (click)="toggleCombination()" class="combined-results">
            <i class="fas fa-random"></i>Combinar cias aereas
        </button>
    </div>

    <app-uncombined-result *ngIf="!this.combined" [flightsSearchFields]="this.flightsSearchFields" [initialFilterValues]="initialFilterValues"></app-uncombined-result>

    <app-combined-result *ngIf="this.combined" [flightsSearchFields]="this.flightsSearchFields"></app-combined-result>

</section>
