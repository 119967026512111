import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as _ from 'lodash';

@Pipe({
  name: 'uniqueFamily'
})
export class UniqueFamilyPipe implements PipeTransform {
  familyList: Array<string> = [];

  addFamily(familyName: string): boolean {
    let isFamilyAdded = this.familyList.indexOf(familyName);
    this.familyList.push(familyName);
    return isFamilyAdded == -1;
  }

  transform(flights: Array<AvailableFlightsResponse>): Array<AvailableFlightsResponse> {
    this.familyList = [];
    let filteredFlights: Array<AvailableFlightsResponse> = [];

    filteredFlights = _.uniqBy(flights.filter(f => !!f), (flight) => flight.slices[0].baseFares[0].family);

    return filteredFlights;
  }
}
