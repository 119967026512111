import { Component, Inject } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { AerialCheckoutBuilderService, AerialCheckoutData, AvailableFlightsResponse } from '@zupper/aerial-components';
import { ApiFlightType, AppCheckerService, EnvironmentInterface } from '@zupper/common';
import * as _ from 'lodash';
import { CreditCardRulesModel } from 'projects/aerial/src/app/models/api-filter.model';
import { AbstractAerialResultComponent } from '../../result-list/result-list-price/aerial-result';
import { AirlineServiceService } from '../airline-service.service';

@Component({
  selector: 'app-same-airline-flight-group-summary',
  templateUrl: './same-airline-flight-group-summary.component.html',
  styleUrls: ['./same-airline-flight-group-summary.component.scss'],
})
export class SameAirlineFlightGroupSummaryComponent extends AbstractAerialResultComponent {
  constructor(
    protected appCheckerService: AppCheckerService,
    public aerialCheckoutBuilder: AerialCheckoutBuilderService,
    private airlineServiceService: AirlineServiceService,
    @Inject('env') protected env: EnvironmentInterface
  ) {
    super(appCheckerService, aerialCheckoutBuilder, env);
  }

  ngOnInit(): void {
    this.flightList = this.flightList.sort((a, b) => a.totalPrice >= b.totalPrice ? 1 : -1);
    this.isInternational = JSON.parse(sessionStorage.getItem("isInternational"));
    this.flightSearch = JSON.parse(sessionStorage.getItem("flightSearch"));
    if (this.tripType !== ApiFlightType.MultiSlice) {
      this.processInstallments();
    }
  }

  setSelectedFamily(familyName: string) {
    this.selectedFamilyName.emit(familyName);
  }

  sendSlicesToCheckout() {
    let flight = this.chosenFlight;
    flight.slices = this.chosenSlices;

    const checkoutData: AerialCheckoutData = {
      meta: {
        request: {
          childrenQty: this.flightSearch.childrenQty,
          infantQty: this.flightSearch.infantQty,
          adultQty: this.flightSearch.adultQty,
        },
      },
      result: [
        flight
      ],
    };
    sessionStorage.setItem("upsellPrice", JSON.stringify(this.upsellPrice));
    const cid = this.aerialCheckoutBuilder.saveCheckoutData(checkoutData);

    let openBrowser = this.appCheckerService.isApp() ? "_self" : "_blank";
    window.open(this.env.checkout.url + '?cid=' + cid, openBrowser);
  }

  setChosenFlight(flight: AvailableFlightsResponse) {
    this.chosenFlight = flight;
  }

  get getTaxes() {
    return (
      Number(this.chosenFlight.totalDuPrice) +
      Number(this.chosenFlight.totalDu2Price) +
      Number(this.chosenFlight.totalAirportTaxesPrice)
    );
  }

  get getAdultTotalPrice() {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result +=
        this.getTotalByAdult +
        slice.baseFares[0].du2Value +
        slice.baseFares[0].incentiveValue;
    });
    return result;
  }

  get getTotalByAdult() {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result += slice.baseFares[0].fareValue + slice.baseFares[0].taxValue;
    });
    return result;
  }

  get totalTaxes() {
    return this.getTaxes;
  }

  get totalPrice() {
    return this.chosenFlight.totalPrice;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get baseFareValue() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenSlices.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.baseFareValue;
        totalFare.All += basefare.baseFareValue;
      });
    });
    return totalFare;
  }

  get totalBaseFarePrice() {
    return (
      this.baseFareValue.Adult * this.flightSearch.adultQty +
      this.baseFareValue.Child * this.flightSearch.childrenQty +
      this.baseFareValue.Infant * this.flightSearch.infantQty
    );
  }

  get fareByPaxType() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenFlight.slices?.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.fareValue;
        totalFare.All += basefare.fareValue;
      });
    });
    return totalFare;
  }

  setUpsellPrice(upsellPrice: number) {
    this.upsellPrice = upsellPrice;
  }

  processInstallments = () => {
    const chosenFlightAirline = this.chosenFlight?.airline;
    if (chosenFlightAirline) {
      const airlines = this.airlineServiceService.getFilteredAirlines(this.chosenFlight.airline);
      const maxInstallmentConfig: { maxInstallment: number; hasTax: boolean } = this.filterMeta[airlines] && this.filterMeta[airlines]['creditRules'] ? (
        this.filterMeta[airlines]['creditRules'] as CreditCardRulesModel[]
      )?.reduce(
        (prev, curr) => {
          const maxCurrent = _.max(curr.allowedInstallments);
          const maxInstallment = curr.minInstallmentValue.find(
            (v) => v.installmentCount === maxCurrent
          ).installmentCount;
          const foundTaxConfig = curr.hasCardTax.find((t) => t.installmentCount === maxInstallment);
          const currentConfig = {
            maxInstallment: maxInstallment,
            hasTax: foundTaxConfig?.hasTax ?? this.hasCardTax,
          };
          if (!prev) {
            return currentConfig;
          } else {
            const usePrevValue = prev.maxInstallment >= currentConfig.maxInstallment;
            return {
              maxInstallment: _.max([prev.maxInstallment, currentConfig.maxInstallment]),
              hasTax: usePrevValue ? prev.hasTax : currentConfig.hasTax,
            };
          }
        },
        { maxInstallment: this.maxAllowedInstallment, hasTax: this.hasCardTax }
      ) : null;
  
      if (!maxInstallmentConfig) {
        const scope = new Sentry.Scope();
        scope.setContext("extra", {
          airlines: airlines,
          filterMeta: this.filterMeta,
          creditRules: this.filterMeta[airlines]?.creditRules,
        })
        Sentry.captureException(new Error("Object 'maxInstallments' is undefined"), scope);
      } else {
        this.maxAllowedInstallment = maxInstallmentConfig.maxInstallment;
        this.hasCardTax = maxInstallmentConfig.hasTax;
      }
    }
    
  };
}
