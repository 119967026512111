<ng-container *ngIf="type === 'goDaddy'">
  <span id="siteseal" #goDaddy></span>
</ng-container>
<ng-container *ngIf="type === 'siteBlindado'">
  <div #siteBlindado>
    <div  id="armored_website">
      <param id="aw_preload" value="true" />
      <param id="aw_use_cdn" value="true" />
    </div>
  </div>
</ng-container>
