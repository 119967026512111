import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { ApiCampaignModel, ApiCampaignService } from "@zupper/data";
import moment from "moment";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { PartnerIdentificationService } from "../partner-identification.service";
import { LocalStorageService } from "../../lib/storage/local-storage.service";

@Injectable({ providedIn: "root" })
export class UtmCampaignResolver implements Resolve<ApiCampaignModel> {
  private partnerId: string;

  constructor(private campaignService: ApiCampaignService,
    private pathIdentification: PartnerIdentificationService,
    private _localStorageService: LocalStorageService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ApiCampaignModel> | Promise<ApiCampaignModel> | ApiCampaignModel {
    const queryParams = route.queryParamMap;
    this.partnerId = this.pathIdentification.getIdPartner(queryParams);
    const utmSource: string = queryParams.get('utm_source');
    const utmCampaign: string = queryParams.get('utm_campaign');
    const utmMedium: string = queryParams.get('utm_medium');

    if (utmSource == null && utmCampaign == null && utmMedium == null) {
      return of(null);
    }

    this.setUTMParams(route);

    const observable = this.campaignService.getCampaignData(
      utmSource,
      utmCampaign,
      utmMedium
    )

    return observable
      .pipe(map((response: ApiCampaignModel[]) => {
        if (Array.isArray(response) && response.length > 0) {
          return response[0];
        }
        else {
          return of(null);
        }
      }),
        tap((response: ApiCampaignModel) => {
          if (response !== null) {
            let storageData = {
              'data': JSON.stringify(response),
              'expiry': moment(Date.now()).add(1, "day")
            };
            this._localStorageService.setItem('utmCampaign', storageData);
          }
        }));
  }

  setUTMParams(route: ActivatedRouteSnapshot) {
    const data = {
      utmSource: route.queryParamMap.get('utm_source'),
      utmMedium: route.queryParamMap.get('utm_medium'),
      utmCampaign: route.queryParamMap.get('utm_campaign'),
      utmTerm: route.queryParamMap.get('utm_term'),
      utmContent: route.queryParamMap.get('utm_content'),
      partnerId: this.partnerId
    };
    let storage = {
      'data': data,
      'expiry': moment().add(7, "day")
    }

    this._localStorageService.setItem('utm_storage', storage);
  }
}

export const utmCampaignResolver: ResolveFn<ApiCampaignModel> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(UtmCampaignResolver).resolve(route, state);
};

