import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McrComponent } from './mcr.component';
import { ZupperCommonModule } from '@zupper/common';

@NgModule({
  declarations: [McrComponent],
  imports: [
    CommonModule,
    ZupperCommonModule
  ]
})
export class McrModule { }
