<ng-template #cookieModal let-modal >
  <div class="modal-header">
    <span class="modal-icon"><i class="fas fa-cookie-bite"></i></span>
    <h4 class="modal-title">Ops... Identificamos um bloqueador de cookies</h4>
  </div>
  <div class="modal-content-c">
    <div class="modal-body">
      <p class="title-info">Com isso, nosso site poderá não funcionar corretamente. <br>Desative o bloqueador de cookies em seu navegador e
        aproveite 100% da experiência de compras das suas próximas viagens com a Zupper!</p>
      <p class="subheading">Fica tranquilo(a) que vamos te ajudar ;)</p>
      <div>
        <h5 class="collapse-title" (click)="toggleCollapse('google')">
          Se você usa <b>Google Chrome</b>
          <i class="fa fa-angle-double-right" [ngClass]="{'rotate': isCollapseExpanded.google}"></i>
        </h5>
        <div class="collapse-content" [class.expanded]="isCollapseExpanded.google">
          <section class="grid">
            <section>
              <p>No computador:</p>
              <ol class="hyphen-list">
                <li>Abra o Chrome</li>
                <li>No canto superior direito, clique em mais</li>
                <li>Configurações</li>
                <li>Em "Privacidade e segurança", clique em <br>Cookies e outros dados do site</li>
                <li>Selecione a opção: "Mostrar todos os cookies"</li>
              </ol>
            </section>
            <section>
              <p>No celular:</p>
              <ol class="hyphen-list">
                <li>Abra o Chrome</li>
                <li>Clique em configurações</li>
                <li>Depois selecione a opção configuração do site</li>
                <li>Cookies</li>
                <li>Selecione permitir cookies</li>
              </ol>
            </section>
          </section>
        </div>
      </div>
      <div>
        <div class="divider"></div>
        <h5 class="collapse-title" (click)="toggleCollapse('safari')">
          Se você usa <b>Safari</b>
          <i class="fa fa-angle-double-right" [ngClass]="{'rotate': isCollapseExpanded.safari}"></i>
        </h5>
        <div class="collapse-content" [class.expanded]="isCollapseExpanded.safari">
          <ol class="hyphen-list">
            <li>Selecione o menu Ação (Canto direito superior da janela no ícone de engrenagem)</li>
            <li>Preferências e clique em Segurança.</li>
            <li>Na opção “Aceitar Cookies”, especifique quando o Safari deve aceitar cookies de sites. Para obter uma
              explicação das opções, clique no botão Ajuda (que se parece com um ponto de interrogação).</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</ng-template>