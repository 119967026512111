<div class="modal-body" style="padding: 0;">
  <div class="row">
    <div class="col-md-6 p0">
      <div class="bgEsquerdaModal"></div>
    </div>
    <div class="col-md-6 p0">
      <div class="boxModalAlert">
        <ng-container *ngIf="!customTemplate">
          <h2><i class="fas fa-exclamation-circle"></i></h2>
          <h3>Ops... Ocorreu algum problema ao processar a reserva</h3>
          <p>Mas fique tranquilo, você pode fazer uma nova busca para sua próxima viagem.</p>
          <p>1... 2... 3... Valendo !!!</p>
        </ng-container>
        <ng-container *ngIf="!!customTemplate">
          <ng-container *ngTemplateOutlet="customTemplate">

          </ng-container>
        </ng-container>
        <button (click)="activeModal.close('Cross click')">Fazer nova busca</button>
      </div>
    </div>
  </div>
</div>
