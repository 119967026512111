import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FlightSlicesModel } from '@zupper/aerial-components';
import {
  ApiAerialPromo,
  ApiAerialPromotionsService,
  ApiCampaignModel,
  ApiFlightRequest,
  ApiFlightType,
  MOMENT_FORMATS
} from '@zupper/data';


import { ActivatedRoute, Params, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagServiceService, WhatsappTag, ZupperLogoBannerService, ZupperProductOffer } from '@zupper/common';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  qtyPromo: number = 0;
  qtyPromoDefault: number = 1;
  utmCampaignData: Array<ApiCampaignModel>;

  zupperProductOffer = ZupperProductOffer.PACKAGES;

  private subs = new SubSink();
  whatsappTag: WhatsappTag;

  public logoImage: string;
  public bannerImage: string;

  constructor(
    private router: Router,
    private promoService: ApiAerialPromotionsService,
    private tagServiceService: TagServiceService,
    private _zupperLogoBannerService: ZupperLogoBannerService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._zupperLogoBannerService.getLogoSrc().subscribe((src: string) => this.logoImage = src);
    this._zupperLogoBannerService.getBannerSrc().subscribe((src: string) => this.bannerImage = src);
  }

  ngOnInit(): void {
    this.subs.add(
      this.promoService.getAll().subscribe((data: ApiAerialPromo[]) => {
        this.qtyPromo = data.length;
      })
    );

    if (!this._activatedRoute.snapshot.data.fromCorporateLogout) {
      this.subs.add(
        this.tagServiceService.configuration$.subscribe((data) => {
          this.whatsappTag = data.whatsappTag;
        })
      )
    }
  }

  searchSubmit(searchRequest: ApiFlightRequest) {
    this.router.navigate(['/resultados'], {
      queryParams: searchRequest.toParams(),
    });
  }

  onPromoSelected(infoPromo: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let slices = this._buildRedirectModel(infoPromo);
    this.router.navigate(['/resultados'], {
      queryParams: {
        type: slices.length == 1 ? ApiFlightType.OneWay : ApiFlightType.RoundTrip,
        adultQty: 1,
        childrenQty: 0,
        infantQty: 0,
        slices: JSON.stringify(slices),
      },
    });
  }

  private _buildRedirectModel(infoPromo: any): Params {
    let slices: FlightSlicesModel[] = [];

    infoPromo.promo.slices
      .sort((a, b) => (a.departureDate.isBefore(b.departureDate) ? -1 : 1))
      .forEach((ps, psIndex) => {
        if (!slices[psIndex]) {
          slices[psIndex] = new FlightSlicesModel();
        }

        slices[psIndex].departureDate = ps.departureDate.format(MOMENT_FORMATS.API_DATE);
        let geoAirport = null;
        if (infoPromo.userLocation.foundAirports) {
          geoAirport = infoPromo.userLocation.foundAirports[0].IATACode;
        } else {
          geoAirport = psIndex == 0 ? ps.originAirportCode : ps.destinationAirportCode;
        }

        if (psIndex == 0) {
          slices[psIndex].originAirport = geoAirport;
          slices[psIndex].destinationAirport = ps.destinationAirportCode;
        } else {
          slices[psIndex].originAirport = ps.originAirportCode;
          slices[psIndex].destinationAirport = geoAirport;
        }
      });

    return slices;
  }

  setQtyPromo(quant: number) {
    this.qtyPromo = quant;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getStyle() {
    const tripType = sessionStorage.getItem('typeTrip');
    if (tripType === 'multiSlice') {
      return {
        top: '-1rem',
      };
    } else {
      return {};
    }
  }
}
