import { Injectable } from '@angular/core';
import { AirlineFamilyModel, FamilyDataModel, FamilyFieldModel } from '../models/airline-family.model';

@Injectable({
  providedIn: "root",
})
export class AirlineConstantService {
  constructor() {}

  static getG3Families(): AirlineFamilyModel {
    return new AirlineFamilyModel("G3")
      .addFamily(
        new FamilyDataModel("PROMO")
          .addField(new FamilyFieldModel("baggage", false, "Bagagem"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("LIGHT")
          .addField(new FamilyFieldModel("baggage", false, "Bagagem"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("PLUS")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", true, "Reembolso de 40%"))
      )
      .addFamily(
        new FamilyDataModel("MAX")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", true, "Reembolso de 95%"))
      );
  }

  static getADFamilies(): AirlineFamilyModel {
    return new AirlineFamilyModel("AD")
      .addFamily(
        new FamilyDataModel("AZUL")
          .addField(new FamilyFieldModel("baggage", false, "Bagagem"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("MAISAZUL")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      );
  }

  static getLAFamilies(): AirlineFamilyModel {
    return new AirlineFamilyModel("LA")
      .addFamily(
        new FamilyDataModel("LIGHT")
          .addField(new FamilyFieldModel("baggage", false, "Bagagem"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("STANDARD")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem (01 peça)"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("FULL")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem (01 peça)"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", true, "Reembolso Integral"))
      )
      .addFamily(
        new FamilyDataModel("PREMIUM ECONOMY")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem (01 peça)"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", true, "Reembolso Integral"))
      )
      .addFamily(
        new FamilyDataModel("PREMIUM BUSINESS")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem (02 peças)"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", true, "Reembolso Integral"))
      );
  }

  static get2ZFamilies(): AirlineFamilyModel {
    return new AirlineFamilyModel("2Z")
      .addFamily(
        new FamilyDataModel("LIGHT")
          .addField(new FamilyFieldModel("baggage", false, "Bagagem"))
          .addField(new FamilyFieldModel("seat", false, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso"))
      )
      .addFamily(
        new FamilyDataModel("BASIC")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso de 70%"))
      )
      .addFamily(
        new FamilyDataModel("PLUS")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso de 40%"))
      )
      .addFamily(
        new FamilyDataModel("TOP")
          .addField(new FamilyFieldModel("baggage", true, "Bagagem"))
          .addField(new FamilyFieldModel("seat", true, "Marcação de Assento"))
          .addField(new FamilyFieldModel("refund", false, "Reembolso de 30%"))
      );
  }
}
