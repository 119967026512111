import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, filter, fromEvent } from 'rxjs';
import { SubSink } from 'subsink';

@Injectable({
    providedIn: 'root'
})
export class UserInteractionDetectorService implements OnDestroy {
    private clickSubject: Subject<void> = new Subject<void>();
    private movementSubject: Subject<void> = new Subject<void>();
    private mouseLeaveSubject: Subject<void> = new Subject<void>();
    private subs = new SubSink();

    constructor() {
        this.subs.add(this.setupClickDetection(), this.setupMovementDetection(), this.setupMouseLeaveDetection());
    }

    public detectClicks(): Subject<void> {
        return this.clickSubject;
    }

    public detectMouseMovements(): Subject<void> {
        return this.movementSubject;
    }

    public detectClosePageAttempt(): Subject<void> {
        return this.mouseLeaveSubject;
    }

    private setupClickDetection(): Subscription {
        return fromEvent(document, 'click')
            .subscribe(() => this.clickSubject.next());
    }

    private setupMovementDetection(): Subscription {
        return fromEvent(document, 'mousemove')
            .subscribe(() => this.movementSubject.next());
    }

    private setupMouseLeaveDetection(): Subscription {
        return fromEvent<MouseEvent>(document, 'mouseleave')
            .pipe(filter(event => event.clientY <= 0))
            .subscribe(() => this.mouseLeaveSubject.next());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}