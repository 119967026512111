<span
  class="next-day"
  *ngIf="showBadge"
  #nextDayPopup="ngbPopover"
  [ngbPopover]="popupTemplate"
  triggers="mouseenter:mouseleave"
>
  +{{ days }}
</span>

<ng-template #popupTemplate>
  <div class="next-day-popup">
    <p>Chega em dia diferente.</p>
  </div>
</ng-template>
