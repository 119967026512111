import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiFlightType } from '@zupper/data';
import { AirportSearchModel, AvailableFlightsRequest } from '@zupper/aerial-components';
import * as _ from 'lodash';

@Component({
  selector: "app-flight-search",
  templateUrl: "./flight-search.component.html",
  styleUrls: ["./flight-search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FlightSearchComponent implements OnInit, AfterContentChecked {
  flightSearchFields: AirportSearchModel = new AirportSearchModel();
  tripTypeChosen: string;
  flightSearch: AvailableFlightsRequest;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.flightSearch = JSON.parse(
      sessionStorage.getItem("flightSearch")
    );
  }

  ngOnInit(): void {
    this.flightSearchFields.type = this.flightSearch ?
      (this.flightSearch.type === ApiFlightType.OneWay ? ApiFlightType.OneWay :
        (this.flightSearch.type === ApiFlightType.MultiSlice ? ApiFlightType.MultiSlice : ApiFlightType.RoundTrip)) :
      ApiFlightType.RoundTrip;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  getTripTypeChosen(): string {
    this.tripTypeChosen = this.flightSearchFields.type;
    sessionStorage.setItem( "typeTrip", this.tripTypeChosen)
    return this.flightSearchFields.type;
  }

  getClass(): string {
    switch (this.flightSearchFields.type) {
      case "oneWay":
        return "arrowTrip-oneWay";
        break;
      case "multiSlice":
        return "arrowTrip-multiSlice";
        break;
      default:
        return "arrowTrip";
    }
  }

}
