import { Component, Input, OnInit } from '@angular/core';
import { ApiOfferService, ApiOffer } from '@zupper/data';
import { ZupperProductOffer } from '../../models/zupper-product.enum';

@Component({
  selector: 'zc-offers',
  templateUrl: './zc-offers.component.html',
  styleUrls: ['./zc-offers.component.scss']
})
export class ZcOffersComponent implements OnInit {
  @Input() zupperProductOffer: ZupperProductOffer;
  isLoading: boolean = false;
  offer: ApiOffer;

  constructor(
    private _apiOfferService: ApiOfferService
  ) { }

  ngOnInit(): void {
    this._apiOfferService.offers(this.zupperProductOffer);
    this._apiOfferService.loadingSubject$.subscribe((isLoading) => {
			this.isLoading = isLoading;
		});
    this._apiOfferService.offerSubject$.subscribe((res: ApiOffer) => {
      this.offer = res;
    });
  }

}
