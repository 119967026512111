<section class="filter_summary">
  <div fxShow.gt-sm="true" fxHide="true">
    <div class="zupper-container" *ngIf="!isChanging" gdColumns.lt-lg="1.2fr 1.2fr repeat(2, 1fr)" >
      <article>
          <h3 class="summary_option"><i class="fa fa-map-marker-alt map-marker-border"></i>Origem</h3>
          <p *ngIf="searchFields.slices" class="filter_option">
            {{this.getAirportInfo(searchFields.slices[0].originAirport).city}},
            {{this.getAirportInfo(searchFields.slices[0].originAirport).country}}
            - {{searchFields.slices[0].originAirport}}
          </p>
      </article>
      <article>
          <h3 class="summary_option"><i class="fa fa-plane"></i>Destino</h3>
          <p *ngIf="searchFields.slices" class="filter_option">
            {{this.getAirportInfo(searchFields.slices[0].destinationAirport).city}},
            {{this.getAirportInfo(searchFields.slices[0].destinationAirport).country}}
            - {{searchFields.slices[0].destinationAirport}}
          </p>
      </article>
      <article>
          <h3 class="summary_option"><i class="fa fa-calendar"></i>Data</h3>
          <p class="filter_option">
            {{searchFields.slices[0].departureDate | date: 'EE dd \'de\' MMM'}}
            <span *ngIf="searchFields.slices[1]"> - {{searchFields.slices[1].departureDate | date: 'EE dd \'de\' MMM'}}</span>
          </p>
      </article>
      <article fxHide.lt-md="true" fxShow="true">
        <h3 class="summary_option"><i class="fa fa-user"></i>Passageiros</h3>
        <p class="filter_option">{{ adult }}{{ underages }}</p>
    </article>
      <article>
          <button (click)="isChanging = true" class="alter_search">Alterar <span fxHide.lt-lg="true">Busca</span></button>
      </article>
  </div>
  </div>

    <!-- MOBILE -->
    <div class="mobile-summary" *ngIf="!isChanging" fxShow.gt-sm="false" fxShow.lt-md="true">
        <section class="itinerary">
          <div class="origin airport">{{searchFields.slices[0].originAirport}}</div>
          <div class="divider">
            <div class="line">
              <svg width="6" height="6" version="1.1"
                xmlns="https://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#464646"/>
              </svg>
              <hr>
              <svg width="6" height="6" version="1.1"
                xmlns="https://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#464646"/>
              </svg>
            </div>
            <!-- <h4><hr></h4> -->
          </div>
          <div class="destination airport">{{searchFields.slices[0].destinationAirport}}</div>
          <div class="origin airport-city">{{ getAirportInfo(searchFields.slices[0].originAirport).city }}</div>
          <div></div>
          <div class="destination airport-city">{{ getAirportInfo(searchFields.slices[0].destinationAirport).city }}</div>
        </section>
        <hr class="hr-dashed" fxShow.lt-md="false">
        <section class="trip-summary">
          <p class="d-flex">
            <img src="assets/img/custom-svg-icons/calendar-gray.svg" alt="GrayCalendar" class="mr-1">
              {{ getFixedCaseStr(searchFields.slices[0].departureDate | date: 'EE dd \'de\' MMM' | titlecase) }}
              <span 
                *ngIf="searchFields.slices[1]">&nbsp;•&nbsp;{{ getFixedCaseStr(searchFields.slices[1].departureDate | date: 'EE dd \'de\' MMM' | titlecase) }}
              </span>
          </p>
          <p>
            <i class="far fa-user mx-1"></i>
            {{searchFields.adultQty}} {{searchFields.adultQty > 1 ? 'adultos' : 'adulto' | titlecase}}
            <span *ngIf="searchFields.childrenQty > 0">•&nbsp;{{searchFields.childrenQty}} {{searchFields.childrenQty > 1 ? 'crianças' : 'criança' | titlecase}}</span>
            <span *ngIf="searchFields.infantQty > 0">&nbsp;•&nbsp;{{searchFields.infantQty}} {{searchFields.infantQty > 1 ? 'bebês' : 'bebê' | titlecase}}</span>
          </p>
        </section>
        <article style="text-align: center;">
          <button (click)="isChanging = true" class="alter_search" ngClass.lt-md="alter_search-mobile">Alterar pesquisa</button>
        </article>
    </div>
    <!-- END MOBILE -->
    <div class="container_new_search" *ngIf="isChanging">
        <div class="search-wrapper">
          <i class="fa fa-times close-search" (click)="isChanging = false"></i>
        </div>
        <app-flight-search></app-flight-search>
    </div>
</section>
