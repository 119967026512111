import { CalendarComponent } from './../calendar/calendar.component';
import { FindAirportsComponent } from './../find-airports/find-airports.component';
import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AirportSearchModel, AirportsModel, FlightSlicesModel } from '@zupper/aerial-components';
import { ApiFlightType, CalendarTypeEnum } from '../../../services/enum/flights.enum';
import * as _ from 'lodash';
import { AerialSearchManagerService } from '../../../services/aerial-search-manager.service';
import {
  SearchHistoryService,
  SearchHistoryStrategyFactoryService,
  AerialSearchHistory,
  ZupperProduct,
  AerialSearchHistorySlice,
} from '@zupper/search-history';
import { CustomerLoginService } from 'zupper-common';
import { toUpper } from 'lodash';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-multi-destination-search',
  templateUrl: './multi-destination-search.component.html',
  styleUrls: ['./multi-destination-search.component.scss'],
})
export class MultiDestinationSearchComponent implements OnInit, OnDestroy {
  @Input() flightSearchFields: AirportSearchModel = new AirportSearchModel();
  @Input() isOrigin: boolean = true;
  @Input() tripTypeChosen;
  @ViewChildren('elOriginAirport, elDestinationAirport, elDepartureDate, elPassengers')
  elements: QueryList<ElementRef>;

  airportDisplay: string = '';
  airportCodeDisplay: string = '';
  availableAirports: AirportsModel[] = [];
  minDate: any[] = [];

  private subs = new SubSink();

  constructor(
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private service: AerialSearchManagerService,
    private searchHistoryService: SearchHistoryService,
    private searchHistoryStrategyFactory: SearchHistoryStrategyFactoryService,
    private customerLoginService: CustomerLoginService
  ) {}

  ngOnInit(): void {
    if (this.flightSearchFields.slices.length == 0) {
      this.flightSearchFields.slices.push(new FlightSlicesModel());
    }
    let flightSearch = sessionStorage.getItem('flightSearch');
    if (JSON.parse(flightSearch)) {
      Object.assign(this.flightSearchFields, JSON.parse(flightSearch));
      this.flightSearchFields.type = this.tripTypeChosen;
    }

    if (
      this.flightSearchFields.slices.length > 1 &&
      !this.flightSearchFields.slices[0].originAirport
    ) {
      this.flightSearchFields.slices = this.flightSearchFields.slices.slice(0, 1);
    }

    if (this.flightSearchFields.slices.length <= 1) {
      this.newSlice();
    }
  }

  onSubmit() {
    if (!this.validateForm()) {
      return false;
    }

    let result = this.flightSearchFields.slices.filter((slice) => {
      return (
        slice.departureDate == undefined ||
        slice.originAirport == undefined ||
        slice.destinationAirport == undefined
      );
    });

    if (result.length == 0) {
      var slices = [];

      let isInternational = false;
      this.flightSearchFields.slices.forEach((slice) => {
        slice.departureDate = this.formatter.format(slice.departureDate);
        slices.push(slice);

        let originAirport: AirportsModel = JSON.parse(
          sessionStorage.getItem('airport.' + slice.originAirport)
        );
        let destinationAirport: AirportsModel = JSON.parse(
          sessionStorage.getItem('airport.' + slice.destinationAirport)
        );

        if (originAirport.countries_iso !== 'BR' || destinationAirport.countries_iso !== 'BR') {
          isInternational = true;
        }
      });

      if (this.verifyMultiSlices(slices)) {
        this.flightSearchFields.type = ApiFlightType.RoundTrip;
      } else {
        this.flightSearchFields.type =
          this.flightSearchFields.slices.length === 1
            ? ApiFlightType.OneWay
            : this.flightSearchFields.type;
      }

      sessionStorage.setItem('isInternational', JSON.stringify(isInternational));
      sessionStorage.setItem('flightSearch', JSON.stringify(this.flightSearchFields));

      const searchHistory = this.generateSearchHistory(isInternational);
      this.prepareSearchHistoryService();
      this.searchHistoryService.save(searchHistory).subscribe();

      this.router.navigate(['/resultados'], {
        queryParams: {
          type: this.flightSearchFields.type,
          adultQty: this.flightSearchFields.adultQty,
          childrenQty: this.flightSearchFields.childrenQty,
          infantQty: this.flightSearchFields.infantQty,
          slices: JSON.stringify(slices),
        },
      });
    }
  }

  verifyMultiSlices(slices): boolean {
    const [slice1, slice2] = slices;
    return slices.length === 2 && slice1.originAirport === slice2.destinationAirport;
  }

  getAirportInfo(airportCode: string) {
    this.subs.add(
      this.service.availableAirports(airportCode).subscribe(
        (data) => {
          this.availableAirports = data.getResult();
          let airport: AirportsModel = this.availableAirports.find((model) => {
            return model.airport_code == airportCode;
          });
          this.airportDisplay = this.cleanUpDisplay(airport, false);
        },
        (error) => {
          return new AirportsModel();
        }
      )
    );
  }

  cleanUpDisplay(airport: AirportsModel, description: boolean) {
    let result = '';
    if (description) {
      airport.description ? (result = result.concat(airport.description, ', ')) : null;
    }
    airport.city ? (result = result.concat(airport.city, ', ')) : null;
    airport.region ? (result = result.concat(airport.region, ', ')) : null;
    airport.country ? (result = result.concat(airport.country)) : null;

    this.airportCodeDisplay = toUpper(airport.airport_code);

    return result;
  }

  setChosenAirport(airport: AirportsModel, index: number, direction: string) {
    let slice = this.flightSearchFields.slices[index];
    direction == 'origin'
      ? (slice.originAirport = airport.airport_code)
      : (slice.destinationAirport = airport.airport_code);
  }

  setChosenDate(date: FlightSlicesModel, index: number) {
    this.flightSearchFields.slices[index].departureDate = date[0].departureDate;
  }

  newSlice() {
    if (this.flightSearchFields.slices.length < 6) {
      let slice = this.flightSearchFields.slices;
      slice.push(new FlightSlicesModel());

      slice[slice.length - 1].originAirport = slice[slice.length - 2].destinationAirport;
      slice[slice.length - 1].departureDate = slice[slice.length - 2].departureDate;
    }
  }

  removeSlice(index: number) {
    if (this.flightSearchFields.slices.length > 1) {
      this.flightSearchFields.slices.splice(index, 1);
    }
  }

  get calendarTypes(): typeof CalendarTypeEnum {
    return CalendarTypeEnum;
  }

  validateForm() {
    let origin: string[] = [];
    let destination: string[] = [];
    let isValid = true;

    this.elements.forEach((el, elIndex) => {
      if (el instanceof FindAirportsComponent) {
        if (el.isOrigin) {
          origin[el.multiDestinationIndex] = el.airportCodeDisplay;
          if (!el.isValid()) {
            isValid = false;
          }
        } else {
          destination[el.multiDestinationIndex] = el.airportCodeDisplay;
          if (!el.isValid()) {
            isValid = false;
          }
        }
        if (
          origin[el.multiDestinationIndex] != '' &&
          destination[el.multiDestinationIndex] != '' &&
          origin[el.multiDestinationIndex] == destination[el.multiDestinationIndex]
        ) {
          if (!el.errors[el.multiDestinationIndex]) {
            el.errors[el.multiDestinationIndex] = [];
          }
          el.errors[el.multiDestinationIndex].push('Origem e Destino precisam ser diferentes');
          isValid = false;
        }
      } else if (el instanceof CalendarComponent) {
        if (!el.isValid()) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  private generateSearchHistory(isInternational?: boolean): AerialSearchHistory {
    const { adultQty, childrenQty, infantQty, slices, type } = this.flightSearchFields;
    const aerialSearchHistory = new AerialSearchHistory();
    aerialSearchHistory.adultQuantity = adultQty;
    aerialSearchHistory.childrenQuantity = childrenQty;
    aerialSearchHistory.infantQuantity = infantQty;
    aerialSearchHistory.type = type;
    aerialSearchHistory.isInternational = !!isInternational;

    slices.forEach((slice, index) =>
      aerialSearchHistory.addSlice({
        destination: slice.destinationAirport,
        origin: slice.originAirport,
        goingDate: slice.departureDate,
        sliceOrder: index,
      } as AerialSearchHistorySlice)
    );
    return aerialSearchHistory;
  }

  private prepareSearchHistoryService(): void {
    const customerSessionId = this.customerLoginService.getCustomerSessionId();
    const strategy = this.searchHistoryStrategyFactory.getStrategy(customerSessionId);
    this.searchHistoryService.setStrategy(strategy);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
