import { Component, Input, OnInit } from '@angular/core';
import { SliceResponse } from '@zupper/aerial-components';
import moment from "moment";

@Component({
  selector: 'app-next-day-trip',
  templateUrl: './next-day-trip.component.html',
  styleUrls: ['./next-day-trip.component.scss']
})
export class NextDayTripComponent implements OnInit {
  @Input() slice: SliceResponse = null;

  public days: number = 0;
  public showBadge: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const departureDate = moment(this.slice.departureDate);
    const arrivalDate = moment(this.slice.arrivalDate);

    this.days = arrivalDate.startOf('day').diff(departureDate.startOf('day'), 'days');
    this.showBadge = (this.days > 0);
  }
}
