import { Injectable } from '@angular/core';
import moment from 'moment';
import { LocalStorageService } from '../lib/storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppCheckerService {

  constructor(private _localStorageService: LocalStorageService) { }

  isApp(): boolean {
    let utmStorageData = this.getParamsUTM();
    if((navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i))
      && utmStorageData
      && utmStorageData['utmSource'] === 'app'
    ){
      return true;
    } else {
      return false;
    }
  }

  getParamsUTM() {
    const utmStorage = this._localStorageService.getItem<any>('utm_storage');
    if (utmStorage && utmStorage['expiry'] && utmStorage['data']) {
      if (moment(utmStorage.expiry).isAfter(moment())) {
        return utmStorage.data;
      }
    }

    return false;
  }
}
