import { Directive, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: '[zcFormSubmitOnEnter]',
  providers: [NgForm]
})
export class FormSubmitOnEnterDirective implements OnInit {

  constructor(
    private ngForm: NgForm
  ) { }

  ngOnInit(): void {

  }

  @HostListener('keyUp', ['$event'])
  public onKeyUp(event: KeyboardEvent): void {
      if (event.code === 'Enter') {
        console.log('Pressed ENTER');
        this.ngForm.ngSubmit.emit();
      }
  }
}
