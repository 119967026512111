import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiCorporateModel } from '@zupper/data';
import { LocalStorageService } from '@zupper/common';
import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root'
})
export class CorporateLoggedGuard implements CanActivate {
  whitelabel: ApiCorporateModel;
  customerSessionLogin: string;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.whitelabel = this.localStorageService.getItem<ApiCorporateModel|any>('whitelabel', {});
    this.customerSessionLogin = this.localStorageService.getTimedItem<string>('customerSessionId');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    if(!this.localStorageService) {
      Sentry.captureException(new Error('Object \'localStorage\' is not valid'));
    }
    let isWhitelabelLogged = this.localStorageService.getItem<boolean>('whitelabelLogged', false);
    if (!this.whitelabel) {
      return true;
    }

    /*if (this.whitelabel.brandingType == 1 && !this.customerSessionLogin) {
      this.router.navigateByUrl('/parceiro/' + this.whitelabel.companyName + '/entrar')
      return false;
    } else */
    if (this.whitelabel.brandingType == 2 && !isWhitelabelLogged) {
      this.router.navigateByUrl('/parceiro/' + this.whitelabel.companyName + '/entrar')
      return false;
    }

    return true;
  }
}
