
<div class="slider">
    <h6>Ida - Voo para {{timeDeparture[0].destinationAirport}}</h6>
    <ng5-slider [(value)]="minValueOne" [(highValue)]="maxValueOne" [options]="optionsOne"
        (valueChange)="onMinChangeOne($event)" (highValueChange)="onMaxChangeOne($event)"></ng5-slider>
</div>
<div class="slider" *ngIf="timeDeparture[1]">
    <h6>Volta - Voo para {{timeDeparture[1].destinationAirport}}</h6>
    <ng5-slider [(value)]="minValueTwo" [(highValue)]="maxValueTwo" [options]="optionsTwo"
        (valueChange)="onMinChangeTwo($event)" (highValueChange)="onMaxChangeTwo($event)"></ng5-slider>
</div>
