import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceMatrixComponent } from './price-matrix.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [PriceMatrixComponent],
  imports: [
    CommonModule,
    NgScrollbarModule,
  ],
  exports: [PriceMatrixComponent]
})
export class PriceMatrixModule { }
