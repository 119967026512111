import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AvailableFlightsRequest, DefaultResponseModel } from '@zupper/aerial-components';
import { ApiBaseService } from '@zupper/data';

@Injectable({
  providedIn: 'root'
})
export class AerialSearchManagerService {
  private serviceUrl;

  constructor(
    private http: HttpClient,
    private apiBaseService: ApiBaseService
  ) {
    this.serviceUrl = apiBaseService.getBaseUrl();
  }

  availableAirports(param: string) {
    return this.http.get<DefaultResponseModel>(this.serviceUrl + '/rest/v2/airport/search',
      { params: { term: param, version: 'v2' } })
      .pipe(
        map(res => Object.assign(new DefaultResponseModel, res)),
        retry({ count: 2, delay: 1000 }),
        catchError(this.handleError)
      );
  }

  availableFlights(search: AvailableFlightsRequest) {
    return this.http.post<DefaultResponseModel>(this.serviceUrl + '/rest/v2/aerial/search', search)
      .pipe(
        map(res => Object.assign(new DefaultResponseModel, res)),
        retry({ count: 2, delay: 1000 }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
