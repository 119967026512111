import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { SegmentsResponseModel, SliceResponse, AvailableFlightsResponse, AvailableFlightsRequest, AirportsModel, FlightDurationPipe } from '@zupper/aerial-components';
import { ApiFilterModel, FilterAirlinesModel } from 'projects/aerial/src/app/models/api-filter.model';
import { ApiFlightType } from '@zupper/data';
import { AirlineServiceService } from '../airline-service.service';

@Component({
  selector: 'app-flight-option',
  templateUrl: './flight-option.component.html',
  styleUrls: ['./flight-option.component.scss'],
  providers: [FlightDurationPipe],
  encapsulation: ViewEncapsulation.None,
})
export class FlightOptionComponent implements OnInit {
  @Input() flights: AvailableFlightsResponse[] = [];
  @Input() responseIdentification: number = 0;
  @Input() groupIndex: number;
  @Input() sliceIndex: number = 0;
  @Input() filterMeta: ApiFilterModel[][] = [];
  @Input() totalSlices: number;
  @Input() selectedFlight: string = null;
  @Input() isMultiCias: boolean = false;
  @Input() isMobile: boolean = false;

  @Input() selectedFamilyName: string = null;
  @Output() chosenFlight = new EventEmitter();
  @Output() chosenFlightSlice: EventEmitter<SliceResponse> = new EventEmitter();

  airports: Array<AirportsModel> = [];

  constructor(private airlineServiceService: AirlineServiceService) { }

  ngOnInit(): void {
  }

  sendFlightSlice(flight: AvailableFlightsResponse, slice: SliceResponse) {
    this.chosenFlight.emit(flight);
    this.chosenFlightSlice.emit(slice);
  }

  getTransferDuration(
    first: SegmentsResponseModel,
    second: SegmentsResponseModel
  ) {
    let departure = new Date(first.arrivalDate);
    let arrival = new Date(second.departureDate);

    return this.timeDiffCalc(arrival, departure);
  }

  private timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let result = hours.toString().concat("h");
    minutes > 0 ? result.concat(minutes.toString()) : null;
    return hours.toString().concat("h", minutes.toString(), 'm');
  }

  getStopsCount(slice: SliceResponse) {
    let segments = slice.segments.length - 1;

    if(slice.segments[0].isScale && slice.segments[0].isConnection){
      return segments == 1 ? 'Voo com parada e conexão' : 'Voo com paradas e conexões';
    } else if (slice.segments[0].isScale) {
      return segments == 1 ? 'Voo com parada' : 'Voo com paradas';
    } else if (slice.segments[0].isConnection) {
      return segments == 1 ? 'Voo com conexão' : 'Voo com conexões';
    } else {
      return 'Voo Direto';
    }
  }

  getAirlineLogo(iata: string): string {
    const airlines = this.filterMeta[iata]?.filterData.trip_1.airlines || this.filterMeta['Sabre']?.filterData.trip_1.airlines;
    const response = airlines?.find(airline => airline.iataCode === iata);

    return response?.logoUrl || '/assets/images/airlines/404.png';
  }

  getAirlineInfo(slice: SliceResponse): FilterAirlinesModel {
    let flightsSearchFields: AvailableFlightsRequest = JSON.parse(sessionStorage.getItem("flightSearch"));

    let airlines = [];
    if (slice.source === 'Sabre') {
      airlines = this.filterMeta[this.airlineServiceService.getFilteredAirlines(slice.issuingAirline)]?.filterData.trip_1.airlines;
    } else if (flightsSearchFields.type == ApiFlightType.MultiSlice) {
      airlines = this.filterMeta[this.sliceIndex][slice.source]?.filterData.trip_1.airlines;
    } else {
      airlines = this.meta(this.flights[0])?.filterData.trip_1.airlines;
    }

    let response = null;

    if (Array.isArray(airlines)) {
      response = airlines.filter((airline) => {
        return airline.iataCode == slice.issuingAirline;
      })[0];
    }



    if (response == undefined || response == null) {
      let rulesNull = {
        name: "Airline " + slice.issuingAirline,
        iataCode: slice.issuingAirline,
        logoUrl: "/assets/images/airlines/404.png",
        color: null,
      };
      return rulesNull;
    }
    return response;
  }

  meta(flight: AvailableFlightsResponse): any {
    var slice = flight.slices[0];
    if(flight.international) {
      if (slice.source === 'Sabre') {
        return this.filterMeta['Sabre'];
      }
    } else if (flight.isMultipleAirlinesFlight) {

    }else if (slice.source === "Sabre"){
      return this.filterMeta[this.airlineServiceService.getFilteredAirlines(slice.issuingAirline)];
    }
    return this.filterMeta[flight.airline];
  }
}
