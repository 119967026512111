export enum DataLayerCheckout {
  // Something = '',
  event = 'event',
  adultQuantity = 'adultQuantity',
  childQuantity = 'childQuantity',
  infantQuantity = 'infantQuantity',
  departureDate = 'departureDate',
  returnDate = 'returnDate',
  originAirport = 'originAirport',
  originCountry = 'originCountry',
  destinationAirport = 'destinationAirport',
  destinationCountry = 'destinationCountry',
  //tripType = 'tripType', // (National, International)
  tripType = 'tripType', // (roundTrip, oneWay)
  airlineName = 'airlineName',
  //TravelingDays = 'TravelingDays',
  orderId = 'orderId',
  customerName = 'customerName',
  customerEmail = 'customerEmail',
  customerPhone = 'customerPhone',
  duValue = 'duValue',
  du2Value = 'du2Value',
  totalAirportTaxes = 'totalAirportTaxes',
  totalCreditCardFee = 'totalCreditCardFee',
  totalFarePrice = 'totalFarePrice',
  totalIncentiveValue = 'totalIncentiveValue',
  totalPrice = 'totalPrice',
  totalWithTax = 'totalWithTax',
  chosenFamilyName = 'chosenFamilyName', // (Light, Plus, Max, Top...)
  upsellValue = 'upsellValue' // (R$ 250,48...)
}
