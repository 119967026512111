import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { AvailableFlightsRequest } from "@zupper/aerial-components";
import { AbandonmentModalConfiguration, AbandomentModalManagerService } from "@zupper/common";
import { ApiFlightType, WEBSITE_PAGE } from "@zupper/data";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SweetAlertOptions } from 'sweetalert2';
import { ResultFiltersParams } from "../../models/result-params.model";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ResultsComponent implements OnInit {
  @ViewChild("modal") private modal: SwalComponent;
  queryParams: Params;
  combined: boolean = false;
  flightsSearchFields: AvailableFlightsRequest = new AvailableFlightsRequest();
  initialFilterValues: ResultFiltersParams;
  abandonmentModal: AbandonmentModalConfiguration;

  constructor(private router: Router, private _route: ActivatedRoute, private ngxLoader: NgxUiLoaderService, private _abandomentModalManagerService: AbandomentModalManagerService) {
    this.queryParams = this._route.snapshot.queryParams;
    this.flightsSearchFields = this._route.snapshot.data['searchRequest'];
    this.initialFilterValues = this._route.snapshot.data['searchFilters'];

    if (this.flightsSearchFields && this.flightsSearchFields.type == ApiFlightType.MultiSlice) {
      this.combined = true;
    }
  }

  ngOnInit(): void {
    if (typeof this._route.snapshot.data['searchRequest'] == 'undefined') {
      setTimeout(() => {
        this.showMessageErro();
      }, 1000)
    }

    this._route.data.subscribe((data: { abandonmentModalConfig: AbandonmentModalConfiguration }) => {
      if (data?.abandonmentModalConfig) {
        this._abandomentModalManagerService.init(data?.abandonmentModalConfig, WEBSITE_PAGE.AERIAL_RESULTS);
      }
    });
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  toggleCombination() {
    this.combined = !this.combined;
  }

  showMessageErro() {
    this.ngxLoader.stop();
    const data: SweetAlertOptions = {
      icon: "error",
      html: "Ocorreu um erro ao efetuar a busca",
      showConfirmButton: true,
    };
    this.modal.update(data);
    this.modal.fire().then((response) => {
      this.router.navigate(["/"]);
    });
  }
}
