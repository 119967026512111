<ng-container *ngIf="currentStep === steps.EMAIL">
  <div class="go-back"><a (click)="goTo(steps.LOGIN_SELECTION)">&larr; Voltar</a></div>
  <section id="email-login" class="login-step">
    <form class="login-form" [formGroup]="emailValidationForm" (ngSubmit)="emailFormSubmitSubject$.next(null)">
      <div class="form-group-zupper input-group" [ngClass]="{ 'validating': validatingEmail }">
        <span class="has-float-label">
          <input class="form-control zupper-input" data-zt="emailLogin" formControlName="email" type="email" placeholder=" " autocomplete="off"/>
          <label for="email">Digite seu e-mail</label>
        </span>
      </div>
    </form>
    <ydd-progress-button class="login-submit-button" data-zt="nextButton" (action)="emailFormSubmitSubject$.next(null)" [disabled]="(emailValidationForm.value.email == '' || emailValidationForm.pending || validatingEmail || loading) ? true : null">
      Próximo
    </ydd-progress-button>
    <!-- <button class="email-form-submit login-submit-button" (click)="emailFormSubmitSubject$.next()" [disabled]="emailValidationForm.invalid || emailValidationForm.pending || validatingEmail || loading">Próximo</button> -->
    <p class="account-create-text">Ainda não tem uma conta? <a [routerLink]="['/conta/nova-conta']">Criar conta agora</a></p>
    <p class="account-alternate-text">Tem conta e não conseguiu entrar? <a [routerLink]="['/conta/recuperar-senha']">Acesse aqui</a></p>
  </section>
</ng-container>

<ng-container *ngIf="currentStep === steps.PASSWORD">
  <div class="go-back"><a (click)="goTo(steps.EMAIL)">&larr; Voltar</a></div>
  <section id="password-login" class="login-step">
    <div class="greeting">
      <h3>Olá</h3>
      <h4>{{email}} <a class="change-login" (click)="goTo(steps.EMAIL)">Alterar</a></h4>
    </div>
    <h3>Agora é só inserir sua senha cadastrada</h3>

    <form class="login-form" [formGroup]="passwordForm" zcFormSubmitOnEnter>

      <div class="form-group-zupper password-form-group">
        <span class="has-float-label">
          <div class="input-wrapper">
            <input (focus)="inputHasFocus = true" (blur)="inputHasFocus = false"
              [type]="showPassword ? 'text' : 'password'" name="password" (capsLockDetector)="caps($event)"
              tooltipClass="error-tooltip" [autoClose]="false" triggers="manual" ngbTooltip="A tecla CAPS LOCK está ativada!"
              #capsTooltip="ngbTooltip" class="form-control zupper-input password-form-control" formControlName="password"
              placeholder=" " required autocomplete="off" data-zt="passwordLogin">
            <span class="input-group-append">
              <button class="toggle-password" [ngClass]="{'has-focus': inputHasFocus}"
                (click)="showPassword = !showPassword">Ver senha</button>
            </span>
          </div>
        <label for="email">Digite sua senha</label>
        <small class="form-text password-hints">
          <ng-container *ngIf="showError">
            <span *ngIf="errorMessage" class="error-text">{{errorMessage}}</span>
            <span *ngIf="showSSOErrorMessage" class="error-text">
              Não foi possível concluir o login, tente novamente utilizando <br />
              outra opção <a (click)="goToFromUI($event, steps.LOGIN_SELECTION)" href="">clicando aqui</a> ou crie uma <a (click)="goToFromUI($event, steps.SEND_PASSWORD_CREATION_LINK)"  href="">nova senha</a>.
            </span>
          </ng-container>
          <bfv-messages></bfv-messages>
        </small>
        </span>
      </div>
    </form>
    <ydd-progress-button class="login-submit-button" (action)="submitPasswordForm($event)" [disabled]="(passwordForm.invalid || loading) ? true : null" data-zt="submitLoginButton" (click)="executeRecaptchaV3()">Próximo</ydd-progress-button>
    <!-- <button class="email-form-submit login-submit-button" (click)="submitPasswordForm()" [disabled]="passwordForm.invalid || loading">Próximo</button> -->

    <p class="account-alternate-text">Tem conta e não conseguiu entrar? <a [routerLink]="['/conta/recuperar-senha']">Acesse aqui</a></p>
  </section>
</ng-container>
