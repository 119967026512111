import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MatrixSelectionModel } from '../models/matrix-selection.model';

@Injectable({
  providedIn: 'root'
})
export class MatrixSelectionNotifierService {
  private message = new Subject<MatrixSelectionModel>();

  sendMessage(data: MatrixSelectionModel) {
      this.message.next(data);
  }

  getMessage(): Observable<MatrixSelectionModel> {
      return this.message.asObservable();
  }
}
