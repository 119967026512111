import { Component, Input } from '@angular/core';
import { WhatsappTag } from '../../models/configuration';
import { WhatsappChatLinkGeneratorService } from '../../whatsapp-chat-link-generator.service';
import { AirportModel } from '@zupper/data';
import { AvailableFlightsRequest } from '../../models/aerial/aerial-api-response.model';

@Component({
  selector: 'zupper-common-whatsapp-tag',
  templateUrl: './whatsapp-tag.component.html',
  styleUrls: ['./whatsapp-tag.component.scss'],
})
export class WhatsappTagComponent {
  @Input() configuration: WhatsappTag;

  searchFields: AvailableFlightsRequest;
  airports: { [key: string]: AirportModel } = {};

  constructor(private whatsappService: WhatsappChatLinkGeneratorService) {}

  onCtaClick(): void {
    const whatsappLink = this.whatsappService.generateInternationalOfferLink(
      this.configuration.whatsappContactNumber,
      this.configuration.route
    );
    window.open(whatsappLink, '_blank');
  }
}
