import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AirportModel } from '@zupper/data';
import { AirportsModel, AvailableFlightsRequest } from "@zupper/aerial-components";

@Component({
  selector: 'app-search-summary',
  templateUrl: './search-summary.component.html',
  styleUrls: ['./search-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchSummaryComponent implements OnInit {
  @Input() searchRequest: AvailableFlightsRequest;
  @Output() summaryResponse = new EventEmitter();

  isChanging: boolean = false;
  searchFields: AvailableFlightsRequest;
  airports: Array<AirportModel> = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.searchFields = JSON.parse(sessionStorage.getItem("flightSearch"));
    this.searchFields.slices.forEach((slice) => {
      var originAirportData = JSON.parse(sessionStorage.getItem('airport.' + slice.originAirport));
      var destinationAirportData = JSON.parse(sessionStorage.getItem('airport.' + slice.destinationAirport));
      this.airports[slice.originAirport] = originAirportData;
      this.airports[slice.destinationAirport] = destinationAirportData;
    });
  }

  getAirportInfo(airportCode: string): AirportsModel {
    let airport = this.airports[airportCode];
    return airport;
  }

  getFixedCaseStr(str: string): string {
    const fixRegex: RegExp = /\bDe\b/g;
    return str.replaceAll(fixRegex, 'de')
      .replaceAll('.', '');
  }

  get adult() {
    return this.searchFields.adultQty <= 1 ? this.searchFields.adultQty + " adulto" : this.searchFields.adultQty + " adultos";
  }

  get underages() {
    let total: number = 0;
    total += this.searchFields.infantQty;
    total += this.searchFields.childrenQty;
    return total == 1 ? ", " + total + " criança" : total > 1 ? ", " + total + " crianças" : null;
  }
}
