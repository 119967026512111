import { WEBSITE_PAGE, WEBSITE_VIEWPORT } from "@zupper/data";

export class AbandonmentModalConfiguration {
    whatsappContactNumber: string;
    desktopModalConfig: AbandonmentModalDeviceConfiguration;
    mobileModalConfig: AbandonmentModalDeviceConfiguration;

    isActive(viewport: WEBSITE_VIEWPORT, page: WEBSITE_PAGE): boolean {
        switch (viewport) {
            case WEBSITE_VIEWPORT.DESKTOP:
                if (page == WEBSITE_PAGE.AERIAL_RESULTS) {
                    return this.desktopModalConfig?.resultsConfig?.activated;
                } else if (page == WEBSITE_PAGE.CHECKOUT) {
                    return this.desktopModalConfig?.checkoutConfig?.activated;
                }
            case WEBSITE_VIEWPORT.MOBILE:
                if (page == WEBSITE_PAGE.AERIAL_RESULTS) {
                    return this.mobileModalConfig?.resultsConfig?.activated;
                } else if (page == WEBSITE_PAGE.CHECKOUT) {
                    return this.mobileModalConfig?.checkoutConfig?.activated;
                }
            default:
                return false;
        }
    }

    idleTimeActivation(viewport: WEBSITE_VIEWPORT, page: WEBSITE_PAGE): number {
        switch (viewport) {
            case WEBSITE_VIEWPORT.DESKTOP:
                if (page == WEBSITE_PAGE.AERIAL_RESULTS) {
                    return this.desktopModalConfig?.resultsConfig?.idleTimeActivation ?? 0;
                } else if (page == WEBSITE_PAGE.CHECKOUT) {
                    return this.desktopModalConfig?.checkoutConfig?.idleTimeActivation ?? 0;
                }
            case WEBSITE_VIEWPORT.MOBILE:
                if (page == WEBSITE_PAGE.AERIAL_RESULTS) {
                    return this.mobileModalConfig?.resultsConfig?.idleTimeActivation ?? 0;
                } else if (page == WEBSITE_PAGE.CHECKOUT) {
                    return this.mobileModalConfig?.checkoutConfig?.idleTimeActivation ?? 0;
                }
            default:
                return 0;
        }
    }

    isActivatedByCloseAttempt(viewport: WEBSITE_VIEWPORT, page: WEBSITE_PAGE): boolean {
        switch (viewport) {
            case WEBSITE_VIEWPORT.DESKTOP:
                if (page == WEBSITE_PAGE.AERIAL_RESULTS) {
                    return this.desktopModalConfig?.resultsConfig?.pageAbandomentActivation;
                } else if (page == WEBSITE_PAGE.CHECKOUT) {
                    return this.desktopModalConfig?.checkoutConfig?.pageAbandomentActivation;
                }
            default:
                return false;
        }
    }
}

export class AbandonmentModalDeviceConfiguration {
    activated: boolean;
    resultsConfig: AbandonmentModalDataConfiguration;
    checkoutConfig: AbandonmentModalDataConfiguration;
}

export class AbandonmentModalDataConfiguration {
    activated: boolean;
    primaryText: string;
    secondaryText: string;
    pageAbandomentActivation: boolean;
    idleTimeActivation: number;
}
