import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { ExpirationItem } from '@zupper/data';
import { STORAGE } from '../tokens/storage.token';
import * as Sentry from "@sentry/browser";

export abstract class StorageService implements Storage {
  public get length(): number {
    return this.api.length;
  }

  constructor(
    @Inject(STORAGE) protected readonly api: Storage,
    protected readonly prefix: string
  ) {

  }

  private _prefixKey(plainKey: string): string {
    if (this.prefix) {
      return `[${this.prefix}]${plainKey}`;
    }

    return plainKey;
  }

  public setTimedItem(key: string, value: any, expiration: moment.Moment): void {
    const expirationItem: ExpirationItem<any> = {
      item: value,
      expiration: expiration
    };

    this.setItem(key, expirationItem);
  }

  public getTimedItem<T>(key: string): T | null;
  public getTimedItem<T>(key: string, otherwise: T): T;
  public getTimedItem<T>(key: string, otherwise?: T): T | null {
    const storedItem  = this.getItem<ExpirationItem<T>>(key);
    if (storedItem !== null) {
      if (moment().isAfter(storedItem.expiration)) {
        this.api.removeItem(key);
      } else {
        return storedItem.item;
      }
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  public setItem(key: string, value: any): void {
    this.api.setItem(this._prefixKey(key), JSON.stringify({ value }));
  }

  public getItem<T>(key: string): T | null;
  public getItem<T>(key: string, otherwise: T): T;
  public getItem<T>(key: string, otherwise?: T): T | null {
    if(!this.api) {
      Sentry.captureException('Object \'api (Storage) \', is undefined', {
        extra: {
          'storage': this.api
        }
      });
    }
    const data: string | null = this.api.getItem(this._prefixKey(key));

    if (data !== null) {
      return JSON.parse(data)['value'];
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  public removeItem(key: string): void {
    this.api.removeItem(this._prefixKey(key));
  }

  public clear(): void {
    this.api.clear();
  }

  public key(index: number): string {
    return this.api.key(index);
  }
}
