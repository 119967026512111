import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZupperCheckboxComponent } from './zp-checkbox/zp-checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZupperUiComponent } from './zupper-ui/zupper-ui.component';
import { ZupperButtonComponent } from './zp-button/zp-button.component';
import { ZupperInformationComponent } from './zupper-information/zupper-information.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ZupperRadioGroupComponent } from './zupper-radio-group/zupper-radio-group.component';
import { ZupperDetailsToggleComponent } from './zupper-details-toggle/zupper-details-toggle.component';

@NgModule({
  declarations: [ZupperCheckboxComponent, ZupperUiComponent, ZupperButtonComponent, ZupperInformationComponent, ZupperRadioGroupComponent, ZupperDetailsToggleComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule
  ],
  exports: [ZupperCheckboxComponent, ZupperUiComponent, ZupperButtonComponent, ZupperInformationComponent, ZupperRadioGroupComponent, ZupperDetailsToggleComponent],
  entryComponents: [ZupperCheckboxComponent]
})
export class ZupperUiModule { }
