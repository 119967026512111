<div>
  <div class="required-countries-mb-30">
    <lib-required-countries *ngIf="_isInsuranceRequired"
    [isSearchPage]="true"></lib-required-countries>
  </div>
</div>
<div class="slices-container" [ngClass.lt-md]="{'no-padding': true, 'd-flex flex-column align-items-center': !anySliceHasValue}">
  <ng-container *ngIf="anySliceHasValue; else noFlightsFound">
    <section class="list-container" gdColumns="repeat(3, 1fr)" gdColumns.lt-md="1fr" gdGap.lt-md="1em 0" *ngFor="let flights of this.flightResponse; let directionIndex = index">
      <article class="available-flights" *ngIf="!flights[0]">
        <ng-container *ngIf="loading; else notFound">
          <div class="ph-item">
            <div class="ph-col-12">
              <div class="ph-row">
                <div class="ph-col-10 big"></div>
              </div>
            </div>
            <div class="ph-col-12">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-12">
              <div class="ph-picture" style="height: 400px;"></div>
            </div>
          </div>
        </ng-container>
        <ng-template #notFound>
          <div class="flight-origin">
            <header data-zt="flightInfo" class="flight-info" gdInline.gt-sm gdColumns.lt-md="1fr">
              <ng-container [ngTemplateOutlet]="sliceLabel" [ngTemplateOutletContext]="{$implicit: directionIndex + 1}"></ng-container>
                <h5 gdInline gdColumns.lt-md="1fr" [ngClass.lt-md]="'smaller'">
                  <span class="flight-info-origin" ngClass.lt-md="mb-2">De: <span data-zt="originAirport">{{ getCityAndAirportText(flightsSearchFields.slices[directionIndex]) }}</span></span>
                  <span class="flight-info-destination">
                    <ng-container *ngIf="!isMobile">&nbsp;</ng-container>
                    <span data-zt="destinationAirport">Para: {{ getCityAndAirportText(flightsSearchFields.slices[directionIndex], true) }}</span>
                  </span>
                </h5>
            </header>
            <app-result-not-found
              [sliceIndex]="directionIndex"
              (doSearchAgain)="doSearchAgain()"
              (removeSlice)="deleteSliceByIndex($event)"
            ></app-result-not-found>
          </div>
        </ng-template>
      </article>
      <article class="available-flights" *ngIf="flights[0]">
          <div class="flight-origin">
              <header data-zt="flightInfo" class="flight-info" gdInline.gt-sm gdColumns.lt-md="1fr">
                  <ng-container [ngTemplateOutlet]="sliceLabel" [ngTemplateOutletContext]="{$implicit: directionIndex + 1}"></ng-container>
                  <h5 gdInline gdColumns.lt-md="1fr" [ngClass.lt-md]="'smaller'">
                    <span class="flight-info-origin" ngClass.lt-md="mb-2">De: <span data-zt="originAirport">{{this.getAirportInfo(directionIndex, flights[0].slices[0])}}</span></span>
                    <span class="flight-info-destination">Para: <span data-zt="destinationAirport">{{this.getAirportInfo(directionIndex, flights[0].slices[0], true)}}</span></span>
                  </h5>
              </header>

              <app-close-dates
                fxHide.xs
                (sendNewSearch)="doFlightSearch(directionIndex, $event)"
                [flightsSearchFields]="this.getCurrentFlightSearchFields(directionIndex)"
                [inputDate]="this.getCurrentDate(directionIndex)"
                [bestPrice]="this.getBestPrice(directionIndex)"
              ></app-close-dates>

              <div class="slice-wrapper">
                  <header fxHide.lt-md="true">
                      <span>Cia</span>
                      <span>Saída</span>
                      <span>Chegada</span>
                      <span>Duração</span>
                      <span>Paradas</span>
                      <span>Preço</span>
                      <span>Bagagem</span>
                  </header>
                  <div class="slice-scroll" data-zt="resultListScroll">
                      <app-result-list-item-multi-slice
                        (chosenFlight)="saveChosenSlice($event, directionIndex)"
                        [flights]="this.flights"
                        [flightIdentification]="directionIndex"
                        [filterMeta]="this.filterMeta"
                        data-zt="resultList"
                      >
                      </app-result-list-item-multi-slice>
                  </div>
              </div>
          </div>
      </article>

      <div class="aside-price" *ngIf="!flights[0]">
        <ng-container *ngIf="loading; else noPrice">
          <div class="ph-item">
            <div class="ph-col-12">
              <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-4 big"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-4 big"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-4 big"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-4 big"></div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noPrice>
          <div class="aside-price">
              <app-result-list-price
                [tripType]="this.getTripType"
                [showFamilies]="false"
                [isMobile]="isMobile"
                [sliceIndex]="directionIndex + 1"
              ></app-result-list-price>
              <ng-container [ngTemplateOutlet]="footerTpl" [ngTemplateOutletContext]="{$implicit: directionIndex}"></ng-container>
          </div>
        </ng-template>
      </div>
      <div class="aside-price" *ngIf="flights[0]">
          <app-result-list-price
              [radioName]="'combined'+directionIndex"
              [chosenSlices]="[this.chosenSliceList[directionIndex]]"
              [chosenFlight]="this.chosenFlightList[directionIndex]"
              [tripType]="this.getTripType"
              [showFamilies]="showFamilies"
              [isMobile]="isMobile"
              [sliceIndex]="directionIndex + 1"
          ></app-result-list-price>
          <ng-container [ngTemplateOutlet]="footerTpl" [ngTemplateOutletContext]="{$implicit: directionIndex}"></ng-container>
      </div>
    </section>
  </ng-container>
  <ng-template #noFlightsFound>
    <section class="list-container" gdColumns="repeat(3, 1fr)" gdColumns.lt-md="1fr" gdGap.lt-md="1em 0" *ngIf="!loading">
      <article class="available-flights">
        <div class="flight-origin">
          <app-result-not-found (doSearchAgain)="doSearchAgain()"></app-result-not-found>
        </div>
      </article>
      <div class="aside-price" ngClass.lt-md="d-flex justify-content-center">
        <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
      </div>
    </section>
  </ng-template>
  <ng-template #sliceLabel let-dirIndex>
    <span gdGridAlign="start start">
      <ng-container *ngIf="isMobile; else nonMobileIcon">
        <img src="assets/img/custom-svg-icons/plane-white.svg" alt="plane">
      </ng-container>
      <small>
        <b>
          <ng-container *ngIf="isMobile; else nonMobileLabelText">
            Trecho {{dirIndex}}
          </ng-container>
        </b>
      </small>
    </span>
    <ng-template #nonMobileIcon>
      <i class="fas fa-plane"></i>
    </ng-template>
    <ng-template #nonMobileLabelText>
      {{dirIndex}}º Trecho
    </ng-template>
  </ng-template>
</div>

<ng-template #footerTpl let-dirIndex>
  <footer class="slices-sum pt-4"
    *ngIf="!anySliceHasValue || dirIndex != undefined && dirIndex == this.flightResponse.length-1"
    [ngClass]="{
      'mx-3 mobile-slices-sum': isMobile,
      'no-results-padding': !anySliceHasValue,
      'flex-size-fix': isMobile && !anySliceHasValue
    }">
      <h5>Resumo da compra</h5>
      <div data-zt="priceInfoFinalize" class="price-info">
        <p data-zt="adultFareFinalize">
          <ng-container *ngIf="isMobile; else nonMobileFeeText">
            <ng-container [ngPlural]="totalSlicesWithFlights">
              <ng-template ngPluralCase="=0">SEM TRECHOS</ng-template>
              <ng-template ngPluralCase="=1">SOMENTE IDA</ng-template>
              <ng-template ngPluralCase="other">{{ totalSlicesWithFlights + ' TRECHOS' }}</ng-template>
            </ng-container>
          </ng-container>
          <ng-template #nonMobileFeeText>Tarifa por Adulto</ng-template>
          <span>{{ this.fareByPaxType.Adult | currency : "R$"}}</span>
        </p>
        <ng-container *ngIf="anySliceHasValue">
          <p *ngIf="this.flightsSearchFields.adultQty > 0">{{ this.flightsSearchFields.adultQty }} {{ this.flightsSearchFields.adultQty > 1 ? 'Adultos' : 'Adulto'}} <span>{{ this.flightsSearchFields.adultQty * this.fareByPaxType.Adult | currency : "R$" }}</span></p>
          <p *ngIf="this.flightsSearchFields.childrenQty > 0">{{ this.flightsSearchFields.childrenQty }} Criança <span>{{ this.flightsSearchFields.childrenQty * this.fareByPaxType.Child | currency : "R$" }}</span></p>
          <p *ngIf="this.flightsSearchFields.infantQty > 0">{{ this.flightsSearchFields.infantQty }} Bebê <span>{{ this.flightsSearchFields.infantQty * this.fareByPaxType.Infant | currency : "R$" }}</span></p>
        </ng-container>
        <p data-zt="totalFeeTaxesFinalize" [ngClass]="{'no-result-border': !anySliceHasValue}">Taxas e impostos <span>{{ this.totalFeeTaxes | currency : "R$" }}</span></p>
        <p data-zt="totalPriceFinalize">Total <span>{{ this.totalPrice | currency : "R$" }}</span></p>
      </div>
      <div class="send-slice" *ngIf="anySliceHasValue">
        <button data-zt="buyButtonToCheckout" class="mb-2" (click)="sendSlicesToCheckout()">Ir para pagamento</button>
        <zc-installment
          [maxInstallments]="maxAllowedInstallment"
          [hasCardTax]="hasCardTax"
          [isMobileAndMultiSlice]="isMobile && isMultiSlice"
        ></zc-installment>
      </div>
  </footer>
</ng-template>
