<div class="go-back"><a (click)="goTo(steps.LOGIN_SELECTION)">&larr; Voltar</a></div>

<section id="send-password-creation-link" class="creation-link-section">
  <h2>Estamos quase lá, falta pouco...</h2>
  <p>Vamos te enviar um link para você <b>criar</b> sua senha</p>

  <div>
    <ydd-progress-button class="login-submit-button"
      [disabled]="(loading || successMessage || errorMessage) ? true : null" data-zt="submitLoginButton"
      (click)="onSendLink()">Link para receber</ydd-progress-button>

    <ng-container *ngIf="!!errorMessage">
      <span class="error">{{errorMessage}}</span>
    </ng-container>
    <ng-container *ngIf="!!successMessage">
      <span class="success">{{successMessage}}</span>
    </ng-container>
  </div>
</section>