import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { ResultFiltersParams } from "../models/result-params.model";

@Injectable({ providedIn: "root" })
export class ResultsFiltersResolver {
  constructor() {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    const queryParams = route.queryParamMap;
    const resultParams = new ResultFiltersParams();
    resultParams.fromJson(queryParams.get('filters'));
    return resultParams;

  }
}

export const resultsFiltersResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(ResultsFiltersResolver).resolve(route, state);
};
