<ng-container *ngIf="!!customer">
  <a class="customer-menu" ngbDropdown container="body">
    <div ngbDropdownToggle>
      <img [src]="customer.photo ? customer.photo : 'assets/img/default-user-image.png'" class="customer-photo">
      <span class="customer-info">
        <span class="customer-name" [class.is-checkout]="isCheckout" [ngClass.lt-md]="'mobile'">{{customer.name | stringMaxLength}}</span>
      </span>
    </div>

    <div class="customer-menu-options" ngbDropdownMenu>
      <button ngbDropdownItem (click)="goToProfile($event)">Meu Perfil</button>
      <hr>
      <button ngbDropdownItem (click)="goToCustomerOrders($event)">Meus Pedidos</button>
      <hr>
      <button ngbDropdownItem (click)="goToCustomerSupport($event)">Meus Atendimentos</button>
      <hr>
      <button ngbDropdownItem (click)="logout($event)">Sair</button>
    </div>
  </a>
</ng-container>
<ng-container *ngIf="!customer">
  <a class="customer-menu" data-zt="signInButton" [class.is-checkout]="isCheckout" href="/minha-conta/login" [ngClass.lt-md]="'mobile'">
    <img fxHide fxShow.gt-sm="true" [src]="isCheckout ? 'assets/img/icon-user-white.svg' : 'assets/img/user-login.svg'">
    <img fxHide fxShow.lt-md="true" [src]="'assets/img/user-login-gray.svg'">
    Login
  </a>
</ng-container>
