<label *ngIf=" this.title === 'Origem' " class="default-input-label"><i class="fas fa-map-marker-alt"></i> {{this.title}}</label>
<label *ngIf=" this.title === 'Destino' " class="default-input-label-plane"><i class="fas fa-plane" style="transform: rotate(-45de);"></i> {{this.title}}</label>
<div [ngClass]="getClass">
    <label for="" class="airportCode">{{ this.airportCodeDisplay }}</label>
    <input
      [ngClass]="hasError"
      #airportCode
      autocomplete="off"
      #inputAirportPopup="ngbPopover"
      onfocus="this.removeAttribute('readonly')"
      [(ngModel)]="airportDisplay"
      (keydown)="searchAvailableAirports($event, inputAirportPopup)"
      placeholder="Cidade ou aeroporto" type="text"
      [ngbPopover]="airportPopup" placement="bottom"
      [autoClose]="'outside'"
      [disabled]="isDisabled"
      required
      minlength="3"
      triggers="manual"
      data-zt="airportSelectionInput"
    />
    <span *ngIf="this.showClearFields"><i data-zt="clearFieldAirport" class="fa fa-trash" (click)="clearFields()"></i></span>
</div>
<ng-template #airportPopup class="popoverTemplate">
    <div class="airport-template" data-zt="airportSelectionPopup">
        <header class="popup-header mobile-only">
            <span *ngIf=" this.title == 'Destino' " ><i class="fas fa-plane plane" ></i>{{this.title}}</span>
            <span *ngIf=" this.title == 'Origem' " ><i class="fas fa-map-marker-alt map-marker"></i>{{this.title}}</span>
            <i class="fas fa-times" (click)="inputAirportPopup.close()"></i>
        </header>

        <label *ngIf=" this.availableAirports.length > 0 && this.title == 'Destino' " [ngClass]="{'desktop-only-plane' : this.title == 'Destino'}">
            <i class="fas fa-plane" ></i>{{this.title}}
        </label>
        <label *ngIf=" this.availableAirports.length > 0 && this.title == 'Origem' " [ngClass]="{'desktop-only' : 'desktop-only'}">
          <i class="fas fa-map-marker-alt"></i>{{this.title}}
        </label>
        <input
          autocomplete="off"
          class="mobile-only"
          [(ngModel)]="airportDisplay"
          (keypress)="searchAvailableAirports($event)"
          placeholder="Cidade ou aeroporto"
          type="text"
          required
          autofocus
        />
        <section class="availablesAirportsPopup">
            <article *ngFor="let airport of this.availableAirports" (click)="setChoosenAirport(airport); inputAirportPopup.close()">
                <span data-zt="airportName" [attr.data-zt-value]="airport.airport_code" class="airportNamePopup">{{airport.airport_code}} -
                    <span>
                      {{ airport.city }}, {{ airport.country }} {{ airport.description ? '&raquo; ' + airport.description : null }}
                    </span>
                </span>
            </article>
            <p class="empty-input" *ngIf="this.availableAirports.length == 0">Digite para buscar</p>
        </section>

        <button type="button" class="button-close-popup mobile-only" (click)="inputAirportPopup.close()">Confirmar</button>
    </div>
</ng-template>
