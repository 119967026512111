<div class="modal-header">
  <a class="listFamiliaDetalhes txR descFamilias fecharListFamiliaDetalhes" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times fa-2x"></i>
  </a>
</div>
<div class="modal-body" [ngClass]="{'four-families':families.length == 4}">
  <div class="hscroll">
    <div class="header">
      <div class="listFamiliaDetalhes txR descFamilias">
        <img src="{{families[0]?.logoUrl}}" alt="{{families[0]?.airline}}" title="{{families[0]?.airline | airline}} ({{families[0]?.airline}})" class="logoCiaDetalhes" />
      </div>
      <div class="p0 txC" *ngFor="let family of families; let idx = index">
        <div class="bordaCinza" [ngClass]="getClass(family.airline, family.name)" *ngIf="family">
          <div class="listFamiliaDetalhes" (click)="changeFamily(family?.name)" style="cursor: pointer">
            <ul class="list">
              <li class="mgT-5">
                <label class="label--radio">
                  <input type="radio" class="radio corFamilia" name="familiaDetalhes"
                  [attr.value]="family?.name"
                  [checked]="internalCurrentFamily === family?.name"
                  (change)="changeFamily(family?.name)"
                  />
                  <span class="titulosPrecosFamiliasPreco titulosPrecosFamiliasPreco">{{family.name}}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow">
      <div class="listFamiliaDetalhes txR descFamilias">
        <p *ngFor="let detail of families[0]?.details; let i = index" [attr.class]="'detail' + i">{{detail.name}}</p>
      </div>
      <div class="p0 txC" *ngFor="let family of families; let idx = index">
        <div class="bordaCinza" [ngClass]="getClass(family.airline, family.name)" *ngIf="family">
          <div class="listFamiliaDetalhes" (click)="changeFamily(family?.name)" style="cursor: pointer">
            <p *ngFor="let detail of family.details; let i = index" [appMatchHeight]="'detail' + i" [attr.class]="'detail' + i">
              <i class="fas" [ngClass]="{'fa-check': detail.value === '1', 'fa-times': detail.value === ''}"></i>
              <span *ngIf="detail.value !== '1' && detail.value !== ''">
                {{detail.value}}
                <span class="ps">{{ getConvertValuePs(detail.notes) }}</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="prices">
      <div class="p0 txC"></div>
      <div class="p0 txC" *ngFor="let family of families; let idx = index">
        <div class="bordaCinza" [ngClass]="getClass(family.airline, family.name)" *ngIf="family">
          <div class="listFamiliaDetalhes" (click)="changeFamily(family?.name)" style="cursor: pointer">
            <p class="txC" *ngIf="getPrice(family.name) > 0">
              <ng-container *ngIf="family.name !== this.internalCurrentFamily">+</ng-container> R$ {{getPrice(family.name) | number: '1.2-2'}}
            </p>
            <p class="txC" *ngIf="getPrice(family.name) < 0">
              - R$ {{getPrice(family.name)*-1 | number: '1.2-2'}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-9 mgT10">
      <div class="mgT10 font13 observacoesFamilias">
          <span *ngFor="let obs of familiesObservation; let i = index">
            <p class="mgT10 mgb5" *ngIf="i == 0"><span class="ps">{{ i+1 }}</span>{{ obs.name }}</p>
            <p class="mgT-5 mgb5" *ngIf="i !== 0"><span class="ps">{{ i+1 }}</span>{{ obs.name }}</p>
          </span>
      </div>
    </div>
    <div class="col-xl-3 p0">
      <button class="botaoFinalizarFamilias" (click)="selectItem()">Selecionar</button>
    </div>
  </div>
</div>
