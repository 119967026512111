import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneDestinationSearchComponent } from './one-destination-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FindAirportsModule } from '../find-airports/find-airports.module';
import { CalendarModule } from '../calendar/calendar.module';
import { PassengersModule } from '../passengers/passengers.module';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [OneDestinationSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FindAirportsModule,
    CalendarModule,
    PassengersModule,
    FlexLayoutModule,
  ],
  exports: [
    OneDestinationSearchComponent
  ]
})
export class SearchModule { }
