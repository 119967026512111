import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../storage/local-storage.service';
import { ZupperProduct } from '../zupper-product.enum';
import { CheckoutDataInterface } from './checkout-data.interface';
import { CHECKOUT_DATA_KEY } from './checkout-data-key.const';
import { MOMENT_FORMATS } from '@zupper/data';
import uniqid from 'uniqid';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDataManagerService {

  constructor(
    private _localStorageService: LocalStorageService
  ) { }

  register(product: ZupperProduct, data: any): string {
    const key = uniqid();
    const payload: CheckoutDataInterface = {
      key: key,
      product: product,
      data: data,
      created: moment()
    };

    let entries = this._getAllEntries();
    entries.push(payload);
    entries = this._clearOldEntries(entries);

    this._save(entries);

    return key;
  }

  retrieve(cid: string): CheckoutDataInterface|null {
    const entries = this._getAllEntries();
    const clearedEntries = this._clearOldEntries(entries);
    this._save(clearedEntries);

    return clearedEntries.find(x => x.key === cid);
  }

  private _getAllEntries(): CheckoutDataInterface[] {
    return this._localStorageService.getItem<CheckoutDataInterface[]>(CHECKOUT_DATA_KEY, []).map(x => this._convert(x)) ?? [];
  }

  private _clearOldEntries(entries: CheckoutDataInterface[]): CheckoutDataInterface[] {

    const max = moment().subtract(1, 'day'); // TODO: make it configurable
    const filteredEntries = entries.filter(x => {
      return x.created.isAfter(max);
    });

    return filteredEntries;
  }

  private _save(entries: CheckoutDataInterface[]): void {
    this._localStorageService.setItem(CHECKOUT_DATA_KEY, entries);
  }

  private _convert(obj: any): CheckoutDataInterface {
    return {
      data: obj['data'],
      key: obj['key'],
      product: obj['product'],
      created: moment(obj['created'], MOMENT_FORMATS.API_FULL_DATETIME_SECONDS),
      orderId: obj['orderId'] ?? null
    };
  }
}
