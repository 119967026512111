<section class="offers" *ngIf="offer">
  <section class="offers-block" *ngFor="let block of offer.blocks">
    <div class="offers-title">
      <p>Pacotes <strong>{{ block.title }}</strong></p>
    </div>
    <div class="offers-block-item">
      <zc-offers-card *ngFor="let item of block.items" [offerBlockItem]="item" [zupperProductOffer]="zupperProductOffer"></zc-offers-card>
    </div>
  </section>
</section>
