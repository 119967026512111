import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NpnSliderModule } from "npn-slider";
import { CiasFilterComponent } from './cias-filter.component';


@NgModule({
  declarations: [
    CiasFilterComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    NpnSliderModule
  ],
  exports: [
    CiasFilterComponent  ]
})
export class CiasFilterModule { }
