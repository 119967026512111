import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { FilterDuration } from '../../../models/api-filter.model';

@Component({
  selector: 'app-duration-filter',
  templateUrl: './duration-filter.component.html',
  styleUrls: ['./duration-filter.component.scss']
})

export class DurationFilterComponent implements OnInit {
  @Input() filterDuration:Array<FilterDuration> = [];
  @Input() filterOptionsMobile: any;
  @Input() clearAllFilters: boolean;
  @Output() durationValuesChange = new EventEmitter<any>();

  minValueOne: number = 0;
  maxValueOne: number = 24;
  minValueTwo: number = 0;
  maxValueTwo: number = 24;
  optionsOne: Options = {
    floor: 0,
    ceil: 24,
    translate: (value: any, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + 'h00';
        case LabelType.High:
          return + value + 'h00';
        default:
          return value + 'h00';
      }
    }
  }

  optionsTwo: Options = {
    floor: 0,
    ceil: 24,
    translate: (value: any, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + 'h00';
        case LabelType.High:
          return value + 'h00';
        default:
          return value + 'h00';
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.setNewCeil();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      this.minValueOne = this.filterDuration[0].minimalDuration;
      this.maxValueOne = this.filterDuration[0].maximumDuration;
      this.minValueTwo = this.filterDuration[1].minimalDuration;
      this.maxValueTwo = this.filterDuration[1].maximumDuration;
    }
  }

  setNewCeil(): void {
    this.minValueOne = this.filterDuration[0].minimalDurationSelected;
    this.maxValueOne = this.filterDuration[0].maximumDurationSelected;
    const newOptions: Options = Object.assign({}, this.optionsOne);
    newOptions.floor = this.filterDuration[0].minimalDuration;
    newOptions.ceil = this.filterDuration[0].maximumDuration;
    this.optionsOne = newOptions;
    if(this.filterDuration[1]) {
      this.minValueTwo = this.filterDuration[1].minimalDurationSelected;
      this.maxValueTwo = this.filterDuration[1].maximumDurationSelected;
      const newOptionsTwo: Options = Object.assign({}, this.optionsTwo);
      newOptionsTwo.floor = this.filterDuration[1].minimalDuration;
      newOptionsTwo.ceil = this.filterDuration[1].maximumDuration;
      this.optionsTwo = newOptionsTwo;
    }
  }

  onMinChangeOne($event) {
    this.filterDuration.map((value, i) => {
      if (i == 0) {
        value.minimalDurationSelected = $event;
      }
    })
    this.sendFilterOpitionDuration();
  }

  onMaxChangeOne($event) {
    this.filterDuration.map((value, i) => {
      if (i == 0) {
        value.maximumDurationSelected = $event;
      }
    })
    this.sendFilterOpitionDuration();
  }

  onMinChangeTwo($event) {
    this.filterDuration.map((value, i) => {
      if (i == 1) {
        value.minimalDurationSelected = $event;
      }
    })
    this.sendFilterOpitionDuration();
  }

  onMaxChangeTwo($event) {
    this.filterDuration.map((value, i) => {
      if (i == 1) {
        value.maximumDurationSelected = $event;

      }
    })
    this.sendFilterOpitionDuration();
  }

  sendFilterOpitionDuration() {
    this.durationValuesChange.emit(this.filterDuration);
  }
}
