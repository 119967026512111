import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NpnSliderModule } from "npn-slider";
import { AirportFilterComponent } from './airport-filter.component';

@NgModule({
  declarations: [
    AirportFilterComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    NpnSliderModule
  ],
  exports: [
    AirportFilterComponent
  ]
})
export class AirportFilterModule { }
