import { Pipe, PipeTransform } from '@angular/core';
import { AirlineService } from '../utils/airline.service';

@Pipe({
  name: 'airline'
})
export class AirlinePipe implements PipeTransform {
  transform(value: string, field = 'name'): string {
    const airline = AirlineService.getInfoFromIata(value);
    return airline ? airline[field] : null;
    // return null;
  }
}
