import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from "lodash";
import { LocalStorageService } from '@zupper/common';
import { ApiCorporateModel } from '@zupper/data';

@Injectable({
  providedIn: "root",
})
export class CorporateLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const whitelabel = this.localStorageService.getItem<ApiCorporateModel>('whitelabel');
    const corporateName = next.params["corporateName"].toLowerCase();
    if (
      !whitelabel ||
      whitelabel.companyName.toLowerCase() !== corporateName
    ) {
      this.router.navigateByUrl("/parceiro/" + corporateName);
      return false;
    }
    return true;
  }
}
