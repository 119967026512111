import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '../tokens/window.token';

@Injectable({
  providedIn: 'root'
})
export class UrlHelper {

  constructor(
    private _router: Router,
    @Inject(WINDOW) private window: any
  ) { }

  openRouteInNewWindow(commands: any[]): void {
    const url = this._router.serializeUrl(this._router.createUrlTree(commands));
    this.window.open(url, '_blank');
  }
}
