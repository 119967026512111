import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[capsLockDetector]'
})
export class CapsLockDetectorDirective {
  @Output('capsLockDetector') capsLock = new EventEmitter<Boolean>();
  constructor() { }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const capsOn = event.getModifierState && event.getModifierState('CapsLock');
    if (!capsOn) {
      this.capsLock.emit(false);
    } else if (capsOn && event.code === 'CapsLock') {
      this.capsLock.emit(true);
    } else {
      this.capsLock.emit(capsOn);
    }
  }
}
