import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { FilterTime } from '../models/api-filter.model';
import { MOMENT_FORMATS } from '@zupper/data';
import moment from "moment";
import * as _ from "lodash";
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'timeArrivalFilter'
})
export class TimeArrivalFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}

  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    timeArrival: Array<FilterTime> = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (timeArrival == null || timeArrival.length == 0) {
      this._buildSelectedFlight(originalflightList);
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      let returnedFlights = _.cloneDeep(flights);
      let hasEmptySlices = false;
      timeArrival.forEach((_filterTime, sliceIndex) => {
        returnedFlights = returnedFlights.map(
          (f) => {
            const sliceArrivalDateMoment = moment(f.slices[sliceIndex].arrivalDate, MOMENT_FORMATS.API_FULL_DATETIME_SECONDS);
            const minimalTimeSelectedMoment = sliceArrivalDateMoment.clone().hour(_filterTime.minimalTimeSelected).startOf('hour');
            const maximumTimeSelectedMoment = _filterTime.maximumTimeSelected === 24
              ? sliceArrivalDateMoment.clone().hour(23).endOf('hour')
              : sliceArrivalDateMoment.clone().hour(_filterTime.maximumTimeSelected).startOf('hour');

            if(!(sliceArrivalDateMoment.isBetween(minimalTimeSelectedMoment, maximumTimeSelectedMoment, 'minute', '[]'))) {
              f.slices[sliceIndex].skip = true;
            }

            return f;
          }
        );
        if (returnedFlights.filter(f => !f.slices[sliceIndex].skip).length == 0) {
          hasEmptySlices = true;
          return;
        }
      });

      if (returnedFlights.length > 0 && !hasEmptySlices) {
        newFlightList[index] = returnedFlights;
      }
    });

    const slicesIndex = timeArrival.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
