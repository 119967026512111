import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AvailableFlightsRequest, DefaultResponseModel } from '@zupper/aerial-components';
import { AerialSearchManagerService } from 'projects/aerial/src/app/services/aerial-search-manager.service';
import moment from 'moment';

@Component({
  selector: 'app-close-dates',
  templateUrl: './close-dates.component.html',
  styleUrls: ['./close-dates.component.scss']
})
export class CloseDatesComponent implements OnInit {

  @Input() flightsSearchFields: AvailableFlightsRequest = new AvailableFlightsRequest();
  @Input() inputDate: Date = new Date();
  @Input() bestPrice: number;

  @Output() sendNewSearch = new EventEmitter();

  defaultResponse: DefaultResponseModel = new DefaultResponseModel;
  currentDate: Date = new Date();
  chosenDates: number[] = [];

  constructor(private service: AerialSearchManagerService, public formatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.currentDate = this.inputDate;
    this.setBestPrice(0, this.bestPrice);
  }

  datesList(position: number): moment.Moment {
    let result = moment({
      day: this.currentDate.getUTCDate(),
      month: this.currentDate.getUTCMonth(),
      year: this.currentDate.getUTCFullYear()
    }).add(position, 'd');
    return result;
  }

  getBestPriceByDate(position: number) {
    let date: Date = new Date(this.currentDate);
    this.currentDate = new Date(date.setDate(date.getDate() + Number(position)));

    this.sendNewSearch.emit(this.formatedDate(this.currentDate));
  }

  getBestPrice(position: number) {
    let date: Date = new Date(this.currentDate);
    let positionDate = new Date(date.setDate(date.getUTCDate() + Number(position)));

    return this.chosenDates[this.formatedDate(positionDate)];
  }

  setBestPrice(position: number, farePrice: number) {
    let date: Date = new Date(this.currentDate);
    let positionDate = new Date(date.setDate(date.getUTCDate() + Number(position)));

    if (
      !this.chosenDates[this.formatedDate(positionDate)] ||
      this.chosenDates[this.formatedDate(positionDate)] > farePrice
    ) {
      this.chosenDates[this.formatedDate(positionDate)] = farePrice;
    }
  }

  formatedDate(date: Date) {
    return this.formatter.format(new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()));
  }
}
