<label class="default-input-label"><i class="fas fa-user"></i> Passageiros</label>
<input #passengersPopup="ngbPopover" data-zt="passengersConfigTrigger" [value]="this.passengerDisplay + ', ' + this.classDisplay " type="text" class='passenger-input' readonly placeholder="Selecione" [ngbPopover]="popuHospedes" placement="bottom" [autoClose]="'outside'"
/>

<ng-template #popuHospedes>
    <div data-zt="passengersConfigPopup">
        <header class="popup-header">
            <span><i class="fas fa-user"></i> Passageiros</span>
            <i class="fas fa-times" (click)="passengersPopup.close()"></i>
        </header>
        <aside class="chosenClass">
            <article class="passengerRow">
                <p><i class="fas fa-user iconMotor"></i> Passageiros</p>
            </article>
            <article class="passenger-qty">
                <p>Adultos <small>(+12 anos)</small></p>
                <div>
                    <span (click)="this.removeAdult()" data-zt="passengersConfigRemoveAdult" class="fa fa-minus btn_quantidade" [ngClass]="{disabled: this.flightSearchFields.adultQty <= 1 || this.flightSearchFields.adultQty == this.flightSearchFields.infantQty}">
          </span>
                    <span #adultos data-zt="passengersConfigAdultQty" class="quantidade_escolhida">{{this.flightSearchFields.adultQty}}</span>
                    <span (click)="this.addAdult()" data-zt="passengersConfigAddAdult" class="fa fa-plus btn_quantidade" [ngClass]="{disabled: this.peopleAmount > 7}">
          </span>
                </div>
            </article>
            <hr>
            <article class="passenger-qty">
                <p>Crianças <small>(2-11 anos)</small></p>
                <div>
                    <span (click)="this.removeChild()" data-zt="passengersConfigRemoveChild" class="fa fa-minus btn_quantidade" [ngClass]="{disabled: this.flightSearchFields.childrenQty < 1}"></span>
                    <span #criancas data-zt="passengersConfigChildrenQty" class="quantidade_escolhida">{{this.flightSearchFields.childrenQty}}</span>
                    <span (click)="this.addChild()" data-zt="passengersConfigAddChild" class="fa fa-plus btn_quantidade" [ngClass]="{disabled: this.peopleAmount > 7}"></span>
                </div>
            </article>
            <hr>
            <article class="passenger-qty">
                <p>Bebês <small>(0-23 meses)</small></p>
                <div>
                    <span (click)="this.removeInfant()" data-zt="passengersConfigRemoveInfant" class="fa fa-minus btn_quantidade" [ngClass]="{disabled: this.flightSearchFields.infantQty < 1}">
          </span>
                    <span #quartos data-zt="passengersConfigInfantQty" class="quantidade_escolhida">{{this.flightSearchFields.infantQty}}</span>
                    <span (click)="this.addInfant()" data-zt="passengersConfigAddInfant" class="fa fa-plus btn_quantidade" [ngClass]="{disabled: this.peopleAmount > 7 || this.flightSearchFields.infantQty == this.flightSearchFields.adultQty}">
          </span>
                </div>
            </article>
            <hr>
            <article class="passengerClass" data-zt="passengersConfigFlightClass">
                <p>Classe</p>
                <div>
                    <label for="economic" class="flightClass">
            <input
              (click)="this.chooseClass('economic')"
              type="radio" id="economic"
              class="" [checked]="this.setFlightClass() == 'economic' ? true : false"
              name="flightClass"
              value="Economic Class"/>
            <p>Econômica</p>
          </label>
                </div>
                <!-- <div>
          <label for="business" class="flightClass">
            <input
            (click)="this.chooseClass('business')"
            type="radio" id="business"
            class=""
            [checked]="this.setFlightClass() == 'business' ? true : false"
            name="flightClass"
            value="Economic Class"/>
            <p>Econômica</p>
        </label>
        </div> -->
            </article>
        </aside>
        <button type="button" data-zt="passengersConfigMobileSubmit" class="button-close-popup mobile-only " (click)="passengersPopup.close() ">Confirmar</button>
    </div>

</ng-template>
