import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerLoginService, FlightSlicesModel } from '@zupper/common';
import {
  AerialSearchHistory,
  SearchHistoryService,
  SearchHistoryStrategyFactoryService,
  ZupperProduct,
} from '@zupper/search-history';
import moment from 'moment';
import { BehaviorSubject, forkJoin, lastValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { AirportManagerService } from '../../services/airport-manager.service';
@Component({
  selector: 'aerial-search-history-list',
  templateUrl: './aerial-search-history-list.component.html',
  styleUrls: ['./aerial-search-history-list.component.scss'],
})
export class AerialSearchHistoryListComponent implements OnInit, OnDestroy {
  @Input() whatsappContactNumber: string;

  searchHistory = new BehaviorSubject<AerialSearchHistory[]>([]);
  loading = true;

  private subs = new SubSink();

  constructor(
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private searchHistoryStrategyFactory: SearchHistoryStrategyFactoryService,
    private customerLoginService: CustomerLoginService,
    private _airportManager: AirportManagerService
  ) {}

  ngOnInit(): void {
    this.getHistory();
  }

  async getHistory() {
    const customerSessionId = this.customerLoginService.getCustomerSessionId();
    const strategy = this.searchHistoryStrategyFactory.getStrategy(customerSessionId);
    this.searchHistoryService.setStrategy(strategy);
    this.subs.add(
      this.searchHistoryService.fetch(ZupperProduct.AERIAL).subscribe({
        next: (history) => {
          this.updateCityNamesFromAirport(history as AerialSearchHistory[]);
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async updateCityNamesFromAirport(history: AerialSearchHistory[]) {
    if (!history?.length) return;

    const allIATAs = history.map((history) => [history.destination, history.origin]).flat();

    const allUniqueIATAs = [...new Set(allIATAs)];
    const observables = allUniqueIATAs.map((iata) => this._airportManager.fetch(iata));

    const airportResult = await lastValueFrom(forkJoin(observables));
    const airportMap = new Map(airportResult.map((airport) => [airport.airportCode, airport]));
    const newValue = history.map((history) => {
      history.originCityName = airportMap.get(history.origin)?.city;
      const destinationAirport = airportMap.get(history.destination);
      history.destinationCityName = destinationAirport?.city;
      history.destinationCountry = destinationAirport?.country;
      return history;
    });

    this.searchHistory.next(newValue);
  }

  continueSearch(history: AerialSearchHistory) {
    this.router.navigate(['/resultados'], {
      queryParams: {
        type: history.type ?? 'roundTrip',
        adultQty: history.adultQuantity,
        childrenQty: history.childrenQuantity,
        infantQty: history.infantQuantity,
        slices: JSON.stringify(
          history.slices.map(
            (slice) =>
              ({
                departureDate: slice.goingDate.format('YYYY-MM-DD'),
                destinationAirport: slice.destination,
                originAirport: slice.origin,
              } as FlightSlicesModel)
          )
        ),
      },
    });
  }

  continueService(history: AerialSearchHistory) {
    const link = this.generateInternationalConsultingLink(this.whatsappContactNumber, history);
    window.open(link, '_blank');
  }

  generateInternationalConsultingLink(contactNumber: string, flight: AerialSearchHistory): string {
    const message = this.generateInternationalConsultingMessage(flight);
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${contactNumber}/?text=${encodedMessage}`;
  }

  private generateInternationalConsultingMessage(flight: AerialSearchHistory) {
    let message = 'Olá, quero a melhor oferta para ';

    const { origin, destination, originCityName, destinationCityName, type } = flight;
    message += `${originCityName ?? origin}/${destinationCityName ?? destination}, `;

    const departureDate = moment(flight.departureDate).format('DD/MM');
    const arrivalDate = moment(flight.arrivalDate);

    if (type === 'roundTrip' && arrivalDate.isValid()) {
      message += `*Ida* ${departureDate} / *Volta* ${arrivalDate.format('DD/MM')}, `;
    } else {
      message += `*Somente Ida* ${departureDate}, `;
    }

    const adults = flight.adultQuantity;
    message += `${adults ?? 0} adultos`;

    const children = flight.childrenQuantity;
    if (children) {
      message += `, ${children} crianças`;
    }
    const infant = flight.infantQuantity;
    if (infant) {
      message += `, ${infant} bebês`;
    }

    return message;
  }
}
