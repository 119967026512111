import { StopPriceFilter } from './../models/aerial-filters.model';
import { Injectable } from "@angular/core";
import { DataLayerResultPageModel } from "../models/data-layer-result-page.model";
import { AvailableFlightsRequest, SessionStorageService } from "@zupper/common";
import { LocalStorageService } from '@zupper/common';
import { ApiCorporateModel } from '@zupper/data';

@Injectable({
  providedIn: 'root'
})

export class DataLayerResultPageService {

  private dataLayer: DataLayerResultPageModel;

  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {
    this.dataLayer = new DataLayerResultPageModel();
  }

  getDataLayer() {
    return this.dataLayer;
  }

  load(category: string, flightRequest: AvailableFlightsRequest, bestPrices: StopPriceFilter[]) {
    const whitelabel = this.localStorageService.getItem<ApiCorporateModel>('whitelabel');

    this.getDataLayer()
      .setCategory(category)
      .setTripType(flightRequest.type)
      .setAdultQty(flightRequest.adultQty)
      .setChildQty(flightRequest.childrenQty)
      .setInfantQty(flightRequest.infantQty)
      .setCabinType(flightRequest.onlyBusinessClass ? "Business" : "Economic")
      .setDaysToTravel(flightRequest.slices[0].departureDate)
      .setTravelingDays(flightRequest)
      .setClassification(flightRequest)
      .setPrice(bestPrices)
      .setPartner(whitelabel)
      .setSlice(flightRequest);

    this.trigger();
  }

  trigger() {
    if (!(<any>window).dataLayer) {
      (<any>window).dataLayer = [];
    }
    (<any>window).dataLayer.push(this.dataLayer);
  }
}
