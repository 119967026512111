import { ApiLogLevel, EnvironmentInterface } from '@zupper/common';
import { EnvironmentsEnum } from '@zupper/common';

const baseApiUrl = 'https://api.zupper.com.br';
const baseWebsiteUrl = 'https://www.zupper.com.br';

export const environment: EnvironmentInterface = {
  environment: EnvironmentsEnum.PRODUCTION,
  production: true,
  apiUrl: `${baseApiUrl}`,
  apiPriceUrl: `${baseApiUrl}`,
  apiBookUrl: `${baseApiUrl}`,
  websiteUrl: '/',
  aerial: {
    url: '/',
  },
  hotel: {
    url: `/hotel`,
    termsAndConditionsUrl:
      'https://natrend.grupotrend.com/content/pdf/CondicoesHotelariaNacional_natrend.pt-BR.pdf?v=3.0.8486.23356',
  },
  firebase: {
    apiKey: 'AIzaSyDCs1cPpsVhfyXHQnPcG1DRpLcJCS6jfZw',
    authDomain: 'zupperwebsite.firebaseapp.com',
    projectId: 'zupperwebsite',
    storageBucket: 'zupperwebsite.appspot.com',
    messagingSenderId: '1027924238978',
    appId: '1:1027924238978:web:8e6efe379038e86a76ca9b',
    measurementId: 'G-FBJVH15SMW',
  },
  account: {
    baseUrl: `/minha-conta`,
    registerUrl: `/minha-conta/conta/nova-conta`,
    loginUrl: `/minha-conta/login`,
    AppVueJsAttendancesUrl: `/atendimentos/`,
  },
  checkout: {
    url: `/carrinho`,
    changePaymentUrl: `/carrinho/alterar-pagamento`,
    timerDuration: { minutes: 15 },
  },
  insurance: {
    baseUrl: `${baseApiUrl}/rest/v2/insurance`
  },
  zupperBlogFeedUrl: `/blog/feed`,
  logging: {
    serverLoggingUrl: `${baseApiUrl}/rest/v2/app/logs`,
    serverLogLevel: ApiLogLevel.ERROR,
  },
  externalConfigUrl: `${baseApiUrl}/rest/v2/app-settings`,
  packages: {
    url: `/pacotes`,
  },
  tracking: {
    storageExpiration: { days: 7 },
  },
  security: {
    goDaddy:
      'https://seal.godaddy.com/getSeal?sealID=bVWG3UOVNZC0REjtJuNhyrWQR9N1Vddt1iF4BJs3KAFf9GazXE2dJkyHzucF',
    siteBlindado: '//cdn.siteblindado.com/aw.js',
  },
  links: {
    homeZupperUrl: `${baseWebsiteUrl}`,
    helpCentralUrl: `https://centraldeajuda.zupper.com.br/`,
    trackOrderUrl: `/minha-conta/meus-pedidos`,
    loginUrl: `/minha-conta/login`,
  },
  recaptcha: {
    siteKey: '6Le5TGElAAAAAPaYx6O6WhUJRIkypCy1SbbTAJQR',
  },
  apiTimeout: { milliseconds: 120000 },
  zupperImagesBaseUrl: 'https://static.zupper.com.br/imagens_destinos_zupper/',
  abandonmentModalExpiration: 24
};
