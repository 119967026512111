<div class="modal-body">
    <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 p0 image">
            <div class="gradient-image">
                <div class="content-terms">
                    <h1 class="corporateName">{{corporateName}}</h1>
                    <h1 class="text-terms">Termos e Condições</h1>
                </div>
            </div>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 p0">
            <h3 class="text-center important-text">
                Importante!
            </h3>
            <div class="description-term">
                TERMO DE ACEITE<br>
                Organização BRADESCO – Viagens a Lazer para Funcionários e Dependentes
            </div>
            <br>
            <p>
                A Organização BRADESCO não exercerá controle ou interferência sobre
                quaisquer transações para aquisição, cancelamento, pagamento, reembolso de
                valores, produtos e serviços efetuados por seus funcionários e dependentes para
                viagens a lazer, por meio desta plataforma Zupper. Complementarmente, o
                BRADESCO não será responsabilizado por quaisquer intermediações, providências,
                dados particulares inseridos na ferramenta pelo funcionário (CPF, Dados de cartão
                de crédito) e indenizações relacionadas à utilização da plataforma Zupper, para fins
                particulares.

            </p>
            <br>
            <p>
                Ressalta-se que deverão ser observados, por seus funcionários e
                dependentes, todos os termos e condições previstos para o uso do Zupper e
                disponíveis no link:
                <a href="/termos-e-condicoes">
                    https://www.zupper.com.br/termos-e-condicoes.</a>
            </p>
            <br>
            <p>
                Cabe esclarecer que, em informações relativas a viagens a lazer
                compartilhadas com o BRADESCO, não haverá identificação de clientes ou do meio
                de pagamento utilizado, limitando-se a dados gerenciais como subsídio para
                obtenção de novos descontos.
            </p>
            <br>
            <p>
                <strong>Ao continuar você declara ter compreendido e aceito os termos acima.</strong>
            </p>
            <br>
            <div class="text-center">
                <button class="btn btn-lg btn-accept" (click)="defineTerms('accept')">
                    Aceitar e Continuar
                </button>
            </div>
            <br>
            <div class="text-center" style="margin-bottom: 20px;">
                <u class="recuse" (click)="defineTerms('refuse')">Recusar Termos e condições</u>
            </div>
        </div>
    </div>
</div>
