import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { FilterDuration, FilterTime } from '../models/api-filter.model';
import moment from "moment";
import * as _ from "lodash";
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'durationFilter'
})
export class DurationFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    durationFilter: Array<FilterDuration> = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (durationFilter == null || durationFilter.length == 0) {
      this._buildSelectedFlight(originalflightList);
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      let returnedFlights = _.cloneDeep(flights);
      let hasEmptySlices = false;
      durationFilter.forEach((_duration, sliceIndex) => {
        returnedFlights = returnedFlights.map(
          (f) => {
            let duration = moment(f.slices[sliceIndex].arrivalDate).diff(f.slices[sliceIndex].departureDate, 'hours')
            if (!(
              duration >= (_duration.minimalDurationSelected) &&
              duration <= (_duration.maximumDurationSelected)
            )) {
              f.slices[sliceIndex].skip = true;
            }

            return f;
          }
        );
        if (returnedFlights.filter(f => !f.slices[sliceIndex].skip).length == 0) {
          hasEmptySlices = true;
          return;
        }
      });
      if (returnedFlights.length > 0 && !hasEmptySlices) {
        newFlightList[index] = returnedFlights;
      }
    });

    const slicesIndex = durationFilter.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
