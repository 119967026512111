import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { contentConfigurationResolver, ZupperProduct, gclidResolver, utmCampaignResolver } from "@zupper/common";
import { HomeComponent } from "./components/home/home.component";
import { CorporateLoggedGuard } from "./corporate/guards/corporate-logged.guard";
import * as _ from 'lodash';
import { resultsSearchResolver } from "./resolvers/results-search.resolver";
import { resultsFiltersResolver } from "projects/packages/src/app/resolvers/results-filters.resolver";
import { WEBSITE_PAGE } from "zupper-data";

const routeData = {
  product: ZupperProduct.AERIAL,
  showHeader: true,
  showHeaderLinks: true
};

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: routeData,
    canActivate: [CorporateLoggedGuard],
    resolve: {
      searchRequest: resultsSearchResolver,
      searchFilters: resultsFiltersResolver,
      utmCampaign: utmCampaignResolver,
      gclid: gclidResolver,
      whatsAppTag: contentConfigurationResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'redirect',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./transaction/transaction.module').then(
            (m) => m.TransactionModule
          )
      },
    ],
  },
  {
    path: 'resultados',
    data: {...routeData, page: WEBSITE_PAGE.AERIAL_RESULTS},
    loadChildren: () =>
      import('./components/results/results.module').then(
        (m) => m.ResultsModule
      )
  },
  {
    path: 'mcr',
    loadChildren: () =>
      import('./mcr/mcr.module').then(
        (m) => m.McrModule
      )
  },
  {
    path: 'termos-e-condicoes',
    loadChildren: () =>
      import('./terms-and-conditions/terms-and-conditions.module').then(
        (m) => m.TermsAndConditionsModule
      )
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      )
  },
  {
    path: 'politica-de-cookie',
    loadChildren: () =>
      import('./components/privacy-policy-cookie/privacy-policy-cookie.module').then(
        (m) => m.PrivacyPolicyCookieModule
      )
  },
  {
    path: 'quem-somos',
    loadChildren: () =>
      import('./components/who-we-are/who-we-are.module').then(
        (m) => m.WhoWerAreModule
      )
  },
  {
    path: 'parceiro',
    loadChildren: () =>
      import('./corporate/components/corporate-login/corporate-login.module').then(
        (m) => m.CorporateLoginModule
      )
  },
  {
    path: "**",
    redirectTo: "/",
    data: routeData
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled",
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
