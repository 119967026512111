import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FilterOptions } from '../../../models/api-filter.model';


@Component({
  selector: 'app-stop-filter',
  templateUrl: './stop-filter.component.html',
  styleUrls: ['./stop-filter.component.scss']
})

export class StopFilterComponent implements OnInit {
  @Input() orderedStopQuantities: Array<number> = [];
  @Input() filterOptionsMobile: FilterOptions;
  @Input() clearAllFilters: boolean;
  @Output() onFilterChange = new EventEmitter<any>();
  @Input() selectedStopFilters: boolean[] = [];

  formStopFilter = this._fb.group({
    stop: this._fb.array([])
  })

  get stopFilterArray(): FormArray {
    return this.formStopFilter.get('stop') as FormArray;
  }

  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
  this.selectedStopFilters.forEach((selected, i) => {
    const isSelected = selected || false;
    this.stopFilterArray.push(new FormControl(isSelected));
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      const formArray = this.stopFilterArray;
      for (let i = 0; i < formArray.length; i++) {
        formArray.at(i).setValue(false);
      }
    }
  }

  sendFilterOption(stop, i) {
    const formArray = this.stopFilterArray;
    formArray.at(i).setValue(!formArray.at(i).value);
    this.onFilterChange.emit(formArray.value);
  }
}
