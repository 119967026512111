<header class="award" *ngIf="bestPrice">
    <i class="fas fa-award"></i>
    <h4>Melhor Preço</h4>
</header>
<aside class="price-container" [ngClass]="{'mx-3': isMobile && isMultiSlice}">
    <header *ngIf="showFamilies" class="family">
        <app-result-list-family
          [flightList]="this.flightList"
          [radioName]="this.radioName"
          (selectedFlight)="setChosenFlight($event)"
          (selectedFamilyName)="setSelectedFamily($event)"
          (upsellPrice)="setUpsellPrice($event)"
        >
        </app-result-list-family>
    </header>
    <footer>
        <div class="price-info" data-zt="priceInfo" [ngClass]="{'mobile-multislice-price': isMobile && isMultiSlice}">
            <p data-zt="adultFare">
                <ng-container *ngIf="isMobile; else nonMobileFeeText">
                    <span class="slice-fee-label">
                        <ng-container *ngIf="!!sliceIndex; noSliceText">
                            Tarifas
                            <small>
                                {{ 'TRECHO ' + sliceIndex }}
                            </small>
                        </ng-container>
                        <ng-template #noSliceText>
                            SEM TRECHOS
                        </ng-template>
                    </span>
                </ng-container>
                <ng-template #nonMobileFeeText>
                    Tarifa por Adulto
                    <!-- <ng-container *ngIf="this.flightSearch.type == 'roundTrip'">ida e volta</ng-container>
                    <ng-container *ngIf="this.flightSearch.type == 'oneWay'">apenas ida</ng-container>
                    <ng-container *ngIf="this.flightSearch.type == 'MultiSlice'">neste trecho</ng-container> -->
                </ng-template>
                <span class="slice-fee-value">{{ this.fareByPaxType.Adult | currency : "R$"}}</span>
            </p>
            <ng-container *ngIf="isMultiSlice; else passengerTypeSection">
                <ng-container [ngTemplateOutlet]="passengerTypeSection" *ngIf="chosenSlices.length > 0"></ng-container>
            </ng-container>
            <ng-template #passengerTypeSection>
                <p *ngIf="this.flightSearch.adultQty > 0">{{ this.flightSearch.adultQty }} {{ this.flightSearch.adultQty > 1 ? 'Adultos' : 'Adulto'}} <span>{{ this.flightSearch.adultQty * this.fareByPaxType.Adult | currency : "R$" }}</span></p>
                <p *ngIf="this.flightSearch.childrenQty > 0">{{ this.flightSearch.childrenQty }} Criança <span>{{ this.flightSearch.childrenQty * this.fareByPaxType.Child | currency : "R$" }}</span></p>
                <p *ngIf="this.flightSearch.infantQty > 0">{{ this.flightSearch.infantQty }} Bebê <span>{{ this.flightSearch.infantQty * this.fareByPaxType.Infant | currency : "R$" }}</span></p>
            </ng-template>
            <p data-zt="totalFeeTaxes">Taxas e impostos <span>R$ {{this.getTaxes | number: '1.2-2'}}</span></p>
            <p>Total <span data-zt="totalPrice">{{ (this.chosenFlight?.totalPrice || 0) | currency : "R$" }}</span></p>
        </div>
        <div class="send-slice" *ngIf="!isMultiSlice">
            <zc-installment
                [maxInstallments]="maxAllowedInstallment"
                [hasCardTax]="hasCardTax"
            ></zc-installment>

            <button (click)="sendSlicesToCheckout()">Comprar</button>
        </div>
    </footer>
</aside>
