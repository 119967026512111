<section class="whitelabel">
  <div class="widthPadrao list-container">
    <h2>
      Bem Vindo ao {{ whitelabel.companyName }} Viagens!
    </h2>
    <h5>É necessário fazer a autenticação para continuar</h5>
    <form #form (ngSubmit)="whitelabelLogin()" action="#">
      <fieldset>
        <label for="username">Usuário</label>
        <input type="text" name="username" id="username" (keyup.enter)="whitelabelLogin()" [(ngModel)]="username">
      </fieldset>

      <fieldset>
        <label for="password">Senha</label>
        <input type="password" name="password" id="password" (keyup.enter)="whitelabelLogin()" [(ngModel)]="password">
      </fieldset>

      <fieldset class="forgot">
        <a [href]="createAccountUrl">Criar conta</a>
        <a [href]="forgotPasswordUrl">Esqueci minha senha</a>
      </fieldset>

      <div class="buttons">
        <button class="back" (click)="whitelabelLogout()">
          <i class="fas fa-chevron-left"></i> Voltar
        </button>
        <button class="login" type="submit">Entrar</button>
      </div>
    </form>
  </div>
</section>

<swal
  #loginFailedSwal
  title="Erro de Login"
  text="Usuário ou Senha inválidos"
  icon="warning">
</swal>
