import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiCorporateModel, ApiCorporateService } from '@zupper/data';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from '@zupper/common';

@Injectable({
  providedIn: 'root'
})
export class CorporateAuthGuard implements CanActivate {
  constructor(
    private corporateService: ApiCorporateService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    const corporateName = next.params['corporateName'];
    if (!corporateName) {
      return false;
    }

    return this.corporateService
      .get(corporateName).pipe(
        map((response: ApiCorporateModel) => {
          this.localStorageService.setItem('whitelabel', response);

          if (response.brandingType > 0) {
            this.router.navigateByUrl('/parceiro/' + response.companyName + '/entrar');
            return false;
          }

          this.router.navigateByUrl('/');
          return true;
        })
      );
  }
}
