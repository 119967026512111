import { Injectable } from '@angular/core';
import { DataLayerThanks } from './models/data-layer-thanks.enum';
import {
  PartialThanksDimensionsSet,
  FullThanksSet
} from './models/full-custom-dimensions-set.model';

@Injectable({
  providedIn: 'root'
})
export class DataLayerThanksService {
  private _currentSet: FullThanksSet;
  private _constantDimensions = {};

  constructor() {
    this._generateEmptyDimensionsSet();
    // this._constantDimensions = [] // Get here dimensions that need to be sent at every push;
  }

  set dimensions(someDimensions: PartialThanksDimensionsSet) {
    Object.keys(DataLayerThanks)
      .map(key => DataLayerThanks[key])
      .forEach(key => {
        this._currentSet[key] =
          someDimensions[key] || this._constantDimensions[key];
      });
  }

  trigger() {
    if (!(<any>window).dataLayer) {
      (<any>window).dataLayer = [];
    }

    (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [DataLayerThanks.event]: undefined,
      [DataLayerThanks.number]: undefined,
      [DataLayerThanks.discount]: undefined,
      [DataLayerThanks.duValue]: undefined,
      [DataLayerThanks.du2Value]: undefined,
      [DataLayerThanks.airline]: undefined,
      [DataLayerThanks.type]: undefined,
      [DataLayerThanks.totalWithDiscount]: undefined,
      [DataLayerThanks.totalPrice]: undefined,
      [DataLayerThanks.totalIncentiveValue]: undefined,
      [DataLayerThanks.totalFarePrice]: undefined,
      [DataLayerThanks.totalCreditCardFee]: undefined,
      [DataLayerThanks.totalAirportTaxes]: undefined,
      [DataLayerThanks.paymentType]: undefined
    };
  }
}
