import { Component, Input, Output, OnInit, ViewEncapsulation, EventEmitter, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import { AirlineFamilyListModel, AirlineFamilyModel, FamilyDataModel, FamilyFieldModel } from 'projects/aerial/src/app/models/airline-family.model';
import { ModalFlightFamilyComponent } from '../../../modal-flight-family/modal-flight-family.component';
import { AirlineConstantService } from '../../../../constants/airline-constant.service';
import { AirlineServiceService } from '../../uncombined-result/airline-service.service';

@Component({
  selector: "app-result-list-family",
  templateUrl: "./result-list-family.component.html",
  styleUrls: ["./result-list-family.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ResultListFamilyComponent implements OnInit {
  @Input() flightList: AvailableFlightsResponse[] = [];
  @Input() radioName: string;
  @Output() selectedFlight: EventEmitter<AvailableFlightsResponse> = new EventEmitter();
  @Output() selectedFamilyName: EventEmitter<string> = new EventEmitter();
  @ViewChildren("selectedRadio") selectedRadio: QueryList<ElementRef>;
  @Output() upsellPrice: EventEmitter<number> = new EventEmitter();

  lastFamilyName: string = null;
  priceList: number[];
  upsellPriceList: number[] = [];
  chosenFamilyIndex: number;
  familyDetails: AirlineFamilyListModel = new AirlineFamilyListModel();

  constructor(
    private modalService: NgbModal,
    private airlineServiceService: AirlineServiceService
  ) { }

  ngOnInit(): void {
    this.familyDetails.push(
      AirlineConstantService.getG3Families()
    );

    this.familyDetails.push(
      AirlineConstantService.getADFamilies()
    );

    this.familyDetails.push(
      AirlineConstantService.getLAFamilies()
    );

     this.familyDetails.push(
      AirlineConstantService.get2ZFamilies()
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.chosenFamilyIndex = 0;
      this.setSelectFamily(this.flightList[0], 0);
    }, 1);
  }

  getFamilyPrice(index: number, flightList: Array<AvailableFlightsResponse>): number {
    let currentFamilyFare = 0;
    flightList[index].slices.forEach((slice) => {
      currentFamilyFare += slice.baseFares[0].fareValue;
    });

    if (index != this.chosenFamilyIndex && this.chosenFamilyIndex != undefined) {
      let familyFare = 0;
      flightList[this.chosenFamilyIndex].slices.forEach((slice) => {
        familyFare += slice.baseFares[0].fareValue;
      });
      if (index < this.chosenFamilyIndex) {
        currentFamilyFare = familyFare - currentFamilyFare;
      } else if (index > this.chosenFamilyIndex) {
        currentFamilyFare -= familyFare;
      }
    }
    this.upsellPriceList[index] = currentFamilyFare;
    return currentFamilyFare;
  }

  setSelectFamily(flight: AvailableFlightsResponse, index: number) {
    this.chosenFamilyIndex = index;
    this.selectedFlight.emit(flight);
    this.lastFamilyName = flight ? flight.slices[0].baseFares[0].family : "";
    this.selectedFamilyName.emit(this.lastFamilyName);
    if (index > 0) {
      this.upsellPrice.emit(this.upsellPriceList[index]);
    } else {
      this.upsellPrice.emit(0);
    }
  }

  get currentFlight() {
    let familyIndex: number = 0;
    let uniqueFlights: AvailableFlightsResponse[] = this.flightList.filter((flight, index, flightArray) => {
      return flightArray.map(mapObj => mapObj.slices[0].baseFares[0].family).indexOf(flight.slices[0].baseFares[0].family) === index;
    });
    this.selectedRadio.forEach((listItem, listIndex) => {
      if (listItem.nativeElement.checked) {
        familyIndex = listIndex;
      }
    });
    return uniqueFlights[familyIndex];
  }

  uniqueFilteredFlights(aFlightList: AvailableFlightsResponse[]): AvailableFlightsResponse[] {
    return aFlightList.filter((flight, index, flightArray) => {
      return (flightArray.map((mapFlight) => mapFlight.slices[0].baseFares[0].family).indexOf(flight.slices[0].baseFares[0].family) === index);
    });
  }

  get familiesEnabled() {
    let families = [];
    families = this.flightList
      .map((flight) => flight.slices[0].baseFares[0].family)
      .filter((value, index, self) => self.indexOf(value) === index);
    return families;
  }

  openFlightFamily() {
    const modal = this.modalService.open(ModalFlightFamilyComponent, {
      size: "lg",
    });
    modal.componentInstance.source = this.flightList[0].airline;
    modal.componentInstance.familiesEnabled = this.familiesEnabled;
    modal.componentInstance.flightFamilies = this.uniqueFilteredFlights(this.flightList);
    modal.componentInstance.currentFamily = this.currentFlight["slices"][0]["baseFares"][0]["family"];

    modal.result.then((result) => {
      if (result) {
        this.uniqueFilteredFlights(this.flightList).forEach((flight, index) => {
          if (flight.slices[0].mainFamily == result.selectedFamily) {
            this.setSelectFamily(flight, index);
            return true;
          }
        });
      }
    });
  }

  getItemClass(airline: string, familyName: string): string {
    return this.airlineServiceService.getFilteredAirlines(airline) + '-' + familyName.replace(/ /g, "_");
  }
}
