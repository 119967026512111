<ng-container *ngFor="let flight of flights
  | family: this.selectedFamilyName : sliceIndex
  | uniqueFlight: sliceIndex;
  let flightIndex = index;
  let isFirst = first;
  ">
  <div fxHide.xs="true" fxShow.gt-xs="true">
    <label data-zt="flightOption" class="flight-option
       {{ (selectedFlight && selectedFlight == flight.slices[sliceIndex].apiSliceId) ? 'selected' : '' }} cias-diff"
      [ngClass]="flight.slices[sliceIndex].direction" *ngIf="flight && ! flight.slices[sliceIndex].skip"
      (click)="sendFlightSlice(flight, flight.slices[sliceIndex])">

      <input type="radio" [checked]="(
  selectedFlight &&
  selectedFlight == flight.slices[sliceIndex].apiSliceId
  ) || (isFirst)
  " [name]="groupIndex+'_'+sliceIndex+flight.slices[sliceIndex].direction">


      <img data-zt="airlineLogo" class="airline-logo" [src]="getAirlineInfo(flight.slices[sliceIndex]).logoUrl" alt="">
      <div class="departure-wrapper">
        <small>Saída</small>
        <p data-zt="departureTime">{{flight.slices[sliceIndex].departureDate | date:'HH:mm'}}</p>
      </div>
      <div class="arrival-wrapper">
        <small>Chegada</small>
        <p data-zt="arrivalTime">
          {{flight.slices[sliceIndex].arrivalDate | date:'HH:mm'}}
          <app-next-day-trip [slice]="flight.slices[sliceIndex]"></app-next-day-trip>
        </p>
      </div>
      <div class="duration-wrapper">
        <small>Duração</small>
        <p data-zt="flightDuration">{{ flight?.slices[sliceIndex]?.flightDuration | flightDuration }}</p>
      </div>
      <div class="stops-wrapper">
        <p data-zt="stopsCount" class="stops">{{this.getStopsCount(flight.slices[sliceIndex])}}</p>
      </div>
      <app-luggage-icons data-zt="baggages" [baggageAllowance]="flight.slices[sliceIndex].baseFares[0].baggageAllowance"
        [sliceIndex]="sliceIndex"></app-luggage-icons>

      <label class="flight-details" [for]="groupIndex+flight.slices[sliceIndex].direction+flightIndex"
        data-zt="flightDetails">
        <small>Detalhes <i class="fas fa-angle-down"></i></small>
      </label>
      <input type="checkbox" [id]="groupIndex+flight.slices[sliceIndex].direction+flightIndex">
      <ng-container [ngTemplateOutlet]="sliceDetails" [ngTemplateOutletContext]="{flight: flight}"></ng-container>
      <ng-template></ng-template>
    </label>
  </div>
</ng-container>

<div class="mobile-result-list-item" *ngFor="let flight of flights
  | family: this.selectedFamilyName : sliceIndex
  | uniqueFlight: sliceIndex;
  let flightIndex = index;
  let isFirst = first;
  " fxShow.xs="true" fxHide.gt-xs="true">
  <div fxFlex fxLayout="column" *ngIf="!flight.slices[sliceIndex].skip">

    <label class="flight-option
      {{ (selectedFlight && selectedFlight == flight.slices[sliceIndex].apiSliceId) ? 'selected' : ''}}
      {{ flight.ciasDiff ? 'cias-diff' : '' }}" [ngClass]="flight.slices[sliceIndex].direction"
      data-zt="flightSelected" *ngIf="flight && ! flight.slices[sliceIndex].skip"
      (click)="sendFlightSlice(flight, flight.slices[sliceIndex])">

      <section class="header">
        <input type="radio" [checked]="(selectedFlight &&
        selectedFlight == flight.slices[sliceIndex].apiSliceId
        ) || (isFirst)" [name]="groupIndex+'_'+sliceIndex+flight.slices[sliceIndex].direction+'m'"
        (click)="sendFlightSlice(flight, flight.slices[sliceIndex])">

        <img class="airline-logo" [src]="getAirlineInfo(flight.slices[sliceIndex]).logoUrl" alt="">

      </section>

      <div class="flight-description">

        <div class="">
          <span class="flight-time">{{flight.slices[sliceIndex].departureDate | date:'HH:mm'}}</span>
        </div>

        <div class="divider">
          <hr class="right">
          <div class="nowrap">
            <ng-container *ngIf="(flight.slices[sliceIndex].segments.length - 1) > 0">
              {{this.getStopsCount(flight.slices[sliceIndex])}} -
              {{ flight?.slices[sliceIndex]?.flightDuration | flightDuration }}
            </ng-container>
            <ng-container *ngIf="(flight.slices[sliceIndex].segments.length - 1) === 0">
              Voo Direto -
              {{ flight?.slices[sliceIndex]?.flightDuration | flightDuration }}
            </ng-container>
          </div>
          <hr class="left">
        </div>

        <div class="arrival-time">
          <span class="flight-time">
            {{flight.slices[sliceIndex].arrivalDate | date:'HH:mm'}}
            <app-next-day-trip [slice]="flight.slices[sliceIndex]"></app-next-day-trip>
          </span>
        </div>

        <div class="flight-description-airport"><span class="flight-airport">{{
            flight.slices[sliceIndex].segments[0].originAirport }}</span></div>
        <div class="divider">

          <ng-container *ngIf="(flight.slices[sliceIndex].segments.length - 1) > 0">
            {{ flight.slices[sliceIndex].segments.length - 1 }} parada(s)
          </ng-container>

          <ng-container *ngIf="(flight.slices[sliceIndex].segments.length - 1) === 0">
            Voo direto
          </ng-container>

        </div>

        <div class="flight-description-airport destination">
          <span class="flight-airport">{{
            flight.slices[sliceIndex].segments[flight.slices[sliceIndex].segments.length - 1].destinationAirport }}
          </span>
        </div>

        <div class="flight-duration">
          Duração: {{ flight?.slices[sliceIndex]?.flightDuration | flightDuration }}
        </div>

        <div class="flight-baggage">
          <app-luggage-icons [baggageAllowance]="flight.slices[sliceIndex].baseFares[0].baggageAllowance"
            [sliceIndex]="sliceIndex"></app-luggage-icons>
        </div>

        <label class="flight-details" [for]="groupIndex+flight.slices[sliceIndex].direction+flightIndex+'m'">
          <small>Detalhes <i class="fas fa-angle-down"></i></small>
        </label>

      </div>

    </label>

  </div>

  <input type="checkbox" [id]="groupIndex+flight.slices[sliceIndex].direction+flightIndex+'m'">
  <ng-container [ngTemplateOutlet]="sliceDetails" [ngTemplateOutletContext]="{flight: flight}"></ng-container>
</div>

<ng-template #sliceDetails let-flight="flight">
  <div class="slice-details-container">
    <div class="slice-details" data-zt="sliceDetails">
      <article class="route" data-zt="route"
        *ngFor="let segment of flight.slices[sliceIndex].segments; let segIndex = index">
        <h5 class="title">
          <span class="issuing-airline">
            <img class="airline-logo" ngClass.xs="airline-logo-mobile"
              [src]="getAirlineInfo(flight.slices[sliceIndex]).logoUrl" alt="">
            {{getAirlineInfo(flight.slices[sliceIndex]).name}} ({{segment.aircraft}}) Voo:
            <b>{{segment.flightNumber}}</b> - {{segment.departureDate | date: 'E dd/MMM/yy' | titlecase}}
          </span>
          <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="flightOperatedBy"
            [ngTemplateOutletContext]="{flight: flight, segment: segment}"></ng-container>
        </h5>
        <aside class="duration">
          <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="flightDuration"
            [ngTemplateOutletContext]="{$implicit: segment}"></ng-container>
          <div class="vertical-line">
            <i class="fas fa-circle"></i>
            <span></span>
            <i class="fas fa-circle"></i>
          </div>
        </aside>
        <div class="flight-info">
          <p class="departure-date" data-zt="detailedDepartureTime">{{segment.departureDate | date:'HH:mm'}}</p>
          <p class="airport-name" *ngIf="meta(flight).airports[segment.originAirport]">
            <b>{{segment?.originAirport}}</b> -
            {{meta(flight)?.airports?.[segment.originAirport]?.name}},
            {{meta(flight)?.airports?.[segment.originAirport]?.city}} -
            {{meta(flight)?.airports?.[segment.originAirport]?.country}}
          </p>
          <ng-container *ngIf="isMobile" [ngTemplateOutlet]="flightDuration"
            [ngTemplateOutletContext]="{$implicit: segment}"></ng-container>
          <ng-container *ngIf="isMobile" [ngTemplateOutlet]="flightOperatedBy"
            [ngTemplateOutletContext]="{flight: flight, segment: segment}"></ng-container>
          <p class="family"><small>Família: </small> {{flight.slices[sliceIndex].baseFares[0].family}}</p>
          <p class="cabin"><small>Classe: </small> {{segment.cabin}}</p>
          <p class="arrival-date" data-zt="detailedArrivalTime">{{segment.arrivalDate | date:'HH:mm'}}</p>
          <p class="airport-name" *ngIf="meta(flight).airports[segment.originAirport]">
            <b>{{segment?.destinationAirport}}</b> -
            {{meta(flight)?.airports?.[segment.destinationAirport]?.name}},
            {{meta(flight)?.airports?.[segment.destinationAirport]?.city}} -
            {{meta(flight)?.airports?.[segment.destinationAirport]?.country}}
          </p>
          <p class="luggage"><i class="fas fa-suitcase-rolling"></i><span
              [class]="flight.slices[sliceIndex].baseFares[0].baggageAllowance ? 'allowed' : 'not-allowed'">{{flight.slices[sliceIndex].baseFares[0].baggageAllowance
              ? 'Com' : 'Sem'}}</span> franquia de bagagem</p>
        </div>
        <article class="transfer" *ngIf="(segIndex < flight.slices[sliceIndex].segments.length - 1) && (segment.isScale !== null || segment.isConnection !== null)">
          <div class="wrapper" gdAlignColumns.lt-md="1fr">
            <span><i class="fas fa-clock"></i>Tempo de espera
              {{getTransferDuration(segment, flight.slices[sliceIndex].segments[segIndex+1])}}</span>
            <span>»</span>
            <ng-container *ngIf="!segment.isConnection" [ngTemplateOutlet]="switchAirplaneText" [ngTemplateOutletContext]="{$implicit: 'Sem troca de aeronave'}"></ng-container>
            <ng-container *ngIf="segment.isConnection" [ngTemplateOutlet]="switchAirplaneText" [ngTemplateOutletContext]="{$implicit: 'Troca de aeronave'}"></ng-container>
            <ng-template #switchAirplaneText let-text>
              <span><i class="fas fa-plane"></i>{{ text }}</span>
            </ng-template>
          </div>
        </article>
      </article>
      <article class="anac-text">
        O Zupper se preocupa com a transparência na comunicação com seus clientes e
        cumprindo os termos da Resolução ANAC 218 disponibiliza acesso aos relatórios
        divulgados diretamente pela Agência Nacional de Aviação Civil (ANAC)
        a respeito de cancelamentos de voos no Brasil através do
        <a href="https://www.gov.br/anac/pt-br" target="_blank">link</a>.
      </article>
    </div>
  </div>
  <ng-template #flightOperatedBy let-segment="segment" let-flight="flight">
    <span *ngIf="segment.carrierAirline !== flight.slices[sliceIndex].issuingAirline" class="carrier-airline">
      Operado por:
      <img class="codeshare-logo" ngClass.xs="codeshare-logo-mobile" [src]="getAirlineLogo(segment.carrierAirline)"
        alt="">
    </span>
  </ng-template>
  <ng-template #flightDuration let-segment>
    <ng-container *ngIf="!isMobile">
      <small>
        <ng-container [ngTemplateOutlet]="durationLabel"></ng-container>
      </small>
    </ng-container>
    <p class="duration-text" ngClass.xs="duration-text-mobile">
      <ng-container *ngIf="isMobile"
        [ngTemplateOutlet]="durationLabel"></ng-container>{{ segment?.flightDuration | flightDuration }}
    </p>
    <ng-template #durationLabel>
      Duração do voo<ng-container *ngIf="isMobile">&nbsp;</ng-container>
    </ng-template>
  </ng-template>
</ng-template>
