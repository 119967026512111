import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AirportInfoModel, BaggageModel, FilterAirlinesModel, FilterDuration, FilterOptions, FilterTime } from '../../../models/api-filter.model';
import { ResultFiltersParams } from '../../../models/result-params.model';
import { ResultFilterComponent } from '../result-filter/result-filter.component';

@Component({
  selector: 'app-modal-mobile',
  templateUrl: './modal-mobile.component.html',
  styleUrls: ['./modal-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalMobileComponent implements OnInit {
  @ViewChild('templateBottomSheet') TemplateBottomSheet: TemplateRef<any>;
  @Input() initialValues: ResultFiltersParams;
  @Input() filtersMobile: FilterOptions;
  @Input() orderedStopQuantities: Array<number> = [];
  @Input() availableStopQuantities: Array<number> = [];
  @Input() availableAirlines: Array<FilterAirlinesModel> = [];
  @Input() availableAirports: Array<AirportInfoModel> = [];
  @Input() minimalPrice: number = 0;
  @Input() maximumPrice: number = 0;
  @Input() timeDeparture: Array<FilterTime> = [];
  @Input() timeArrival: Array<FilterTime> = [];
  @Input() filterDuration: Array<FilterDuration> = [];
  @Input() isMultiCias: boolean;
  @Output() sendFilters = new EventEmitter<FilterOptions>();
  @Output() clearFilters = new EventEmitter();
  
  public isCollapsed = true;
  public isCollapsedCia = true;
  public isCollapsedBaggage = true;
  public isCollapsedAirport = true;
  public isCollapsedPrices = true;
  public isCollapsedDeparture = true;
  public isCollapsedArrival = true;
  public isCollapsedDuration = true;
  public clearAllFilters: boolean = false

  public currentFilters: ResultFiltersParams;
  public filterOptionsMobile: FilterOptions = new FilterOptions();
  public filters: FilterOptions = new FilterOptions();
  public selectedMinimalPrice: number = 0;
  public selectedMaximumPrice: number = 0;

  selectedStopFilters = [];
  selectedAirlinesFilters = [];
  selectedAirportsFilters = [];
  selectedBaggagesFilters = [];
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal
  ) { this.setupInitialValues(); }

  ngOnInit(): void {
    for (let i = 0; i < this.filtersMobile.stopQuantities.length; i++) {
      this.selectedStopFilters.push(!!this.filtersMobile.stopQuantities[i]);
    }
    if (!this.selectedStopFilters || !this.selectedStopFilters.length) {
      this.selectedStopFilters = new Array<boolean>(this.orderedStopQuantities.length).fill(false);
    } else {
      this.filterOptionsMobile.stopQuantities = this.selectedStopFilters.map((value, index) => {
        return value ? index : null;
      }).filter(v => v !== null);
    }
    for (let i = 0; i < this.filtersMobile.airlines.length; i++) {
      this.selectedAirlinesFilters.push(!!this.filtersMobile.airlines[i]);
    }

    if (!this.selectedAirlinesFilters || !this.selectedAirlinesFilters.length) {
      this.selectedAirlinesFilters = new Array<boolean>(this.availableAirlines.length).fill(false);
    } else {
      this.filterOptionsMobile.airlines = this.selectedAirlinesFilters.map((value, index) => {
        return value ? this.availableAirlines[index].iataCode : null;
      }).filter(v => v !== null);
    }
    for (let i = 0; i < this.filtersMobile.airports.length; i++) {
      this.selectedAirportsFilters.push(!!this.filtersMobile.airports[i]);
    }

    if (!this.selectedAirportsFilters || !this.selectedAirportsFilters.length) {
      this.selectedAirportsFilters = new Array<boolean>(this.availableAirports.length).fill(false);
    } else {
      this.filterOptionsMobile.airports = this.selectedAirportsFilters.map((value, index) => {
        if (value == true) {
          return this.availableAirports[index].IATACode;
        }
        return null;
      }).filter(v => v !== null) as string[];
    }


    if (!this.filtersMobile.baggage) {
      this.selectedBaggagesFilters = [false, false]
      let baggageModel = new BaggageModel();
      this.filters.baggage = baggageModel;
      this.filterOptionsMobile.baggage = baggageModel;
    } else {
      this.selectedBaggagesFilters = [];
      Object.values(this.filtersMobile.baggage).forEach((item) => {
        if (item) {
          this.selectedBaggagesFilters.push(item);
        }
      })
      let baggageModel = new BaggageModel(
        this.filtersMobile.baggage[0],
        this.filtersMobile.baggage[1]
      );
      this.filterOptionsMobile.baggage = baggageModel;
    }

    if (this.selectedMinimalPrice == 0 && this.selectedMaximumPrice == 0) {
      this.filterOptionsMobile.minimalPrice = this.filtersMobile.minimalPrice;
      this.filterOptionsMobile.maximumPrice = this.filtersMobile.maximumPrice;
    }

    if (this.timeDeparture.length != 0) {
      this.filterOptionsMobile.timeDeparture = this.timeDeparture;

    }

    if (this.timeArrival.length != 0) {
      this.filterOptionsMobile.timeArrival = this.timeArrival;

    }

    if (this.filterDuration.length != 0) {
      this.filterOptionsMobile.filterDuration = this.filterDuration;

    }
  }

  setupInitialValues() {
    if (!!this.initialValues) {
      this.currentFilters = this.initialValues;
      if (this.initialValues.stops.length > 0) {
        this.filterOptionsMobile.stopQuantities = this.initialValues.stops;
      }

      if (this.initialValues.airlines.length > 0) {
        this.filterOptionsMobile.airlines = this.initialValues.airlines;
      }

      if (this.initialValues.airports.length > 0) {
        this.filterOptionsMobile.airports = this.initialValues.airports;
      }
      if (!!this.initialValues.baggage) {
        this.filterOptionsMobile.baggage = new BaggageModel(
          this.initialValues.baggage.free,
          this.initialValues.baggage.paid
        );
      }

      if (this.initialValues.minPrice > 0) {
        this.filterOptionsMobile.minimalPrice = this.initialValues.minPrice;
      }

      if (this.initialValues.maxPrice > 0) {
        this.filterOptionsMobile.maximumPrice = this.initialValues.maxPrice;
      }

      if (this.initialValues.departureTime.length > 0) {
        this.filterOptionsMobile.timeDeparture = this.timeDeparture;
      }

      if (this.initialValues.durationTime.length > 0) {
        this.filterOptionsMobile.filterDuration = this.filterDuration;
      }

      if (this.initialValues.arrivalTime.length > 0) {
        this.filterOptionsMobile.timeArrival = this.timeArrival;
      }
    } else {
      this.currentFilters = new ResultFiltersParams();
    }
  }

  initValueFromResultFilterComponent(component: ResultFilterComponent) {
    this.initialValues = component.initialValues;
    this.orderedStopQuantities = component.orderedStopQuantities;
    this.availableStopQuantities = component.availableStopQuantities;
    this.availableAirlines = component.availableAirlines;
    this.availableAirports = component.availableAirports;
    this.filtersMobile = component.filtersMobile
    this.minimalPrice = component.minimalPrice;
    this.maximumPrice = component.maximumPrice;
    this.timeArrival = component.timeArrival;
    this.timeDeparture = component.timeDeparture;
    this.filterDuration = component.filterDuration;
    this.isMultiCias = component.isMultiCias;
  }

  clear() {
    this.clearAllFilters = true;
    this.filterOptionsMobile.stopQuantities = [];
    this.filterOptionsMobile.airlines = [];
    this.filterOptionsMobile.airports = [];
    this.filterOptionsMobile.baggage = [];
    this.selectedBaggagesFilters = [false, false];
    this.clearFilters.emit('clear');
  }

  sendFilterOption(filterOptionsStopQuantities) {
    this.clearAllFilters = false;
    this.selectedStopFilters = filterOptionsStopQuantities;
    this.filters.stopQuantities = filterOptionsStopQuantities;
    this.filterOptionsMobile.stopQuantities = filterOptionsStopQuantities.map((value, index) => {
      if (value == true) {
        return index;
      }
      return false;
    }).filter(v => v !== false);
  }

  sendFilterOptionCias(filterOptionsAirlines) {
    this.clearAllFilters = false;
    this.selectedAirlinesFilters = filterOptionsAirlines;
    this.filters.airlines = filterOptionsAirlines;
    this.filterOptionsMobile.airlines = filterOptionsAirlines.map((value, index) => {
      if (value == true) {
        return this.availableAirlines[index].iataCode;
      }
      return false;
    }).filter(v => v !== false);

  }

  sendFilterOptionAirport(filterOptionsAirports) {
    this.clearAllFilters = false;
    this.selectedAirportsFilters = filterOptionsAirports;
    this.filters.airports = filterOptionsAirports;
    this.filterOptionsMobile.airports = filterOptionsAirports.map((value, index) => {
      if (value == true) {
        return this.availableAirports[index].IATACode;
      }
      return false;
    }).filter(v => v !== false);

  }

  sendFilterOptionBaggage(filterOptionsBaggages) {
    this.clearAllFilters = false;
    this.selectedBaggagesFilters = filterOptionsBaggages;
    this.filters.baggage = filterOptionsBaggages;

    let baggageModel = new BaggageModel(
      this.filters.baggage[0],
      this.filters.baggage[1]
    );

    this.filterOptionsMobile.baggage = baggageModel;
  }

  sendFilterOpitionPrice(filterOptionsPrices) {
    this.clearAllFilters = false;
    this.filters.minimalPrice = filterOptionsPrices.minimalPrice;
    this.filters.maximumPrice = filterOptionsPrices.maximumPrice;
    this.filterOptionsMobile.minimalPrice = filterOptionsPrices.minimalPrice;
    this.filterOptionsMobile.maximumPrice = filterOptionsPrices.maximumPrice;
  }

  sendFilterOpitionDeparture(filterOptionsDeparture) {
    this.clearAllFilters = false;
    this.filters.timeDeparture = filterOptionsDeparture;
    this.filterOptionsMobile.timeDeparture = filterOptionsDeparture;
  }

  sendFilterOpitionArrival(filterOptionArrival) {
    this.clearAllFilters = false;
    this.filters.timeArrival = filterOptionArrival;
    this.filterOptionsMobile.timeArrival = filterOptionArrival;
  }

  sendFilterOpitionDuration(filterOpitionDuration) {
    this.clearAllFilters = false;
    this.filters.filterDuration = filterOpitionDuration;
    this.filterOptionsMobile.filterDuration = filterOpitionDuration;
  }

  applyFilter() {
    if (!this.filterOptionsMobile.baggage) {      
      let baggageModel = new BaggageModel();
      this.filterOptionsMobile.baggage = baggageModel;
    }
    this.sendFilters.emit(this.filterOptionsMobile);
    this.activeModal.close();
  }
}
