import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AirportModel } from '@zupper/data';


@Injectable({
    providedIn: 'root'
})
export class AirportService {
    private static _airports = new Set<AirportModel>();

    static push(item: AirportModel) {
        this._airports.add(item);
    }

    static getAll(): AirportModel[] {
        return Array.from(this._airports.values());
    }

    static getInfoFromIata(iata: string): AirportModel {
        const arr = Array.from(this._airports.values());
        return arr.find(a => a.airportCode === iata);
    }

    static getCityFromIata(iata: string): string {
        const arr = Array.from(this._airports.values());

        const found = _.find(arr, (item) => item.airportCode === iata);
        if (found) {
            return found.city;
        }

        return null;
    }
}
