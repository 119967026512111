<section class="filter-container" fxHide.lt-md="true">
  <div class="title-grid">
    <span class="title">
      <h3 class="section-title">Filtros</h3>
    </span>
  </div>
  <div class="placeholder-container" *ngIf="isLoading">
    <article class="filter-options">
      <div class="ph-items">
        <div class="ph-col-12">
          <div class="ph-row">
            <div class="ph-col-2 big"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-2 big"></div>
          </div>
        </div>
      </div>
    </article>
  </div>
  <form [formGroup]="filterForm" *ngIf="!isLoading">
    <article class="filter-options" data-zt="filterOptions">
      <div class="filter-option" data-zt="stopQuantities">
        <label data-zt="airlineFilter" class="filter-name" #stopsPopover="ngbPopover" [ngbPopover]="stopsPopup" placement="bottom"
          [autoClose]="'outside'">
          Paradas <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions.stopQuantities.length == 0}" class="filtered-data">
            (
            <ng-container *ngIf="filterOptions.stopQuantities.length > 0; else allStops">
              <ng-container *ngFor="let qty of filterOptions.stopQuantities">
                <span class="filtered-value" *ngIf="qty || qty === 0">{{qty == 0 ? 'Direto' : qty}}</span>
              </ng-container>
            </ng-container>
            <ng-template #allStops>Todas</ng-template>
            )
          </span>
        </label>
        <ng-template #stopsPopup>
          <div class="template-container" formArrayName="stopQuantities">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Paradas</span>
              <small (click)="clearStopsFilter(stopsPopover)"><i class="fa fa-times-circle"></i> Limpar filtro</small>
            </h5>
            <label class="container" *ngFor="let qty of orderedStopQuantities; let i = index"
              (change)="sendStopsFilterOptions(stopsPopover, $event)">
              <input type="checkbox" [formControlName]="i" [checked]="filterOptions.stopQuantities.indexOf(i) != -1">
              <span class="checkmark"></span>
              {{ qty == 0 ? 'Sem paradas' : qty + ' ' + (qty == 1 ? 'parada' : 'paradas') }}
            </label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option">
        <label data-zt="airlines" #ciasPopover="ngbPopover" [ngbPopover]="ciasPopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Cias aéreas <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions.airlines.length == 0}" class="filtered-data">
            (
            <ng-container *ngIf="filterOptions.airlines.length > 0; else allAirlines">
              <ng-container *ngFor="let airline of availableAirlines">
                <span class="filtered-value" *ngIf="filterOptions.airlines.indexOf(airline.iataCode) != -1">
                  {{airline.name}}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #allAirlines>Todas</ng-template>
            )
          </span>
        </label>
        <ng-template #ciasPopup>
          <div class="template-container" formArrayName="airlines">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Cias Aéreas</span>
              <small (click)="clearAirlinesFilter(ciasPopover)"><i class="fa fa-times-circle"></i> Limpar filtro</small>
            </h5>
            <label data-zt="airlinesDropdown" class="container" *ngFor="let airline of availableAirlines; let i = index"
              (change)="sendAirlinesFilterOptions(ciasPopover)">
              <input type="checkbox" [formControlName]="i"
                [checked]="filterOptions.airlines.indexOf(airline.iataCode) != -1">
              <span class="checkmark"></span>
                {{airline.name}}
            </label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="airports">
        <label #airportsPopover="ngbPopover" [ngbPopover]="airportsPopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Aeroportos <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions.airports.length == 0}" class="filtered-data">
            (
            <ng-container *ngIf="filterOptions.airports.length > 0; else allAirports">
              <ng-container *ngFor="let airport of availableAirports">
                <span class="filtered-value" *ngIf="filterOptions.airports.indexOf(airport.IATACode) != -1">
                  {{airport.IATACode}}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #allAirports>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #airportsPopup>
          <div class="template-container" formArrayName="airports">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Aeroportos</span>
              <small (click)="clearAirportsFilter(airportsPopover)"><i class="fa fa-times-circle"></i> Limpar
                filtro</small>
            </h5>
            <label class="container" *ngFor="let airport of availableAirports; let i = index"
              (change)="sendAirportsFilterOptions(airportsPopover)">
              <input type="checkbox" [formControlName]="i"
                [checked]="filterOptions.airports.indexOf(airport.IATACode) != -1">
              <span class="checkmark"></span>
              {{airport.IATACode}} - {{airport.name}}, {{airport.city}} - {{airport.country}}
            </label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="minimalPrice">
        <label #pricePopover="ngbPopover" [ngbPopover]="pricePopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Preços <i class="fas fa-angle-down"></i>

          <span [ngClass]="{'no-filter': filterOptions.minimalPrice == 0 && filterOptions.maximumPrice == 0 }"
            class="filtered-data price">
            (
            <ng-container *ngIf="
                      (filterOptions.minimalPrice > 0 && filterOptions.minimalPrice != minimalPrice) ||
                      (filterOptions.maximumPrice > 0 && filterOptions.maximumPrice != maximumPrice) ;else allPrices">
              <ng-container if="selectedMinimalPrice != minimalPrice">de {{filterOptions.minimalPrice | currency:
                "R$"}}</ng-container>
              <ng-container if="selectedMaximumPrice != maximumPrice"> até {{filterOptions.maximumPrice | currency:
                "R$"}}</ng-container>
            </ng-container>
            <ng-template #allPrices>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #pricePopup>
          <div class="template-container">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Preços</span>
              <small (click)="clearPriceFilter(pricePopover)"><i class="fa fa-times-circle"></i> Limpar filtro</small>
            </h5>
            <npn-slider (onChange)="onPriceFilterChange($event)" class="range-slider"
              [min]="(minimalPrice - 1).toFixed(0)" [minSelected]="filterOptions.minimalPrice"
              [max]="(maximumPrice + 1).toFixed(0)" [maxSelected]="filterOptions.maximumPrice"
              [step]="((maximumPrice - minimalPrice)/10).toFixed(0)" [showStepIndicator]="true"></npn-slider>
            <label class="apply-filter" (click)="sendPriceFilterOptions(pricePopover)">Aplicar Filtro</label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="timeDeparture">
        <label #departurePopover="ngbPopover" [ngbPopover]="departurePopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Horário de saída <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions.timeDeparture.length == 0}" class="filtered-data time-filter">
            (
            <ng-container *ngIf="filterOptions.timeDeparture.length > 0; else allDepartures">
              <ng-container *ngFor="let departureTime of filterOptions.timeDeparture">
                <span class="filtered-value">
                  {{departureTime.minimalTimeSelected}}:00 - {{departureTime.maximumTimeSelected == 24 ? '23:59':
                  departureTime.maximumTimeSelected + ':00'}}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #allDepartures>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #departurePopup>
          <div class="template-container">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Horário de saída</span>
              <small (click)="clearTimeDepartureFilter(departurePopover)"><i class="fa fa-times-circle"></i> Limpar
                filtro</small>
            </h5>

            <div *ngFor="let item of timeDeparture">

              <p class="popup-subtitle">
                Voo
                <span>{{ item.originAirport }} - {{ item.destinationAirport }}</span>
              </p>
              <npn-slider (onChange)="onTimeDepartureFilterChange($event, item)" hide-values
                class="range-slider range-slider-time" [min]="0" [minSelected]="item.minimalTimeSelected" [max]="24"
                [maxSelected]="item.maximumTimeSelected" [step]="1" [showStepIndicator]="true"></npn-slider>
              <div class="range-slider-values arrival-time-values">
                <span class="minimum">0:00</span>
                <span class="maximum">23:59</span>
              </div>
            </div>
            <label class="apply-filter" (click)="sendTimeDepartureFilterOptions(departurePopover)">Aplicar
              Filtro</label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="timeArrival">
        <label #arrivalPopover="ngbPopover" [ngbPopover]="arrivalPopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Horário de chegada <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions.timeArrival.length == 0}" class="filtered-data time-filter">
            (
            <ng-container *ngIf="filterOptions.timeArrival.length > 0; else allArrival">
              <ng-container *ngFor="let arrivalTime of filterOptions.timeArrival">
                <span class="filtered-value">
                  {{arrivalTime.minimalTimeSelected}}:00 - {{arrivalTime.maximumTimeSelected == 24 ? '23:59':
                  arrivalTime.maximumTimeSelected + ':00'}}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #allArrival>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #arrivalPopup>
          <div class="template-container">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Horário de chegada</span>
              <small (click)="clearTimeArrivalFilter(arrivalPopover)"><i class="fa fa-times-circle"></i> Limpar
                filtro</small>
            </h5>

            <div *ngFor="let item of timeArrival">
              <p class="popup-subtitle">
                Voo
                <span>{{ item.originAirport }} - {{ item.destinationAirport }}</span>
              </p>
              <npn-slider (onChange)="onTimeArrivalFilterChange($event, item)" hide-values
                class="range-slider range-slider-time" [min]="0" [minSelected]="item.minimalTimeSelected" [max]="24"
                [maxSelected]="item.maximumTimeSelected" [step]="1" [showStepIndicator]="true"></npn-slider>
              <div class="range-slider-values arrival-time-values">
                <span class="minimum">0:00</span>
                <span class="maximum">23:59</span>
              </div>
            </div>
            <label class="apply-filter" (click)="sendTimeArrivalFilterOptions(arrivalPopover)">Aplicar Filtro</label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="flightDuration">
        <label #durationPopover="ngbPopover" [ngbPopover]="durationPopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Duração do voo <i class="fas fa-angle-down"></i>

          <span [ngClass]="{'no-filter': filterOptions.filterDuration.length == 0}" class="filtered-data time-filter">
            (
            <ng-container *ngIf="filterOptions.filterDuration.length > 0; else allDuration">
              <ng-container *ngFor="let duration of filterOptions.filterDuration">
                <span class="filtered-value">
                  {{duration.minimalDurationSelected}}:00 - {{duration.maximumDurationSelected}}:00
                </span>
              </ng-container>
            </ng-container>
            <ng-template #allDuration>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #durationPopup>
          <div class="template-container">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Duração do voo </span>
              <small (click)="clearDurationFilter(durationPopover)"><i class="fa fa-times-circle"></i> Limpar
                filtro</small>
            </h5>
            <div *ngFor="let item of filterDuration">
              <p class="popup-subtitle">
                Voo
                <span>{{ item.originAirport }} - {{ item.destinationAirport }}</span>
              </p>
              <npn-slider (onChange)="onDurationFilterChange($event, item)" class="range-slider range-slider-time"
                [min]="item.minimalDuration" [minSelected]="item.minimalDurationSelected" [max]="item.maximumDuration"
                [maxSelected]="item.maximumDurationSelected" [step]="1" [showStepIndicator]="true"></npn-slider>
            </div>
            <label class="apply-filter" (click)="sendDurationFilterOptions(durationPopover)">Aplicar Filtro</label>
          </div>
        </ng-template>
      </div>

      <div class="filter-option" data-zt="baggage">
        <label #baggagePopover="ngbPopover" [ngbPopover]="baggagePopup" placement="bottom" [autoClose]="'outside'"
          class="filter-name">
          Bagagem <i class="fas fa-angle-down"></i>
          <span [ngClass]="{'no-filter': filterOptions?.baggage?.freeBaggage == filterOptions?.baggage.paidBaggage }"
            class="filtered-data">
            (
            <ng-container
              *ngIf="filterOptions?.baggage?.freeBaggage !== filterOptions?.baggage?.paidBaggage; else allBaggages">
              <span class="filtered-value">
                {{filterOptions.baggage.freeBaggage? 'Com' : 'Sem'}}
              </span>
            </ng-container>
            <ng-template #allBaggages>Todos</ng-template>
            )
          </span>
        </label>
        <ng-template #baggagePopup>
          <div class="template-container" formArrayName="baggages">
            <h5 class="popup-title">
              <span class="title"><i class="fas fa-angle-up"></i> Bagagens</span>
              <small (click)="clearBaggageFilter(baggagePopover)"><i class="fa fa-times-circle"></i> Limpar
                filtro</small>
            </h5>
            <label class="container" (change)="sendBaggageFilterOptions(baggagePopover)">
              <input type="checkbox" [formControlName]="0" [checked]="filterOptions.baggage.freeBaggage">
              <span class="checkmark"></span>
              Com Bagagem despachada
            </label>
            <label class="container" (change)="sendBaggageFilterOptions(baggagePopover)">
              <input type="checkbox" [formControlName]="1" [checked]="filterOptions.baggage.paidBaggage">
              <span class="checkmark"></span>
              Apenas bagagem de mão
            </label>
          </div>
        </ng-template>
      </div>
      <div class="divider">
      </div>
      <div data-zt="clearFilters">
        <span class="clear-filter">
          <p class="click-link" style="font-weight: bold; width: 120px;" (click)="clearFilters()"> Limpar filtros</p>
        </span>
      </div>
    </article>
  </form>

  <div class="options-container" *ngIf="!isLoading">
    <span class="span-text">Visualizar por: </span>
    <div class="dropdown">
      <span (click)="toggleDropdown()">
        {{ selectedOption }}
        <i class="fas fa-angle-down"></i>
      </span>
      <div *ngIf="isDropdownOpen" class="dropdown-content">
        <span
          class="text-option"
          *ngFor="let option of sortingOptions; let i = index"
          (click)="selectOption(option, i)"
        >
          {{ option }}
        </span>
      </div>
    </div>
  </div>
</section>
<!-- MODAL MOBILE  -->
<div fxHide.gt-sm="true" [ngClass]="{'btFloating': windowScrolled }">
  <article class="mobile-container">
    <button (click)="open()" class="button-filter" [ngClass]="{ 'button-filter-fl': windowScrolled }"
      [style.color]="!windowScrolled && !isMobileFiltersInitialState ? '#0079BE' : ''"
      [style.border-color]="!windowScrolled && !isMobileFiltersInitialState ? '#0079BE' : ''">
      <span class="material-symbols-outlined">filter_list</span>
      <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      {{isMobileFiltersInitialState ? 'Filtrar voos' : 'Alterar filtros'}}
    </button>
  </article>

  <div class="options-container" *ngIf="!isLoading">
    <span class="span-text">Visualizar por: </span>

    <div class="dropdown">
      <span (click)="toggleDropdown()">
        {{ selectedOption }}
        <i class="fas fa-angle-down"></i>
      </span>
      <div *ngIf="isDropdownOpen" class="dropdown-content">
        <span
          class="text-option"
          *ngFor="let option of sortingOptions; let i = index"
          (click)="selectOption(option, i)"
        >
          {{ option }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="flight-qty">
  <h6>{{numberFlights}} voos encontrados</h6>
</div>