import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'flightDuration',
})
export class FlightDurationPipe implements PipeTransform {
  transform(flightDuration: string): string {
    let duration = moment.duration(flightDuration);

    if (flightDuration.includes('D')) {
      duration = moment.duration(
        flightDuration.replace('PT', 'P').replace(/D(?!T)/, 'DT')
      );
    }

    const totalHours = duration.days() * 24 + duration.hours();
    return `${totalHours}h` + `${duration.minutes()}m`;
  }
}
