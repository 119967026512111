<section class="multi-slices-container">
    <article class="slices-container">
        <div class="slice-item"  data-zt="destinySlice" *ngFor="let slice of this.flightSearchFields.slices; let index = index" gdColumns="auto 1fr" gdColumns.midMd="1fr 1fr" gdColumns.lt-md="1fr">
            <span class="slice-identification" id="destino_{{ index + 1 }}" gdGridAlign="stretch end" gdGridAlign.midMd="start start" gdGridAlign.lt-md="stretch start">Trecho {{index+1}}</span>
            <div class="slice-fields" gdColumns="1.6fr 1.6fr 1.2fr .5fr" gdColumns.lt-md="1fr" gdColumns.midMd="1fr 1fr" >
                <app-find-airports
                  data-zt="flightSearchOrigin"
                  #elOriginAirport
                  [flightSearchFields]="this.flightSearchFields"
                  title="Origem"
                  [multiDestinationIndex]="index"
                  (chosenAirport)="setChosenAirport($event, index, 'origin')">
                </app-find-airports>
                <app-find-airports
                  data-zt="flightSearchDestination"
                  #elDestinationAirport
                  [flightSearchFields]="this.flightSearchFields"
                  [isOrigin]="false"
                  title="Destino"
                  [multiDestinationIndex]="index"
                  (chosenAirport)="setChosenAirport($event, index, 'destination')">
                </app-find-airports>
                <app-calendar
                  #elDepartureDate
                  data-zt="flightSearchDateSelection"
                  [multiDestinationIndex]="index"
                  [flightSearchFields]="this.flightSearchFields"
                  [calendarType]="this.calendarTypes.OneDate"
                  [minDate]="this.minDate"
                  (chosenDates)="setChosenDate($event, index)">
                </app-calendar>
                <a class="remove-slice" data-zt="removeSliceButton" *ngIf="this.flightSearchFields.slices.length > 1" (click)="removeSlice(index)">
                    <i class="fas fa-times"></i>
                    <p>Remover</p>
                </a>
            </div>
        </div>
    </article>
    <footer class="finish-slice-search" gdColumns="15rem 1fr 15rem 10rem" gdColumns.lt-md="1fr">
        <section>
            <button class="add-slice-button" data-zt="addSliceButton" (click)="newSlice()"><i class="fa fa-plus"></i> Adicionar destino</button>
            <div data-zt="flightSearchPassengerConfig">
                <app-passengers #elPassengers [(flightSearchFields)]="this.flightSearchFields"></app-passengers>
            </div>
        </section>
        <section></section>
        <div></div>
        <section>
            <div class="send-slices"><button data-zt="flightSearchSubmit" type="submit" (click)="onSubmit()">Buscar</button></div>
        </section>
    </footer>
</section>
