import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { ApiCorporateModel } from '@zupper/data';
import { LocalStorageService } from '../../storage/local-storage.service';

@Component({
  selector: 'zupper-logo',
  templateUrl: './zupper-logo.component.html',
  styleUrls: ['./zupper-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZupperLogoComponent implements OnInit {
  @Input() isCheckout = false;
  @Input() hasWhiteLabel = false;
  @Input() urlLink = '';

  logoUrl: string;

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.setLogo();
  }

  setLogo(): void {
    this.logoUrl = this.isCheckout
      ? (window.innerWidth < 960 ? 'assets/img/zupper-logo-brazilian-way.svg' : 'assets/img/zupper-logo-brazilian-way-checkout.png')
      : 'assets/img/zupper-logo-brazilian-way.svg';
    this._fetchLogo();
  }

  private _fetchLogo(): void {
    const whitelabel =
      this.localStorageService.getItem<ApiCorporateModel>('whitelabel');
    if (!!whitelabel) {
      this.logoUrl = whitelabel.path + '/' + whitelabel.logoUrl;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setLogo();
  }
}
