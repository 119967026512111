<ng-container *ngFor="let flight of flights; let flightIndex = index">
  <label class="flight-option-multi"
  data-zt="selectedFlight"
  (click)="sendFlightSlice(flight)"
  [ngClass]="flight.slices[0].direction"
  gdColumns="0.3fr 0.4fr repeat(3, 0.4fr) 0.4fr 0.7fr 0.3fr 0.5fr"
  gdColumns.lt-md="32px repeat(4, auto)" gdInline.lt-md
  gdRows.lt-md="auto auto"
  gdAreas.lt-md="selectionRadio airCiaLogo . . luggage | . departure arrival price detail"
  [ngClass.lt-md]="flight.slices[0].direction+' smaller'"
>
    <input gdGridAlign="center center" data-zt="flightRadio" type="radio" [checked]="currentFlightId == flight.flightId" [name]="flightIdentification+flight.slices[0].direction">
    <img gdArea.lt-md="airCiaLogo"
      gdGridAlign.lt-md="start center"
      data-zt="airlineLogo"
      class="airline-logo"
      [src]="getAirlineInfo(flight.slices[0]).logoUrl"
      alt="">
    <div class="departure-wrapper" gdArea.lt-md="departure"
        gdGridAlign.lt-md="start center"
        ngClass.lt-md="mr-2">
        <small fxHide.gt-sm="true">Saída</small>
        <p data-zt="departureTime">{{flight.slices[0].departureDate | date:'HH:mm'}}</p>
    </div>
    <div class="arrival-wrapper" gdArea.lt-md="arrival"
        gdGridAlign.lt-md="start center"
        ngClass.lt-md="mr-2">
        <small fxHide.gt-sm="true">Chegada</small>
        <p data-zt="arrivalTime">
          {{flight.slices[0].arrivalDate  | date:'HH:mm'}}
          <app-next-day-trip [slice]="flight.slices[0]"></app-next-day-trip>
        </p>
    </div>
    <div class="duration-wrapper" fxHide.lt-md="true">
        <p data-zt="departureTime">{{this.getSliceDuration(flight.slices[0])}}</p>
    </div>
    <div class="stops-wrapper" fxHide.lt-md="true">
        <p data-zt="stopsCount" class="stops">{{this.getStopsCount(flight.slices[0])}}</p>
    </div>
    <div class="price-wrapper" gdArea.lt-md="price"
        gdGridAlign.lt-md="start center">
        <small fxHide.gt-sm="true"
        ngClass.lt-md="mr-2">Preço</small>
        <p data-zt="totalPrice" class="price">R$ {{flight.totalPrice|number:'1.2-2'}}</p>
    </div>
    <div gdArea.lt-md="luggage"
        gdGridAlign.lt-md="end center">
        <app-luggage-icons
          [baggageAllowance]="flight.slices[0].baseFares[0].baggageAllowance"
          [sliceIndex]="0"
          [displayMobileIcons]="isMobile"
        ></app-luggage-icons>
    </div>
    <label class="flight-details"
        [for]="flightIdentification+flight.slices[0].direction+flightIndex"
        (click)="toggleSliceDetails(flightIndex, $event)"
        gdGridAlign.lt-md="end end"
        gdArea.lt-md="detail"
        data-zt="toggleDetails">
        <small>Detalhes
          <ng-container *ngIf="isMobile; else notMobile">
              <img src="assets/img/custom-svg-icons/arrow-down.svg">
          </ng-container>
          <ng-template #notMobile>
              <i class="fas fa-angle-down"></i>
          </ng-template>
      </small>
    </label>
</label>
<input type="checkbox" class="multi" [id]="flightIdentification+flight.slices[0].direction+flightIndex">
<div class="slice-details-container multi" data-zt="sliceDetailsContainer" *ngIf="expandDetails.includes(flightIndex)">
    <div class="slice-details" data-zt="sliceDetails">
        <article class="route" data-zt="route" *ngFor="let segment of flight.slices[0].segments; let segIndex = index">
            <h5 class="title">
                <img class="airline-logo" [src]="getAirlineInfo(flight.slices[0]).logoUrl" alt=""> {{getAirlineInfo(flight.slices[0]).name}} ({{segment.aircraft}}) Voo: <b>{{segment.flightNumber}}</b> - {{segment.departureDate | date: 'E dd/MMM/yy' |
                titlecase}}
            </h5>
            <aside class="duration">
                <small>Duração do voo</small>
                <p class="duration-text">{{this.getSliceDuration(segment)}}</p>
                <div class="vertical-line">
                    <i class="fas fa-circle"></i>
                    <span></span>
                    <i class="fas fa-circle"></i>
                </div>
            </aside>
            <div class="flight-info">
                <p class="departure-date" data-zt="detailedDepartureTime">{{segment.departureDate | date:'HH:mm'}}</p>
                <p class="airport-name"><b>{{segment.originAirport}}</b> - {{
                  this.filterMeta[this.flightIdentification][flight.slices[0].source].airports[segment.originAirport] != undefined ?
                  this.filterMeta[this.flightIdentification][flight.slices[0].source].airports[segment.originAirport].name : "Aeroporto de Saída..."
                  }}
                </p>
                <p class="family"><small>Família: </small> {{flight.slices[0].baseFares[0].family}}</p>
                <p class="cabin"><small>Classe: </small> {{segment.cabin}}</p>
                <p class="arrival-date" data-zt="detailedArrivalTime">{{segment.arrivalDate | date:'HH:mm'}}</p>
                <p class="airport-name"><b>{{segment.destinationAirport}}</b> - {{
                  this.filterMeta[this.flightIdentification][flight.slices[0].source].airports[segment.destinationAirport] != undefined ?
                  this.filterMeta[this.flightIdentification][flight.slices[0].source].airports[segment.destinationAirport].name : "Aeroporto de Chegada..."
                  }}
                </p>
                <p class="luggage"><i class="fas fa-suitcase-rolling"></i> <span [class]="flight.slices[0].baseFares[0].baggageAllowance ? 'allowed' : 'not-allowed'">{{flight.slices[0].baseFares[0].baggageAllowance ? 'Com' : 'Sem'}}</span> franquia de bagagem</p>
            </div>
            <article class="transfer" *ngIf="segIndex < flight.slices[0].segments.length-1">
                <div class="wrapper">
                    <span><i class="fas fa-clock"></i>Tempo de espera
                            {{getTransferDuration(segment, flight.slices[0].segments[segIndex+1])}}</span>
                    <span>»</span>
                    <span><i class="fas fa-plane"></i>Troca de aeronave</span>
                </div>
            </article>
        </article>
        <article class="anac-text">
            O Zupper se preocupa com a transparência na comunicação com seus clientes e
            cumprindo os termos da Resolução ANAC 218 disponibiliza acesso aos relatórios
            divulgados diretamente pela Agência Nacional de Aviação Civil (ANAC)
            a respeito de cancelamentos de voos no Brasil através do
            <a href="https://www.gov.br/anac/pt-br" target="_blank">link</a>.
          </article>
    </div>
</div>
</ng-container>

