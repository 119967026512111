import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCorporateModel } from '@zupper/data';
import { Subscription } from 'rxjs';
import { ConstantsService } from '../../constants.service';
import { TagModels, WhatsappTag } from '../../models/configuration';
import { ZupperProduct, ZupperProductModel } from '../../models/zupper-product.enum';
import { LocalStorageService } from '../../storage/local-storage.service';

@Component({
  selector: 'app-header-product',
  templateUrl: './app-header-product.component.html',
  styleUrls: ['./app-header-product.component.scss'],
})
export class AppHeaderProductComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchSummaryTpl: TemplateRef<any>;
  // tslint:disable-next-line: no-input-rename
  @Input('product') selectedProduct: ZupperProduct;
  @Input() tagModels: TagModels;

  whatsappTag: WhatsappTag;
  products: ZupperProductModel[];
  menuColor: string = '#008c99';
  headerStyles = {};
  zupperProduct = ZupperProduct;

  isMobile: boolean = false;
  protected _subscription = new Subscription();

  productImageUrls = {
    [ZupperProduct.AERIAL]: 'assets/img/custom-svg-icons/plane-white.svg',
    [ZupperProduct.HOTEL]: 'assets/img/custom-svg-icons/hotel-white.svg',
    [ZupperProduct.PACKAGES]: 'assets/img/custom-svg-icons/packages-white.svg',
  };

  productImageUrlsSelected = Object.fromEntries<any>([
    [ZupperProduct.AERIAL, 'assets/img/custom-svg-icons/plane-green.svg'],
    [ZupperProduct.HOTEL, 'assets/img/custom-svg-icons/hotel-green.svg'],
    [ZupperProduct.PACKAGES, 'assets/img/custom-svg-icons/packages-green.svg'],
  ]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private constantsService: ConstantsService,
    private localStorageService: LocalStorageService,
    private _mediaObserver: MediaObserver,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.products = this.constantsService
      .headerProducts(this.tagModels?.destinationGuideModel)
      .filter(product => product.visible)
      .sort((a, b) => (a.order > b.order ? 1 : 0));

    this.whatsappTag = this.tagModels?.whatsappTag;
    const whitelabel = this.localStorageService.getItem<ApiCorporateModel | any>('whitelabel', {});
    if (whitelabel.primaryColor) {
      this.menuColor = whitelabel.primaryColor;
    }
    this._subscription.add(
      this._mediaObserver.asObservable().subscribe((media) => {
        this.isMobile = ['sm', 'xs'].includes(media[0].mqAlias);
        this._cd.detectChanges();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tagModels.currentValue) {
      this.tagModels = changes.tagModels.currentValue;
      this.whatsappTag = this.tagModels.whatsappTag;
    }
  }

  navigate(product: ZupperProductModel) {
    this.router.navigateByUrl(product.url);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  isSelectedProduct(product: ZupperProductModel) {
    return this.selectedProduct === product.product;
  }
}
