import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, ViewEncapsulation, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ApiCustomer, ApiCustomerLoginRequest } from '@zupper/data';
import * as _ from 'lodash';
import { ProgressButtonComponent } from 'progress-button-zupper';
import { interval, merge, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { CustomerLoginService, LoginError } from '../../../../customer-login.service';
import { ACCOUNT_STEPS } from '../../../../models/account-steps.enum';
import { FormValidatorService } from '../../../../form-validator.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'zc-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EmailLoginComponent implements OnInit {
  @Output() onForgotPassword = new EventEmitter();
  @Output() onLoginSuccessful = new EventEmitter();
  @Output() onSendPasswordCreationLink = new EventEmitter<string>();

  @Input() currentStep: ACCOUNT_STEPS = ACCOUNT_STEPS.EMAIL;
  @Output() currentStepChange = new EventEmitter<ACCOUNT_STEPS>();

  public email: string;

  public loading = false;
  public validatingEmail = false;

  // recaptchaResolved = false;
  // public log: string[] = [];

  public showError = false;
  public showSSOErrorMessage = false;
  public errorMessage: string;

  public showPassword = false;
  public inputHasFocus = false;

  public emailValidationForm = this._fb.group({
    email: ['', Validators.compose([Validators.required, FormValidatorService.isValidEmail()])]
  });

  public passwordForm = this._fb.group({
    password: ['', Validators.compose([
      Validators.required
    ])]
  });

  steps = ACCOUNT_STEPS;

  emailFormSubmitSubject$ = new Subject();

  @ViewChildren(NgbTooltip) capsTooltips: QueryList<NgbTooltip>;

  constructor(
    private _customerLoginService: CustomerLoginService,
    private _fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit(): void {
    this.emailValidationForm.statusChanges.subscribe(() => this._changeDetectorRef.markForCheck());
    this.emailFormSubmitSubject$.pipe(
      tap(() => {
        this.emailValidationForm.markAsDirty();
        this.validatingEmail = true;
      }),
      debounceTime(1500),
      switchMap(() => this.emailValidationForm.statusChanges.pipe(
        startWith(this.emailValidationForm.status),
        filter(status => status !== 'PENDING'),
        take(1)
      )),
      tap(() => {
        this.validatingEmail = false;
        this._changeDetectorRef.detectChanges();
      }),
      filter(status => status === 'VALID')
    ).subscribe(validationSuccessful => this.submitEmailValidationForm());
  }

  goTo(step: ACCOUNT_STEPS): void {
    this.currentStepChange.emit(step);
    this._changeDetectorRef.detectChanges();
  }

  goToFromUI(event: MouseEvent, step: ACCOUNT_STEPS) {
    event.preventDefault();

    if(step === ACCOUNT_STEPS.SEND_PASSWORD_CREATION_LINK) {
      this.onSendPasswordCreationLink.emit(this.emailValidationForm.get('email').value);
    }

    this.goTo(step);
  }

  submitEmailValidationForm(): void {
    this.email = this.emailValidationForm.get('email').value;
    this.goTo(ACCOUNT_STEPS.PASSWORD);
    // this._customerService.validateEmail(this.emailValidationForm.get('email').value).subscribe(_isEmail => {
    //   if(_isEmail) {
    //   } else {
    //     this.validatingEmail = false;
    //   }
    // })
  }

  submitPasswordForm(buttonInstance: ProgressButtonComponent): void {
    if (this.passwordForm.valid) {
      this.showError = false;

      buttonInstance.progressInit();
      const progressStep = 4;
      const timer$ = interval(300).pipe(
        map(x => x * progressStep),
        take(100 / progressStep)
      );


      this.executeRecaptchaV3().subscribe((token) => {
        if (token) {

          const request = new ApiCustomerLoginRequest();
          request.email = this.email;
          request.password = this.passwordForm.get('password').value;
          request.recaptchaToken = token;

          const apiCall$ = this._customerLoginService.customerLogin(request);

          const mergedObservables$ = merge(timer$, apiCall$).subscribe({
            next: (response: number | ApiCustomer) => {
              if (_.isNumber(response)) {
                buttonInstance.progressValue = Number(response);
              } else if (response instanceof ApiCustomer) {
                const status = !!response ? 'success' : 'error';
                buttonInstance.progressValue = 100;
                const sub = buttonInstance.progressStop(status).subscribe({ complete: () => { sub.unsubscribe(); } });
                this.showError = false;

                mergedObservables$.unsubscribe();
                this.loading = false;
                this._changeDetectorRef.detectChanges();
                this.onLoginSuccessful.emit();
              }

            },
            error: (error: any) => {
              this.errorMessage = 'Houve um erro durante sua solicitação. Tente novamente mais tarde.'
              if (error instanceof LoginError) {
                this.showSSOErrorMessage = error.isSSOError;
                this.errorMessage = error.isLoginError && !error.isSSOError ? 'Ops!... Verifique o usuário e senha digitados.' : "";
              }
              buttonInstance.progressValue = 100;
              const sub = buttonInstance.progressStop('error').subscribe({
                complete: () => {
                  this.showError = true;
                  this.loading = false;
                  this._changeDetectorRef.detectChanges();
                  sub.unsubscribe();
                }
              });
            },
            complete: () => {
              this.loading = false;
              mergedObservables$.unsubscribe();
            }
          });
        } else {
          console.error('Token reCAPTCHA não foi obtido corretamente.');
        }
      });
    }
  }

  executeRecaptchaV3() {
    return this.recaptchaV3Service.execute('login');
  }

  caps(capsOn: boolean): void {
    if (this.capsTooltips.length > 0) {
      this.capsTooltips.first.tooltipClass = 'error-tooltip';

      if (capsOn) {
        this.capsTooltips.first.open();
      } else {
        this.capsTooltips.first.close();
      }
    }
  }

  forgotPassword(event: Event): void {
    event.preventDefault();
    this.onForgotPassword.emit();
  }

}
