import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class ReuseStrategy implements RouteReuseStrategy {
    private routeHandles: Map<string, DetachedRouteHandle> = new Map<string, DetachedRouteHandle>();

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const isSameComponent = future.component === current.component;

        const isSameParameters = this.areParametersEqual(future.params, current.params);

        return isSameComponent && isSameParameters;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.routeHandles.get(this.getRouteKey(route));
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        this.routeHandles.set(this.getRouteKey(route), handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.routeHandles.has(this.getRouteKey(route));
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    private getRouteKey(route: ActivatedRouteSnapshot): string {
        return `${route.routeConfig?.path}/${this.serializeParams(route.params)}`;
    }

    private areParametersEqual(params1: any, params2: any): boolean {
        return JSON.stringify(params1) === JSON.stringify(params2);
    }

    private serializeParams(params: any): string {
        return JSON.stringify(params);
    }
}