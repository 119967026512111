
import { FormControl, FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ValidationsService } from './validations.service';
import moment_ from 'moment';

const moment = moment_;

export class FormValidatorService {
  static validUsername(): ValidatorFn | null {
    const validator = (control: FormControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidUsername(value) ? { validUsername: {value} } : null;
    };

    return validator;
  }

  static emailMatchValidator(): ValidatorFn | null {
    const validator = (g: FormGroup): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const email = g.get('email').value;
      const confirmEmail = g.get('confirmEmail').value;

      return !validationService.isEqualsTo(email, confirmEmail) ? { validUsername: {email} } : null;
    };

    return validator;
  }

  static fieldMatch(compareTo: string): ValidatorFn | null {
    const validatorFn = (g: AbstractControl) => {
      const validationService = new ValidationsService();
      const value1 = g.value;
      const value2 = g.parent?.controls[compareTo].value;

      return !!g.parent && !!g.parent.value && !!validationService.isEqualsTo(value1, value2) ? null : { fieldMatch: true };
    };

    return validatorFn;
  }

  static isValidPhone(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validService = new ValidationsService();
      const value = control.value;

      return !validService.isValidPhone(value) ? { validPhone: { value } } : null;
    };

    return validatorFn;
  }

  static isValidEmail(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidEmail(value) ? { invalidEmail: true } : null;
    };


    return validatorFn;
  }

  static isValidCCDate(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidCCDate(value) ? { validCCDate: { value } } : null;
    };

    return validatorFn;
  }

  static isValidCCName(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidName(value, true) ? { validCCName: { value } } : null;
    };

    return validatorFn;
  }

  static isValidAddress(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidAddress(value) ? { validAddress: { value } } : null;
    };

    return validatorFn;
  }

  static isValidTextWithNumber(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidTextWithNumber(value) ? { validTextWithNumber: { value } } : null;
    };

    return validatorFn;
  }

  static isValidFirstName(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidName(value, true, 2) ? { validFirstName: { value } } : null;
    };

    return validatorFn;
  }

  static isValidLastName(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      if(validationService.hasAgnome(value)) {
        return { validLastName: { value } }
      }

      const firstNameValidation = FormValidatorService.isValidFirstName();
      return firstNameValidation(control);
    };

    return validatorFn;
  }

  static isValidCC(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const value = control.value;

      return !validationService.isValidCC(value) ? { validCC: { value } } : null;
    };

    return validatorFn;
  }

  static isValidCPF(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const str = control.value ? control.value.replace(/[^0-9]/gi, '') : '';

      return !(str && validationService.validCPF(str)) ? { validCPF: { value: str } } : null;
    };

    return validatorFn;
  }

  static isValidCNPJ(): ValidatorFn | null {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const validationService = new ValidationsService();
      const str = control.value ? control.value.replace(/[^0-9]/gi, '') : '';

      return !(str && validationService.isValidCNPJ(str)) ? { validCNPJ: { value: str } } : null;
    };

    return validatorFn;
  }

  static isDate(format: string = 'YYYY-MM-DD'): ValidatorFn | null {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const val = control.value;
      const mm = moment(val, format);
      if (mm.isValid()) {
        const year = mm.year();

        if (year <= 1900 ) {
          return { invalidYear: true };
        }
      } else {
        return { invalidDate: true };
      }
      return null;
    };

    return validator;
  }

  static isValidBirthday(type: 'ADT' | 'CHD' | 'INF', format: string = 'YYYY-MM-DD'): ValidatorFn | null {
    const validator = (g: AbstractControl) => {
      const val = g.value;
      const mm = moment(val, format);
      if (mm.isValid()) {
        const validationService = new ValidationsService();
        return !validationService.isValidBirthday(val, type, format) ? { isValidBirthday: false } : null;
      }
      return { invalidDate: true };
    };

    return validator;
  }

  static in(list: any[]): ValidatorFn | null {
    const validator = (g: AbstractControl) => {
      const val = g.value;
      return list.includes(val) ? null : { in: false };
    };

    return validator;
  }

  static isDuplicateCPF(): ValidatorFn | null {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;

      if (!formGroup) {
        return null;
      }

      const order = formGroup.value.order;
      const currentValue = control.value;

      if (order && currentValue && currentValue.length === 11) {
        const cpfArray: string[] = formGroup.value.cpfArray || [];
        cpfArray[order - 1] = currentValue;

        for (let i = 0; i < cpfArray.length; i++) {
          if (i !== order - 1 && cpfArray[i] === currentValue) {
            return { duplicateCPF: true };
          }
        }

        return null;
      }

      return null;
    };

    return validator;
  }

  static isCardholderNameValid(control: FormControl): { [key: string]: boolean } | null {
    const forbiddenNames = ['Mastercard', 'Master card', 'Visa', 'Elo', 'Hiper Card', 'Hipercard', 'American Express', 'Dinners Club'];
    const cardHolderName = control.value?.toLowerCase();

  if (cardHolderName) {
    const regex = new RegExp(`\\b(?:${forbiddenNames.join('|')})\\b`, 'i');
    if (cardHolderName.match(regex)) {
      return { forbiddenName: true };
    }
  }

  return null;
}

  static isValidMaxBirthday(maxDiffMonths: number, date: Date | string): ValidatorFn | null {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const startDate = moment(control.value, 'DD/MM/YYYY');
      const endDate = moment(date, 'DD/MM/YYYY');

      if (!startDate.isValid() || !endDate.isValid()) {
        return;
      }

      const diffMonths = Math.abs(endDate.diff(startDate, 'months'));
      if (diffMonths > maxDiffMonths) {
        return { invalidDateDifference: { maxDiffMonths } };
      }

      return null;
    };

    return validator;
  }

  static equalsTo(validatedControl: FormControl | FormGroup) {
    const otherControl = (<FormGroup>(validatedControl.root));
    let result = { 'equalsTo': true };
    if (<FormControl>otherControl.get('contactEmail')) {
      if (<FormControl>otherControl.get('contactEmail').value.toLocaleLowerCase() == validatedControl.value.toLocaleLowerCase()) {
        result = null;
      }
    }
    return result;
  }

  static uppercase(allUppercase = false): ValidatorFn | null {
    const validator = (fc: AbstractControl) => {
      const value = fc.value as string;
      if (!value || value.toLocaleLowerCase() === value) {
        return { uppercase: true };
      }

      return;
    };
    return validator;
  }

  static hasNumber(): ValidatorFn | null {
    const validator = (fc: AbstractControl) => {
      const value = fc.value as string;
      return /\d/.test(value) ? null : { hasNumber: true };
    }
    return validator;
  }
  
  static hasSpecialCharacter(): ValidatorFn | null {
    const validator = (fc: AbstractControl) => {
      const value = fc.value as string;
      const format = /[!@#$%^&*()_+\-=\[\]{};:\\<>\/?~]/;
      return format.test(value) ? null : { hasSpecialCharacter: true };
    }
    return validator;
  }
  
  static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    const validator =  (formGroup: FormGroup) => {
      if (!formGroup.controls[controlName] || !formGroup.controls[matchingControlName]) {
        return { mustMatch: true };
      }

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };

    return validator;
  }
}
