import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ViewEncapsulation, Inject, Input } from '@angular/core';

@Component({
  selector: 'zc-login-selection',
  templateUrl: './login-selection.component.html',
  styleUrls: ['./login-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LoginSelectionComponent implements OnInit {
  @Output() onEmailLogin = new EventEmitter<any>();
  @Output() onGoogleLogin = new EventEmitter<any>();
  @Output() onFacebookLogin = new EventEmitter<any>();
  @Input() isLoading: boolean = false;
  providerName: string = '';

  homeLink: string;

  constructor(@Inject('env') private env) { }

  ngOnInit(): void {
    this.homeLink = this.env['aerial']['url'];
  }


  emailLogin(event: Event): void {
    event.preventDefault();
    this.onEmailLogin.emit();
  }

  googleLogin(event: Event): void {
    this.providerName = "Google";
    event.preventDefault();
    this.onGoogleLogin.emit();
  }

  facebookLogin(event: Event): void {
    this.providerName = "Facebook";
    event.preventDefault();
    this.onFacebookLogin.emit();
  }
}
