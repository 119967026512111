import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultFilterComponent } from './result-filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ZupperCommonModule } from '@zupper/common';
import { Ng5SliderModule } from 'ng5-slider';
import { NpnSliderModule } from 'npn-slider';


@NgModule({
  declarations: [
    ResultFilterComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxUiLoaderModule,
    ZupperCommonModule,
    Ng5SliderModule,
    NpnSliderModule
  ],
  exports: [
    ResultFilterComponent
  ]
})
export class ResultFilterModule { }
