import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { AirportInfoModel, FilterOptions } from '../../../models/api-filter.model';

@Component({
  selector: 'app-airport-filter',
  templateUrl: './airport-filter.component.html',
  styleUrls: ['./airport-filter.component.scss']
})
export class AirportFilterComponent implements OnInit {
  @Input() availableAirports: Array<AirportInfoModel> = [];
  @Input() clearAllFilters: boolean;
  @Output() onFilterChangeAirport = new EventEmitter<any>();
  @Input() filterOptions: FilterOptions;
  @Input() filterOptionsMobile: FilterOptions;
  @Input() selectedAirportsFilters: boolean[] = [];

  formAirportFilter = this._fb.group({
    airport: this._fb.array([])
  })

  get airportFilterArray(): FormArray {
    return this.formAirportFilter.get('airport') as FormArray;
  }
  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.selectedAirportsFilters.forEach((selected, i) => {
      const isSelected = selected || false;
      this.airportFilterArray.push(new FormControl(isSelected));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearAllFilters && changes.clearAllFilters.currentValue) {
      const formArray = this.airportFilterArray;
      for (let i = 0; i < formArray.length; i++) {
        formArray.at(i).setValue(false);
      }
    }
  }

  sendFilterOptionAirport(airports, i) {
    const formArray = this.airportFilterArray;
    formArray.at(i).setValue(!formArray.at(i).value);
    this.onFilterChangeAirport.emit(formArray.value);
  }
}
