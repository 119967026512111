import { AfterViewInit, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AerialCheckoutBuilderService,
  AerialCheckoutData,
  AvailableFlightsRequest,
  AvailableFlightsResponse,
  SliceResponse,
} from '@zupper/aerial-components';
import { AppCheckerService, EnvironmentInterface } from '@zupper/common';
import { ApiFlightType } from '@zupper/data';
import { ApiFilterModel } from 'projects/aerial/src/app/models/api-filter.model';
import * as _ from 'lodash';

@Directive()
export abstract class AbstractAerialResultComponent implements OnInit, OnChanges {
  @Input() flightList: AvailableFlightsResponse[] = [];
  @Input() chosenSlices: SliceResponse[] = [];
  @Input() bestPrice: boolean = false;
  @Input() radioName: string;
  @Input() tripType: string;
  @Input() filterMeta: ApiFilterModel[] = [];
  @Input() showFamilies: boolean = false;
  @Input() chosenFlight: AvailableFlightsResponse = new AvailableFlightsResponse();
  @Input() isMobile: boolean = false;
  @Input() sliceIndex: number;
  @Output() selectedFamilyName: EventEmitter<string> = new EventEmitter();
  upsellPrice: number = 0;
  hasCardTax: boolean = false;
  maxAllowedInstallment: number = 1;

  flightSearch: AvailableFlightsRequest = new AvailableFlightsRequest();
  isInternational: boolean = true;
  paxTypes = { ADT: 'Adult', CHD: 'Child', INF: 'Infant' };

  abstract processInstallments: VoidFunction;

  public get isMultiSlice(): boolean {
    return this.tripType == this.flightTypes.MultiSlice;
  }

  constructor(
    protected appCheckerService: AppCheckerService,
    protected aerialCheckoutBuilder: AerialCheckoutBuilderService,
    protected env: EnvironmentInterface
  ) {}

  ngOnInit(): void {
    this.flightList = this.flightList.sort((a, b) => (a.totalPrice >= b.totalPrice ? 1 : -1));
    this.isInternational = JSON.parse(sessionStorage.getItem('isInternational'));
    this.flightSearch = JSON.parse(sessionStorage.getItem('flightSearch'));

    if (this.isInternational) {
      this.chosenSlices = this.chosenFlight?.slices;
    }

    if (this.chosenFlight?.ciasDiff) {
      this.setSelectedFamily(this.chosenFlight.slices[0]?.mainFamily);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.chosenFlight?.currentValue) {
      this.chosenFlight = changes?.chosenFlight?.currentValue;

      if (this.chosenFlight && this.tripType !== ApiFlightType.MultiSlice) {
        this.processInstallments();
      }
    }
  }

  setSelectedFamily(familyName: string) {
    this.selectedFamilyName.emit(familyName);
  }

  sendSlicesToCheckout() {
    let flight = _.cloneDeep<AvailableFlightsResponse>(this.chosenFlight);
    if (this.chosenSlices?.length) flight.slices = this.chosenSlices;

    const checkoutData: AerialCheckoutData = {
      meta: {
        request: {
          childrenQty: this.flightSearch.childrenQty,
          infantQty: this.flightSearch.infantQty,
          adultQty: this.flightSearch.adultQty,
        },
      },
      result: [flight],
    };
    sessionStorage.setItem('upsellPrice', JSON.stringify(this.upsellPrice));
    const cid = this.aerialCheckoutBuilder.saveCheckoutData(checkoutData);

    let openBrowser = this.appCheckerService.isApp() ? '_self' : '_blank';
    window.open(this.env.checkout.url + '?cid=' + cid, openBrowser);
  }

  setChosenFlight(flight: AvailableFlightsResponse) {
    this.chosenFlight = flight;
  }

  get getTaxes(): number {
    return (
      Number(this.chosenFlight?.totalDuPrice | 0) +
      Number(this.chosenFlight?.totalDu2Price | 0) +
      Number(this.chosenFlight?.totalAirportTaxesPrice | 0)
    );
  }

  get getAdultTotalPrice(): number {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result +=
        this.getTotalByAdult + slice.baseFares[0].du2Value + slice.baseFares[0].incentiveValue;
    });
    return result;
  }

  get getTotalByAdult(): number {
    let result: number = 0;
    this.chosenSlices.forEach((slice) => {
      result += slice.baseFares[0].fareValue + slice.baseFares[0].taxValue;
    });
    return result;
  }

  get totalTaxes(): number {
    return this.getTaxes;
  }

  get totalPrice(): number {
    return this.chosenFlight.totalPrice;
  }

  get flightTypes(): typeof ApiFlightType {
    return ApiFlightType;
  }

  get baseFareValue() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenSlices.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.baseFareValue;
        totalFare.All += basefare.baseFareValue;
      });
    });
    return totalFare;
  }

  get totalBaseFarePrice() {
    return (
      this.baseFareValue.Adult * this.flightSearch.adultQty +
      this.baseFareValue.Child * this.flightSearch.childrenQty +
      this.baseFareValue.Infant * this.flightSearch.infantQty
    );
  }

  get fareByPaxType() {
    let totalFare = { Adult: 0, Child: 0, Infant: 0, All: 0 };

    this.chosenFlight?.slices?.forEach((slice) => {
      slice.baseFares.forEach((basefare) => {
        totalFare[this.paxTypes[basefare.paxType]] += basefare.fareValue;
        totalFare.All += basefare.fareValue;
      });
    });
    return totalFare;
  }

  setUpsellPrice(upsellPrice: number) {
    this.upsellPrice = upsellPrice;
  }
}
