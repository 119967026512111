import { Injectable } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightFilterStateService {
  private _selectedFlightList$ = new Subject<Array<Array<string>>>();
  private _flightCounter$ = new Subject<number>();
  selectedFlightList: Array<Array<string>> = [];

  constructor() { }

  setSelectedFlight(flightList: AvailableFlightsResponse[][], slicesIndex: number[]): void {
    flightList.forEach((_group, indexGroup) => {
      if(!!_group && !!_group.find(_flight => !_flight.slices.find(_slice => !!_slice.skip))) {
        slicesIndex.forEach(indexSlice => {
          let sortedGroup = _group.sort((f1, f2) => {
            if (
              f1.slices[indexSlice].departureDate >
              f2.slices[indexSlice].departureDate
            ) {
              return 1;
            }
            return -1;
          });
          this.saveChosenFlight(
            _group.find(_flight => !_flight.slices.find(_slice => !!_slice.skip)), 
            indexGroup, 
            indexSlice
          );
        });
      }
    });
    this.setFlightCounter(flightList.filter(_x => !!_x));
  }

  setFlightCounter(flightList: AvailableFlightsResponse[][]): void {
    this._flightCounter$.next(flightList.length);
  }

  saveChosenFlight(
    flight: AvailableFlightsResponse,
    groupIndex: number,
    sliceIndex: number
  ) {
    if (!this.selectedFlightList[groupIndex]) {
      this.selectedFlightList[groupIndex] = [];
    }
    if (!this.selectedFlightList[groupIndex][sliceIndex]) {
      this.selectedFlightList[groupIndex][sliceIndex] = null;
    }
    this.selectedFlightList[groupIndex][sliceIndex] =
      flight.slices[sliceIndex].apiSliceId;
    
    this._selectedFlightList$.next(this.selectedFlightList);
  }

  get selectedFlightList$(): Observable<Array<Array<string>>> {
    return this._selectedFlightList$;
  }

  get flightCounter$(): Observable<number> {
    return this._flightCounter$;
  }
}
