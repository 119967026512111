import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-luggage-icons',
  templateUrl: './luggage-icons.component.html',
  styleUrls: ['./luggage-icons.component.scss']
})
export class LuggageIconsComponent implements OnInit {
  @Input() baggageAllowance: boolean = false;
  @Input() sliceIndex: number = 0;
  @Input() isMultiCias: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() displayMobileIcons: boolean = false;

  public get suitcaseGrayIcon(): string {
    return 'assets/img/custom-svg-icons/outlined-suitcase-gray.svg';
  }

  public get suitcaseBlueIcon(): string {
    return 'assets/img/custom-svg-icons/outlined-suitcase-blue.svg';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
