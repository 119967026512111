import { Injectable } from '@angular/core';
import { DataLayerCheckout } from './models/data-layer-checkout.enum';
import { PartialCheckoutDimensionsSet, FullCheckoutSet } from './models/full-custom-dimensions-set.model';

@Injectable({
  providedIn: 'root'
})
export class DataLayerCheckoutService {
  private _currentSet: FullCheckoutSet;
  private _constantDimensions = {};

  constructor() {
      this._generateEmptyDimensionsSet();
      // this._constantDimensions = [] // Get here dimensions that need to be sent at every push;
  }

  set dimensions(someDimensions: PartialCheckoutDimensionsSet) {
      Object.keys(DataLayerCheckout).map(key => DataLayerCheckout[key])
          .forEach(key => {
              this._currentSet[key] = someDimensions[key] || this._constantDimensions[key];
          });
  }

  trigger() {
      if (!(<any>window).dataLayer) {
          (<any>window).dataLayer = [];
      }

      (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
      this._currentSet = {
        [DataLayerCheckout.event]: undefined,
        [DataLayerCheckout.adultQuantity]: undefined,
        [DataLayerCheckout.childQuantity]: undefined,
        [DataLayerCheckout.infantQuantity]: undefined,
        [DataLayerCheckout.departureDate]: undefined,
        [DataLayerCheckout.returnDate]: undefined,
        [DataLayerCheckout.originAirport]: undefined,
        [DataLayerCheckout.originCountry]: undefined,
        [DataLayerCheckout.destinationAirport]: undefined,
        [DataLayerCheckout.destinationCountry]: undefined,
        //[DataLayerCheckout.tripType]: undefined,
        [DataLayerCheckout.tripType]: undefined,
        [DataLayerCheckout.airlineName]: undefined,
        //[DataLayerCheckout.TravelingDays]: undefined,
        [DataLayerCheckout.orderId]: undefined,
        [DataLayerCheckout.customerName]: undefined,
        [DataLayerCheckout.customerEmail]: undefined,
        [DataLayerCheckout.customerPhone]: undefined,
        [DataLayerCheckout.duValue]: undefined,
        [DataLayerCheckout.du2Value]: undefined,
        [DataLayerCheckout.totalAirportTaxes]: undefined,
        [DataLayerCheckout.totalWithTax]: undefined,
        [DataLayerCheckout.totalPrice]: undefined,
        [DataLayerCheckout.totalFarePrice]: undefined,
        [DataLayerCheckout.totalCreditCardFee]: undefined,
        [DataLayerCheckout.totalIncentiveValue]: undefined,
        [DataLayerCheckout.chosenFamilyName]: undefined,
        [DataLayerCheckout.upsellValue]: undefined,
      };
  }
}
