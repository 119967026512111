import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ZupperDataModule } from '@zupper/data';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ZcCalendarComponent } from './zc-calendar.component';



@NgModule({
  declarations: [ZcCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ZupperDataModule,
    NgbModule,
    FlexLayoutModule,
  ],
  exports: [ZcCalendarComponent]
})
export class ZcCalendarModule { }
