import moment from 'moment';
import * as _ from 'lodash';

export class AerialFiltersModel {
  durations: DurationFilter[];
  departureDates: DepartureTimeFilter[];
  minFare: number;
  maxFare: number;
  stopQuantities: number[];
  priceByAirport: AirportPriceFilter[];
  priceByStops: StopPriceFilter[];
  priceByAirline: AirlinePriceFilter[];
  luggageOptions: boolean[];

  constructor() {
    this.minFare = null;
    this.maxFare = null;
    this.durations = [];
    this.departureDates = [];
    this.priceByAirport = [];
    this.priceByStops = [];
    this.priceByAirline = [];
    this.stopQuantities = [];
    this.luggageOptions = [];
  }

  addMinFare(minFare: number) {
    if (this.minFare === null) {
      this.minFare = minFare;
    } else {
      this.minFare = minFare ? Math.min(this.minFare, minFare) : this.minFare;
    }
  }

  addMaxFare(maxFare: number) {
    if (this.maxFare === null) {
      this.maxFare = maxFare;
    } else {
      this.maxFare = maxFare ? Math.max(this.maxFare, maxFare) : this.maxFare;
    }
  }

  addAirport(airportPrice: AirportPriceFilter) {
    const currentIndex = this.priceByAirport.findIndex(
      p => p.airport === airportPrice.airport
    );
    if (currentIndex === -1) {
      this.priceByAirport.push(airportPrice);
    } else {
      this.priceByAirport[currentIndex].price = Math.min(
        airportPrice.price,
        this.priceByAirport[currentIndex].price
      );
    }
  }

  addStop(stopPrice: StopPriceFilter) {
    const currentIndex = this.priceByStops.findIndex(
      p => p.airline === stopPrice.airline
    );
    if (currentIndex === -1) {
      this.priceByStops.push(stopPrice);
    } else {
      stopPrice.prices.forEach(p =>
        this.priceByStops[currentIndex].addStopQty(p)
      );
    }

    this.sortAirlines();
  }

  addAirline(airlinePrice: AirlinePriceFilter) {
    const currentIndex = this.priceByAirline.findIndex(
      p => p.airline === airlinePrice.airline
    );
    if (currentIndex === -1) {
      this.priceByAirline.push(airlinePrice);
    } else {
      this.priceByAirline[currentIndex].price = Math.min(
        airlinePrice.price,
        this.priceByAirline[currentIndex].price
      );
    }
  }

  private sortAirlines() {
    this.priceByAirline.sort((a, b) =>
      this.getPriceFromAirline(a.airline) > this.getPriceFromAirline(b.airline)
        ? 1
        : -1
    );
  }

  addStopQty(qty: number) {
    const currentIndex = this.stopQuantities.findIndex(s => s === qty);
    if (currentIndex === -1) {
      this.stopQuantities.push(qty);
      this.stopQuantities.sort();
    }
  }

  addLuggageOption(option: boolean) {
    const currentIndex = this.luggageOptions.findIndex(s => s === option);
    if (currentIndex === -1) {
      this.luggageOptions.push(option);
    }
  }

  getAirlines() {
    return this.priceByAirline.map(p => p.airline);
  }

  getAirports() {
    return this.priceByAirport.map(p => p.airport);
  }

  getPriceFromAirlineAndStopQuantity(airline: string, stopQty: number) {
    const priceByStop = this.priceByStops.find(p => p.airline === airline);
    if (priceByStop) {
      return priceByStop.getPrice(stopQty);
    }

    return null;
  }

  getMinPriceFromStopQuantity(stopQty: number) {
    const prices = this.priceByStops
      .map(p => p.airline)
      .map(airline => {
        const result = this.getPriceFromAirlineAndStopQuantity(
          airline,
          stopQty
        );
        if (result) {
          return {
            airline: airline,
            price: this.getPriceFromAirlineAndStopQuantity(airline, stopQty)
              .price
          };
        }

        return null;
      });

    return _.minBy(
      prices.filter(p => p !== null),
      p => p['price']
    );
  }

  getPriceFromAirline(airline: string) {
    return this.priceByAirline.find(p => p.airline === airline).price;
  }

  getPriceFromAirport(airport: string) {
    return this.priceByAirport.find(p => p.airport === airport).price;
  }
}

export class DurationFilter {
  min: number;
  max: number;
  direction: string;

  constructor(min: number, max: number, direction: string) {
    this.min = min;
    this.max = max;
    this.direction = direction;
  }
}

export class DepartureTimeFilter {
  min: moment.Moment;
  max: moment.Moment;
  direction: string;

  constructor(min: moment.Moment, max: moment.Moment, direction: string) {
    this.min = min;
    this.max = max;
    this.direction = direction;
  }
}

export class AirportPriceFilter {
  airport: string;
  price: number;

  constructor(airport: string, price: number) {
    this.airport = airport;
    this.price = price;
  }
}

export class StopPriceFilter {
  airline: string;
  prices: StopQtyPrice[];

  constructor(airline: string, prices: StopQtyPrice[]) {
    this.airline = airline;
    this.prices = prices;
  }

  getPrice(stopQty: number) {
    const result = this.prices.find(p => p.stopQuantity === stopQty);

    return result ? result : null;
  }

  addStopQty(stopQty: StopQtyPrice) {
    if (stopQty.price !== null) {
      const currentIndex = _.findIndex(
        this.prices,
        p => p.stopQuantity === stopQty.stopQuantity
      );
      if (currentIndex === -1) {
        this.prices.push(stopQty);
      } else {
        this.prices[currentIndex].price = Math.min(
          stopQty.price,
          this.prices[currentIndex].price
        );
      }
    }
  }
}

export interface IStopPrices {
  [airline: string]: StopQtyPrice[];
}

export class StopQtyPrice {
  stopQuantity: number;
  price: number;

  constructor(stopQuantity: number, price: number) {
    this.stopQuantity = stopQuantity;
    this.price = price;
  }
}

export class AirlinePriceFilter {
  airline: string;
  price: number;

  constructor(airline: string, price: number) {
    this.airline = airline;
    this.price = price;
  }
}

export class FilterDataModel {
  minFare: number;
  maxFare: number;
  stopQuantities: number[];
  priceByAirline: AirlinePriceFilter[];
  priceByAirport: AirportPriceFilter[];
  priceByStops: StopPriceFilter[];
  luggageOptions: boolean[];
}

export class StopPriceSelect {
  price: number;
  airline: string;
  stopCount: number;
}
