<!-- <div class="modal-header">
  <h4 class="modal-title">&nbsp;</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<div class="modal-body" style="padding: 0;">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p0">
      <div class="bgEsquerdaModal"></div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p0">
      <div class="boxModalAlert">
        <h2><i class="fas fa-exclamation-circle"></i></h2>
        <h3>Ops... Não temos resultado para essa pesquisa</h3>
        <p>Mas fique tranquilo, você pode fazer uma nova busca para sua próxima viagem.</p>
        <p>1... 2... 3... Valendo !!!</p>
        <button (click)="activeModal.close('Cross click')">Fazer nova busca</button>
      </div>
    </div>
  </div>
</div>
