import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiAerialFamiliesBasefareService, GroupedFamiliesDetails, GroupedFamiliesBasefare } from '@zupper/data';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-modal-flight-family',
  templateUrl: './modal-flight-family.component.html',
  styleUrls: ['./modal-flight-family.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ModalFlightFamilyComponent implements OnInit, OnDestroy {
  @Input() source: string;
  @Input() familiesEnabled: String[];
  @Input() flightFamilies: [];
  @Input() currentFamily: string;
  @Output() selectedFamily = new EventEmitter();
  // public familiesBaseFare: any;
  public families: Array<GroupedFamiliesBasefare> = [];
  public internalCurrentFamily: string;
  public familiesObservation: any[] = [];

  private subs = new SubSink();

  constructor(
    public activeModal: NgbActiveModal,
    public familiesService: ApiAerialFamiliesBasefareService,
  ) { }

  ngOnInit() {
    this.changeFamily(this.currentFamily);
    let familiesSubscription = this.familiesService.run({ 'airlines[0]': this.source, 'international': false }).subscribe(result => {
      for (const idx of Object.keys(result['result'])) {
        result['result'][idx].map(family => {
          const familiesBaseFare = new GroupedFamiliesBasefare;
          familiesBaseFare.name = family.name;
          familiesBaseFare.enabled = this.familiesEnabled.indexOf(family.name) >= 0;

          if (!familiesBaseFare.enabled) {
            return false;
          }

          familiesBaseFare.order = family.order;
          familiesBaseFare.airline = family.airline.iataCode;
          familiesBaseFare.logoUrl = family.airline.logoUrl;

          family.details.map(detail => {
            const oDetail = new GroupedFamiliesDetails;
            oDetail.name = detail.name;
            oDetail.value = detail.value;
            oDetail.order = detail.order;
            oDetail.notes = detail.notes;

            this.getMountFamiliesObservation(detail.notes, detail.value);

            familiesBaseFare.addDetails(oDetail);
          });
          familiesBaseFare.details.sort((a, b) => a.order <= b.order ? -1: 0);
          family.details.sort((a, b) => a.order <= b.order ? -1: 0);

          this.families.push(familiesBaseFare);
        });
      }
      this.families.sort((a, b) => a.order <= b.order ? -1: 0);
    });

    this.subs.add(familiesSubscription);
  }

  getMountFamiliesObservation(notes, value) {
    if(notes) {
      notes.forEach(_note => {
        let result = this.familiesObservation.find(_obs => _obs.name == _note);
        if(result) {
          value = value + result.order;
          return value;
        } else {
          let obs = {
            name: _note,
            order: this.familiesObservation.length
          }
          this.familiesObservation.push(obs);
          value = value + (obs.order+1);
          return value;
        }
      })
    }
    return value;
  }

  getConvertValuePs(notes) {
    if(notes) {
      let result: string = "";
      notes.forEach(_note => {
        let filter = this.familiesObservation.find(_obs => _obs.name == _note);
        if(filter) {
          result += filter.order.toString();
        }
      });
      return Number(result)+1;
    } else {
      return "";
    }
  }

  selectItem() {
    this.activeModal.close({ selectedFamily: this.internalCurrentFamily });
  }

  changeFamily(family: string) {
    this.internalCurrentFamily = family;
  }

  checkEnabledFamily(family: string) {
    return this.familiesEnabled ? this.familiesEnabled.indexOf(family) >= 0 : false;
  }

  getClass(airline: string, familyName: string) {
    return airline + '-' + familyName.replace(/ /g, "_");
  }

  getPrice(family: string) {
    const currentFamily = this.flightFamilies.filter(item => {
      return item['slices'][0]['baseFares'][0]['family'] === family;
    });

    let familyPrice = currentFamily[0]['totalFarePrice'];
    if (family === this.internalCurrentFamily) {
      return familyPrice;
    }

    return familyPrice - this.getPrice(this.internalCurrentFamily);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
