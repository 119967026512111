import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AerialComponentsModule } from '@zupper/aerial-components';
import { environment } from 'src/environments/environment';
import { FlightSearchModule } from './../flight-search/flight-search.module';
import { CombinedResultModule } from './combined-result/combined-result.module';
import { ModalMobileComponent } from './modal-mobile/modal-mobile.component';
import { PriceMatrixModule } from './price-matrix/price-matrix.module';
import { ResultFilterModule } from './result-filter/result-filter.module';
import { ResultListModule } from './result-list/result-list.module';
import { ResultsComponent } from './results.component';
import { SearchSummaryComponent } from './search-summary/search-summary.component';
import { UncombinedResultModule } from './uncombined-result/uncombined-result.module';
import { RouterModule, Routes } from '@angular/router';
import { ZupperProduct, contentConfigurationResolver, AbandonmentModalConfigurationResolver } from "@zupper/common";
import { utmCampaignResolver, gclidResolver } from "@zupper/common";
import { CorporateLoggedGuard } from '../../corporate/guards/corporate-logged.guard';
import { resultsSearchResolver } from '../../resolvers/results-search.resolver';
import { resultsFiltersResolver } from '../../resolvers/results-filters.resolver';

const routeData = {
  product: ZupperProduct.AERIAL,
  showHeader: true,
  showHeaderLinks: true
};

const routes: Routes = [
  {
    path: '',
    component: ResultsComponent,
    data: routeData,
    canActivate: [CorporateLoggedGuard],
    resolve: {
      searchRequest: resultsSearchResolver,
      searchFilters: resultsFiltersResolver,
      utmCampaign: utmCampaignResolver,
      gclid: gclidResolver,
      whatsAppTag: contentConfigurationResolver,
      abandonmentModalConfig: AbandonmentModalConfigurationResolver
    },
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  declarations: [
    ResultsComponent,
    SearchSummaryComponent
  ],
  imports: [
    CommonModule,
    ResultListModule,
    ResultFilterModule,
    UncombinedResultModule,
    CombinedResultModule,
    FlightSearchModule,
    FormsModule,
    ReactiveFormsModule,
    PriceMatrixModule,
    FlexLayoutModule,
    SweetAlert2Module,
    AerialComponentsModule.forRoot(environment),
    RouterModule.forChild(routes)
  ],
  exports: [
    ResultsComponent,
    ResultListModule,
    ResultFilterModule,
    UncombinedResultModule,
    CombinedResultModule,
    FlightSearchModule,
    PriceMatrixModule,
    RouterModule
  ],
  entryComponents: [
    ModalMobileComponent,
  ]
})
export class ResultsModule { }
