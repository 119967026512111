import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse } from '@zupper/aerial-components';
import * as moment from 'moment';
import * as _ from 'lodash';
@Pipe({
  name: 'recommendedTripFilter',
})
export class RecommendedTripFilterPipe implements PipeTransform {
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    filterRecommendedTrip: boolean = false
  ): Array<Array<AvailableFlightsResponse>> {
    if (filterRecommendedTrip) {
      let newFlightList: Array<Array<AvailableFlightsResponse>> = originalflightList.map((item) => item);
      newFlightList.sort((a, b) => {
        const flightA = calculateFlightDurationSegments(a);
        const flightB = calculateFlightDurationSegments(b);
        //Se for a mesma quantidade de conexões
        if(flightA.totalSegments === flightB.totalSegments) {
          //Se for a mesma duração
          if(flightA.totalSmallestDuration === flightB.totalSmallestDuration){
            //Ordena pelo menor preço
            return Math.ceil(a[0].totalPrice) - Math.ceil(b[0].totalPrice);
          }
          //Ordena pela duração
          if(flightA.totalSmallestDuration < flightB.totalSmallestDuration) {
            return 1;
          } else {
            return -1;
          }
        }
        //Ordena pela quantidade de conexões
        if(flightA.totalSegments < flightB.totalSegments){
          return -1;
        } else {
          return 1;
        }
      });
      return newFlightList;
    } else {
      return originalflightList;
    }
  }
}
function calculateFlightDurationSegments(obj: AvailableFlightsResponse[]): FlightResponseCalculation {
  let flightCalculation: FlightResponseCalculation = {
    totalSmallestDuration: Infinity,
    totalSegments: 0
  }
  let smallestGoingDuration = Infinity;
  let smallestReturnDuration = Infinity;
  let goingSegments = 0;
  let returnSegments = 0;
  obj.forEach((item) => {
    item.slices.forEach((slice) => {
      const departureDate = moment(slice.departureDate);
      const arrivalDate = moment(slice.arrivalDate);
      const duration = moment.duration(departureDate.diff(arrivalDate));
      const sliceDuration = duration.asMilliseconds();
      if (slice.direction === 'going' && sliceDuration < smallestGoingDuration) {
        smallestGoingDuration = sliceDuration;
      }
      if (slice.direction === 'return' && sliceDuration < smallestReturnDuration) {
        smallestReturnDuration = sliceDuration;
      }
      if(goingSegments < slice.segments.length) {
        goingSegments = slice.segments.length;
      }
      if(returnSegments < slice.segments.length) {
        returnSegments = slice.segments.length;
      }
    })
  })
  flightCalculation.totalSmallestDuration = Math.min(smallestGoingDuration, smallestReturnDuration);
  flightCalculation.totalSegments = goingSegments + returnSegments;
  return flightCalculation;
}
interface FlightResponseCalculation {
  totalSmallestDuration: number,
  totalSegments: number
}