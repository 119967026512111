import { ApiCorporateModel } from '@zupper/data';
import { StopPriceFilter } from './aerial-filters.model';
import { ApiFlightType } from './../services/enum/flights.enum';
import moment from 'moment';
import { AirportsModel, AvailableFlightsRequest, FlightSlicesModel } from '@zupper/aerial-components';
export class DataLayerResultPageModel {
  private category: string = undefined;
  private event: string = "resultPageData";
  private tripType: string = undefined;
  private classification: string[] = [];
  private cabinType: string = undefined;
  private travelingDays: number = undefined;
  private daysToTravel: number = undefined;
  private adultQty: number = undefined;
  private childQty: number = undefined;
  private infantQty: number = undefined;
  private slice: SliceTrip[] = [];
  private price: PriceTrip[] = [];
  private minorPrice: number = undefined;
  private minorDirectPrice: number = undefined;
  private partner: string = undefined;

  setCategory(category: string) {
    this.category = category;
    return this;
  }

  setTripType(tripType: string) {
    this.tripType = tripType;
    return this;
  }

  setClassification(flightRequest: AvailableFlightsRequest) {
    let slice = flightRequest.slices[0];
    let originAirport: AirportsModel = new AirportsModel();
    let destinationAirport: AirportsModel = new AirportsModel();

    if (flightRequest.type != ApiFlightType.OneWay) {
      if (
        JSON.parse(
          sessionStorage.getItem("airport.".concat(slice.originAirport))
        )
      ) {
        Object.assign(
          originAirport,
          JSON.parse(
            sessionStorage.getItem("airport.".concat(slice.originAirport))
          )
        );
      }
      if (
        JSON.parse(
          sessionStorage.getItem("airport.".concat(slice.destinationAirport))
        )
      ) {
        Object.assign(
          destinationAirport,
          JSON.parse(
            sessionStorage.getItem("airport.".concat(slice.destinationAirport))
          )
        );
      }
      originAirport.countries_iso == "BR" &&
      destinationAirport.countries_iso == "BR"
        ? this.classification.push("domestic-flight")
        : this.classification.push("international-flight");
    } else {
      flightRequest.slices.forEach((slice, sliceIndex) => {
        if (
          JSON.parse(
            sessionStorage.getItem("airport.".concat(slice.originAirport))
          )
        ) {
          Object.assign(
            originAirport,
            JSON.parse(
              sessionStorage.getItem("airport.".concat(slice.originAirport))
            )
          );
        }
        if (
          JSON.parse(
            sessionStorage.getItem("airport.".concat(slice.destinationAirport))
          )
        ) {
          Object.assign(
            destinationAirport,
            JSON.parse(
              sessionStorage.getItem(
                "airport.".concat(slice.destinationAirport)
              )
            )
          );
        }
        originAirport.countries_iso == "BR" &&
        destinationAirport.countries_iso == "BR"
          ? this.classification.push("domestic-flight")
          : this.classification.push("international-flight");
      });
    }

    return this;
  }

  setCabinType(cabinType: string) {
    this.cabinType = cabinType;
    return this;
  }

  setTravelingDays(flightRequest: AvailableFlightsRequest) {
    switch (flightRequest.type) {
      case ApiFlightType.RoundTrip:
        this.travelingDays = Math.abs(
          moment(flightRequest.slices[0].departureDate).diff(
            moment(flightRequest.slices[1].departureDate),
            "days"
          )
        );
        break;

      case ApiFlightType.OneWay:
        this.travelingDays = Math.abs(
          moment(flightRequest.slices[0].departureDate).diff(
            moment(
              flightRequest.slices[flightRequest.slices.length - 1]
                .departureDate
            ),
            "days"
          )
        );
        break;

      default:
        this.travelingDays = null;
        break;
    }
    return this;
  }

  setDaysToTravel(departureDate: Date) {
    let now = moment(Date.now());
    this.daysToTravel = Math.abs(now.diff(departureDate, "days"));
    return this;
  }

  setAdultQty(adultQty: number) {
    this.adultQty = adultQty;
    return this;
  }

  setChildQty(childQty: number) {
    this.childQty = childQty;
    return this;
  }

  setInfantQty(infantQty: number) {
    this.infantQty = infantQty;
    return this;
  }

  setSlice(flightRequest: AvailableFlightsRequest) {
    if (flightRequest.type != "oneWay") {
      let sliceTemp = new SliceTrip();
      sliceTemp
        .setOriginAirPort(flightRequest.slices[0].originAirport)
        .setDestinationAirPort(flightRequest.slices[0].destinationAirport)
        .setOriginCity(flightRequest.slices[0].originAirport)
        .setDestinationCity(flightRequest.slices[0].destinationAirport)
        .setOriginCountry(flightRequest.slices[0].originAirport)
        .setDestinationCountry(flightRequest.slices[0].destinationAirport)
        .setDepartureDate(flightRequest.slices[0].departureDate)
        .setReturnDate(
          flightRequest.slices[flightRequest.slices.length - 1].departureDate
        );
      this.slice.push(sliceTemp);
    } else {
      flightRequest.slices.forEach((slice, sliceIndex) => {
        let sliceTemp = new SliceTrip();
        sliceTemp
          .setOriginAirPort(slice.originAirport)
          .setDestinationAirPort(slice.destinationAirport)
          .setOriginCity(slice.originAirport)
          .setDestinationCity(slice.destinationAirport)
          .setOriginCountry(slice.originAirport)
          .setDestinationCountry(slice.destinationAirport)
          .setDepartureDate(slice.departureDate);
        this.slice.push(sliceTemp);
      });
    }

    return this;
  }

  setPrice(bestsPrices: StopPriceFilter[]) {
    if (bestsPrices == null) {
      return this;
    }

    let minorPrices: number[] = [];
    let minorDirectPrice: number[] = [];
    bestsPrices.forEach((field, fieldIndex) => {
      field.prices.forEach((stoped) => {
        let bestPrice = new PriceTrip();
        bestPrice.setCiaName(field.airline);
        bestPrice.setPrice(stoped.price);
        bestPrice.setStops(stoped.stopQuantity);
        this.price.push(bestPrice);
        stoped.stopQuantity == 0
          ? minorDirectPrice.push(stoped.price)
          : minorPrices.push(stoped.price);
      });
    });

    this.setMinorDirectPrice(Math.min(...minorDirectPrice));
    this.setMinorPrice(
      Math.min(...minorPrices) <= Math.min(...minorDirectPrice)
        ? Math.min(...minorPrices)
        : Math.min(...minorDirectPrice)
    );

    return this;
  }

  setPartner(partner?: ApiCorporateModel) {
    if (partner) {
      this.partner = partner.companyName;
    }

    return this;
  }

  setMinorPrice(minor: number) {
    this.minorPrice = minor;
    return this;
  }

  setMinorDirectPrice(minorDirect: number) {
    this.minorDirectPrice = minorDirect;
    return this;
  }
}

export class SliceTrip {
  private originAirPort: string = undefined;
  private originCity: string = undefined;
  private destinationAirPort: string = undefined;
  private destinationCity: string = undefined;
  private originCountry: string = undefined;
  private destinationCountry: string = undefined;
  private departureDate: string = undefined;
  private returnDate: string = undefined;

  setOriginAirPort(originAirPort: string) {
    this.originAirPort = originAirPort;
    return this;
  }

  setDestinationAirPort(destinationAirPort: string) {
    this.destinationAirPort = destinationAirPort;
    return this;
  }

  setOriginCity(originAirport: string) {
    let airport: AirportsModel = new AirportsModel();
    if (JSON.parse(sessionStorage.getItem("airport.".concat(originAirport)))) {
      Object.assign( airport, JSON.parse(sessionStorage.getItem("airport.".concat(originAirport))));
      this.originCity = airport.city;
    } else {
      this.originCity = null;
    }

    return this;
  }

  setDestinationCity(airportCode: string) {
    let airport: AirportsModel = new AirportsModel();
    if (JSON.parse(sessionStorage.getItem("airport.".concat(airportCode)))) {
      Object.assign(airport, JSON.parse(sessionStorage.getItem("airport.".concat(airportCode))));
      this.destinationCity = airport.city;
    } else {
      this.destinationCity = null;
    }

    return this;
  }

  setOriginCountry(airportCode: string) {
    let airport: AirportsModel = new AirportsModel();
    if (JSON.parse(sessionStorage.getItem("airport.".concat(airportCode)))) {
      Object.assign(airport, JSON.parse(sessionStorage.getItem("airport.".concat(airportCode))));
      this.originCountry = airport.country;
    } else {
      this.originCountry = null;
    }

    return this;
  }

  setDestinationCountry(airportCode: string) {
     let airport: AirportsModel = new AirportsModel();
     if (JSON.parse(sessionStorage.getItem("airport.".concat(airportCode)))) {
       Object.assign(airport, JSON.parse(sessionStorage.getItem("airport.".concat(airportCode))));
       this.destinationCountry = airport.country;
     } else {
       this.destinationCountry = null;
     }

    return this;
  }

  setDepartureDate(departureDate: string) {
    this.departureDate = departureDate;
    return this;
  }

  setReturnDate(returnDate: string) {
    this.returnDate = returnDate;
    return this;
  }

  getSliceTrip() {
    return this;
  }
}

export class PriceTrip {
  private ciaName: string = undefined;
  private price: number = undefined;
  private stops: number = undefined;

  setCiaName(ciaName: string) {
    this.ciaName = ciaName;
    return this;
  }

  setPrice(price: number) {
    this.price = price;
    return this;
  }

  setStops(stops: number) {
    this.stops = stops;
    return this;
  }
}
