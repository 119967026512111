import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCustomer, ApiCustomerService, ApiCustomerLoginRequest, ExpirationItem, ApiCorporateLoginService, ApiCorporateLoginModel } from '@zupper/data';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ZcLoginComponent } from './components/zc-login/zc-login.component';
import { Auth, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, UserCredential  } from '@angular/fire/auth';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../lib/storage/local-storage.service';
import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root'
})
export class CustomerLoginService implements OnDestroy {
  private _customerSessionId: string;

  currentCustomer$ = new BehaviorSubject<ApiCustomer>(null);

  constructor(
    private apiCustomerService: ApiCustomerService,
    private apiCorporateLoginService: ApiCorporateLoginService,
    private modalService: NgbModal,
    private afAuth: Auth,
    private localStorageService: LocalStorageService,
  ) {
    this._customerSessionId = this.localStorageService?.getTimedItem<string>('customerSessionId');
    const currentCustomer = this.localStorageService?.getTimedItem<ApiCustomer>('customer');
    this._emitCustomer(currentCustomer);
  }

  openLoginForm(): Observable<ApiCustomer> {
    const modalRef = this.modalService.open(ZcLoginComponent, {
      windowClass: 'zupper-login-modal',
      size: 'xl'
    });

    const obs = from(modalRef.result);
    return obs;
  }

  getCustomerSessionId(): string | null {
    return this.localStorageService.getTimedItem<string>('customerSessionId');
  }

  setCustomerOnStorage(customer: ApiCustomer){
    let session = this.localStorageService.getItem('customerSessionId') as ExpirationItem<string>;
    this.localStorageService.setTimedItem('customer', customer, session.expiration);
  }

  customerLogin(request: ApiCustomerLoginRequest): Observable<ApiCustomer> {
    return this.apiCustomerService.customerLogin(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorResponse = new LoginError();

        if (error.status === 401) {
          errorResponse.isLoginError = true;
          errorResponse.isSSOError = error.error?.['error']?.[0]?.['ErrorCode'] === 'SSO';

          return throwError(() =>  errorResponse);
        } else {
          errorResponse.isApiError = true;
          return throwError(() =>  errorResponse);
        }
      }),
      switchMap((customerSession: ExpirationItem<string>) => this._handleCustomerSession(customerSession, "e-mail"))
    );
  }

  updateCustomer(customer: ApiCustomer): Observable<boolean> {
    return this.apiCustomerService.putCustomer(this._customerSessionId, customer);
  }

  updatePassword(body: any): Observable<boolean> {
    return this.apiCustomerService.updatePassword(this._customerSessionId, body);
  }

  sendPasswordCreationLink(email: string): Observable<boolean> {
    return this.apiCustomerService.sendPasswordCreationLink(email).pipe(
      catchError((error: HttpErrorResponse) => {
        Sentry.captureMessage(error.message);

        const errorResponse = new PasswordCreationLinkError();

        if (error.status === 401) {
          errorResponse.isApiError = true;
          return throwError(() =>  errorResponse);
        } else {
          errorResponse.isApiError = true;
          return throwError(() =>  errorResponse);
        }
      })
    );
  }

  pictureUpload(file: File): Observable<any> {
    return this.apiCustomerService.pictureUpload(this._customerSessionId, file);
  }

  logout(): Observable<boolean> {
    return this.apiCustomerService.logout(this._customerSessionId, '').pipe(
      catchError((err) => {
        // ignore errors that might have happened in the API and log the user out of the frontend
        return of(true);
      }),
      tap((success) => {
        if (success) {
          this.localStorageService.removeItem('customerSessionId');
          this.localStorageService.removeItem('customer');
          this.localStorageService.removeItem('whitelabel');
          this.localStorageService.removeItem('whitelabelLogged');
          this._customerSessionId = null;
          this._emitCustomer(null);
        }
      })
    );
  }

  removeLastSessions(customerSessionId: string, sessionId: string): Observable<boolean> {
    return this.apiCustomerService.logout(customerSessionId, sessionId).pipe(
      tap((success) => {
        if(success) {
          const customerSession: ExpirationItem<string> = this.localStorageService.getItem('customerSessionId');
          this._handleCustomerSession(customerSession).subscribe(data => {
            console.log(data)
          }, error => console.log(error.message || error))
        }
      })
    );
  }

  googleSignIn(): Observable<ApiCustomer> {
    const provider = new GoogleAuthProvider();
    const credential$ = from(signInWithPopup(this.afAuth, provider));

    return credential$.pipe(
      switchMap((googleCredential: UserCredential) => {
        return from(googleCredential.user.getIdToken());
      }),
      switchMap((idToken) => {
        return this.apiCustomerService.firebaseGoogleLogin(idToken);
      }),
      switchMap((item: ExpirationItem<string>) => this._handleCustomerSession(item, "google"))
    );
  }

  facebookSignIn(): Observable<ApiCustomer> {
    const provider = new FacebookAuthProvider();
    const credential$ = from(signInWithPopup(this.afAuth, provider));
    return credential$.pipe(
      switchMap((fbCredential: UserCredential) => {
        return from(fbCredential.user.getIdToken());
      }),
      switchMap((idToken) => {
        return this.apiCustomerService.facebookLogin(idToken);
      }),
      switchMap((item: ExpirationItem<string>) => this._handleCustomerSession(item, "facebook"))
    );
  }

  corporateLogin(username: string, password: string, corporateId: number): Observable<any> {
    return this.apiCorporateLoginService.login(username, password, corporateId).pipe(
      switchMap((model: ApiCorporateLoginModel) => {
        this.localStorageService.setItem('whitelabel', model.branding);
        this.localStorageService.setTimedItem('whitelabelLogged', true, model.expirationDate);

        if (model.customerSessionId && model.expirationDate) {
          const customerSession: ExpirationItem<string> = {
            item: model.customerSessionId,
            expiration: model.expirationDate
          };

          return this._handleCustomerSession(customerSession).pipe(
            map((customer: ApiCustomer) => {
              return model; // keeps compatibility with previous version
            })
          );
        } else {
          return of(model);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.currentCustomer$.complete();
  }

  private _emitCustomer(customer: ApiCustomer | null): void {
    if (!customer) {
      this.currentCustomer$.next(null);
    } else {
      this.currentCustomer$.next(Object.assign(new ApiCustomer(), customer));
    }
  }

  private _handleCustomerSession(customerSession: ExpirationItem<string>, provider?: string): Observable<ApiCustomer> {
    if (!!customerSession) {
      this._customerSessionId = customerSession.item;
      this.localStorageService.setTimedItem('customerSessionId', this._customerSessionId, customerSession.expiration);
      return this.apiCustomerService.getCustomer(
        this._customerSessionId,
        provider != null ? provider : null
      ).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.localStorageService.removeItem('customerSessionId');
            this.localStorageService.removeItem('customer');
            this.localStorageService.removeItem('whitelabel');
            this.localStorageService.removeItem('whitelabelLogged');
            this._customerSessionId = null;
            this._emitCustomer(null);
            return throwError(() => new Error('Sessão expirada ou inválida. Faça o login novamente.'));
          } else {
            return throwError(() => new Error('Houve um problema durante sua solicitação. Tente novamente mais tarde'));
          }
        }),
        tap((customer) => {
          this.setCustomerOnStorage(customer);
          this._emitCustomer(customer);
        })
      );
    }
  }
}

export class LoginError {
  isLoginError: boolean = false;
  isApiError: boolean = false;
  isSSOError: boolean = false;

  message: string;

  constructor(message?: string) {
    this.message = message;
  }
}

export class PasswordCreationLinkError extends LoginError {
  isPasswordCreationLinkError = false;

  constructor(message?: string) {
    super(message);
  }
}
