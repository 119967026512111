import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import moment_ from 'moment';

const moment = moment_;

// Might need to polyfill depending on needed browser support...
const isInt = Number.isInteger;

@Injectable()
export class NgbMomentjsAdapter extends NgbDateAdapter<moment_.Moment> {

  fromModel(date: moment_.Moment): NgbDateStruct {
    if (!date || !moment.isMoment(date)) {
      return null;
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  toModel(date: NgbDateStruct): moment_.Moment {
    if (!date || !isInt(date.day) || !isInt(date.day) || !isInt(date.day)) {
      return null;
    }

    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD');
  }
}
