<section class="rodapeMaior">
  <div class="widthPadrao">

    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-6  mgB50XS txC tm30 wow fadeInUp animated p0XS animated" data-wow-duration="1s" data-wow-delay="0.6s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.6s; animation-name: fadeInUp;">
        <div class="receiveOffers">
          <i class="fas fa-envelope fa-5x mgb5"></i>
          <p class="">Receba nossas ofertas no seu e-mail</p>
        </div>
        <form #frmContact="ngForm" (ngSubmit)="onSubmit(frmContact)" novalidate>
          <p>
            <input
              type="email"
              name="email"
              [(ngModel)]="email"
              [ngClass]="getClass()"
              placeholder="Seu melhor email aqui"
              required email
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            >
            <button
              class="buttonRodape"
              type="submit"
              >
              <i class="fab fa-telegram-plane"></i> Enviar
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>
</section>
<swal #newsletterSwal></swal>
