import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
// import { SpinnerService } from "../../spinner.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "app-spinner",
  template: `
    <div class="spinner-container" *ngIf="show">
      <h1>Carregando</h1>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./app-spinner.component.scss"],
})
export class AppSpinnerComponent implements OnInit, OnDestroy {
  private isShowing = false;
  @Input() group: string;
  @Input() name: string;

  @Input()
  get show(): boolean {
    return this.isShowing;
  }

  @Output() showChange = new EventEmitter<boolean>();

  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  // constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    if (!this.name) {
      throw new Error("Spinner must have a 'name' attribute.");
    }

    // this.spinnerService._register(this);
  }

  ngOnDestroy(): void {
    // this.spinnerService._unregister(this);
  }
}
