import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalyticsService, ApiBaseService, ZupperDataModule } from '@zupper/data';
import { ZupperCommonModule } from '@zupper/common';
import { FlightResultNotifierService } from './services/flight-result-notifier.service';
import { MatrixSelectionNotifierService } from './services/matrix-selection-notifier.service';
import { MainComponent } from './main/main.component';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { AirlinePipe } from './pipes/airline.pipe';
import { AirportPipe } from './pipes/airport.pipe';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalFlightFamilyComponent } from './components/modal-flight-family/modal-flight-family.component';
import { AppInitializerService } from './services/app-initializer.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PassengerSelectionModalComponent } from './components/passenger-selection-modal/passenger-selection-modal.component';
import { PassengerSelectionContentComponent } from './components/passenger-selection-content/passenger-selection-content.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { McrModule } from './mcr/mcr.module';
import { ThanksModule } from './thanks/thanks.module';
import { MatchHeightDirective } from './utils/match-height.directive';
import { ModalVoidSearchComponent } from './components/modal-void-search/modal-void-search.component';
import { DateSelectionModalComponent } from '@zupper/common';
import { TransactionComponent } from './transaction/transaction.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TokenInterceptor } from '@zupper/data';
import { ComponentsModule } from './components/components.module';
import { FlightSearchComponent } from './components/flight-search/flight-search.component';
import { FlightSearchModule } from './components/flight-search/flight-search.module';
import { ResultsModule } from './components/results/results.module';
import localePt from '@angular/common/locales/pt';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AirlineConstantService } from './constants/airline-constant.service';
import { CorporateLoginModule } from './corporate/components/corporate-login/corporate-login.module';
import { ModalWhitelabelComponent } from './components/modal-whitelabel/modal-whitelabel.component';
import { ZupperUiModule } from '@zupper/common';
import { environment } from 'src/environments/environment';
import { MomentModule } from 'ngx-moment';
import { GlobalErrorHandler } from './utils/global-error-handler';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import packageInfo from '../../../../package.json';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZupperSearchHistoryModule } from '@zupper/search-history';
import { InsuranceLibModule } from 'insurance-lib';

registerLocaleData(localePt, 'pt-BR');

const version = packageInfo.version;

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MatchHeightDirective,
    AirlinePipe,
    AirportPipe,
    ModalFlightFamilyComponent,
    TransactionComponent,
    TermsAndConditionsComponent,
    ModalVoidSearchComponent,
    PassengerSelectionModalComponent,
    PassengerSelectionContentComponent,
    TypeaheadComponent,
    PrivacyPolicyComponent,
    ModalWhitelabelComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    AppRoutingModule,
    ZupperSearchHistoryModule.forRoot({
      baseApiUrl: environment.apiUrl,
      zupperImagesBaseUrl: environment.zupperImagesBaseUrl,
    }),
    ZupperDataModule.forRoot(environment),
    ZupperCommonModule.forRoot(environment),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    NgxUiLoaderModule,
    Ng5SliderModule,
    NgbModule,
    McrModule,
    ThanksModule,
    ContentLoaderModule,
    CarouselModule,
    NgScrollbarModule,
    ComponentsModule,
    FlightSearchModule,
    ResultsModule,
    CorporateLoginModule,
    ZupperUiModule,
    MomentModule,
    InsuranceLibModule.forRoot(environment.insurance.baseUrl)
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    FlightResultNotifierService,
    MatrixSelectionNotifierService,
    AirlineConstantService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: APP_INITIALIZER,
      useFactory: (aps: AppInitializerService) =>
        function () {
          return aps.loadApp(version);
        },
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      deps: [HttpClient, AnalyticsService, ApiBaseService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  exports: [TermsAndConditionsComponent],
  entryComponents: [
    ModalFlightFamilyComponent,
    ModalVoidSearchComponent,
    DateSelectionModalComponent,
    PassengerSelectionModalComponent,
    FlightSearchComponent,
    ModalWhitelabelComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
