import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ThanksComponent } from "./thanks.component";
import { ZupperCommonModule } from "@zupper/common";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { ApprovalProcessComponent } from "./approval-process/approval-process.component";
import { CardOptionComponent } from "./card-option/card-option.component";
import { McrOptionComponent } from "./mcr-option/mcr-option.component";
import { TransferOptionComponent } from "./transfer-option/transfer-option.component";
import { TravelersComponent } from "./travelers/travelers.component";
import { ThanksTermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";

@NgModule({
  declarations: [
    ThanksComponent,
    ApprovalProcessComponent,
    CardOptionComponent,
    McrOptionComponent,
    ThanksTermsAndConditionsComponent,
    TransferOptionComponent,
    TravelersComponent,
  ],
  imports: [
    CommonModule,
    ZupperCommonModule,
    SweetAlert2Module,
    ContentLoaderModule,
  ],
})
export class ThanksModule {}
