import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zupper-common-order-tracking-modal',
  templateUrl: './order-tracking-modal.component.html',
  styleUrls: ['./order-tracking-modal.component.scss']
})
export class OrderTrackingModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    @Inject('env') private _env: any
  ) { }

  ngOnInit(): void {
  }

  register(ev: Event): void {
    ev.preventDefault();
    window.open(`${this._env['account']['registerUrl']}`);
    this.activeModal.close('');
  }

  customerOrders(ev: Event): void {
    ev.preventDefault();
    window.open(`${this._env['account']['baseUrl']}/meus-pedidos`);
    this.activeModal.close('');
  }

}
