<div class="search-wrapper">
  <article class="search-options">
    <label class="search-option">
      <input type="radio" [(ngModel)]="this.flightSearchFields.type" data-zt="searchEngineOptionRoundTrip" [value]="this.flightTypes.RoundTrip" checked />
      <span>Ida e volta</span>
    </label>
    <label class="search-option">
      <input type="radio" [(ngModel)]="this.flightSearchFields.type" data-zt="searchEngineOptionOneWay" [value]="this.flightTypes.OneWay" />
      <span>Somente ida</span>
    </label>
    <label class="search-option">
      <input type="radio" [(ngModel)]="this.flightSearchFields.type" data-zt="searchEngineOptionMultiSlice" [value]="this.flightTypes.MultiSlice" />
      <span>Multi destinos</span>
    </label>
  </article>
  <article [ngClass]="getClass()"></article>
  <article class="search-container">
      <app-one-destination-search *ngIf="this.flightSearchFields.type != this.flightTypes.MultiSlice"
        [flightSearchFields]="this.flightSearchFields"
        [tripTypeChosen]="this.getTripTypeChosen()">
      </app-one-destination-search>
      <app-multi-destination-search *ngIf="this.flightSearchFields.type == this.flightTypes.MultiSlice"
        [flightSearchFields]="this.flightSearchFields"
        [tripTypeChosen]="this.getTripTypeChosen()">
      </app-multi-destination-search>
  </article>
  
</div>
