import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnvironmentInterface, ModalVoidSearchComponent } from '@zupper/common';
import { ZupperSearchHistoryModule } from '@zupper/search-history';
import { MomentModule } from 'ngx-moment';
import { AerialComponentsComponent } from './aerial-components.component';
import { AerialSearchHistoryListComponent } from './components/aerial-search-history-list/aerial-search-history-list.component';
import { AirlineLogoComponent } from './components/airline-logo/airline-logo.component';
import { AlertCancelMultiAirlinesComponent } from './components/alert-cancel-multi-airlines/alert-cancel-multi-airlines.component';
import { AlertDifferentAirportsComponent } from './components/alert-different-airports/alert-different-airports.component';
import { FlightDirectionLabelComponent } from './components/flight-direction-label/flight-direction-label.component';
import { ResultsComponent } from './components/results/results.component';
import { ResultsModule } from './components/results/results.module';
import { AirlineDataPipe } from './pipes/airline-data.pipe';
import { AirportDataPipe } from './pipes/airport-data.pipe';
import { FlightDurationPipe } from './pipes/flight-duration.pipe';
import { FlightSegmentsQuantityPipe } from './pipes/flight-segments-quantity.pipe';
import { FlightTimePipe } from './pipes/flight-time.pipe';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    AerialComponentsComponent,
    FlightDirectionLabelComponent,
    AirlineLogoComponent,
    AirportDataPipe,
    AirlineDataPipe,
    FlightTimePipe,
    AlertCancelMultiAirlinesComponent,
    AlertDifferentAirportsComponent,
    AlertCancelMultiAirlinesComponent,
    AlertDifferentAirportsComponent,
    FlightSegmentsQuantityPipe,
    AerialSearchHistoryListComponent,
  ],
  imports: [ResultsModule, CommonModule, MomentModule, ZupperSearchHistoryModule, PipesModule],
  exports: [
    AerialComponentsComponent,
    ResultsComponent,
    FlightDirectionLabelComponent,
    AirlineLogoComponent,
    AirportDataPipe,
    AirlineDataPipe,
    FlightTimePipe,
    AlertCancelMultiAirlinesComponent,
    AlertDifferentAirportsComponent,
    AlertCancelMultiAirlinesComponent,
    AlertDifferentAirportsComponent,
    FlightSegmentsQuantityPipe,
    AerialSearchHistoryListComponent,
    PipesModule,
  ],
  entryComponents: [
    ModalVoidSearchComponent,
  ],
})
export class AerialComponentsModule {
  public static forRoot(
    environment: EnvironmentInterface
  ): ModuleWithProviders<AerialComponentsModule> {
    return {
      ngModule: AerialComponentsModule,
      providers: [
        { provide: 'env', useValue: environment },
        ZupperSearchHistoryModule.forRoot({
          baseApiUrl: environment.apiUrl,
          zupperImagesBaseUrl: environment.zupperImagesBaseUrl,
        }).ngModule,
      ],
    };
  }
}
