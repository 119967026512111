import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLayerService } from './data-layer.service';
import { DataLayerCheckoutService } from './data-layer-checkout.service';
import { DataLayerThanksService } from './data-layer-thanks.service';
import { PartialCheckoutDimensionsSet } from './models/full-custom-dimensions-set.model';
import { DataLayerCheckout } from './models/data-layer-checkout.enum';
import { ApiTokenRepositoryService, Price } from '@zupper/data';
import { JwtHelperService } from "@auth0/angular-jwt";
import moment from "moment";
// import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AppCheckerService } from './app-checker.service';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { LocalStorageService } from '../lib/storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private _appCheckerService: AppCheckerService,
    // private angularFireAnalytics: AngularFireAnalytics,
    private _dataLayerCustomDimensions: DataLayerService,
    private _dataLayerCheckout: DataLayerCheckoutService,
    private _dataLayerThanks: DataLayerThanksService,
    private _router: Router,
    private route: ActivatedRoute,
    private _localStorageService: LocalStorageService
  ) { }

  logEvent(name, params) {
    if(this._appCheckerService.isApp()) {
      logEvent(getAnalytics(), name, params);
      // this.angularFireAnalytics.logEvent(name, params);
    }
  }

  pageView() {
    (<any>window).dataLayer.push({
      event: 'pageview',
      pagePath: this._router.url
    });
    this.logEvent('pageview', {url: this._router.url});
  }

  session() {
    let token = null;

    try {
      let tokenData = localStorage.getItem('token') || null;
      let parsedToken = !!tokenData ? JSON.parse(tokenData) : null;
      if (parsedToken.token) {
        token = parsedToken.token;
      }
    } catch (e) {
      token = null;
    }

    if (token !== null && token !== '') {
      try {
        const helper = new JwtHelperService();
        const decoded: any = helper.decodeToken(token);
        const sessionId = decoded['jti'];
        if (sessionId !== null) {
          (<any>window).dataLayer.push({
            event: 'session',
            id: sessionId
          });
          this.logEvent('session', {id: sessionId});
        }
      } catch (e) {

      }
    }
  }

  pageViewCheckout(someDimensions: PartialCheckoutDimensionsSet, overridePath?: string) {
    this._dataLayerCheckout.dimensions = someDimensions;
    this._dataLayerCheckout.trigger();
    this.logEvent("add_to_cart", someDimensions);
  }

  addTransThanks(someDimensions: PartialCheckoutDimensionsSet, overridePath?: string) {
    this._dataLayerThanks.dimensions = someDimensions;
    this._dataLayerThanks.trigger();
    this.logEvent("purchase", someDimensions);
  }

  private _getPassengers(obj: any) {
    let retorno = [];
    if (obj && obj.hasOwnProperty('result')) {
      if (obj.result.hasOwnProperty('passengers')) {
        retorno = obj.result.passengers;
      } else if (obj.result instanceof Array && obj.result[0].hasOwnProperty('reservation')
        && obj.result[0].reservation.hasOwnProperty('passengers')) {
        retorno = obj.result[0].reservation.passengers;
      }
    }

    return retorno;
  }

  private _getSlices(obj: any) {
    let retorno = [];
    if (obj && obj.hasOwnProperty('result')) {
      if (obj.result.hasOwnProperty('slices')) {
        retorno = obj.result.slices;
      } else if (obj.result instanceof Array &&
        obj.result[0].hasOwnProperty('reservation') &&
        obj.result[0].reservation.hasOwnProperty('slices')
      ) {
        retorno = obj.result[0].reservation.slices;
      }
    }

    return retorno;
  }

  setPageViewCheckout(priceContent: Price) {
    //Associar session upsell
    const someObject = {
      [DataLayerCheckout.event]: "pageData",
      [DataLayerCheckout.adultQuantity]: undefined,
      [DataLayerCheckout.orderId]: undefined,
      [DataLayerCheckout.childQuantity]: undefined,
      [DataLayerCheckout.infantQuantity]: undefined,
      [DataLayerCheckout.departureDate]: undefined,
      [DataLayerCheckout.returnDate]: undefined,
      [DataLayerCheckout.originAirport]: undefined,
      [DataLayerCheckout.originCountry]: undefined,
      [DataLayerCheckout.destinationAirport]: undefined,
      [DataLayerCheckout.destinationCountry]: undefined,
      //[DataLayerCheckout.tripType]: "National",
      [DataLayerCheckout.tripType]: undefined,
      [DataLayerCheckout.airlineName]: undefined,
      //[DataLayerCheckout.TravelingDays]: undefined,
      [DataLayerCheckout.duValue]: undefined,
      [DataLayerCheckout.du2Value]: undefined,
      [DataLayerCheckout.totalAirportTaxes]: undefined,
      [DataLayerCheckout.totalWithTax]: undefined,
      [DataLayerCheckout.totalPrice]: undefined,
      [DataLayerCheckout.totalFarePrice]: undefined,
      [DataLayerCheckout.totalCreditCardFee]: undefined,
      [DataLayerCheckout.totalIncentiveValue]: undefined,
      [DataLayerCheckout.chosenFamilyName]: undefined,
      [DataLayerCheckout.upsellValue]: undefined
    };

    if (priceContent && priceContent.result && Array.isArray(priceContent.result) && priceContent.result.length === 0) {
      this.pageViewCheckout(someObject);
      return someObject;
    }

    const passengers = this._getPassengers(priceContent);
    let total_adt = 0;
    let total_chd = 0;
    let total_inf = 0;
    passengers.map(item => {
      total_adt += (item.type === 'ADT' ? 1 : 0);
      total_chd += (item.type === 'CHD' ? 1 : 0);
      total_inf += (item.type === 'INF' ? 1 : 0);
    });

    someObject[DataLayerCheckout.customerEmail] = passengers[0].email;
    someObject[DataLayerCheckout.customerName] = passengers[0].firstName ?
      `${passengers[0].firstName} ${passengers[0].lastName}` : undefined;
    someObject[DataLayerCheckout.customerPhone] = passengers[0].phoneAreaCode ?
      `${passengers[0].phoneAreaCode}${passengers[0].phoneNumber}` : undefined;

    const slices = this._getSlices(priceContent);
    // TODO: No response do booke precisa colocar preencher o conteudo da propriedade  direction
    // slices.map(item => {
    const firstItem = slices[0];
    // if (item['direction'] === 'going' && !someObject[DataLayerCheckout.departureDate]) {
    someObject[DataLayerCheckout.departureDate] = firstItem['departureDate'];
    someObject[DataLayerCheckout.originAirport] = firstItem['origin'];
    someObject[DataLayerCheckout.destinationAirport] = firstItem['destination'];
    // }

    const lastItem = slices.slice(-1)[0];
    // if (item['direction'] === 'return' && !someObject[DataLayerCheckout.returnDate]) {
    if (lastItem) {
      someObject[DataLayerCheckout.returnDate] = lastItem['departureDate'];
    }
    // }
    // });

    let resultContent: any;
    if (priceContent.result instanceof Array && priceContent.result[0].hasOwnProperty('type')) {
      resultContent = priceContent.result[0];
    } else {
      resultContent = priceContent.result;
    }

    someObject[DataLayerCheckout.orderId] = resultContent.hasOwnProperty('orderId') ? resultContent.orderId : undefined;
    someObject[DataLayerCheckout.tripType] = resultContent['type'];
    someObject[DataLayerCheckout.airlineName] = resultContent.hasOwnProperty('reservation')
      ? resultContent['reservation']['slices'][0]['source']
      : slices[0].source;
    someObject[DataLayerCheckout.adultQuantity] = total_adt.toString();
    someObject[DataLayerCheckout.childQuantity] = total_chd.toString();
    someObject[DataLayerCheckout.infantQuantity] = total_inf.toString();

    //someObject[DataLayerCheckout.tripType] = total_inf.toString();
    someObject[DataLayerCheckout.originCountry] = priceContent.meta.airports[someObject[DataLayerCheckout.originAirport]]['country'];
    if (someObject[DataLayerCheckout.destinationAirport]) {
      someObject[DataLayerCheckout.destinationCountry] =
        priceContent.meta.airports[someObject[DataLayerCheckout.destinationAirport]]['country'];
      // tslint:disable-next-line: max-line-length
      // someObject[DataLayerCheckout.tripType] = someObject[DataLayerCheckout.originCountry] !== someObject[DataLayerCheckout.destinationCountry]
      //   ? 'International' : 'National';
    }

    // if (someObject[DataLayerCheckout.departureDate] && someObject[DataLayerCheckout.returnDate]) {
    //   someObject[DataLayerCheckout.TravelingDays] = <string><unknown>this.diffDates(
    //     new Date(someObject[DataLayerCheckout.departureDate]),
    //     new Date(someObject[DataLayerCheckout.returnDate])
    //   );
    // }

    someObject[DataLayerCheckout.duValue] = resultContent.hasOwnProperty("reservation")
      ? resultContent['reservation']['duValue'].toFixed(2)
      : resultContent['duValue'].toFixed(2);
    someObject[DataLayerCheckout.du2Value] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]["du2Value"].toFixed(2)
      : resultContent['du2Value'].toFixed(2);
    someObject[DataLayerCheckout.totalAirportTaxes] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]['totalAirportTaxes']
      : resultContent['totalAirportTaxesPrice'];
    someObject[DataLayerCheckout.totalWithTax] = resultContent['totalWithTax'];
    someObject[DataLayerCheckout.totalPrice] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]["totalPrice"]
      : resultContent["totalPrice"];
    someObject[DataLayerCheckout.totalFarePrice] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]['totalFarePrice']
      : resultContent['totalFarePrice'];
    someObject[DataLayerCheckout.totalCreditCardFee] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]['totalCreditCardFee'].toFixed(2)
      : resultContent['totalCreditCardFee'].toFixed(2);
    someObject[DataLayerCheckout.totalIncentiveValue] = resultContent.hasOwnProperty("reservation")
      ? resultContent["reservation"]["totalIncentiveValue"].toFixed(2)
      : resultContent["totalIncentiveValue"].toFixed(2);
    someObject[DataLayerCheckout.chosenFamilyName] = slices[0]['baseFares'][0]['family'];

    sessionStorage.getItem("upsellPrice")
    ? someObject[DataLayerCheckout.upsellValue] = parseInt(JSON.parse(sessionStorage.getItem("upsellPrice")))
    : someObject[DataLayerCheckout.upsellValue] = 0;


    this.pageViewCheckout(someObject);

    return someObject;
  }

  setPageViewThanks(priceContent: Price) {
    const someObject = {
      [DataLayerCheckout.orderId]: undefined
    };
     let resultContent: any;
    if (priceContent.result instanceof Array && priceContent.result[0].hasOwnProperty('type')) {
      resultContent = priceContent.result[0];
    } else {
      resultContent = priceContent.result;
    }
    someObject[DataLayerCheckout.orderId] = resultContent.hasOwnProperty('orderId') ? resultContent.orderId : undefined;
    this.pageViewCheckout(someObject);
    return someObject;
  }

  private diffDates(date1: Date, date2: Date) {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  getParamsUTM() {
    const utmStorage = this._localStorageService.getItem<any>('utm_storage');
    if (utmStorage && utmStorage['expiry'] && utmStorage['data']) {
      if (moment(utmStorage.expiry).isAfter(moment())) {
        return utmStorage.data;
      }
    }

    return false;
  }
}
