import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { ApiCustomer } from '@zupper/data';
import { CustomerLoginService } from '../../customer-login.service';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'zc-customer-menu',
  templateUrl: './customer-menu.component.html',
  styleUrls: ['./customer-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CustomerMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customer: ApiCustomer;
  @Input() notifications: number = 0;
  @Input() isCheckout: boolean = false;
  isMobile: boolean = false;
  
  private _mediaObserverSubscription$: Subscription;
  
  constructor(
    private _media: MediaObserver,
    private _changeDetectorRef: ChangeDetectorRef,
    private _customerLoginService: CustomerLoginService,
    @Inject('env') private env
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('customer')) {
      this.customer = changes.customer.currentValue;
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this._mediaObserverSubscription$ = this._media
    .asObservable()
    .subscribe((change: MediaChange[]) => {
       const mobile = ['xs', 'sm'];
       if (mobile.includes(change[0].mqAlias)) {
        this.isMobile = true;
       } else {
        this.isMobile = false;
       }
       this._changeDetectorRef.detectChanges();
    });
  }

  goToCustomerSupport(ev: Event): void {
    ev.preventDefault();

    window.open(`${this.env.account.baseUrl}/meus-atendimentos`, '_self');
  }

  goToCustomerOrders(ev: Event): void {
    ev.preventDefault();

    window.open(`${this.env.account.baseUrl}/meus-pedidos`, '_self');
  }

  goToProfile(ev: Event): void {
    ev.preventDefault();
    window.open(`${this.env.account.baseUrl}/perfil`, '_self');
  }

  logout(ev: Event): void {
    ev.preventDefault();
    this._customerLoginService.logout().subscribe(loggedOut => {
      if (loggedOut) {
        window.open(this.env.aerial.url, '_self');
      }
    });
  }

  get iconUser(): string {
    return this.isCheckout && !this.isMobile ? 'assets/img/icon-user-white.svg' : 'assets/img/icon-user.svg';
  }

  ngOnDestroy(): void {
    this._mediaObserverSubscription$.unsubscribe();
  }

}
