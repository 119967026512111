import { Pipe, PipeTransform } from '@angular/core';
import { AvailableFlightsResponse, SliceResponse } from '@zupper/aerial-components';
import { FlightFilterStateService } from '../services/flight-filter-state.service';

@Pipe({
  name: 'airportsFilter'
})
export class AirportsFilterPipe implements PipeTransform {

  constructor(private _flightFilterStateService: FlightFilterStateService) {}
  
  transform(
    originalflightList: Array<Array<AvailableFlightsResponse>>,
    airports: Array<string> = null
  ): Array<Array<AvailableFlightsResponse>> {
    if (airports == null || airports.length == 0) {
      this._flightFilterStateService.setFlightCounter(originalflightList.filter(_x => !!_x));
      return originalflightList;
    }

    let newFlightList: Array<Array<AvailableFlightsResponse>> = [];
    originalflightList.forEach((flights: Array<AvailableFlightsResponse>, index: number) => {
      let returnThisFlight = false;
      flights[0].slices.forEach((slice) => {
        slice.segments.forEach((segment) => {
          if (
            airports.indexOf(segment.originAirport) != -1 ||
            airports.indexOf(segment.destinationAirport) != -1
          ) {
            returnThisFlight = true;
          }
        });
      });

      if (returnThisFlight) {
        newFlightList[index] = originalflightList[index];
      }
    });

    const slicesIndex = airports.map((_time, index) => index);
    this._buildSelectedFlight(newFlightList, slicesIndex);

    return newFlightList;
  }

  private _buildSelectedFlight(flightList: Array<Array<AvailableFlightsResponse>>, slicesIndex: number[] = []): void {
    this._flightFilterStateService.setSelectedFlight(flightList, slicesIndex);
  }
}
